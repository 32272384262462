/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// libraries
import {
    Box,
    Typography,
    Grid,
    Card,
    FormControl,
    FormHelperText,
    ToggleButtonGroup,
    ToggleButton,
    Switch,
    useTheme
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { isArray, isEmpty, has } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import Iconify from '../Iconify';
import { UserSearchDropdown } from '..';
import AssignedToLead from '../AssignedToLead';
import { FormProvider, RHFTextField } from '../hook-form';
import { NormalLoading } from '../loading';
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import TreatmentFlowComponent from '../treatmentComponents/TreatmentFlowComponent';
import DepositPaymentComponent from '../paymentComponents/DepositPaymentComponent';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import {
    updateCompleteStatus,
    updateSteps,
    saveNewLeadDetail,
    UpdateDepositPendingStatus,
    getIndividualTreatment,
    setDepositStatus,
    setAttendedActiveTab,
    setCurrentTreatmentData,
    setTreatmentLoading,
    updateAssignToUser,
} from '../../redux/slices/lead';
import { setDepositData } from '../../redux/slices/deposit';
// hooks/validations/utils
import { useAddTreatment, useLeadTableHelper } from '../../hooks';
import { depositPaymentSchema } from '../../validations/validations';
import { handleGetPaymentTypeData } from '../../utils/depositUtils';
// style
import {
    appointmentGridStyle,
    appointmentGridContainer,
    appointmentCard,
} from '../../styles/DialogueTabs';
import { toggleButtonStyle, appointmentTypoStyle, assignToUserBoxStyle } from '../../styles/Common';
import { helperTextStyle, toggleButtonGroup, toggleButtonIcon, typoStyle } from '../../styles/newAppointmentPopupStyle';

const NewAppointment = forwardRef(({ selectedTreatmentData, popupType, type, handleClose, startTreatment, handleReloadLeads }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practice } = useSelector((state) => state.practice);
    const {
        createLead,
        followLead,
        newLeadDetail: { lead_treatment: leadTreatment },
        currentTreatmentData,
        treatmentLoading,
    } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [assignToState, setAssignToState] = useState([]);
    const [assignToLeadState, setAssignToLeadState] = useState(createLead?.assignees || followLead?.assignees || []);
    const [treatmentData, setTreatment] = useState('');
    const [amountError, setAmountError] = useState(false);
    const [additionalAmountError, setAdditionalAmountError] = useState(false);
    const [depositPaidStatus, setDepositPaidStatus] = useState(false);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const { getSortedDepositPaymentData } = useLeadTableHelper();
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    const getAmount = (index) => getSortedDepositPaymentData(getTreatmentData()?.lead_treatment_payments || [])?.[index]?.amount || 0.00;

    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    const getDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss');

    const getTreatmentData = () => {
        if (!isEmpty(selectedTreatmentData)) return selectedTreatmentData?.treatmentData;
        if (createLead && createLead.lead_treatment && createLead.lead_treatment) return createLead.lead_treatment;
        if (followLead && isArray(followLead.lead_treatments) && followLead.lead_treatments.length > 0) {
            const latestData = followLead.lead_treatments.slice().sort((a, b) =>
                new Date(b.updated_at) - new Date(a.updated_at)
            );
            return latestData;
        }
        return {};
    };

    const [paymentMethod, setPaymentMethod] = useState(handleGetPaymentTypeData({ deposit_payment_data: getTreatmentData()?.lead_treatment_payments }) || '');
    const [additionalAmount, setAdditionalAmount] = useState(getAmount(1));

    useEffect(() => {
        if (isPaymentMandatory && popupType !== 'CreatedLead') {
            const detail = getTreatmentData();
            let id = '';
            if (isArray(detail) && detail.length > 0) {
                id = detail[0]?.id;
            } else if (!isEmpty(detail)) {
                id = detail.id;
            } else {
                return;
            }
            dispatch(setTreatmentLoading(true));
            dispatch(getIndividualTreatment({ leadId: createLead?.id, treatmentId: id }));
        }
        // eslint-disable-next-line consistent-return
        return () => {
            dispatch(setAttendedActiveTab(''));
            dispatch(setCurrentTreatmentData(null));
            dispatch(setDepositStatus(false));
        };
    }, []);

    const getType = (type) => {
        if (type === 'followUp') {
            return 'Yes';
        } if (createLead && createLead.lead_appointment && createLead.lead_appointment.id) {
            return 'Yes';
        }
        return '';
    };

    const getCurrentTreatmentDetail = (value) => {
        if (!isEmpty(leadTreatment?.treatment)) {
            switch (value) {
                case 'treatment':
                    return leadTreatment?.treatment;
                case 'price':
                    return leadTreatment?.custom_price;
                case 'teeth':
                    return leadTreatment?.teethAmount;
                default:
                    return '';
            }
        }
        if (!isEmpty(selectedTreatmentData?.treatmentData)) {
            switch (value) {
                case 'treatment':
                    return selectedTreatmentData?.treatmentData?.treatment;
                case 'price':
                    return selectedTreatmentData?.treatmentData?.price;
                case 'teeth':
                    return selectedTreatmentData?.treatmentData?.leadtreatment_specification?.teeth_count;
                default:
                    return '';
            }
        }
        return '';
    };

    const getTreatmentDetail = (data, value) => {
        if (data?.lead_treatments?.length > 0) {
            const latestData = data.lead_treatments.slice().sort((a, b) =>
                new Date(b.updated_at) - new Date(a.updated_at)
            );
            switch (value) {
                case 'treatment':
                    return latestData?.length > 0 ? latestData[0].treatment : '';
                case 'price':
                    return latestData?.length > 0 ? latestData[0].price : '';
                case 'teeth':
                    return latestData?.length > 0 ? latestData[0].leadtreatment_specification : null;
                default:
                    return '';
            }
        }
        return '';
    };

    const getTeeth = () => {
        if (getCurrentTreatmentDetail('treatment'))
            return getCurrentTreatmentDetail('teeth') || null;
        if (createLead && createLead.lead_treatment && createLead.lead_treatment.treatment)
            return createLead?.lead_treatment?.leadtreatment_specification?.teeth_count || null;
        if (has(leadTreatment, 'teethAmount') && !isEmpty(leadTreatment))
            return leadTreatment?.teethAmount;
        if (followLead && followLead.lead_treatments && getTreatmentDetail(followLead, 'treatment'))
            return getTreatmentDetail(followLead, 'teeth')?.teeth_count || null;
        return null;
    };

    const defaultValues = {
        treatment: getCurrentTreatmentDetail('treatment') || createLead && createLead.lead_treatment && createLead.lead_treatment.treatment || leadTreatment?.treatment || followLead && followLead.lead_treatments && getTreatmentDetail(followLead, 'treatment') || '',
        treatmentValue: getCurrentTreatmentDetail('price') || createLead && createLead.lead_treatment && createLead.lead_treatment.price || leadTreatment?.custom_price || followLead && followLead.lead_treatments && getTreatmentDetail(followLead, 'price') || '',
        teethAmount: getTeeth(),
        radioButton: getType(type),
        appoinmentDate: null,
        bookedWith: [],
        practiceContactedVia: 'PHONE',
        preferredContactMethod: 'IN_PRACTICE',
        appointmentType: 'TREATMENT',
        depositPaidStatus: false,
        paymentMethod: handleGetPaymentTypeData({ deposit_payment_data: getTreatmentData()?.lead_treatment_payments }) || '',
        paymentDate: getTreatmentData()?.lead_treatment_payments?.[0]?.paid_at ? moment(getTreatmentData()?.lead_treatment_payments?.[0]?.paid_at, 'DD-MM-YYYY HH:mm:ss').toDate() : '' || '',
        depositAmount: getAmount(0),
        additionalAmount: getAmount(1),
    };

    const [amount, setAmount] = useState(getCurrentTreatmentDetail('price') || createLead && createLead.lead_treatment && createLead.lead_treatment.price || leadTreatment?.custom_price || followLead && followLead.lead_treatments && getTreatmentDetail(followLead, 'price') || 0.00);
    const [depositAmount, setDepositAmount] = useState(getAmount(0));

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [amount, assignToState, leadTreatment, assignToLeadState]
    );

    const methods = useForm({
        resolver: !isEmpty(paymentMethod) ? yupResolver(depositPaymentSchema) : null,
        defaultValues,
    });

    const {
        control,
        setValue,
        handleSubmit,
        watch
    } = methods;

    const values = watch();

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (defaultValues.treatment) {
            setTreatment(defaultValues.treatment);
            setValue('treatment', defaultValues.treatment);
            setValue('treatmentValue', defaultValues.treatmentValue);
        }
    }, [defaultValues.treatment]);

    useEffect(() => {
        if (createLead) {
            if (createLead.lead_appointment && createLead.lead_appointment.datetime && type !== 'Starting treatment') {
                const date = getDate(createLead.lead_appointment.datetime);
                updateNewStep(date);
            }
        }
    }, [createLead]);

    useEffect(() => {
        if (popupType !== 'CreatedLead' && !isEmpty(currentTreatmentData)) {
            setValue('depositPaidStatus', currentTreatmentData.is_treatment_fee_pending);
            setDepositPaidStatus(!currentTreatmentData.is_treatment_fee_pending);
            dispatch(setDepositStatus(currentTreatmentData.is_treatment_fee_pending));
        } else if (popupType === 'CreatedLead') {
            dispatch(setDepositStatus(false));
        }
    }, [currentTreatmentData]);

    const handleSubmitForDepositPending = async (data) => {
        const detail = getTreatmentData();
        let id = '';
        if (isArray(detail) && detail.length > 0) {
            id = detail[0]?.id;
        } else if (!isEmpty(detail)) {
            id = detail.id;
        }
        const payload = {
            depositAmount: data.depositAmount,
            additionalAmount: data.additionalAmount,
            paymentMethod: data.paymentMethod,
            treatmentId: id,
            isDepositFlow: true,
            teethAmount: data.teethAmount,
            depositPaymentData: getSortedDepositPaymentData(getTreatmentData()?.lead_treatment_payments || [])
        };
        if (data.paymentDate) {
            payload.paymentDate = moment(data.paymentDate).format('YYYY-MM-DD HH:mm:ss');
        }
        dispatch(setDepositData(payload));
    };

    const onSubmit = async (data, status) => {
        if (!isEmpty(data.paymentMethod)) {
            const isPaymentMethodIn = ['DEPOSIT_AND_GOCARDLESS', 'DEPOSIT_AND_STRIPE', 'DEPOSIT_AND_FINANCE', 'DEPOSIT_AND_BALANCE'].includes(data.paymentMethod);
            const isAdditionalAmountEmpty = data.additionalAmount === 0 || data.additionalAmount === '0.00' || data.additionalAmount === undefined;
            if ((data.depositAmount === 0 || data.depositAmount === '0.00' || data.depositAmount === undefined)) {
                setAmountError(true);
                if (isPaymentMethodIn && isAdditionalAmountEmpty) {
                    setAdditionalAmountError(true);
                    return;
                }
                return;
            }
            if (isPaymentMethodIn && isAdditionalAmountEmpty) {
                setAdditionalAmountError(true);
                return;
            }
            handleSubmitForDepositPending(data);
        } else {
            dispatch(setDepositData(null));
        }
        if (isPaymentMandatory === false && popupType !== 'CreatedLead' && data.depositPaidStatus) {
            handleClose();
            return;
        }
        if (type === 'Starting treatment') {
            const updatedTreatment = {
                treatment: data.treatment,
                custom_price: Number(amount),
                teethAmount: data.teethAmount,
            };
            if (isPaymentMandatory && popupType === 'CreatedLead') {
                updatedTreatment.is_treatment_fee_pending = !data.depositPaidStatus;
            }
            const appointmentDetail = {
                datetime: data.appoinmentDate ? `${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` : null,
                note: data.notes || '',
                assignees: assignToState?.map((el) => el?.id),
            };
            const mapping = {
                practiceContactedVia: 'contacted_via',
                preferredContactMethod: 'method',
                appointmentType: 'type'
            };
            Object.keys(mapping).forEach(key => {
                if (data[key]) {
                    appointmentDetail[mapping[key]] = data[key] || '';
                }
            });
            dispatch(saveNewLeadDetail({
                startTreatment: {
                    appointmentDetail,
                    updatedTreatment
                }
            }));
            const AssignData = {
                assignees: assignToLeadState?.map((el) => el?.id) || [],
            };
            if (createLead?.id || followLead?.id) {
                dispatch(updateAssignToUser(AssignData, createLead?.id || followLead?.id, practiceId, handleSuccess));
            }
            if (status === 'finish') {
                dispatch(updateCompleteStatus(true));
                return;
            }
            handleClose();
        }
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    const updateNewStep = (value) => {
        if (type !== 'Starting treatment') {
            const attaendence = new Date(value) > new Date();
            dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
        }
    };

    const handleChangeDepositAmount = (value, name) => {
        setValue(name, value);
        if (name === 'additionalAmount') {
            setAdditionalAmount(value);
            if (value > 0) {
                setAdditionalAmountError(false);
                return;
            }
            setAdditionalAmountError(true);
            return;
        }
        setDepositAmount(value);
        if (value > 0) {
            setAmountError(false);
            return;
        }
        setAmountError(true);
    };

    const handleDepositPendingChange = (e) => {
        setDepositPaidStatus(e.target.checked);
        setValue('depositPaidStatus', e.target.checked);
        dispatch(setDepositStatus(e.target.checked));
        if (popupType === 'CreatedLead') return;
        const detail = getTreatmentData();
        let treatment = '';
        let id = '';
        if (isArray(detail) && detail.length > 0) {
            treatment = detail[0]?.treatment;
            id = detail[0]?.id;
        } else if (!isEmpty(detail)) {
            treatment = detail.treatment;
            id = detail.id;
        } else {
            return;
        }
        const data = {
            leadId: createLead?.id,
            treatmentId: id,
            payload: {
                treatment,
                is_treatment_fee_pending: !e.target.checked
            }
        };
        dispatch(setTreatmentLoading(true));
        dispatch(UpdateDepositPendingStatus(data, handleReloadLeads));
    };

    const shouldRenderForm = () => {
        if (isPaymentMandatory === false) return true;
        return depositPaidStatus;
    };

    const handleChangePaymentMethod = (event) => {
        setValue('paymentMethod', event.target.value);
        setPaymentMethod(event.target.value);
        const depositedAmount = getAmount(0);
        const additionalDepositedAmount = getAmount(1);
        if (handleGetPaymentTypeData({ deposit_payment_data: getTreatmentData()?.lead_treatment_payments }) === event.target.value) {
            setValue('depositAmount', depositedAmount);
            setValue('additionalAmount', additionalDepositedAmount);
            setDepositAmount(depositedAmount);
            setAdditionalAmount(additionalDepositedAmount);
            return;
        }
        setValue('depositAmount', 0.0);
        setValue('additionalAmount', 0.0);
        setDepositAmount(0.00);
        setAdditionalAmount(0.00);
    };

    const handleClickAwayLister = () => {
        onCloseEditTreatment();
    };

    const handleSaveTreatment = (data) => {
        setTreatment(data.selectedTreatment);
        setAmount(data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const handleChange = (e, value, field) => {
        if (value === null) {
            field.onChange('');
            return;
        }
        field.onChange(value);
    };

    const amountHandleChange = (value) => {
        setAmount(value);
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        setTeethAmountError(value === 0);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={appointmentCard}>
                <NormalLoading tableData={treatmentLoading && 'No Found'} pageCount={treatmentLoading && 'No Found'} />
                {isPaymentMandatory && <Box sx={{ mb: 2 }}>
                    <FormControl sx={{ mb: 2 }}>
                        <Typography
                            variant='subtitle2'
                            sx={{ m: '0 0 10px', fontSize: '18px', fontWeight: 'bold' }}
                        >
                            Deposit Paid?
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>No</Typography>
                            <Switch
                                checked={depositPaidStatus}
                                onChange={(e) => {
                                    handleDepositPendingChange(e);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography>Yes</Typography>
                        </Box>
                    </FormControl>
                </Box>}
                {shouldRenderForm() && <>
                    <Grid container spacing={1} sx={appointmentGridContainer(type)}>
                        {EditTreatment && (
                            <TreatmentEditPopover
                                treatment={treatmentData || ''}
                                leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                                open={EditTreatment}
                                anchorEl={anchorEditTreatment}
                                handleClickAwayLister={handleClickAwayLister}
                                treatmentPrice={amount}
                                handleSaveTreatment={handleSaveTreatment}
                                isFlow
                            />
                        )}
                        <TreatmentFlowComponent treatmentData={treatmentData} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={amount} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                    </Grid>
                    <Typography variant='h6' sx={appointmentTypoStyle}>
                        How was it paid? (optional)
                    </Typography>
                    <DepositPaymentComponent
                        paymentMethod={paymentMethod}
                        isPaymentMandatory={isPaymentMandatory}
                        handleChangePaymentMethod={handleChangePaymentMethod}
                        handleChangeDepositAmount={handleChangeDepositAmount}
                        depositAmount={depositAmount}
                        amountError={amountError}
                        additionalAmountError={additionalAmountError}
                        depositPaidStatus={depositPaidStatus}
                        control={control}
                        additionalAmount={additionalAmount}
                    />
                    <Box sx={{ mt: 3 }}>
                        <Grid sx={appointmentGridStyle}>
                            <Box>
                                <Typography variant='h6' sx={appointmentTypoStyle}>
                                    Schedule a new appointment (optional)
                                </Typography>
                                <Controller
                                    name='appoinmentDate'
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <>
                                            <DateTimePicker
                                                label={'Appointment date'}
                                                format='dd/MM/yyyy HH:mm'
                                                ampm={false}
                                                value={field.value || null}
                                                onChange={(newValue) => {
                                                    field.onChange(newValue);
                                                }}
                                                minDateTime={startTreatment === 'StartTreatment' ? '' : new Date()}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        onKeyDown: (e) => e.preventDefault()
                                                    },
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 3 }}>
                                <Box>
                                    <Typography variant='h6' sx={appointmentTypoStyle}>Booked with</Typography>
                                    <Box sx={assignToUserBoxStyle}>
                                        <UserSearchDropdown
                                            name={'bookedWith'}
                                            control={control}
                                            assignToState={assignToState}
                                            setAssignTo={setAssignToState}
                                        />
                                    </Box>
                                </Box>
                                {(createLead?.id || followLead?.id) && <Box>
                                    <Typography variant='h6' sx={appointmentTypoStyle}>Assigned to</Typography>
                                    <Box sx={assignToUserBoxStyle}>
                                        <AssignedToLead
                                            name={'assignedTo'}
                                            control={control}
                                            assignToState={assignToLeadState}
                                            setAssignTo={setAssignToLeadState}
                                        />
                                    </Box>
                                </Box>}
                            </Box>
                        </Grid>
                        <Box sx={{ ...appointmentGridStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                            <Controller
                                name='preferredContactMethod'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <Typography
                                            variant='subtitle2'
                                            sx={typoStyle}
                                        >
                                            Appointment method
                                        </Typography>
                                        <ToggleButtonGroup
                                            color='primary'
                                            exclusive
                                            value={field.value}
                                            onChange={(e, value) => handleChange(e, value, field)}
                                            aria-label='Platform'
                                            sx={toggleButtonGroup}
                                            error={error && !field.value?.length}
                                        >
                                            {appointmentMethod?.map((options, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    value={options.value}
                                                    sx={toggleButtonStyle(isLight)}
                                                >
                                                    <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ ...toggleButtonIcon({ field, options }) }} />{options.label}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {error && !field.value?.length &&
                                            <FormHelperText error sx={helperTextStyle}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name='appointmentType'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <Typography
                                            variant='subtitle2'
                                            sx={typoStyle}
                                        >
                                            Appointment type
                                        </Typography>
                                        <ToggleButtonGroup
                                            color='primary'
                                            exclusive
                                            value={field.value}
                                            onChange={(e, value) => handleChange(e, value, field)}
                                            aria-label='Platform'
                                            sx={toggleButtonGroup}
                                            error={error && !field.value?.length}
                                        >
                                            {appointmentType?.map((options, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    value={options.value}
                                                    sx={toggleButtonStyle(isLight)}
                                                >
                                                    <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ ...toggleButtonIcon({ field, options }) }} />{options.label}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {error && !field.value?.length &&
                                            <FormHelperText error sx={helperTextStyle}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name='practiceContactedVia'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <Typography
                                            variant='subtitle2'
                                            sx={typoStyle}
                                        >
                                            Contact method
                                        </Typography>
                                        <ToggleButtonGroup
                                            color='primary'
                                            exclusive
                                            value={field.value}
                                            onChange={(e, value) => handleChange(e, value, field)}
                                            aria-label='Platform'
                                            sx={toggleButtonGroup}
                                            error={error && !field.value?.length}
                                        >
                                            {contactMethod?.map((options, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    value={options.value}
                                                    sx={toggleButtonStyle(isLight)}
                                                >
                                                    <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ ...toggleButtonIcon({ field, options }) }} />{options.label}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {error && !field.value?.length &&
                                            <FormHelperText error sx={helperTextStyle}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </Box>
                        <Box>
                            <RHFTextField
                                sx={{ width: { xs: '100%', md: '100%' } }}
                                name='notes' multiline rows={4}
                                label='Appointment notes'
                                placeholder='Add notes here'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                    </Box>
                </>}
            </Card>
        </FormProvider>
    );
});

NewAppointment.propTypes = {
    type: PropTypes.string,
    popupType: PropTypes.string,
    handleClose: PropTypes.func,
    startTreatment: PropTypes.string,
    handleReloadLeads: PropTypes.func,
    selectedTreatmentData: PropTypes.object
};

export default NewAppointment;