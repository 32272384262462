/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useForm, Controller } from 'react-hook-form';
// libraries
import { Box, Grid, Button, Typography, Container, Card, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
// components
import Iconify from '../Iconify';
import { useSettingsContext } from '../settings/context';
import SimilarLeadWarningPopup from '../../sections/@dashboard/leadDetail/SimilarLeadWarningPopup';
import { FormProvider, RHFTextField } from '../hook-form';
import { Archive, FollowUp, TabsComponents, NewAppointment, TreatmentComplete } from '../tabs';
import AssignedUsersTooltip from '../AssignedUsersTooltip';
import ClinCheckForm from '../clinCheck/ClinCheckForm';
// store
import { clearClinCheckData, getClinCheckList } from '../../redux/slices/clincheck';
import { useSelector, dispatch } from '../../redux/store';
import { updateError } from '../../redux/slices/user';
import { saveNewLeadDetail, getAppointmentNotes } from '../../redux/slices/lead';
// constant/utils/hooks
import { useTabs } from '../../hooks';
import { Assessments as assessments, Clinical as clinical } from '../../constants/attendedAppointMentConst';
import { getTreatmentDate } from '../../utils/multiTreatmentFlow';
import { isDisabledTabForFinishButton } from '../../utils/clinCheckUtils';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
// style
import { common, primary } from '../../theme/palette';
import {
    leadFooterButtonsStyle,
    attendedTitleStyle,
    attendedCardStyle,
    attachmentTitleStyle,
    attendedGridStyle,
    attendedTabsTitle,
    attendedFooterStyle,
    btnStyle,
    labelTextStyle,
    changeButtonStyle,
    cardWrapper,
    typoWrapper,
    typoStyle,
    normalTypoStyle,
    widthPadding
} from '../../styles/AttendedAppointment';
import TCOScript from './TCOScript';
import AssignedUsersShow from '../AssignedUsersShow';

const ConsultationAttendedAppointment = ({ selectedTreatmentData, handleReloadLeads, type, inputRef, handleClose, user, startTreatment, OpenAssignTask, editDetailHandler, handleCloseAndReload }) => {
    const { isLoader } = useSelector((state) => state.loaderReducer);
    const settings = useSettingsContext();
    const { currentTab, onChangeTab } = useTabs('');
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practice } = useSelector((state) => state.practice);
    const { followLead, appointmentNotes, currentTreatmentData } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { clinCheckListing } = useSelector((state) => state.clinCheck);
    const clinCheckData = clinCheckListing?.results?.[0];
    const { enqueueSnackbar } = useSnackbar();
    const [active, setActive] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [depositPendingStatus, setDepositPendingStatus] = useState(false);
    const followLeadFilterAttend = followLead?.lead_appointment?.filter((el) => el.status === 'ATTENDED');
    const leadId = followLead?.id || user?.lead_id || user?.id;
    const treatmentId = selectedTreatmentData?.treatmentData?.id || followLead?.lead_treatments?.[0]?.id || user?.treatment_id || user?.lead_treatments?.[0]?.id;

    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    const [assigneeSelected, setAssigneeSelected] = useState(followLead?.assignees || []);

    useEffect(() => {
        const data = {
            leadId,
            treatmentId,
            practiceId
        };
        dispatch(clearClinCheckData());
        dispatch(getClinCheckList(data));
    }, []);

    useEffect(() => {
        if (type !== 'CreatedLead' && !isEmpty(currentTreatmentData)) {
            setDepositPendingStatus(currentTreatmentData.is_treatment_fee_pending);
        }
    }, [currentTreatmentData]);

    const getFieldsValue = (value) => {
        if (appointmentNotes.length > 0) {
            switch (value) {
                case 'is_clinical_photographs_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_clinical_photographs_taken || false;
                case 'is_oral_health_assessment_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_oral_health_assessment_taken || false;
                case 'is_xray_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_xray_taken || false;
                case 'is_digital_scan_taken':
                    return appointmentNotes[0].assessment_attachments[0].is_digital_scan_taken || false;
                case 'is_impressions_taken':
                    return appointmentNotes[0].assessment_attachments[0].is_impressions_taken || false;
                case 'is_treatment_options_discussed':
                    return appointmentNotes[0].assessment_attachments[0].is_treatment_options_discussed || false;
                case 'is_treatment_plan_confirmed':
                    return appointmentNotes[0].assessment_attachments[0].is_treatment_plan_confirmed || false;
                case 'is_finance_options_discussed':
                    return appointmentNotes[0].assessment_attachments[0].is_finance_options_discussed || false;
                case 'notes':
                    return appointmentNotes[0].additional_notes || '';
                default:
                    return false;
            }
        }
        return false;
    };

    const defaultValues = {
        is_clinical_photographs_taken: getFieldsValue('is_clinical_photographs_taken') || false,
        is_oral_health_assessment_taken: getFieldsValue('is_oral_health_assessment_taken') || false,
        is_xray_taken: getFieldsValue('is_xray_taken') || false,
        is_digital_scan_taken: getFieldsValue('is_digital_scan_taken') || false,
        is_impressions_taken: getFieldsValue('is_impressions_taken') || false,
        is_treatment_options_discussed: getFieldsValue('is_treatment_options_discussed') || false,
        is_treatment_plan_confirmed: getFieldsValue('is_treatment_plan_confirmed') || false,
        is_finance_options_discussed: getFieldsValue('is_finance_options_discussed') || false,
        notes: getFieldsValue('notes') || ''
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        control,
        reset,
        handleSubmit
    } = methods;

    const appointmentNotesUpdate = async (data, noteData, callAppointmentNote) => {
        if (data?.length > 0) {
            const noteDetail = {
                additional_notes: noteData.notes,
                clinical_attachments: [
                    {
                        is_clinical_photographs_taken: noteData.is_clinical_photographs_taken,
                        is_oral_health_assessment_taken: noteData.is_oral_health_assessment_taken,
                        is_xray_taken: noteData.is_xray_taken
                    }
                ],
                assessment_attachments: [
                    {
                        is_digital_scan_taken: noteData.is_digital_scan_taken,
                        is_impressions_taken: noteData.is_impressions_taken,
                        is_treatment_options_discussed: noteData.is_treatment_options_discussed,
                        is_treatment_plan_confirmed: noteData.is_treatment_plan_confirmed,
                        is_finance_options_discussed: noteData.is_finance_options_discussed
                    }
                ]
            };
            dispatch(saveNewLeadDetail({ attentendAppoiment: { noteDetail, activeTab, callAppointmentNote } }));
            await inputRef.current.submit('finish');
        }
    };

    const onSubmit = async (data) => {
        if (!activeTab) {
            dispatch(updateError({ message: 'Please select any one Tab', success: false }));
            return;
        }
        const callAppointmentNote = JSON.stringify(data) !== JSON.stringify(defaultValues);
        appointmentNotesUpdate(followLead?.lead_appointment, data, callAppointmentNote);
    };

    const handleNext = async () => {
        handleSubmit(onSubmit)();
    };

    const PROFILE_TABS = [
        {
            value: 'Starting treatment',
            icon: <Iconify icon={'bxs:flag'} width={20} height={20} />,
            component: <NewAppointment selectedTreatmentData={selectedTreatmentData} ref={inputRef} type={type} rowId={user.lead_id} handleClose={handleClose} startTreatment={startTreatment} handleReloadLeads={handleReloadLeads} />,
        },
        {
            value: 'ClinCheck',
            icon: <Iconify icon={'fluent:check-20-filled'} width={20} height={20} />,
            component: <ClinCheckForm ref={inputRef} handleClose={handleClose} handleCloseAndReload={handleCloseAndReload} leadDetail={user} currentTab={currentTab} onChangeTab={onChangeTab} />,
        },
        {
            value: 'Follow Up',
            icon: <Iconify icon={'ant-design:calendar-filled'} width={20} height={20} />,
            component: <FollowUp ref={inputRef} handleClose={handleClose} type="ConsultationAttended" />,
        },
        {
            value: 'Treatment complete',
            icon: <Iconify icon={'akar-icons:circle-check-fill'} width={20} height={20} />,
            component: <TreatmentComplete ref={inputRef} type={type} handleNext={handleNext} handleClose={handleClose} />,
        },
        {
            value: 'Archive',
            icon: <Iconify icon={'eva:archive-fill'} width={20} height={20} />,
            component: <Archive ref={inputRef} handleClose={handleClose} />,
        }
    ];

    const getAppointmentId = () => {
        if (!isEmpty(selectedTreatmentData)) {
            return followLead?.lead_appointment?.find((ele) => ele?.lead_treatment === selectedTreatmentData?.treatmentData?.id)?.id || followLead.lead_appointment.filter((el) => el.status === 'ATTENDED' || el.status === 'CONSULTATION_ATTENDED')?.[0].id;
        }
        return followLead.lead_appointment.filter((el) => el.status === 'ATTENDED' || el.status === 'CONSULTATION_ATTENDED')?.[0].id;
    };

    useEffect(() => {
        if (followLead && followLead.lead_appointment && followLead.lead_appointment.length > 0) {
            const appointmentID = followLead.lead_appointment.filter((el) => el.status === 'ATTENDED' || el.status === 'CONSULTATION_ATTENDED');
            if (appointmentID.length > 0) {
                dispatch(getAppointmentNotes(practiceId, user?.lead_id, getAppointmentId(), handleSuccess));
            }
        }
    }, [followLead]);

    useEffect(() => {
        reset(defaultValues);
        if (getFieldsValue('is_clinical_photographs_taken') ||
            getFieldsValue('is_oral_health_assessment_taken') ||
            getFieldsValue('is_digital_scan_taken') ||
            getFieldsValue('is_xray_taken') ||
            getFieldsValue('is_impressions_taken') ||
            getFieldsValue('is_treatment_options_discussed') ||
            getFieldsValue('is_treatment_plan_confirmed') ||
            getFieldsValue('is_finance_options_discussed') ||
            getFieldsValue('notes')
        ) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }, [appointmentNotes]);

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    useEffect(() => {
        if (startTreatment === 'StartTreatment') {
            setActiveTab('Starting treatment');
        }
    }, [startTreatment]);

    const getSelectedTreatment = () => selectedTreatmentData?.treatmentData?.treatment || followLead?.lead_treatments?.[0]?.treatment;

    const TABS = (
        getSelectedTreatment() === 'Invisalign'
            ? PROFILE_TABS :
            PROFILE_TABS.filter(el => el.value !== 'ClinCheck'));

    const getSelectedTreatmentDateTime = () => {
        const tempDate = followLead?.lead_appointment?.find((ele) => ele?.lead_treatment === selectedTreatmentData?.treatmentData?.id)?.datetime;
        if (!isEmpty(selectedTreatmentData) && tempDate) return getTreatmentDate(tempDate) || '';
        return getTreatmentDate(followLeadFilterAttend?.[0]?.datetime) || '';
    };

    const getCurrentTreatmentAssignees = () => {
        if (!isEmpty(selectedTreatmentData)) return followLead?.lead_appointment?.find((ele) => ele?.lead_treatment === selectedTreatmentData?.treatmentData?.id)?.assignees;
        return followLeadFilterAttend?.[0]?.assignees;
    };

    useEffect(() => {
        if (followLead?.assignees) {
            setAssigneeSelected(followLead.assignees || []);
        }
    }, [followLead]);

    return (
        <Box>
            <Grid container justifyContent='flex-start'>
                <Box sx={attendedTitleStyle}>
                    <Typography variant='h3' sx={{ fontSize: '24px !important', lineHeight: '36px' }}>Attended consultation details</Typography>
                </Box>
            </Grid>
            <SimilarLeadWarningPopup type={'DialogPopup'} dialogClose={() => handleClose()} />
            <TCOScript handleClose={handleClose} handleNext={onSubmit} ref={inputRef} />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {user !== undefined && user && <Box sx={{ m: '30px 0 50px' }}>
                    <Box sx={cardWrapper}>
                        <Box sx={{ ...widthPadding, borderRight: `1px dotted ${common.fadedBlueGray}` }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={labelTextStyle}>Patient details</Typography>
                                <Button sx={changeButtonStyle} onClick={() => editDetailHandler()}><Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} /> Change</Button>
                            </Box>
                            <Box sx={typoWrapper}>
                                <Typography sx={typoStyle}>{capitalizeFirstLetter(followLead?.first_name || '')} {capitalizeFirstLetter(followLead?.last_name || '')}</Typography>
                                <Typography sx={normalTypoStyle}>{followLead?.lead_address && `${followLead?.lead_address?.address_line_1} ${followLead?.lead_address?.address_line_2} ${followLead?.lead_address?.city} ${followLead?.lead_address?.county} ${followLead?.lead_address?.postcode}`}</Typography>
                                <Typography sx={normalTypoStyle}>Phone: {followLead?.phone || followLead?.lead_contact?.phone || ''}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%', p: '0 0 0 40px' }}>
                            <Typography sx={labelTextStyle}>Treatment</Typography>
                            <Box sx={typoWrapper}>
                                <Typography sx={typoStyle}>{getSelectedTreatment() || ''}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={cardWrapper}>
                        <Box sx={{ ...widthPadding, borderRight: `1px dotted ${primary.paperColor}` }}>
                            <Typography sx={labelTextStyle}>Date & time</Typography>
                            <Box sx={typoWrapper}>
                                <Typography sx={typoStyle}>{getSelectedTreatmentDateTime(followLeadFilterAttend)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <AssignedUsersTooltip assignees={getCurrentTreatmentAssignees(followLeadFilterAttend)} handleClick={(e) => OpenAssignTask(user, getCurrentTreatmentAssignees(followLeadFilterAttend), e)} practiceUser={practiceUser} />
                            <AssignedUsersShow assigneeSelected={assigneeSelected} setAssigneeSelected={setAssigneeSelected} leadId={leadId} />

                        </Box>

                    </Box>
                </Box>}
                <Card sx={attendedCardStyle}>
                    <Typography variant='h6' sx={attachmentTitleStyle(active)} onClick={() => setActive(!active)}>
                        Attachments
                        {
                            active ? <Iconify width={20} height={20} icon={'akar-icons:chevron-down'} /> : <Iconify width={20} height={20} icon={'akar-icons:minus'} />
                        }
                    </Typography>
                    {!active ?
                        <Box>
                            <Grid container spacing={1} sx={attendedGridStyle}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                        Assessment attachments
                                    </Typography>
                                    <FormGroup sx={{ gap: 2 }}>
                                        {assessments.map((clinic, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Controller
                                                        control={control}
                                                        name={clinic.value}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                onChange={e => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={clinic.label}
                                                sx={{ width: 'fit-content' }}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                        Clinical attachments
                                    </Typography>
                                    <FormGroup sx={{ gap: 2 }}>
                                        {clinical.map((assessment, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Controller
                                                        control={control}
                                                        name={assessment.value}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                onChange={e => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={assessment.label}
                                                sx={{ width: 'fit-content' }}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <RHFTextField
                                sx={{ width: { xs: '100%', md: '100%' } }}
                                name="notes" multiline rows={5}
                                label="Attachment notes"
                                placeholder="Add notes here"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box> : null
                    }
                </Card>
            </FormProvider>
            <Grid container justifyContent='flex-start' sx={{ mt: 8 }}>
                <Box sx={attendedTabsTitle}>
                    <Typography variant='h6'>Select the outcome of the attended consultation</Typography>
                </Box>
                <TabsComponents TabsContent={TABS} setActiveTab={setActiveTab} activeAttachments={setActive} startTreatment={startTreatment} />
            </Grid>
            <Box sx={attendedFooterStyle}>
                <Container
                    maxWidth={settings.themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <LoadingButton
                        color='primary'
                        variant='contained'
                        loading={isLoader}
                        loadingPosition='start'
                        onClick={() => handleSubmit(onSubmit)()}
                        className='finish-button'
                        sx={btnStyle(type)}
                        disabled={isDisabledTabForFinishButton(clinCheckData?.status, currentTab, activeTab, isPaymentMandatory, depositPendingStatus, type)}
                    >
                        Finish
                    </LoadingButton>
                </Container>
            </Box>
        </Box>
    );
};

ConsultationAttendedAppointment.propTypes = {
    handleReloadLeads: PropTypes.func,
    type: PropTypes.string,
    inputRef: PropTypes.object,
    handleClose: PropTypes.func,
    user: PropTypes.object,
    startTreatment: PropTypes.string,
    OpenAssignTask: PropTypes.func,
    editDetailHandler: PropTypes.func,
    handleCloseAndReload: PropTypes.func,
    selectedTreatmentData: PropTypes.func,
};

export default ConsultationAttendedAppointment;