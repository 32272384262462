import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import { Stack, Box, Button, Table, TableBody, TableContainer, Typography } from '@mui/material';

// components
import { Iconify } from '../../../components';
import { RefundPaymentPopup } from '../../../components/dialogPopUp';
import { TableHeadCustom } from '../../../components/table';
import { TableLoading } from '../../../components/loading';
import { NotFound } from '../../../components/empty-content';
// hooks
import { useRefundPaymentToggle, useTable } from '../../../hooks';
//
import RefundTableRow from './refundTableRow';
// redux
import { getLeadRefundRequest, retryRefundRequest } from '../../../redux/slices/lead';
import { useSelector, dispatch } from '../../../redux/store';
// style
import {
    RefundBoxStyle,
    RefundBackButtonStyle,
    RefundTitleStyle,
    disableRefundButtonStyle,
    RefundButtonStyle,
    PaymentTableStyle
} from '../../../styles/PaymentRefund';
// constant
import { refundTableLabel } from '../../../constants/payment';

// -----------------------------------------------------------
export default function RefundList({ practiceId, leadId, treatmentId, paymentId, checkoutId, setRenderComponent, paymentAmount }) {
    const { refundListing } = useSelector((state) => state.lead);

    const { enqueueSnackbar } = useSnackbar();

    const {
        selected,
        onSort,
        onSelectRow,
    } = useTable();

    const {
        toggle: openRefundPayment,
        onOpen: onOpenRefundPayment,
        onClose: onCloseRefundPayment
    } = useRefundPaymentToggle();

    useEffect(() => {
        if (paymentId) {
            dispatch(getLeadRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentId]);


    const handleBack = () => {
        setRenderComponent('checkoutList');
    };

    const RefundPaymentOpen = () => {
        onOpenRefundPayment();
    };

    const handleRetryClick = (id) => {
        dispatch(retryRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId, id, handleSuccess));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    return (
        <Stack>
            <Box sx={{ position: 'relative' }}>
                <Box sx={RefundBoxStyle}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            color="inherit"
                            variant='outlined'
                            onClick={handleBack}
                            className="back-button"
                            sx={RefundBackButtonStyle}
                        >
                            <Iconify icon={'material-symbols:arrow-back-rounded'} width={18} height={18} />
                        </Button>
                        <Typography variant="h5" sx={RefundTitleStyle}> Refunds</Typography>
                    </Box>
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={RefundPaymentOpen}
                        sx={refundListing?.length > 0 ? disableRefundButtonStyle : RefundButtonStyle}
                    >
                        <Iconify sx={{ mr: '6px' }} icon={'eva:plus-fill'} width={20} height={20} /> Create Refund
                    </Button>
                </Box>
                <TableLoading tableData={!refundListing && 'No Found'} pageCount={refundListing !== undefined ? 0 : 'No Found'} />
                {refundListing?.length > 0 &&
                    <TableContainer sx={PaymentTableStyle}>
                        <Table sx={{ minWidth: { xs: 'auto', sm: 'auto' } }}>
                            <TableHeadCustom
                                headLabel={refundTableLabel}
                                onSort={onSort}
                                rowCount={refundListing.length}
                                numSelected={selected.length}
                            />
                            <TableBody>
                                {refundListing !== undefined && refundListing.map((row, index) => (
                                    <Box sx={{ display: 'contents' }} key={index}>
                                        <RefundTableRow
                                            row={row}
                                            selected={selected.includes(row.id)}
                                            onSelectRow={() => onSelectRow(row.id)}
                                            handleRetryClick={handleRetryClick}
                                        />
                                    </Box>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {refundListing?.length === 0 && <NotFound />}
            </Box>
            <RefundPaymentPopup
                open={openRefundPayment}
                onClose={() => onCloseRefundPayment()}
                leadId={leadId}
                treatmentId={treatmentId}
                paymentId={paymentId}
                checkoutId={checkoutId}
                paymentAmount={paymentAmount}
            />
        </Stack>
    );
}

RefundList.propTypes = {
    practiceId: PropTypes.any,
    leadId: PropTypes.any,
    treatmentId: PropTypes.any,
    paymentId: PropTypes.any,
    checkoutId: PropTypes.any,
    setRenderComponent: PropTypes.any,
    paymentAmount: PropTypes.any,
};