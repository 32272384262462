import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
    Box,
    MenuItem,
    Grid,
    Button,
    ButtonGroup
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useResponsive } from '../../hooks';
import Iconify from '../Iconify';
import UserSearchDropdown from '../UserSearchDropdown';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
import { btnHandler } from '../../utils/reminderSelectedTimeFunc';
import { useSelector, dispatch } from '../../redux/store';
import { addScheduleReminder, saveNewLeadDetail } from '../../redux/slices/lead';
import { ScheduleReminderSchema } from '../../validations/validations';
import {
} from '../../styles/ScheduleReminder';
import {
    FollowUpTitle,
    followUpTabStyle,
    followUpButtonGroupStyle,
    followUpHourButtonStyle,
    followUpTomorrowButtonStyle,
    followUpWeekButtonStyle,
    followUpCustomButtonStyle,
    followUpTwoWeekButtonStyle,
    followUpNoReminderButtonStyle,
    customReminderBoxStyle,
    selectMenuItemStyle,

} from '../../styles/DialogueTabs';

const FollowUp = forwardRef(({ handleClose, type }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { createLead, followLead, tableRowLead } = useSelector((state) => state.lead);
    const { reminderMethod } = useSelector((state) => state.schema);
    const [activeTime, setActiveTime] = useState('custom reminder');

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const defaultValues = {
        reminder: 'CALL',
        reminderDateTime: null,
        assignTo: [],
        ReminderNotes: '',
        otherNotes: '',
        customReminder: '',
        callReminder: ''
    };

    const methods = useForm({
        resolver: yupResolver(ScheduleReminderSchema),
        defaultValues
    });
    const onSubmit = (data) => {
        const leadId = followLead && followLead.id || createLead && createLead.id || '';
        if (data.reminderDateTime) {
            const assignedUser = data.assignTo ? getUserList()
                ?.filter(el => {
                    const user = el?.user;
                    const fullName = `${user?.salutation || ''} ${user?.first_name || ''} ${user?.last_name || ''}`;
                    return data.assignTo.includes(user?.first_name) || data.assignTo.includes(fullName);
                })
                .map(el => el?.id) : [];
            const addReminderObj = {
                method: data.reminder,
                datetime: `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                note: data.ReminderNotes,
                is_completed: false,
                assignees: assignedUser,
                group: 'FOLLOW_UP',
                lead: leadId,
                lead_treatment: type === 'scheduleReminder' ? tableRowLead : tableRowLead?.treatment_id
            };
            dispatch(saveNewLeadDetail({ addReminder: addReminderObj }));
            dispatch(addScheduleReminder(addReminderObj, practiceId, handleSuccess, 'LeadDetail'));
        } else {
            enqueueSnackbar('Please select tab', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    };

    const handleSuccess = (data) => {
        if (data.success) {
            enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
            handleClose();
        }
        if (!data.success) {
            enqueueSnackbar(data.message.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
            handleClose();
        }
    };

    const {
        control,
        setValue,
        clearErrors,
        handleSubmit,
    } = methods;

    useEffect(() => {
        setValue('customReminder', activeTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTime]);

    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);

    const handleButtonClick = (value) => {
        btnHandler({ value, btnValues, reminderMethod });
        setActiveTime(value);
    };

    const isMobile = useResponsive('up', 'sm');

    const btnValues = (reminder, DateTime) => {
        setValue('reminder', reminder);
        setValue('reminderDateTime', DateTime);
        clearErrors('reminder');
        clearErrors('reminderDate');
        clearErrors('reminderDateTime');
    };

    return (
        <Box sx={{ width: '100%', pt: '30px' }}>
            <FollowUpTitle>Add a follow-up reminder</FollowUpTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={followUpTabStyle}>
                    <ButtonGroup
                        orientation={!isMobile ? 'vertical' : 'horizontal'}
                        sx={followUpButtonGroupStyle}
                    >
                        <Button
                            variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('2 hours-call')}
                            sx={followUpHourButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 hours
                        </Button>
                        <Button
                            variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('tomorrow')}
                            sx={followUpTomorrowButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call 9am tomorrow
                        </Button>
                        <Button
                            variant={activeTime === 'week' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('week')}
                            sx={followUpWeekButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
                        </Button>
                        <Button
                            variant={activeTime === 'two-week' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('two-week')}
                            sx={followUpTwoWeekButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
                        </Button>
                        <Button
                            variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('custom reminder')}
                            sx={followUpCustomButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'solar:clock-circle-bold'} width={20} height={20} /> Custom reminder
                        </Button>
                        <Button
                            variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('no-reminder')}
                            sx={followUpNoReminderButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
                        </Button>
                    </ButtonGroup>
                </Box>
                {activeTime === 'custom reminder' ?
                    <Grid item xs={12} md={12}>
                        <Box sx={customReminderBoxStyle}>
                            <RHFSelect
                                name='reminder'
                                label='Reminder method (Required)'
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            >
                                {reminderMethod?.map((options) => (
                                    <MenuItem
                                        key={options.label}
                                        value={options.value}
                                        sx={selectMenuItemStyle}
                                    >
                                        {options.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                <Controller
                                    name='reminderDateTime'
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <DateTimePicker
                                            {...field}
                                            label='Custom date and time (Required)'
                                            value={field.value || null}
                                            format={'dd/MM/yyyy HH:mm'}
                                            ampm={false}
                                            minDateTime={new Date()}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    onKeyDown: (e) => e.preventDefault()
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <UserSearchDropdown name={'assignTo'} type="reminder" control={control} label='Assign to (Required)' />
                        </Box>
                    </Grid>
                    : null
                }
                <RHFTextField
                    sx={{ width: '100%', mt: 0 }}
                    name="ReminderNotes" multiline rows={4}
                    label="Reminder notes"
                    placeholder="Add notes here"
                    InputLabelProps={{ shrink: true }}
                />
            </FormProvider>
        </Box>
    );
});

FollowUp.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.any,
};

export default FollowUp;