import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useParams } from 'react-router';
import { useImperativeHandle, forwardRef, useEffect } from 'react';
import {
    useForm,
    Controller
} from 'react-hook-form';
// @mui
import {
    Box,
    Grid,
    Typography,
    Card,
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from './hook-form';
// constant
import { Assessments as assessments, Clinical as clinical } from '../constants/attendedAppointMentConst';
// store
import { useSelector, dispatch } from '../redux/store';
import { saveNewLeadDetail, getAppointmentNotes, addAppointmentNotes, updateAppointmentNotes } from '../redux/slices/lead';
// style
import {
    attendedTitleStyle,
    attendedCardStyle,
    attendedGridStyle,
} from '../styles/AttendedAppointment';

// -------------------------------------------------------------------------------------------------------
const AttachmentAppointment = forwardRef(({ handleClose, appoinmentID }, ref) => {
    const { appointmentNotes } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getFieldsValue = (value) => {
        if (appointmentNotes.length > 0) {
            switch (value) {
                case 'is_clinical_photographs_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_clinical_photographs_taken || false;
                case 'is_oral_health_assessment_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_oral_health_assessment_taken || false;
                case 'is_xray_taken':
                    return appointmentNotes[0].clinical_attachments[0].is_xray_taken || false;
                case 'is_digital_scan_taken':
                    return appointmentNotes[0].assessment_attachments[0].is_digital_scan_taken || false;
                case 'is_impressions_taken':
                    return appointmentNotes[0].assessment_attachments[0].is_impressions_taken || false;
                case 'is_treatment_options_discussed':
                    return appointmentNotes[0].assessment_attachments[0].is_treatment_options_discussed || false;
                case 'is_treatment_plan_confirmed':
                    return appointmentNotes[0].assessment_attachments[0].is_treatment_plan_confirmed || false;
                case 'is_finance_options_discussed':
                    return appointmentNotes[0].assessment_attachments[0].is_finance_options_discussed || false;
                case 'additional_notes':
                    return appointmentNotes[0].additional_notes || false;
                default:
                    return false;
            }
        }
        return false;
    };

    useEffect(() => {
        if (appointmentNotes) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentNotes]);

    const defaultValues = {
        is_clinical_photographs_taken: getFieldsValue('is_clinical_photographs_taken') || false,
        is_oral_health_assessment_taken: getFieldsValue('is_oral_health_assessment_taken') || false,
        is_xray_taken: getFieldsValue('is_xray_taken') || false,
        is_digital_scan_taken: getFieldsValue('is_digital_scan_taken') || false,
        is_impressions_taken: getFieldsValue('is_impressions_taken') || false,
        is_treatment_options_discussed: getFieldsValue('is_treatment_options_discussed') || false,
        is_treatment_plan_confirmed: getFieldsValue('is_treatment_plan_confirmed') || false,
        is_finance_options_discussed: getFieldsValue('is_finance_options_discussed') || false,
        notes: getFieldsValue('additional_notes') || ''
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        control,
        reset,
        handleSubmit
    } = methods;

    const appointmentNotesUpdate = async (noteData, callAppointmentNote) => {
        const noteDetail = {
            additional_notes: noteData.notes,
            clinical_attachments: [
                {
                    is_clinical_photographs_taken: noteData.is_clinical_photographs_taken,
                    is_oral_health_assessment_taken: noteData.is_oral_health_assessment_taken,
                    is_xray_taken: noteData.is_xray_taken
                }
            ],
            assessment_attachments: [
                {
                    is_digital_scan_taken: noteData.is_digital_scan_taken,
                    is_impressions_taken: noteData.is_impressions_taken,
                    is_treatment_options_discussed: noteData.is_treatment_options_discussed,
                    is_treatment_plan_confirmed: noteData.is_treatment_plan_confirmed,
                    is_finance_options_discussed: noteData.is_finance_options_discussed
                }
            ]
        };
        dispatch(saveNewLeadDetail({ attentendAppoiment: { noteDetail, callAppointmentNote } }));
        if (appointmentNotes.length === 0) {
            dispatch(addAppointmentNotes(practiceId, id, appoinmentID, noteDetail, handleClose));
        } else {
            dispatch(updateAppointmentNotes(practiceId, id, appoinmentID, appointmentNotes, noteDetail, handleClose));
        }
    };

    const onSubmit = (data) => {
        const callAppointmentNote = JSON.stringify(data) !== JSON.stringify(defaultValues);
        if (callAppointmentNote) {
            appointmentNotesUpdate(data, callAppointmentNote);
        } else {
            reset(defaultValues);
            handleClose();
        }
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getAppointmentNotes(practiceId, id, appoinmentID, handleSuccess));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    return (
        <Box>
            <Grid container justifyContent='flex-start'>
                <Box sx={attendedTitleStyle}>
                    <Typography variant='h3' sx={{ fontSize: '24px !important', lineHeight: '36px' }}>Attachments</Typography>
                </Box>
            </Grid>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card sx={attendedCardStyle}>
                    <Box>
                        <Grid container sx={{ ...attendedGridStyle, mt: '0' }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                    Assessment attachments
                                </Typography>
                                <FormGroup sx={{ gap: 2 }}>
                                    {assessments.map((clinic, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Controller
                                                    control={control}
                                                    name={clinic.value}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            onChange={e => {
                                                                field.onChange(e.target.checked);
                                                            }}
                                                            checked={field.value}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={clinic.label}
                                            sx={{ width: 'fit-content' }}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                    Clinical attachments
                                </Typography>
                                <FormGroup sx={{ gap: 2 }}>
                                    {clinical.map((assessment, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Controller
                                                    control={control}
                                                    name={assessment.value}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            onChange={e => {
                                                                field.onChange(e.target.checked);
                                                            }}
                                                            checked={field.value}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={assessment.label}
                                            sx={{ width: 'fit-content' }}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <RHFTextField
                            sx={{ width: { xs: '100%', md: '100%' } }}
                            name="notes" multiline rows={5}
                            label="Attachment notes"
                            placeholder="Add notes here"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Card>
            </FormProvider>
        </Box>
    );
});

AttachmentAppointment.propTypes = {
    handleClose: PropTypes.func,
    appoinmentID: PropTypes.any,
};

export default AttachmentAppointment;