import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useParams } from 'react-router';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import { useAuth } from '../../hooks';
// style
import { ContentStyle } from '../../styles/ConfirmEmail';

// -------------------------------------------------------------------

export default function ConfirmEmail() {
    const { keyId } = useParams();
    const { confirmemail } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const data = {
        key: keyId
    };

    const emailVerification = (message) => {
        setShowError(true);
        if (message.error) {
            setError(true);
            enqueueSnackbar(message.error, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        } else {
            setError(false);
            enqueueSnackbar('Email confirm successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        }
    };

    useEffect(() => {
        if (data) {
            confirmemail(data, emailVerification);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> Confirm Email | Dental SEM CRM </title>
            </Helmet>
            <Container>
                <ContentStyle sx={{ textAlign: 'center' }}>
                    {showError && <Typography variant="h3" paragraph>
                        {error ? 'Email confirmation failed' : 'Email confirmation successful'}
                    </Typography>}
                    {showError && <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                        {error ? 'Please try it again.' : 'Now you can login with your email address'}
                    </Typography>}
                </ContentStyle>
            </Container>
        </>
    );
}
