import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui 
import {
    Card,
    Typography,
    TableRow,
    Divider,
    TableCell,
    Box,
    Button,
    ButtonGroup,
    Grid,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    Switch,
    FormControlLabel,
    Table,
    TableBody,
    ListItemText
} from '@mui/material';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// components
// import useSnackbar from 'src/hooks/useCustomSnackbar';;
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import { TableHeadCustom, TableMoreMenu } from '../../../../components/table';
import { Iconify, UserSearchDropdown } from '../../../../components';
import { btnHandler } from '../../../../utils/reminderSelectedTimeFunc';
// hooks
import { useTable, useResponsive } from '../../../../hooks';
import { useSelector, dispatch } from '../../../../redux/store';
import { updateCompleteStatus, saveNewLeadDetail, getAutoMatedMessages, AutoMatedMessageStatusUpdate } from '../../../../redux/slices/lead';
import {
    AutomatedMessages,
} from '../../leadDetail';
//  style
import {
    followUpTabStyle,
    followUpButtonGroupStyle,
    followUpHourButtonStyle,
    followUpTomorrowButtonStyle,
    followUpWeekButtonStyle,
    followUpCustomButtonStyle,
    followUpNoReminderButtonStyle,
    customReminderBoxStyle,
    selectMenuItemStyle,
    followUpTwoWeekButtonStyle,
    MessageBoxTreatmentHeading,
    MessageBox,
    AutomatedMessage,
    MessageTableContainer
} from '../../../../styles/DialogueTabs';
// constant
import { tableData, TABLE_HEAD } from '../../../../constants/FollowUpConst';

// ----------------------------------------------------------------

const MessageAndReminder = forwardRef(({ handleNext, type, row }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();
    const {
        newLeadDetail,
        autoMatedMessages
    } = useSelector((state) => state.lead);
    const { reminderMethod } = useSelector((state) => state.schema);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const [sentTasks, setSentTasks] = useState([]);
    const [upcommingTask, setUpcommingTask] = useState([]);
    const [failedTasks, setFailedTasks] = useState([]);
    const id = row?.lead_id;

    const [activeTime, setActiveTime] = useState('custom reminder');

    const isMobile = useResponsive('up', 'sm');

    const [AccordionActive, setAccordionActive] = useState(true);

    useEffect(() => {
        if (autoMatedMessages.results) {
            setSentTasks(autoMatedMessages?.results.filter((el) => el?.is_enqueued));
            setUpcommingTask(autoMatedMessages?.results.filter((el) => !el?.is_enqueued && !el?.is_cancelled));
            setFailedTasks(autoMatedMessages?.results.filter((el) => el?.is_cancelled));
        }
    }, [autoMatedMessages]);

    useEffect(() => {
        if (practiceId && type && type !== 'CreatLead') {
            dispatch(getAutoMatedMessages(practiceId, id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    const defaultValues = {
        reminder: '',
        reminderTime: null,
        reminderDate: null,
        reminderDateTime: null,
        assignTo: [],
        addNote: '',
    };

    const {
        order,
        orderBy,
        selected,
        onSelectAllRows,
        onSort,
    } = useTable();

    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);

    const handleButtonClick = (value) => {
        btnHandler({ value, btnValues, reminderMethod });
        setActiveTime(value);
    };

    const onSubmit = (data, status) => {
        const assignedUser = data.assignTo ? getUserList()
            ?.filter(el => {
                const user = el?.user;
                const fullName = `${user?.salutation || ''} ${user?.first_name || ''} ${user?.last_name || ''}`;
                return data.assignTo.includes(user?.first_name) || data.assignTo.includes(fullName);
            })
            .map(el => el?.id) : [];
        const addReminderObj = {
            method: data.reminder,
            datetime: `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            note: data.addNote,
            is_completed: false,
            assignees: assignedUser,
            group: 'FOLLOW_UP',
        };
        dispatch(saveNewLeadDetail({ lead_reminder: addReminderObj }));
        if (status === 'finish') {
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const {
        control,
        setValue,
        clearErrors,
        handleSubmit,
    } = methods;

    const btnValues = (reminder, reminderDateTime) => {
        setValue('reminder', reminder);
        setValue('reminderDateTime', reminderDateTime);
        clearErrors('reminder');
        clearErrors('reminderDate');
        clearErrors('reminderDateTime');
    };

    useImperativeHandle(
        ref, () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [openMenu, setOpenMenuActions] = useState(null);

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleAccordion = () => {
        setAccordionActive(!AccordionActive);
    };

    const onUpdateStatus = (value, taskId) => {
        const detail = {
            'is_cancelled': value
        };
        dispatch(AutoMatedMessageStatusUpdate(practiceId, id, taskId, detail, handleSuccess));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3, boxShadow: 'none' }}>
                <Typography variant='h4' sx={{ marginBottom: '15px' }}>
                    Messages and reminders
                </Typography>

                <Divider sx={{ mb: '20px' }} />

                <Box sx={{ display: 'flex', mb: 6 }} >
                    <Box sx={MessageBox} >
                        <Typography variant='Overline' sx={MessageBoxTreatmentHeading}>
                            Treatment
                        </Typography>
                        <Typography variant='Body2' sx={{ fontSize: '14px' }}>
                            {newLeadDetail && newLeadDetail.lead_treatment && newLeadDetail.lead_treatment.treatment}
                        </Typography>
                    </Box>
                    <Box sx={{ ...MessageBox, textTransform: 'capitalize' }}>
                        <Typography variant='Overline' sx={MessageBoxTreatmentHeading}>
                            Appointment date & Time
                        </Typography>
                        <Typography variant='Body2' sx={{ fontSize: '14px' }}>
                            {newLeadDetail && newLeadDetail.lead_appointment && moment(newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.datetime, 'DD/MM/YYYY hh:mm:ss').format('DD MMM YYYY hh:mm A')}
                        </Typography>
                    </Box>
                </Box>
                {type && type !== 'CreatLead' && <AutomatedMessages sentTasks={sentTasks} upcommingTask={upcommingTask} failedTasks={failedTasks} onUpdateStatus={onUpdateStatus} />}
                <Box sx={AutomatedMessage}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h6'>
                            Message Log
                        </Typography>
                        <Typography variant='Body2' sx={{ color: 'grey.600' }}>
                            These are the automation's that will be sent to the lead. You can turn them off by toggling the buttons.
                        </Typography>
                    </Box>
                    <FormControlLabel
                        sx={{ minWidth: '121px' }}
                        control={
                            <Switch

                                color="primary"
                            />
                        }
                        label='All Active'
                    />
                </Box>

                <TableContainer
                    sx={MessageTableContainer}
                >
                    <Table size={'medium'}>
                        <TableHeadCustom
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            numSelected={selected.length}
                            onSort={onSort}
                            practiceMessageTable
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                )
                            }
                        />
                        <TableBody>
                            {tableData.map((options, index) => (
                                <TableRow key={index} hover selected={false} sx={{ borderTop: '2px solid rgba(145, 158, 171, 0.12)' }}>
                                    <TableCell>
                                        <Box sx={{ ml: 0 }}>
                                            <Typography variant="span" noWrap sx={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
                                                <Iconify icon={'eva:email-fill'} width={20} height={20} mr={1} /> {options.type}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: '320px' }}>
                                        <Box sx={{ ml: 0 }}>
                                            <Typography
                                                variant="subtitle2"
                                                noWrap
                                                sx={{
                                                    width: '100%',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {options.subject}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ ml: 0 }}>
                                            <Typography variant="body" noWrap>
                                                {options.send}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={options.status}
                                                    color="primary"
                                                />
                                            }
                                            label={`${options.status ? 'Active' : 'Inactive'}`}
                                        />
                                    </TableCell>
                                    <TableCell align="right" >
                                        <TableMoreMenu
                                            open={openMenu}
                                            sx={{ width: '200px' }}
                                            onOpen={handleOpenMenu}
                                            onClose={handleCloseMenu}
                                            actions={
                                                <>
                                                    <MenuItem
                                                        sx={{ color: 'primary.main' }}
                                                    >
                                                        <Iconify icon={'solar:pen-bold'} sx={{ mr: 0 }} />
                                                        <ListItemText primary="Edit" primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{ color: 'primary.main' }}
                                                    >
                                                        <Iconify icon={'eva:email-fill'} sx={{ mr: 0 }} />
                                                        <ListItemText primary="Send Test Email" primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                                                    </MenuItem>
                                                </>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'none' }}>
                    {type && type !== 'CreatLead' &&
                        <Accordion onChange={() => handleAccordion()} defaultExpanded sx={{ marginTop: '40px', width: '100%', '::before': { display: 'none' } }}>
                            <AccordionSummary
                                expandIcon={<Iconify icon={'entypo:chevron-small-down'} width={25} height={25} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                sx={{ bgcolor: 'transparent', p: 0 }}
                            >
                                <Typography sx={{ fontWeight: 600 }}> Add a reminder </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <Box sx={followUpTabStyle(isMobile)}>
                                    <ButtonGroup
                                        orientation={!isMobile ? 'vertical' : 'horizontal'}
                                        sx={followUpButtonGroupStyle}
                                    >
                                        <Button
                                            variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('2 hours-call')}
                                            sx={followUpHourButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 hours
                                        </Button>
                                        <Button
                                            variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('tomorrow')}
                                            sx={followUpTomorrowButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call 9am tomorrow
                                        </Button>
                                        <Button
                                            variant={activeTime === 'week' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('week')}
                                            sx={followUpWeekButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
                                        </Button>
                                        <Button
                                            variant={activeTime === 'two-week' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('two-week')}
                                            sx={followUpTwoWeekButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
                                        </Button>
                                        <Button
                                            variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('custom reminder')}
                                            sx={followUpCustomButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'solar:clock-circle-bold'} width={20} height={20} /> Custom reminder
                                        </Button>
                                        <Button
                                            variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('no-reminder')}
                                            sx={followUpNoReminderButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                {activeTime === 'custom reminder' ?
                                    <Grid item xs={12} md={12}>
                                        <Box sx={customReminderBoxStyle} >
                                            <RHFSelect
                                                name='reminder'
                                                label='Reminder method (Required)'
                                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                            >
                                                {reminderMethod?.map((options) => (
                                                    <MenuItem
                                                        key={options.label}
                                                        value={options.value}
                                                        sx={selectMenuItemStyle}
                                                    >
                                                        {options.label}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>
                                            <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                                <Controller
                                                    name='reminderDateTime'
                                                    control={control}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <>
                                                            <DateTimePicker
                                                                label='Custom date and time (Required)'
                                                                value={field.value || null}
                                                                format="dd/MM/yyyy HH:mm"
                                                                ampm={false}
                                                                minDateTime={new Date()}
                                                                onChange={(newValue) => {
                                                                    field.onChange(newValue);
                                                                }}
                                                                slotProps={{
                                                                    textField: {
                                                                        fullWidth: true,
                                                                        error: !!error,
                                                                        helperText: error?.message,
                                                                        onKeyDown: (e) => e.preventDefault()
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Box>
                                            <UserSearchDropdown name={'assignTo'} type="reminder" control={control} label='Assign to (Required)' />
                                        </Box>
                                    </Grid>
                                    : null
                                }
                                <RHFTextField name="addNote" multiline rows={4} label="Add a note (Required)" />
                            </AccordionDetails>
                        </Accordion>
                    }
                </Box>
            </Card>
        </FormProvider>
    );
});

MessageAndReminder.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
    row: PropTypes.any
};
export default MessageAndReminder;
