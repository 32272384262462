import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Table, TableBody, TableContainer, TableCell, TableRow, MenuItem, Dialog, DialogTitle, DialogActions, Button, Zoom, Tooltip } from '@mui/material';
// other libraries
import moment from 'moment';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { TableHeadCustom, TableMoreMenu } from '../table';
import Iconify from '../Iconify';
import { OpenReSchedulePopup, OpenAttachmentsPopup } from '../fullScreenPopUps';
import CustomTableCell from './CustomTableCell';
import NoAppointmentComponent from '../NoAppointmentComponent';
import { useSelector, dispatch } from '../../redux/store';
import { cancelAppointment, appointmentsAttended } from '../../redux/slices/lead';
import { useTable, useToggle } from '../../hooks';
import { upcomingTableLabels } from '../../constants/LeadDetailConst';
import { getAssignees } from '../../utils/multiAsignees';
import { capitalizeFirstWord } from '../../utils/capitalizedFunc';
import { getIcon, StatusValue } from '../../utils/appointmentUtils';
import { cancelBtnStyle, yesBtnStyle, dateTypoTextStyle, timeTypoTextStyle, methodTypoTextStyle, treatmentTypoStyle, statusTypoTextStyle, dialogTitle, dialogActions, normalWhiteSpace } from '../../styles/UpcommingAppointments';

const UpcommingAppointments = ({ leadAppointment, treatmentData, leadDetail, handleUpdate }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [tableData, setTableData] = useState(leadAppointment);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const [openMenu, setOpenMenuActions] = useState(null);
    const rowData = [];
    const upcomingRef = useRef();
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [appoinmentID, setAppoinmentID] = useState(null);
    const [appoinmentSelectData, setAppoinmentSelectData] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef();
    const [confirmation, setConfirmation] = useState(false);

    useEffect(() => {
        if (leadAppointment) {
            leadAppointment?.map((item) => {
                const latestData = item;
                rowData.push({
                    assignees: item?.assignees || [],
                    Date: moment(latestData?.datetime, 'DD-MM-YYYY').format('ddd, D MMM'),
                    Time: moment(latestData?.datetime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A'),
                    Treatment: treatmentData?.treatment,
                    method: latestData?.method,
                    Practioner: latestData?.assignees?.length > 0 ? practiceUser?.results?.filter(el => latestData?.assignees?.some((ele) => ele === el.id) || latestData?.assignees?.some((ele) => ele?.id === el.id)) : [],
                    Status: latestData?.status,
                    appointment_id: latestData?.id,
                    datetime: latestData?.datetime,
                    contacted_via: latestData?.contacted_via,
                    type: latestData?.type || '',
                    note: latestData?.note || ''
                });
                return item;
            });
            setTableData(rowData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadAppointment]);

    const { onSelectAllRows } = useTable();

    const handleOpenMenu = (event, row) => {
        setOpenMenuActions(event.currentTarget);
        upcomingRef.current = row?.appointment_id;
    };

    const handleCloseMenu = () => setOpenMenuActions(null);

    const cancelAppointmentClick = (appointmentId) => {
        setStartConfirmation(true);
        setOpenMenuActions(null);
        setAppoinmentID(appointmentId);
    };

    const cancelAppointmentAPI = () => {
        dispatch(cancelAppointment(practiceId, leadDetail?.id, appoinmentID, handleSuccess));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setStartConfirmation(false);
    };

    const handleReScheduleClose = () => onCloseReSchedule();

    const handleAttachmentClose = () => onCloseAttachment();

    const {
        toggle: OpenReSchedule,
        onOpen: onOpenReSchedule,
        onClose: onCloseReSchedule
    } = useToggle();

    const {
        toggle: OpenAttachments,
        onOpen: onOpenAttachment,
        onClose: onCloseAttachment
    } = useToggle();

    useEffect(() => {
        const htmlElement = document.querySelector('body');
        if (OpenAttachments) {
            htmlElement.classList.add('overflow-hidden');
        } else {
            htmlElement.classList.remove('overflow-hidden');
        }
    }, [OpenAttachments]);

    const handlePopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenReSchedule();
            setOpenMenuActions(null);
        }, 800);
    };

    const attachmentPopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenAttachment();
            setOpenMenuActions(null);
        }, 800);
    };

    const handleAttended = (appointmentData) => {
        setAppoinmentSelectData(appointmentData);
        setConfirmation(true);
        setOpenMenuActions(null);
    };

    const attendedTreatmentAPI = () => {
        const appointmentDetail = {
            contacted_via: appoinmentSelectData?.contacted_via || '',
            datetime: appoinmentSelectData?.datetime,
            method: appoinmentSelectData.method,
            note: appoinmentSelectData.note,
        };
        dispatch(appointmentsAttended(practiceId, leadDetail?.id, appoinmentSelectData?.appointment_id, appointmentDetail, handleStatusUpdate));
    };

    const handleStatusUpdate = (data) => {
        enqueueSnackbar('Lead treatment status updated successfully', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setConfirmation(false);
        handleUpdate();
    };

    const customIcon = ({ title, icon }) => <Tooltip title={title} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
        <Iconify icon={icon} width={18} height={18} />
    </Tooltip>;

    return (
        <Box>
            {leadAppointment && leadAppointment?.length > 0 ?
                <TableContainer sx={{ width: '100%', position: 'relative' }}>
                    <Table>
                        <TableHeadCustom
                            headLabel={upcomingTableLabels}
                            rowCount={tableData?.length}
                            sx={{ ' .MuiTableCell-head': { boxShadow: 'none !important', minWidth: 'auto' } }}
                            onSelectAllRows={(checked) => onSelectAllRows(checked)}
                        />
                        <TableBody >
                            {tableData !== undefined && tableData?.map((row, index) => (
                                <TableRow key={index} selected={false} sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.12)' }}>
                                    <CustomTableCell label={row?.Date} style={dateTypoTextStyle} />
                                    <CustomTableCell label={row?.Time} style={timeTypoTextStyle} />
                                    <CustomTableCell label={row?.Treatment} style={treatmentTypoStyle} />
                                    <CustomTableCell label={getIcon(row, customIcon)} style={methodTypoTextStyle} />
                                    <CustomTableCell label={capitalizeFirstWord(row?.type)} style={methodTypoTextStyle} />
                                    <TableCell>
                                        <Box sx={{ ml: 0 }}>
                                            <Typography variant='span' noWrap sx={statusTypoTextStyle}>
                                                <Tooltip title={getAssignees({ assignees: row?.Practioner })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                    <span style={{ textTransform: 'capitalize', marginRight: row?.Practioner?.[0]?.user?.salutation ? '4px' : '0' }}>{`${row?.Practioner?.[0]?.user?.salutation || ''}`.toLowerCase()}</span>{row.Practioner?.[0]?.user?.first_name} {row.Practioner?.[0]?.user?.last_name} {row?.Practioner?.length > 1 ? `+ ${row?.Practioner?.length - 1}` : ''}
                                                </Tooltip>
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ ml: 0 }}>
                                            <Typography variant='span' noWrap sx={statusTypoTextStyle}>
                                                {StatusValue(row?.Status)}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align='right' >
                                        {row.Status !== 'CANCELLED' && <TableMoreMenu
                                            open={upcomingRef.current === row.appointment_id && openMenu}
                                            sx={{ width: '180px' }}
                                            onOpen={(e) => handleOpenMenu(e, row)}
                                            onClose={handleCloseMenu}
                                            actions={
                                                <Box>
                                                    {row.Status !== 'CANCELLED' &&
                                                        <MenuItem onClick={() => handlePopup(row.appointment_id, row)} sx={normalWhiteSpace}>
                                                            Edit/Reschedule
                                                        </MenuItem>
                                                    }
                                                    {row.Status !== 'CANCELLED' && row.Status !== 'FAILED_TO_ATTEND' &&
                                                        <MenuItem onClick={() => cancelAppointmentClick(row.appointment_id)} sx={normalWhiteSpace}>
                                                            Cancel
                                                        </MenuItem>
                                                    }
                                                    {row?.Status === 'BOOKED' &&
                                                        <MenuItem onClick={() => handleAttended(row)} sx={normalWhiteSpace}>
                                                            Attended
                                                        </MenuItem>
                                                    }
                                                    <MenuItem onClick={() => attachmentPopup(row.appointment_id, row)} sx={normalWhiteSpace}>
                                                        View detail
                                                    </MenuItem>
                                                </Box>
                                            }
                                        />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <NoAppointmentComponent />
            }
            <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
                <DialogTitle sx={dialogTitle}>
                    Are you sure you want to attended the treatment?
                </DialogTitle>
                <DialogActions sx={dialogActions}>
                    <Button sx={cancelBtnStyle} onClick={() => setConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button type='submit' sx={yesBtnStyle} onClick={() => attendedTreatmentAPI()}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={startConfirmation} onClose={() => setStartConfirmation(false)}>
                <DialogTitle sx={dialogTitle}>Are you sure you want to cancel the appointment ?</DialogTitle>
                <DialogActions sx={dialogActions}>
                    <Button sx={cancelBtnStyle} onClick={() => setStartConfirmation(false)}>Cancel</Button>
                    <Button type='submit' sx={yesBtnStyle} onClick={() => cancelAppointmentAPI()}>Yes</Button>
                </DialogActions>
            </Dialog>
            {OpenReSchedule && <OpenReSchedulePopup
                open={OpenReSchedule}
                headerButtons
                onClose={handleReScheduleClose}
                treatmentData={treatmentData}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
                appoinmentSelectData={appoinmentSelectData}
            />}
            {OpenAttachments && <OpenAttachmentsPopup
                open={OpenAttachments}
                headerButtons
                onClose={handleAttachmentClose}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
            />}
        </Box>
    );
};

UpcommingAppointments.propTypes = {
    leadAppointment: PropTypes.object,
    treatmentData: PropTypes.object,
    leadDetail: PropTypes.object,
    handleUpdate: PropTypes.func,
};

export default UpcommingAppointments;