import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// use @mui
import {
    Dialog,
    Box,
    Button,
    Typography
} from '@mui/material';

import moment from 'moment';
// use form
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// use components
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function EditOffersDialog({
    openEditOffer,
    handleCloseEditDialog,
    isAddingOffer,
    setIsAddingOffer,
    activeIndex,
    inputValue,
    setInputValue,
}) {

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const getDate = (date) => {
        if (!date) return null;

        // Parse date in the specific format
        const parsedDate = moment(date, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
        return parsedDate.isValid() ? parsedDate.toDate() : null;
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Offer name is required'),
        offer: Yup.string().required('Offer description is required')
    });

    const methodsEditOffer = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            offer: '',
            expires_at: null,
        },
    });
    const {
        control: controlEditOffer,
        handleSubmit: handleSaveEditOffer,
        reset
    } = methodsEditOffer;

    const getOffers = (offer) => {
        const stripHtmlAndSplit = (htmlString) => {
            if (!htmlString) return [];
            // return strippedString.split('\n').filter((item) => item.trim() !== "");
            const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, '\n');
            return strippedString.split('\n');
        };

        const offerItems = stripHtmlAndSplit(offer?.offer);
        return offerItems.join('\n');
    };

    useEffect(() => {
        if (openEditOffer) {
            if (isAddingOffer) {
                reset({
                    name: '',
                    offer: '',
                    expires_at: null,
                });
            } else {
                const currentOffer = inputValue?.offers?.[activeIndex];
                reset({
                    name: currentOffer?.name || '',
                    offer: currentOffer?.offer ? getOffers(currentOffer) : '',
                    expires_at: getDate(currentOffer?.expires_at) || null,
                });
            }
        }
    }, [openEditOffer, activeIndex, inputValue, isAddingOffer, reset]);
    const onSubmitEditOffer = (data) => {
        if (isAddingOffer) {
            setInputValue((prevState) => ({
                ...prevState,
                offers: [
                    ...prevState.offers,
                    {
                        name: data.name,
                        offer: data.offer,
                        expires_at: data.expires_at
                            ? `${moment(data.expires_at).format('DD-MM-YYYY HH:mm:ss.SSSSSS')}Z`
                            : null,
                    },
                ],
            }));
        } else {
            setInputValue((prevState) => {
                const updatedOffers = [...prevState.offers];
                updatedOffers[activeIndex] = {
                    ...updatedOffers[activeIndex],
                    name: data.name,
                    offer: data.offer,
                    expires_at: data.expires_at
                        ? `${moment(data.expires_at).format('DD-MM-YYYY HH:mm:ss.SSSSSS')}Z`
                        : null,
                };
                return { ...prevState, offers: updatedOffers };
            });
        }
        handleCloseEditDialog();
        setIsAddingOffer(false); // Reset the add offer state
    };


    return (
        <Dialog fullWidth maxWidth="xs" open={openEditOffer} onClose={handleCloseEditDialog} sx={{height: isDatePickerOpen ? '900px' : 'hidden'}}>
            <Typography sx={{ fontWeight: "500", fontSize: "18px", p:1, m:2 }}>New offer</Typography>
            <FormProvider methods={methodsEditOffer} onSubmit={handleSaveEditOffer(onSubmitEditOffer)}>
                <Box sx={{ px: 2, mt: 0 }} >
                    <RHFTextField
                        sx={{ width: '100%' }}
                        name="name"
                        label="Offer Name"
                        control={controlEditOffer}
                    />
                    <RHFTextField
                        sx={{ width: '100%', mt: 2 }}
                        name="offer"
                        label="Offer Description"
                        multiline
                        control={controlEditOffer}
                    />
                    <Controller
                        name="expires_at"
                        control={controlEditOffer}
                        defaultValue={getDate(inputValue?.offers[activeIndex]?.expires_at) || null}
                        render={({ field }) => {
                            const parsedDate = getDate(field.value);
                            return (
                                <DateTimePicker
                                    {...field}
                                    sx={{ mt: 2 }}
                                    label="Expiry Date"
                                    value={parsedDate}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                        // handleOfferChange(activeIndex, 'expires_at', newValue)
                                    }
                                    }
                                    format={'dd/MM/yyyy HH:mm'}
                                    ampm={false}
                                    // minDateTime={new Date()}
                                    onOpen={() => setIsDatePickerOpen(true)} 
                                    onClose={() => setIsDatePickerOpen(false)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!field.error,
                                            helperText: field.error?.message,
                                            onKeyDown: (e) => e.preventDefault()
                                        },
                                    }}
                                />
                            )
                        }
                        }
                    />
                </Box>
                <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center" }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleCloseEditDialog}
                    >
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{ ml: 2 }}
                    >
                        Edit
                    </Button>
                </Box>
            </FormProvider>
        </Dialog>
    );
}

EditOffersDialog.propTypes = {
    openEditOffer: PropTypes.bool,
    handleCloseEditDialog: PropTypes.func,
    isAddingOffer: PropTypes.bool,
    setIsAddingOffer: PropTypes.func,
    activeIndex: PropTypes.number,
    inputValue: PropTypes.object,
    setInputValue: PropTypes.func,
};