import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Grid,
    Button,
    Box,
    Typography,
    Dialog,
    DialogTitle
} from '@mui/material';
// components
import { orderBy } from 'lodash';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { Scrollbar } from '../../../components';
import {
    FormProvider,
    RHFAutocomplete,
} from '../../../components/hook-form';
// hooks
import { useCopyToClipboard } from '../../../hooks';
// sections
import LeadTemplate from '../../../pages/dashboard/LeadTemplate';
import AddPractitioner from './AddPractitioner';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { generateConsentUrl } from '../../../redux/slices/practice';
import { updateError } from '../../../redux/slices/user';
import { clearLead, updateConsentLink, clearConsentData } from '../../../redux/slices/lead';
// import { sendTemplateEmail } from '../../../redux/slices/practiceMessage';
// style
import {
    header,
    autoComplete,
    consentButton,
    topButton,
    assignToBoxStyle
} from '../../../styles/ConsentBuilder';
// style
import {
    cancelBtnStyle,
    yesBtnStyle,
} from '../../../styles/NewLead';
// validation
import { consentLinkSchema } from '../../../validations/validations';
// constants
import { userInputs } from '../../../constants/LeadDetailConst';

//----------------------------------------------------------------------------------------------------------

const ConsentLink = ({ onClose }) => {

    const [show, setShow] = useState({
        share: false,
        link: '',
        practiceName: ''
    });
    const [consentList, setConsentList] = useState([]);
    const [templateOpen, setTemplateOpen] = useState(false);
    const [composeDetail, setComposeDetail] = useState({});
    const [userList, setUserList] = useState([]);

    const { copy } = useCopyToClipboard();

    const navigate = useNavigate();

    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practice } = useSelector((state) => state.practice);
    const { practiceId, practiceTreatment } = useSelector((state) => state.practiceTreatmentList);
    const [userOpen, setUserOpen] = useState(false);
    const [assignToValue, setAssignToValue] = useState('');

    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
    
    const defaultValues = {
        consentType: [],
        assignTo: assignToValue || '',
        practiceName: '',
    };

    useEffect(() => {
        if (assignToValue) {
            setValue('assignTo', assignToValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignToValue]);

    const methods = useForm({
        resolver: yupResolver(consentLinkSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
        clearErrors
    } = methods;

    useEffect(() => {
        if (getValues('assignTo') === 'Add Practitioner') {
            setValue('assignTo', '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues('assignTo')]);

    useEffect(() => {
        if (practiceUser) {
            const getUserList = () => practiceUser?.results?.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
            const data = orderBy(getUserList(), [o => (o.user?.first_name).toLowerCase(), o => (o.user?.last_name).toLowerCase()], ['asc', 'asc']);
            const consentUsers = data?.filter((value) => value.role === 'Practitioner');
            setUserList(consentUsers);
        }
    }, [practiceUser]);

    useEffect(() => {
        if (practiceId) {
            const name = practiceTreatment.results.filter((value) => value.id === Number(practiceId));
            setValue('practiceName', name[0]?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId, practiceTreatment]);

    useEffect(() => {
        if (Object.entries(practice).length) {
            const consentValue = [];
            practice?.consent_types?.map((value) => consentValue.push(value));
            setConsentList(consentValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practice]);

    useEffect(() => {
        if (show?.link?.length > 0) {
            setComposeDetail({
                body: `<p><a href=${show?.link} rel="noopener noreferrer" target="_blank"> Consent link  </a></p>`,
            });
        }
    }, [show]);

    const onSubmit = (data) => {
        const consentId = [];
        practice?.consent_types.filter((id1) => data.consentType.some((id2) => (id2.name === id1.name ? consentId.push(parseInt(id1.id, 10)) : null)));
        const assignedUser = getUserList().filter(el => (el.user.first_name === data.assignTo.split(' ')[1]) || (`${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) === (data.assignTo))[0]?.id;

        if (consentId.length && assignedUser) {
            const payloadData = {
                consent_types: consentId,
                practice_user: assignedUser
            };
            dispatch(generateConsentUrl(practiceId, payloadData, handleSuccess));
            copy('');
            setShow({ share: true });
        }
    };

    const handleSuccess = (data) => {
        copy('');
        setShow({ link: data.url, share: true });
        dispatch(updateConsentLink(data.url));
    };

    const handleCopyClipboard = () => {
        if (show.link) {
            dispatch(updateError({ success: true, message: 'Consent link copied successfully' }));
            copy(show.link);
        }
    };

    const handleRevisit = () => {
        navigate('/dashboard/user/practice-consent');
        onClose(true);
    };

    const options = consentList.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const handleTags = (e, value) => {
        const data = value.filter(value => typeof value !== 'string');
        if (data.length > 0) {
            clearErrors('consentType');
        }
        setValue('consentType', data);
    };


    const handleOpenCompose = () => {
        setTemplateOpen(true);
    };

    const handleCloseConsent = () => {
        onClose(true);
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(clearConsentData());
    };

    const handleAddPractitioner = () => {
        setValue('assignTo', '');
        setUserOpen(true);
    }; 

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ margin: '0 -8px' }}>
                    <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                        {practice?.settings?.consent_base_url.length > 0 ?
                            <>
                                <Grid container spacing={1} sx={header}  >
                                    <Typography variant='h5' sx={{ fontSize: '18px', ml: 1 }} > Consent Builder</Typography>
                                </Grid>
                                <Grid container spacing={1} sx={header}  >
                                    <RHFAutocomplete
                                        name={'consentType'}
                                        label="Consent Type (Required)"
                                        multiple
                                        key={'consent'}
                                        freeSolo
                                        groupBy={(option) => option.firstLetter}
                                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                        getOptionLabel={(option) => option.name}
                                        ChipProps={{ size: 'small', color: 'info', variant: 'soft' }}
                                        sx={autoComplete}
                                        onChange={handleTags}
                                    />
                                    <Box sx={assignToBoxStyle}>
                                        <RHFAutocomplete
                                            disableClearable
                                            control={control}
                                            name='assignTo'
                                            label='Assign to (Required)'
                                            onKeyDown={(evt) => (userInputs.includes(evt.key) || (/^[a-zA-Z]*$/).test(evt.key)) ? evt : evt.preventDefault()}
                                            options={['Add Practitioner',...(userList?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) || [])]}
                                            noOptionsText={'No options'}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            getOptionLabel={(option) => option === 'Add Practitioner' ? '' : option}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option} style={{ padding: '5px 9px' }}>
                                                    {option === 'Add Practitioner' ? (
                                                        <Button
                                                            onClick={() => handleAddPractitioner()}
                                                            variant="outlined"
                                                            color="primary"
                                                            fullWidth
                                                        >
                                                            Add Practitioner
                                                        </Button>
                                                    ) : (
                                                        <Box sx={{ padding: '0', textTransform: 'capitalize' }}>
                                                            {option || ''}
                                                        </Box>
                                                    )}
                                                </li>
                                            )}
                                            sx={{ '.MuiInputBase-input': { textTransform: 'capitalize' } }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                                        <Button
                                            color="inherit"
                                            sx={topButton}
                                            onClick={handleCloseConsent}
                                        >
                                            cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={consentButton}
                                            type="submit"
                                        >
                                            Generate Consent link
                                        </Button>
                                        {(show.share && !errors.consentType) &&
                                            <>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    sx={consentButton}
                                                    onClick={handleCopyClipboard}
                                                >
                                                    Copy link
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    sx={consentButton}
                                                    onClick={handleOpenCompose}
                                                >
                                                    Send email
                                                </Button>
                                            </>}
                                    </Box>
                                </Grid>
                            </> :
                            <Box sx={{ textAlign: 'center' }}>
                                <Box sx={{ marginBottom: '35px', width: '100%' }}>
                                    <Typography variant='subtitle1'>Revisit this again after configure "Base URL" and "Consent Type".</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Button
                                        color="inherit"
                                        sx={cancelBtnStyle}
                                        onClick={handleCloseConsent}
                                    >
                                        cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={yesBtnStyle}
                                        onClick={handleRevisit}
                                    >
                                        Yes
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Scrollbar>
                </Box>
            </FormProvider>
            {templateOpen &&
                <LeadTemplate
                    openEmailCompose={Boolean(templateOpen)}
                    onCloseEmailCompose={() => setTemplateOpen(false)}
                    practiceId={practiceId}
                    type={'EMAIL'}
                    screen={'consent'}
                    consentLink={composeDetail}
                />
            }
            <Dialog open={userOpen} onClose={() => setUserOpen(false)}>
                <DialogTitle sx={{ pb: 2, fontSize: '20px !important' }}>Add a new user</DialogTitle>
                <AddPractitioner setUserOpen={setUserOpen} setAssignToValue={setAssignToValue} selectPractitioner />
            </Dialog>
        </>
    );
};

ConsentLink.propTypes = {
    onClose: PropTypes.func
};

export default ConsentLink;

