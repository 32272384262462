/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useDebounce } from 'use-lodash-debounce';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
// @mui
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    MenuItem,
    Divider,
    Switch,
    List,
    ListItem,
} from '@mui/material';
//
import ConfirmDelete from './ConfirmDelete';
import PracticeEditDialog from './practiceEditDialog';
// components
import CustomColorPicker from '../../../components/CustomColorPicker';
import { COLOR_CODES } from '../../../constants/newLead';
import { Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { FormProvider } from '../../../components/hook-form';
// hooks
import { useToggle, useAuth } from '../../../hooks';
// store
import { updatePracticeTreatment } from '../../../redux/slices/practiceTreatment';
import { dispatch, useSelector } from '../../../redux/store';
import {
    ErrorTextStyle,
    AmountInputStyle
} from '../../../styles/PaymentRefund';
import TreatmentIconChangePopup from './TreatmentIconChangePopup';

;

// ------------------------------------------------------------------------------------------------------

export default function PracticeTreatmentTableRow({ row, selected, onDeleteRow, isEdit, reloadData }) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { price, enabled, id, is_starred: isStarred, colour, icon, offers, description, additional_infos: additionalInfo } = row;
    const { user } = useAuth();
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { enqueueSnackbar } = useSnackbar();
    const [openMenu, setOpenMenuActions] = useState(null);
    const [treatmentPrice, setTreatmentPrice] = useState(price);
    const debouncedValue = useDebounce(treatmentPrice, 1200);
    const [openEditMenu, setOpenEditMenu] = useState(false);
    const [enableTreatment, setEnableTreatment] = useState(false);
    const [isTreatmentStarred, setIsStarred] = useState(false);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [amountError, setAmountError] = useState(false);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');
    const [defaultValue, setDefaultValue] = useState({
        price,
        checked: enabled,
        colour,
        is_starred: isStarred,
        icon,
        offers,
        description,
        additional_infos: additionalInfo
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [treatmentAnchorEl, setTreatmentAnchorEl] = useState(null);

    const { toggle: openColor, onOpen: onColorOpen, onClose: onColorClose } = useToggle();
    const { toggle: openTreatmentIcon, onOpen: onTreatmentIconOpen, onClose: onTreatmentIconClose } = useToggle();

    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && el.user.is_superuser);
    const isSuperUser = () => practiceUser.results.filter(el => el.user && el.user.id === user?.pk);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
        setOpenEditMenu(false);
    };

    useEffect(() => {
        setEnableTreatment(enabled);
    }, [enabled]);

    useEffect(() => {
        setIsStarred(isStarred);
    }, [isStarred]);

    useEffect(() => {
        if (price !== debouncedValue) {
            const detail = {
                enabled: enableTreatment,
                price: debouncedValue.toString(),
                practice: Number(practiceId)
            };
            dispatch(updatePracticeTreatment(detail, id, handleSuccess));
        }
    }, [debouncedValue]);

    const defaultValues = useMemo(
        () => ({
            price: defaultValue?.price || '',
        }),
        [defaultValue]
    );

    const [amount, setAmount] = useState(price || 0.00);

    const methods = useForm({
        defaultValues,
    });

    const handleChangeColor = (color) => {
        onColorClose();
        const detail = {
            enabled,
            price: amount,
            practice: Number(practiceId),
            color,
            isStarred: isStarred || false,
            icon,
            offers,
            description,
            additional_infos: additionalInfo || []
        };
        saveTreatMentData(detail);
    };
    const handleChangeTreatmentIcon = (e) => {
        onTreatmentIconClose();
        const detail = {
            enabled,
            price: amount,
            practice: Number(practiceId),
            color: colour,
            isStarred: isStarred || false,
            icon: e.target.value,
            offers,
            description,
            additional_infos: additionalInfo || []
        };
        saveTreatMentData(detail);
    };

    const handleFavoriteChange = (checked) => {
        setIsStarred(checked);
        const detail = {
            enabled,
            price: amount,
            practice: Number(practiceId),
            color: colour,
            icon,
            isStarred: checked || false,
            offers,
            description,
            additional_infos: additionalInfo || []
        };
        saveTreatMentData(detail);
    };

    const handleOpenColorPicker = (event) => {
        onColorOpen();
        setAnchorEl(event.currentTarget);
    };
    const handleOpenTreatmentIconPopup = (event) => {
        onTreatmentIconOpen();
        setTreatmentAnchorEl(event.currentTarget);
    };

    const handleCloseColorPicker = () => {
        onColorClose();
        setAnchorEl(null);
    };
    const handleCloseTreatmentIconPopup = () => {
        onTreatmentIconClose();
        setTreatmentAnchorEl(null);
    };

    const saveTreatMentData = (value) => {
        if (value) {
            setAmount(value?.price);
            setDefaultValue({
                ...defaultValue,
                ...value
            });
        }
        const detail = {
            enabled: value ? value.checked : defaultValue.checked,
            price: value ? value.price : defaultValue.price,
            practice: Number(practiceId),
            colour: value ? value.color : defaultValue.colour,
            is_starred: value ? value.isStarred : defaultValue.is_starred,
            icon: value ? value.icon : defaultValue.icon,
            offers: value ? value.offers : defaultValue.offers,
            description: value ? value.description : defaultValue.description,
            additional_infos: value ? value.additionalInfo : defaultValue.additional_infos
        };
        dispatch(updatePracticeTreatment(detail, id, handleSuccess));
    };

    const handleSuccess = (data) => {
        if (!data.success) {
            setDefaultValue({
                ...defaultValue,
                [price]: price
            });
        }
        enqueueSnackbar(data.message || 'Something went wrong',
            {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
        );
        reloadData();
    };

    const switchHandler = (value) => {
        setEnableTreatment(value);
        const detail = {
            enabled: value,
            price: amount,
            practice: Number(practiceId)
        };
        dispatch(updatePracticeTreatment(detail, id, handleSuccess));
    };

    const handleChange = (value) => {
        setAmount(value);
        if (value > 0) {
            setAmountError(false);
            setTreatmentPrice(value);
            return;
        }
        setAmountError(true);
    };

    const ICONS = ['mdi:tooth-outline', 'mdi:tooth'];
    const getOffers = (offer) => {
        const stripHtmlAndSplit = (htmlString) => {
            if (!htmlString) return [];
            const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, '\n');
            return strippedString.split('\n').filter((item) => item.trim() !== "");
        };

        const offerItems = stripHtmlAndSplit(offer?.offer);

        return (
            <List sx={{ listStyleType: "none", padding: 0 }}>
                {offerItems.map((item, index) => (
                    <ListItem key={index} sx={{ fontSize: "12px", py: 0 }}>
                        {item}
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <TableRow hover selected={selected} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell sx={{ alignItems: 'center', border: 'none' }}>
                <Box sx={{ ml: 0, minWidth: 150 }}>
                    <Typography variant="subtitle2" noWrap>
                        {row.treatment_item.name}
                    </Typography>
                    <Typography variant="body2">
                        {row.treatment_item.treatment_category.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left" sx={{ border: 'none' }}>
                <FormProvider methods={methods}>
                    <Box sx={{ width: '40%', minWidth: 150 }}>
                        <CurrencyInput
                            name="treatmentValue"
                            value={amount}
                            decimalSeparator={decimalSeparator}
                            thousandSeparator={thousandSeparator}
                            onValueChange={(value, name) => handleChange(value, name)}
                            className="currency-input"
                            decimalsLimit={2}
                            decimalScale={2}
                            inputType={inputType}
                            prefix={'£ '}
                            style={AmountInputStyle(amountError, 'appointment', isLight)}
                            step={1}
                        />
                        {amountError && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                    </Box>
                </FormProvider>

            </TableCell>
            <TableCell sx={{ border: 'none' }}>
                <CustomColorPicker open={openColor}
                    openColorPicker={handleOpenColorPicker}
                    handleClose={handleCloseColorPicker}
                    colorList={COLOR_CODES}
                    value={colour}
                    anchorEl={anchorEl}
                    onChange={handleChangeColor}
                    style={{ marginLeft: '15px' }}
                />
            </TableCell>
            <TableCell sx={{ border: 'none', marginLeft: '50px' }}>
                <Box sx={{ ml: 2 }}>
                    <TreatmentIconChangePopup
                        open={openTreatmentIcon}
                        openTreatmentIconPopup={handleOpenTreatmentIconPopup}
                        handleClose={handleCloseTreatmentIconPopup}
                        iconList={ICONS}
                        value={icon}
                        anchorEl={treatmentAnchorEl}
                        onChange={handleChangeTreatmentIcon}
                    />
                </Box>
            </TableCell>
            <TableCell sx={{ border: 'none' }}>
                <Switch
                    checked={isTreatmentStarred}
                    onClick={(e) => handleFavoriteChange(e.target.checked)}
                    color="primary"
                />
            </TableCell>
            <TableCell sx={{ border: 'none' }}>
                {getUserList().filter((el) => el?.user?.id === user?.pk).length > 0 &&
                    <Switch
                        checked={enableTreatment}
                        onClick={(e) => switchHandler(e.target.checked)}
                        color="primary"
                    />
                }
            </TableCell>
            <TableCell sx={{ border: "none" }}>
                <Box sx={{ ml: 1 }}>
                    {row?.offers?.filter(offer => !offer.is_expired).map((offer, index) => (<Box key={index} sx={index !== 0 ? { mt: "0" } : {}}>
                            <List sx={{ listStyleType: `${row?.offers?.filter(offer => !offer.is_expired)?.length > 1 ? "disc" : "none"}` }}>
                                <ListItem sx={{ p: 0, display: 'list-item' }}> <Typography variant="subtitle2">{offer.name}</Typography>
                                </ListItem>
                                {getOffers(offer)}
                            </List>
                        </Box>))}
                </Box>
            </TableCell>
            {isEdit && <TableCell align="right" sx={{ border: 'none' }}>
                <TableMoreMenu
                    open={openMenu}
                    onOpen={(e) => setOpenMenuActions(e.currentTarget)}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem onClick={() => setOpenEditMenu(true)}>
                                <Iconify icon={'solar:pen-bold'} />
                                Edit
                            </MenuItem>
                            {(isSuperUser()[0]?.user?.is_superuser) && <Divider sx={{ borderStyle: 'dashed' }} />}
                            {(isSuperUser()[0]?.user?.is_superuser) && <MenuItem
                                onClick={onOpenContacts}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon={'solar:trash-bin-trash-bold'} />
                                Delete
                            </MenuItem>}
                            {openEditMenu && <PracticeEditDialog
                                treatmentName={row.treatment_item.name}
                                treatmentCategory={row.treatment_item.treatment_category.name}
                                treatmentStatus={defaultValue.checked}
                                treatmentPrice={amount}
                                treatmentData={row}
                                open={openEditMenu}
                                onClose={handleCloseMenu}
                                saveTreatMentData={saveTreatMentData}
                                offers={row?.offers}
                                description={row?.description}
                                additionalInfo={row?.additional_infos}
                            />}
                            <ConfirmDelete onDeleteRow={onDeleteRow} handleCloseMenu={handleCloseMenu} open={openContacts} onClose={onCloseContacts} />
                        </>
                    }
                />
            </TableCell>}
        </TableRow>
    );
}

PracticeTreatmentTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onDeleteRow: PropTypes.func,
    reloadData: PropTypes.func,
    isEdit: PropTypes.bool,
};