export const archiveSelection = [
    'CREDIT_CHECK_FAILED',
    'TOO_EXPENSIVE',
    'CHANGED_MIND',
    'DUPLICATE_ENTRY',
    'WANT_NHS',
    'CLINICALLY_NOT_SUITABLE',
    'PRACTICE_TOO_FAR',
    'ANOTHER_PRACTICE',
    'NO_RESPONSE'
];

export const followUpSelection = [
    'FOLLOW_UP',
    'UNABLE_TO_CONTACT',
    'AWAITING_RESPONSE'
];