import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import {
    Box,
    MenuItem,
    Grid,
    Button,
    Typography,
    Tooltip,
    Zoom,
    ClickAwayListener,
    Popper,
    Grow,
    Paper
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// components
import { getTreatment } from 'src/utils/multiTreatmentFlow';
import Iconify from '../Iconify';
import { AssignUserAvatar } from '../custom-avatar';
import { getAssignees } from '../../utils/multiAsignees';
import { btnHandler, btnValues } from '../../utils/reminderSelectedTimeFunc';
import { AssignTaskPopup } from '../dialogPopUp';
import FollowUpButtonGroup from './FollowUpButtonGroup';
// hooks
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
import { useResponsive, useAssignTaskToggle } from '../../hooks';
// store
import { useSelector, dispatch } from '../../redux/store';
import { saveNewLeadDetail, updateLeadDetail } from '../../redux/slices/lead';
import { clearPracticeUserData } from '../../redux/slices/practiceUser';
import { FailedToReminderSchema } from '../../validations/validations';
// style
import { selectMenuItemStyle } from '../../styles/DialogueTabs';
import { openTaskPopupStyle, saveNoteButtonStyle } from '../../styles/FailedToAttendTabs';
import {
    taskUserAvatar,
    openTask,
    assignUserAvatarStyle,
    assignLengthStyle,
    assignPopperStyle,
} from '../../styles/leadTaskReminder';

const FailedToReminder = forwardRef(({ isOverview, value, handleReminderUpdate, onOpen, setConfirmMessage }, ref) => {
    const [activeTime, setActiveTime] = useState('custom reminder');
    const { followLead, createLead } = useSelector((state) => state.lead);
    const { reminderMethod } = useSelector((state) => state.schema);
    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [assignToState, setAssignToState] = useState([]);
    const [anchorElAssign, setAnchorElAssign] = useState(null);

    const defaultValues = {
        notes: '',
        reminder: '',
        reminderDateTime: null,
        customReminder: '',
        value: ''
    };

    const methods = useForm({
        resolver: yupResolver(FailedToReminderSchema),
        mode: 'onChange',
        defaultValues,
    });

    const {
        control,
        setValue,
        getValues,
        clearErrors,
        handleSubmit,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status, 'unableToContactFlow'))();
            },
        }),
        []
    );

    useEffect(() => {
        setValue('customReminder', activeTime);
    }, [activeTime]);

    useEffect(() => {
        setValue('value', value);
    }, [value]);

    const onSubmit = async (data, status, type) => {
        if (type === 'unableToContactFlow' && status !== 'finish') {
            setConfirmMessage({ followup: getValues('reminder') === 'no-reminder' ? 'No reminder' : data.reminderDateTime });
            onOpen();
        } else if (getValues('reminder') === 'no-reminder') {
            let unableToContact = {};
            const Details = {
                lead: followLead.id,
                additional_notes: '',
                note: 'UNABLE_TO_CONTACT',
                treatment: getTreatment(isOverview, currentTreatmentDataGlobal, followLead),
            };
            unableToContact = {
                ...unableToContact,
                noteDetail: Details
            };
            dispatch(saveNewLeadDetail({ unableToContact }));
            dispatch(updateLeadDetail('Unable to contact', handleUpdate));
        } else if (activeTime !== '') {
            const leadId = followLead && followLead.id || createLead && createLead.id || '';
            const addReminderObj = {
                note: data.notes,
                is_completed: false,
                group: 'FOLLOW_UP',
                lead: leadId,
                assignees: assignToState?.map((el) => el?.id)
            };
            if (data.reminder) {
                addReminderObj.method = data.reminder;
            }
            if (data.reminderDateTime) {
                addReminderObj.datetime = `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`;
            }
            dispatch(saveNewLeadDetail({ addReminder: addReminderObj }));
            let unableToContact = {};
            const Details = {
                lead: followLead.id,
                additional_notes: '',
                note: 'UNABLE_TO_CONTACT',
                treatment: getTreatment(isOverview, currentTreatmentDataGlobal, followLead),
            };
            if (status !== 'Archive') {
                unableToContact = {
                    ...unableToContact,
                    noteDetail: Details
                };
                dispatch(saveNewLeadDetail({ unableToContact }));
            }
            if (isOverview) {
                dispatch(updateLeadDetail('Unable to contact', handleUpdate, true));
                return;
            }
            dispatch(updateLeadDetail('Unable to contact', handleUpdate));
        } else {
            enqueueSnackbar('Please select tab', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    };

    const handleUpdate = () => {
        handleReminderUpdate();
    };

    const handleButtonClick = (value) => {
        btnHandler({ value, btnValues: btnValues(setValue, clearErrors), reminderMethod });
        setActiveTime(value);
    };

    const isMobile = useResponsive('up', 'sm');

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const assignTaskPopupCloseEvent = () => {
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    return (
        <Box sx={{ width: '100%', pt: '30px' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <FollowUpButtonGroup
                    isMobile={isMobile}
                    activeTime={activeTime}
                    handleButtonClick={handleButtonClick}
                />
                {activeTime === 'custom reminder' ?
                    <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ width: '50%', mb: 3 }}>
                                <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                    <Controller
                                        name='reminderDateTime'
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <DateTimePicker
                                                    open={isOpen}
                                                    onOpen={handleToggleOpen}
                                                    onClose={() => setIsOpen(false)}
                                                    label='Custom date and time (Required)'
                                                    value={field.value || null}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    ampm={false}
                                                    minDateTime={new Date()}
                                                    onChange={(newValue) => field.onChange(newValue)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: !!error,
                                                            helperText: error?.message,
                                                            onKeyDown: (e) => e.preventDefault()
                                                        },
                                                        toolbarButton: { onClick: handleToggleOpen }
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: '50%', mb: 3 }}>
                                <RHFSelect
                                    name='reminder'
                                    label='Reminder method'
                                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                    sx={{ mb: 2 }}
                                >
                                    {reminderMethod?.map((options) => (
                                        <MenuItem
                                            key={options.label}
                                            value={options.value}
                                            sx={selectMenuItemStyle}
                                        >
                                            {options.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <Box>
                                    <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                                    <Box sx={{ cursor: 'pointer' }}>
                                        {assignToState?.length > 0 ? (
                                            <Box sx={taskUserAvatar}>
                                                <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                            <AssignUserAvatar
                                                                key={index}
                                                                sx={assignUserAvatarStyle}
                                                                src={assignee.user?.avatar || ''}
                                                                name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                role={assignee.role}
                                                                onClick={(e) => assignTaskHandler(e)}
                                                            />
                                                        ))}
                                                        {assignToState?.length > 2 && (
                                                            <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                                + {assignToState?.length - 2}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        ) : (
                                            <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                                <Box sx={openTaskPopupStyle} onClick={(e) => assignTaskHandler(e)}>
                                                    <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    :
                    <Box sx={{ mb: 4, display: (activeTime === 'no-reminder' || activeTime === '') ? 'none' : 'block' }}>
                        <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                        <Box sx={{ cursor: 'pointer' }}>
                            {assignToState?.length > 0 ? (
                                <Box sx={taskUserAvatar}>
                                    <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                        <Box sx={{ display: 'flex' }}>
                                            {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                <AssignUserAvatar
                                                    key={index}
                                                    sx={assignUserAvatarStyle}
                                                    src={assignee.user?.avatar || ''}
                                                    name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                    role={assignee.role}
                                                    onClick={(e) => assignTaskHandler(e)}
                                                />
                                            ))}
                                            {assignToState?.length > 2 && (
                                                <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                    + {assignToState?.length - 2}
                                                </Box>
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Box>
                            ) : (
                                <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                    <Box sx={openTaskPopupStyle} onClick={(e) => assignTaskHandler(e)}>
                                        <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
                {activeTime !== 'no-reminder' &&
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                        <RHFTextField
                            sx={{ width: '50%', mt: 0 }}
                            name='notes'
                            multiline
                            rows={4}
                            label='Reminder notes'
                            placeholder='Add notes here'
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                }
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: '24px 0 24px' }}>
                    <Button
                        onClick={() => { handleSubmit((data) => onSubmit(data))(); }}
                        variant='contained'
                        sx={saveNoteButtonStyle}
                    >
                        Set reminder <Iconify sx={{ marginLeft: '8px' }} icon={'charm:clock-alarm'} width={24} height={24} />
                    </Button>
                </Box>
                {openAssignTask && (
                    <ClickAwayListener onClickAway={onClickAwayEvent}>
                        <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement='top' sx={assignPopperStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={{ borderRadius: 2 }}>
                                        <AssignTaskPopup
                                            open={openAssignTask}
                                            onClose={() => assignTaskPopupCloseEvent()}
                                            type={'AddReminder'}
                                            userSpecific={'TCOUsers'}
                                            setAssignTo={setAssignToState}
                                            assignees={assignToState?.map((ele) => ele.id)}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
            </FormProvider>
        </Box>
    );
});

FailedToReminder.propTypes = {
    value: PropTypes.bool,
    handleReminderUpdate: PropTypes.func,
    isOverview: PropTypes.bool,
    onOpen: PropTypes.func,
    setConfirmMessage: PropTypes.string,
};

export default FailedToReminder;