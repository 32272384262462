/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
// utils
import { callApi } from '../../utils/apiCall';
//
import { dispatch, store } from '../store';

// ---------------------------------------------------------------------
const initialState = {
    facebookAuthToken: null,
    isFacebookAuthTokenLoading: false,
    practiceMetaData: null,
    allLeadData: [],
    allLeadDataLoading: false,
    threads: [],
    availablePhoneNumbers: [],
    currentPhoneNumberData: {},
    jwtToken: null,
    whatsAppThreadData: {},
    whatsaAppNotifCount: {},
    isFailed: 0,
    threadLoading: false,
    whatsAppTemplates: [],
    whatsAppMapAccountToPracticeLoading: false,
    availableBusinessAccounts: [],
    phoneIdArrayAddedToCrm: [],
    threadNext: null,
    messageNext: null,
    searchQuery: '',
    filter: ''
};

const slice = createSlice({
    name: 'whatsApp',
    initialState,
    reducers: {
        // For Reseting the reducer
        reset(state) {
            Object.assign(state, initialState);
        },
        resetChatData(state) {
            state.practiceMetaData = null;
            state.allLeadData = [];
            state.allLeadDataLoading = false;
            state.threads = [];
            state.availablePhoneNumbers = [];
            state.currentPhoneNumberData = {};
            state.jwtToken = null;
            state.whatsAppThreadData = {};
            state.isFailed = 0;
            state.whatsAppTemplates = [];
            state.code = '';
        },
        startWhatsAppMapAccountToPracticeLoading(state) {
            state.code = '';
        },
        endWhatsAppMapAccountToPracticeLoading(state, action) {
            state.availableBusinessAccounts = action?.payload?.businessAccounts;
            state.code = action?.payload?.code;
            state.whatsAppMapAccountToPracticeLoading = true;
        },
        completeWhatsappMapping(state) {
            state.whatsAppMapAccountToPracticeLoading = false;
        },
        resetWhatsAppNotifCount(state) {
            state.whatsaAppNotifCount = {};
        },
        // For Facebook oAuth Tokens
        setFacebookAuthToken(state, action) {
            state.facebookAuthToken = action?.payload;
        },
        completeAuthTokenInitialization(state, action) {
            const { threads, currentPhoneNumberData, availablePhoneNumbers, phoneIdArrayAddedToCrm, jwtToken, failed, ...rest } =
                action?.payload ?? {};
            state.isFacebookAuthTokenLoading = false;
            state.practiceMetaData = rest ?? null;
            state.threads = threads ?? [];
            state.currentPhoneNumberData = currentPhoneNumberData ?? {};
            state.availablePhoneNumbers = availablePhoneNumbers ?? [];
            state.phoneIdArrayAddedToCrm = phoneIdArrayAddedToCrm ?? [];
            state.jwtToken = jwtToken ?? null;
            state.isFailed = failed ?? 0;
            state.whatsAppTemplates = rest?.whatsAppTemplates;
            state.threadNext = rest?.threadNext;
            if (failed) {
                state.facebookAuthToken = null;
            }
        },
        getFacebookAuthToken(state) {
            state.isFacebookAuthTokenLoading = true;
        },
        initializeWhatsapp(state, action) {
            const { isPhNoAvailable, phNoData } = action?.payload;
            if (isPhNoAvailable) {
                state.currentPhoneNumberData = phNoData;
            }
            state.isFacebookAuthTokenLoading = true;
            state.jwtToken = null;
            state.isFailed = 0;
        },
        // For All Leads Dropdown
        getAllLeadsInfo(state) {
            state.allLeadData = [];
            state.allLeadDataLoading = true;
        },
        storeAllLeadDataInfo(state, action) {
            state.allLeadData = action?.payload;
            state.allLeadDataLoading = false;
        },
        refreshThreads(state, action) {
            let refreshAll = action?.payload?.refreshAll;
            const threadId = action?.payload?.threadId || -1;
            if (threadId === -1) refreshAll = true;
            if (refreshAll) {
                state.threads = action?.payload?.filteredThreadMessage;
                state.threadNext = action?.payload?.threadNext;
            } else {
                try {
                    const threadsDuplicate = window.structuredClone(action?.payload?.currentThreads);
                    const threadIndex = threadsDuplicate?.findIndex(obj => obj?.id === threadId);
                    if (threadIndex === -1) console.error("Error while fetching whatsapp thread info :: ", action?.payload?.filteredThreadMessage);
                    threadsDuplicate[threadIndex] = action?.payload?.filteredThreadMessage;
                    state.threads = threadsDuplicate;
                } catch (error) {
                    console.error(`Error while fetching whatsapp thread info :: ${action?.payload?.filteredThreadMessage} \r Error :: ${error}`);
                }
            }
        },
        getThreadData(state) {
            state.threadLoading = true;
        },
        storeWhatsappThreadMessages(state, action) {
            state.whatsAppThreadData = {
                ...(state.whatsAppThreadData ?? {}),
                [action?.payload?.to_waid]: action?.payload?.threadData,
            };
            state.messageNext = {
                ...(state.messageNext ?? {}),
                [action?.payload?.to_waid]: action?.payload?.messageNext
            }
            state.threadLoading = false;
        },
        addWhatsappNotifCount(state, action) {
            const phoneNumber = action?.payload;
            state.whatsaAppNotifCount = {
                ...state.whatsaAppNotifCount,
                [phoneNumber]: (state?.whatsaAppNotifCount?.[phoneNumber] ?? 0) + 1,
            };
        },
        subtractWhatsappNotifCount(state, action) {
            const phoneNumber = action?.payload;
            state.whatsaAppNotifCount = { ...state.whatsaAppNotifCount, [phoneNumber]: 0 };
        },
    },
});

// Reducer
export default slice.reducer;

// For Facebook oAuth Tokens
export const { setFacebookAuthToken, getFacebookAuthToken, initializeWhatsapp, reset, resetChatData } = slice.actions;

// For All Leads Dropdown
export const { getAllLeadsInfo, storeAllLeadDataInfo } = slice.actions;

// For whatsapp Notifications
export const { addWhatsappNotifCount, subtractWhatsappNotifCount, resetWhatsAppNotifCount } = slice.actions;

const getPracticeId = () => store.getState()?.practiceTreatmentList?.practiceId;

export function initializeFacebookAuthToken(isPhNoAvailable = false, phNoData = {}, businessIndex = -1, syncTemplate = false, phNoIndex = 0) {
    return async () => {
        try {
            dispatch(slice.actions.initializeWhatsapp({ isPhNoAvailable, phNoData }));

            const practiceId = getPracticeId();

            // 2. get all the available business accounts - fetches detail of account mapped
            const availableWhatsaAppBusinessAccResp = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/`,
                'GET'
            );
            const { count, results } = availableWhatsaAppBusinessAccResp?.data ?? {};
            if (count === 0) {
                dispatch(slice.actions.completeAuthTokenInitialization({ failed: 1 }));
                return;
            }
            const name = businessIndex === -1 ? results?.[0]?.name : results?.[businessIndex]?.name;
            const id = businessIndex === -1 ? results?.[0]?.id : results?.[businessIndex]?.id;
            const reviewStatus = businessIndex === -1 ? results?.[0]?.reviewStatus : results?.[businessIndex]?.reviewStatus;
            const finalFormData = new FormData();
            finalFormData.append('practice', practiceId);
            finalFormData.append('name', name);
            finalFormData.append('account_id', id);
            finalFormData.append('account_review_status', reviewStatus);

            const resp = {
                data: {
                    id: businessIndex === -1 ? results?.[0]?.id : results?.[businessIndex]?.id,
                },
            };

            // 5. Find all the phone numbers mapped to CRM
            const checkPhoneNumberToCrmResponse = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/whatsapp-phones/`,
                'GET'
            );
            const { count: phoneNumberCount, results: phoneNumbersAddedToCrm } = checkPhoneNumberToCrmResponse?.data;
            const phoneIdArrayAddedToCrm = phoneNumbersAddedToCrm?.map(e => e?.phone_number_id);
            const whatsAppBusiessAccountId = resp?.data?.id;
            // 4. Get List of All the Available Phone Numbers
            const respAvlPhoneNumbers = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/available_phone_numbers/`,
                'GET',
                finalFormData
            );
            const allAvlPhoneData = {};

            const getAvailablePhoneNumbers = async (uId) => {
                const respAvlPhoneNumbersAll = await callApi(
                    `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${uId}/available_phone_numbers/`,
                    'GET',
                    finalFormData
                );
                return respAvlPhoneNumbersAll;
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const result of results || []) {
                const { id: uId } = result;
                // eslint-disable-next-line no-await-in-loop
                const respAvlPhoneNumbersAll = await getAvailablePhoneNumbers(uId);
                const allPhoneIds = respAvlPhoneNumbersAll?.data?.data?.map((dt) => dt?.id);
                allAvlPhoneData[uId] = allPhoneIds;
            };

            let respDataObj = respAvlPhoneNumbers?.data?.data?.filter(phoneNumberId => phoneIdArrayAddedToCrm?.includes(phoneNumberId?.id))?.[phNoIndex] ?? {};
            if (isPhNoAvailable) {
                respDataObj = phNoData;
            }
            const {
                verified_name,
                code_verification_status,
                display_phone_number,
                quality_rating,
                id: phoneId,
            } = respDataObj ?? {};


            const index = phoneNumbersAddedToCrm?.findIndex((dt) => dt?.phone_number_id === phoneId);
            let addPhoneNumberToCrmResponse;
            if (phoneNumberCount === 0 || index === -1) {
                // 6. Register the phone number if not found in CRM
                const phoneFormData = new FormData();
                phoneFormData.append('whatsapp_business_account', resp?.data?.id);
                phoneFormData.append('phone_number_id', phoneId);
                phoneFormData.append('display_phone_number', display_phone_number);
                phoneFormData.append('verified_name', verified_name);
                phoneFormData.append('code_verification_status', code_verification_status);
                phoneFormData.append('quality_rating', quality_rating);

                addPhoneNumberToCrmResponse = await callApi(
                    `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/whatsapp-phones/`,
                    'POST',
                    phoneFormData
                );
            }
            const uniqueId = addPhoneNumberToCrmResponse?.data?.id || phoneNumbersAddedToCrm?.[index]?.id;

            if (syncTemplate) {
                await callApi(
                    `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/sync_templates/`,
                    'POST'
                );
            }
            // getting whatsapp template
            const templateResponse = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/whatsapp-message-templates/`
            );

            // 7. Get the thread informations
            const threadsResponse = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/whatsapp-phones/${uniqueId}/whatsapp-message-threads?ordering=whatsapp_messages__created_time&is_archived_included=true`,
                'GET'
            );

            // 8. Get JWT Token for whatsapp response
            const jwtResp = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${resp?.data?.id}/whatsapp-phones/${uniqueId}/generate_websocket_jwt/`,
                'POST'
            );
            const filteredThreadMessage = threadsResponse?.data?.results?.filter((dt) => dt?.from_phone === uniqueId);
            const threadNext = threadsResponse?.data?.next;
            dispatch(
                slice.actions.completeAuthTokenInitialization({
                    name,
                    accountId: id,
                    reviewStatus,
                    practiceId,
                    whatsAppBusiessAccountId,
                    phoneId,
                    uniqueId,
                    whatsAppAccountId: resp?.data?.id,
                    availablePhoneNumbers: respAvlPhoneNumbers?.data?.data,
                    currentPhoneNumberData: isPhNoAvailable ? phNoData : respAvlPhoneNumbers?.data?.data?.[phNoIndex],
                    jwtToken: jwtResp?.data?.auth,
                    threads: filteredThreadMessage,
                    allBusinessAccounts: results,
                    businessIndex: businessIndex === -1 ? 0 : businessIndex,
                    allAvlPhoneData,
                    whatsAppTemplates: templateResponse?.data?.results ?? [],
                    phoneIdArrayAddedToCrm,
                    threadNext
                })
            );
        } catch (err) {
            console.error('Error triggered while initializaing facebook OAuth');
            dispatch(slice.actions.completeAuthTokenInitialization());
        }
    };
}

export function setAccountWithFacebookAuthToken(account) {
    return async () => {
        dispatch(slice.actions.completeWhatsappMapping());
        const practiceId = getPracticeId();
        const { account_review_status: reviewStatus, id, name } = account ?? {};
        const finalFormData = new FormData();
        finalFormData.append('practice', practiceId);
        finalFormData.append('name', name);
        finalFormData.append('account_id', id);
        finalFormData.append('account_review_status', reviewStatus);
        await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/`, 'POST', finalFormData);
        dispatch(initializeFacebookAuthToken());
    };
}

export function startMapAccountToPractice(code) {
    return async () => {
        try {
            if (code === -1) {
                dispatch(slice.actions.completeAuthTokenInitialization({ failed: 1 }));
                return;
            }
            dispatch(slice.actions.startWhatsAppMapAccountToPracticeLoading());
            const practiceId = getPracticeId();

            // 1. Get Business accounts mapped
            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/get_available_business_accounts/`, 'GET');
            dispatch(slice.actions.endWhatsAppMapAccountToPracticeLoading({
                code,
                businessAccounts: response?.data?.data ?? []
            }));
        } catch (err) {
            console.error(err);
            dispatch(slice.actions.endWhatsAppMapAccountToPracticeLoading([]));
        }
    };
}

export function getAllLeadDataForDropdown(searchpayload) {
    return async () => {
        try {
            dispatch(getAllLeadsInfo());
            const practiceId = getPracticeId();
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/search/?query=${searchpayload}&offset=0&ordering=-created_at&is_archived=false`
            );
            dispatch(storeAllLeadDataInfo(response?.data?.results ?? []));
        } catch (err) {
            console.error('Error in getting all lead info', err);
            dispatch(storeAllLeadDataInfo([]));
        }
    };
}

export function getLeadDataForPhoneNumber(phoneNumber, cb) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/search/?query=${phoneNumber}&offset=0&ordering=-created_at&is_archived=false`
            );
            const results = response?.data?.results;
            if (results?.length > 0 && phoneNumber?.length > 0) {
                const dataObj = results?.[0];
                cb({ success: true, ...dataObj });
                return dataObj;
            }
            cb({ success: false });
            return {};
        } catch (err) {
            console.error('Error in getting phoneNumber Details', err);
            cb({ success: false });
            return {};
        }
    };
}

export function getLeadInfo(leadId, callBackToSetLeadInfo) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const respLead = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadId}/`,
                'GET'
            );
            if (respLead?.status === 200) callBackToSetLeadInfo(respLead?.data);
        } catch (err) {
            console.error(err);
        }
    };
}

export function getThreadMessages({ phoneNumber, id }) {
    const practiceId = getPracticeId();
    const whatsAppStore = store.getState().whatsApp;
    const whatsAppAccountId = whatsAppStore?.practiceMetaData?.whatsAppAccountId;
    const uniqueId = whatsAppStore?.practiceMetaData?.uniqueId;
    dispatch(slice.actions.getThreadData());
    if (!id) {
        dispatch(
            slice.actions.storeWhatsappThreadMessages({
                to_waid: phoneNumber,
                threadData: []
            })
        );
    } else
        callApi(
            `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads/${id}/whatsapp-messages/?ordering=-created_time&is_archived_included=true`,
            'GET'
        ).then((data) =>
            dispatch(
                slice.actions.storeWhatsappThreadMessages({
                    to_waid: phoneNumber,
                    threadData: data?.data?.results?.reverse(),
                    messageNext: data?.data?.next
                })
            )
        );
}

export function getNextThreads() {
    return async () => {
        try {
            const whatsAppStore = store.getState().whatsApp;
            const nextQuery = whatsAppStore?.threadNext;

            const result = nextQuery.match(/api\/v1.*/);
            const extractedPath = result ? result[0] : null;
            const threadsResponse = await callApi(`/${extractedPath}`, 'GET');
            const threadNext = threadsResponse?.data?.next;
            const uniqueId = whatsAppStore?.practiceMetaData?.uniqueId;
            const filteredThreadMessage = threadsResponse?.data?.results?.filter((dt) => dt?.from_phone === uniqueId);
            const oldThreads = whatsAppStore?.threads ?? [];
            const newThreads = [...oldThreads, ...filteredThreadMessage];
            dispatch(slice.actions.refreshThreads({ filteredThreadMessage: newThreads, threadNext, refreshAll: true }));
        } catch (err) {
            console.error("Error in getting next thread Info :: ", err);
        }
    }
}

export function getNextMessages({ phoneNumber }) {
    return async () => {
        try {
            const whatsAppStore = store.getState().whatsApp;
            const currentMessageThread = [...(whatsAppStore?.whatsAppThreadData?.[phoneNumber] ?? [])]?.reverse();
            const nextQuery = whatsAppStore?.messageNext?.[phoneNumber];
            const result = nextQuery.match(/api\/v1.*/);
            const extractedPath = result ? result[0] : null;
            await callApi(
                `/${extractedPath}`,
                'GET'
            ).then((data) =>
                dispatch(
                    slice.actions.storeWhatsappThreadMessages({
                        to_waid: phoneNumber,
                        threadData: [...(currentMessageThread ?? []), ...(data?.data?.results ?? [])]?.reverse(),
                        messageNext: data?.data?.next
                    })
                )
            );
        } catch (err) {
            console.error("Error in fetching next set of whatsapp messages :: ", err);
        }
    }
}

export function refreshThreadMessages(threadId, refreshMessages = true) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const whatsAppStore = store.getState().whatsApp;
            const whatsAppAccountId = whatsAppStore?.practiceMetaData?.whatsAppAccountId;
            const uniqueId = whatsAppStore?.practiceMetaData?.uniqueId;
            const refreshAll = !threadId || threadId === -1;
            const threadsResponse = refreshAll ? await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads?ordering=whatsapp_messages__created_time&is_archived_included=true`,
                'GET'
            ) : await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads/${threadId}/?ordering=whatsapp_messages__created_time&is_archived_included=true`,
                'GET'
            );
            const threadNext = threadsResponse?.data?.next;
            let filteredThreadMessage = !refreshAll ? threadsResponse?.data : threadsResponse?.data?.results?.filter((dt) => dt?.from_phone === uniqueId);
            dispatch(slice.actions.refreshThreads({ filteredThreadMessage, threadNext, refreshAll, threadId, currentThreads: whatsAppStore?.threads }));
            dispatch(slice.actions.getThreadData());
            if (!refreshAll) filteredThreadMessage = [filteredThreadMessage]; // changing to array
            filteredThreadMessage?.forEach((thread) => {
                if (refreshMessages && (!threadId || threadId === thread?.id))
                    callApi(
                        `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads/${thread?.id}/whatsapp-messages/?ordering=-created_time&is_archived_included=true`,
                        'GET'
                    ).then((data) =>
                        dispatch(
                            slice.actions.storeWhatsappThreadMessages({
                                to_waid: thread?.to_waid,
                                threadData: data?.data?.results?.reverse(),
                                messageNext: data?.data?.next
                            })
                        )
                    );
            });
        } catch (err) {
            console.error('Error in refershing threads', err);
        }
    };
}

export function whatsappMarkAsRead(threadId) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const whatsAppStore = store.getState().whatsApp;
            const whatsAppAccountId = whatsAppStore?.practiceMetaData?.whatsAppAccountId;
            const uniqueId = whatsAppStore?.practiceMetaData?.uniqueId;
            await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads/${threadId}/mark_as_read/?is_archived_included=true`,
                'POST'
            );
            dispatch(refreshThreadMessages(threadId, false));
        } catch (e) {
            console.error(e);
        }
    };
}

export function whatsappMarkAsUnRead(threadId) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const whatsAppStore = store.getState().whatsApp;
            const whatsAppAccountId = whatsAppStore?.practiceMetaData?.whatsAppAccountId;
            const uniqueId = whatsAppStore?.practiceMetaData?.uniqueId;
            await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${whatsAppAccountId}/whatsapp-phones/${uniqueId}/whatsapp-message-threads/${threadId}/mark_as_unread/?is_archived_included=true`,
                'POST'
            );
            dispatch(refreshThreadMessages(threadId, false));
        } catch (e) {
            console.error(e);
        }
    };
}

export function mapWhatsappNumberToLead(payload) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            let phoneNumberToSend = payload?.phoneNumber;
            const leadDetails = payload?.lead;
            if (phoneNumberToSend?.length === 10) {
                // Adding UK Code for NonCode Phone Numbers
                phoneNumberToSend = `44${phoneNumberToSend}`;
            }

            await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${payload?.whatsAppAccountId}/whatsapp-phones/${payload?.uniqueId}/whatsapp-message-threads/${payload?.id}`,
                'PATCH',
                {
                    name: `${leadDetails?.first_name} ${leadDetails?.last_name}`,
                    lead: leadDetails?.id
                }
            );
            dispatch(refreshThreadMessages(payload?.id));
        } catch (err) {
            console.error('Error in mapping whatsapp number to lead');
        }
    };
}

export function sendWhatsAppMessage(payload) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const data = {};
            const threadId = payload?.threadInfo?.id;
            let phoneNumberToSend = payload?.phoneNumber;
            if (phoneNumberToSend?.length === 10) {
                // Adding UK Code for NonCode Phone Numbers
                phoneNumberToSend = `44${phoneNumberToSend}`;
                const updatePhoneNoFormData = {
                    id: payload?.leadId,
                    lead_contact: { phone: phoneNumberToSend }
                };
                await callApi(`/api/v1/practices/${practiceId}/leads/${payload?.leadId}/`, 'PATCH', updatePhoneNoFormData);
            }
            data.message_type = payload?.messageType ?? 'text';
            data.to = phoneNumberToSend;
            if (payload?.messageType) data.template_name = payload?.messageToSend;
            else data.message = payload?.messageToSend;
            if (payload?.leadId) {
                data.lead_id = payload?.leadId;
            }
            let context = {};
            if (payload?.messageType === 'template') {
                const respContextPractice = await callApi(
                    `/api/v1/practices/${practiceId}/get_context/`,
                    'GET'
                );
                const respContextLead = await callApi(
                    `/api/v1/practices/${practiceId}/leads/${payload?.leadId}/get_context/`,
                    'GET'
                );
                const responseTemplateForLead = await callApi(`/api/v1/practices/${practiceId}/leads/${payload?.leadId}/treatments/`);
                const templateIdList = responseTemplateForLead?.data?.results?.map(dt => dt?.id) ?? [];
                let treatmentContext = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const treatmentId of templateIdList) {
                    // eslint-disable-next-line no-await-in-loop
                    const treatMentContextResponse = await callApi(`/api/v1/practices/${practiceId}/leads/${payload?.leadId}/treatments/${treatmentId}/get_context/`);
                    treatmentContext = { ...treatmentContext, ...treatMentContextResponse?.data };
                }
                context = {
                    ...context, ...(respContextPractice?.data ?? {}), ...(respContextLead?.data ?? {}), ...(treatmentContext ?? {})
                };
                data.template_context = context;
            }
            // eslint-disable-next-line no-unused-vars
            const response = await callApi(
                `/api/v1/practices/${practiceId}/whatsapp-business-accounts/${payload?.whatsAppBusiessAccountId}/whatsapp-phones/${payload?.uniqueId}/send_message/`,
                'POST',
                data
            );
            dispatch(refreshThreadMessages(threadId));
        } catch (err) {
            console.error('Error in sending whatsapp message', err);
        }
    };
}

export function deleteBusinessAccount(account) {
    return async () => {
        try {
            await callApi(`/api/v1/practices/${account?.practice}/whatsapp-business-accounts/${account?.id}/`, 'DELETE');
            dispatch(initializeFacebookAuthToken());
        } catch (err) {
            console.error('Error in sending whatsapp message', err);
            dispatch(initializeFacebookAuthToken());
        }
    };
}