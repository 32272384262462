import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// libraries
import { useLocation } from 'react-router-dom';
import { isEmpty, isPlainObject } from 'lodash';
import { Box, Card, Table, TableBody, TableContainer, TablePagination, Button } from '@mui/material';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// sections
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { NewLeadTableRow } from '../../sections/@dashboard/lead';
// component
import { Iconify, Scrollbar } from '../../components';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../components/table';
import { TableLoading } from '../../components/loading';
// redux
import { getActivities } from '../../redux/slices/activity';
import { dispatch, useSelector } from '../../redux/store';
import {
    clearLead,
    updateSteps,
    updateFollowUpLead,
    getLeadReminderData,
    startLoadingData,
    updateCounter,
    updateDashNewLead,
    updateTableType,
    startArchivedLoading,
} from '../../redux/slices/lead';
import { setCallScriptSection } from '../../redux/slices/callScript';
import { clearMessage, } from '../../redux/slices/practiceTreatment';
// hooks/constants/util
import { useTabs, useTable } from '../../hooks';
import { emptyRows } from '../../hooks/useTable';
import { onSortValue } from '../../constants/newLead';
import {
    SelectTableTab,
    TablePageNumber,
    TablePerPage,
    TableTreatment,
    TableFilterDate,
    TableOrder,
    TableOrderBy,
    UpdateTableData,
} from '../../utils/allTableTab';
import { applySortFilter, handleClearReportTable, isDisabled, processLeadData } from '../../utils/reportsUtils';
// style
import { tableStyle, paginationBox } from '../../styles/NewLead';
import { tableContainerStyle } from '../../styles/ReportsNewLead';

const ReportsNewLead = ({
    leadData,
    updateLeadPage,
    pageCount,
    tableLabels,
    type,
    onClose,
    rowStatus,
    handleCreateAudience
}) => {
    const {
        page,
        order,
        onSort,
        orderBy,
        setPage,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        setRowsPerPage
    } = useTable({ defaultOrder: 'desc' });
    const location = useLocation();
    const currentPagePath = location.pathname;
    const { enqueueSnackbar } = useSnackbar();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { message, error } = useSelector((state) => state.practiceTreatment);
    const { results } = useSelector((state) => state.practiceTreatment?.treatmentsList);
    const { reportsLoading } = useSelector((state) => state.analytics);
    const {
        followUpLead,
        taskTableData: { limit, offset, filter, assignUser }
    } = useSelector((state) => state.lead);
    const { practiceTreatment } = useSelector((state) => state.practiceTreatmentList);
    const isReportData = type !== 'reportTableData';
    const pageNumber = TablePageNumber(type);
    const perPage = TablePerPage(type);
    const treatmentValue = TableTreatment(type);
    const searchFilterDate = TableFilterDate(type);
    const tableOrder = TableOrder(type);
    const tableOrderBy = TableOrderBy(type);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const [tableData, setTableData] = useState(leadData);
    const [selectedRow,] = useState(null);
    const [sortValue, setSortValue] = useState('');
    const [checkOptions, setCheckOptions] = useState(false);
    const headLabel = JSON.parse(localStorage.getItem(`${practiceId}-${type}`)) || tableLabels;
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    useEffect(() => {
        if (type) {
            dispatch(updateTableType(type));
        }
    }, [type]);

    const handleClose = (status, data) => {
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            order: tableOrder,
            orderId: tableOrderBy,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
        };
        if (status === 'finish' && followUpLead !== 'restoreArchived') {
            dispatch(updateCounter([]));
            updateLeadPage(
                !isEmpty(data) && isPlainObject(data) ? data : reloadPayLoad
            );
            dispatch(
                getLeadReminderData(
                    practiceId,
                    'dashboard',
                    limit,
                    offset,
                    filter,
                    '',
                    assignUser
                )
            );
        }
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateSteps(null));
        dispatch(updateFollowUpLead(null));
        dispatch(setCallScriptSection(null));
    };

    const {
        currentTab: filterStatus,
    } = useTabs(SelectTableTab(type));

    const dataFiltered = applySortFilter({
        tableData,
        filterStatus
    });

    const isNotFound =
        (!dataFiltered.length &&
            (!!filterStatus || filterStatus === 0 ? true : filterStatus)) ||
        !dataFiltered;

    useEffect(() => {
        setRowsPerPage(perPage);
        setPage(pageNumber / perPage);
        dispatch(startLoadingData(true));
        dispatch(updateDashNewLead({ results: [] }));
        if (results) {
            dispatch(updateCounter([]));
        }
    }, [practiceId, practiceTreatment]);

    useEffect(() => {
        if (selectedRow && followUpLead === 'contactLead') {
            dispatch(getActivities(practiceId, selectedRow?.lead_id, 10, 0));
        }
    }, [selectedRow, practiceId]);

    useEffect(() => {
        const rowData = processLeadData(leadData, rowStatus);
        if (rowData) {
            setTableData(rowData);
        }
    }, [leadData, page]);

    useEffect(() => {
        if (leadData.length > 0) {
            dispatch(startArchivedLoading(false));
        }
    }, [leadData]);

    useEffect(() => {
        if (message) {
            enqueueSnackbar(message, {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
        if (error) {
            enqueueSnackbar(error?.detail || 'something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
    }, [message, error]);

    const handleChangePage = (event, newPage) => {
        const payloadData = {
            limit: rowsPerPage,
            offset: rowsPerPage * newPage,
            order: tableOrder,
            orderId: tableOrderBy,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
        };
        updateLeadPage(payloadData);
        onChangePage(event, newPage);
        UpdateTableData(type, { pageNumber: rowsPerPage * newPage });
    };

    const handleChangePerPage = (event) => {
        const payloadData = {
            limit: event.target.value,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
        };
        updateLeadPage(payloadData);
        onChangeRowsPerPage(event);
        UpdateTableData(type, { perPage: event.target.value });
    };

    const orderHandler = (headCell, data) => {
        if (data.sort === '') return;
        UpdateTableData(type, {
            order,
            orderValue: data.id,
            perPage: 10,
            pageNumber: 0
        });
        onSort(data.id);
        setSortValue(data.id);
    };

    useEffect(() => {
        if (tableOrder || tableOrderBy) {
            sortingApiCall(sortValue, tableOrder, tableOrderBy);
        }
    }, [tableOrder, tableOrderBy]);

    const sortingApiCall = (id, defaultOrder, fieldValue) => {
        if (!onSortValue.includes(fieldValue) && id !== '') {
            onChangePage(id, 0);
            const payloadData = {
                limit: rowsPerPage,
                offset: 0,
                filterStatus,
                order: defaultOrder,
                orderId: id,
                treatment: treatmentData?.toString(),
                startDate,
                endDate,
            };
            updateLeadPage(payloadData);
        }
    };

    useEffect(() => {
        if (!followUpLead) {
            handleClose();
            dispatch(updateSteps(null));
        }
    }, [followUpLead]);

    useEffect(() => {
        if (results && checkOptions) {
            const data = treatmentValue.filter(
                (el) => results.map((elem) => elem?.id).includes(el?.id) && el
            );
            UpdateTableData(type, { treatment: data, perPage: 10, pageNumber: 0 });
            setCheckOptions(false);
        }
    }, [checkOptions]);

    return (
        <>
            <Box sx={{ textAlign: 'right', mb: 3 }}>
                <Button
                    variant='contained'
                    sx={{ marginRight: '16px' }}
                    onClick={handleCreateAudience}
                    disabled={tableData.length === 0}
                >
                    <Iconify
                        icon={'ic:baseline-plus'}
                        width={20}
                        height={20}
                        sx={{ mr: 1 }}
                    />
                    Generate Audience
                </Button>
                <Button variant='outlined' onClick={() => handleClearReportTable({ onClose, UpdateTableData, type })}>Cancel</Button>
            </Box>
            <Box sx={{ mb: 0 }}>
                <Card sx={{ padding: '0', ...isDisabled({ reportsLoading }) }}>
                    <Scrollbar sx={{ pl: '0', pr: '0' }}>
                        <TableContainer sx={tableContainerStyle}>
                            <Table sx={tableStyle} stickyHeader>
                                <TableHeadCustom
                                    headLabel={headLabel}
                                    rowCount={tableData.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSort={orderHandler}
                                    type={type}
                                    leadTable
                                    tableHeadCustom
                                    isReportData={isReportData}
                                />
                                <TableBody>
                                    {
                                        <TableLoading
                                            tableData={
                                                (tableData.length === 0 ||
                                                    reportsLoading) &&
                                                'No Found'
                                            }
                                            pageCount={
                                                (pageCount === undefined ||
                                                    reportsLoading) &&
                                                'No Found'
                                            }
                                            type={'table'}
                                        />
                                    }
                                    {tableData.length > 0 &&
                                        tableData.map((row, index) => (
                                            <NewLeadTableRow
                                                key={index}
                                                row={row}
                                                typed={type}
                                                tableTitle={'Reports table'}
                                                tablePath={currentPagePath}
                                            />
                                        ))}
                                    {tableData.length === 0 && pageCount === 0 && (
                                        <>
                                            <TableEmptyRows
                                                emptyRows={emptyRows(
                                                    page,
                                                    rowsPerPage,
                                                    tableData.length
                                                )}
                                            />
                                            {isNotFound && <TableNoData isNotFound={isNotFound} />}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <Box sx={paginationBox}>
                        {pageCount > 10 && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25]}
                                component='div'
                                rowsPerPage={rowsPerPage}
                                count={pageCount}
                                page={page}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                onRowsPerPageChange={(event) => handleChangePerPage(event)}
                            />
                        )}
                    </Box>
                </Card>
            </Box>
        </>
    );
};

ReportsNewLead.propTypes = {
    leadData: PropTypes.array,
    updateLeadPage: PropTypes.func,
    pageCount: PropTypes.number,
    tableLabels: PropTypes.array,
    type: PropTypes.string,
    onClose: PropTypes.func,
    rowStatus: PropTypes.func,
    handleCreateAudience: PropTypes.func
};

export default ReportsNewLead;