/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Grow, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import getStyles from 'src/styles/whatsappStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sendWhatsAppMessage, getThreadMessages, getLeadDataForPhoneNumber, mapWhatsappNumberToLead, whatsappMarkAsUnRead, getLeadInfo, refreshThreadMessages, getNextMessages } from 'src/redux/slices/whatsapp';
import { ClickAwayListener } from '@mui/base';
import { getTime } from 'date-fns';
import { ArchivedLeadDashBoard, clearLead, ConsultationAttended, followUpAppointmentHandler, followUpSlice, getLeadReminderData, leadPage, RecordAttended, recordUnAttended, restoreArchived, saveNewLeadDetail, selectLeads, tableLead, updateBookingSteps, updateFollowUpLead, updateLeadDetail, updateSteps } from 'src/redux/slices/lead';
import { useLocation, useNavigate } from 'react-router';
import { statusStyle } from 'src/styles/OverView';
import { CreateLeadHeaderData, TAB_HEAD } from 'src/constants/LeadDetailConst';
import { callApi } from 'src/utils/apiCall';
import { assignMainBoxStyle, tableCellInnerBoxStyle } from 'src/styles/NewLeadTableRowStyle';
import { useAddTreatment, useArchivedToggle, useAssignTaskToggle, useLeadTableHelper, useToggle } from 'src/hooks';
import TreatmentEditPopover from 'src/pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import { AppointmentPage, FollowUp } from 'src/sections/@dashboard/lead';
import { AttendedAppointment, MessageAndReminder, PaymentOption, PersonalDetails, TreatmentAndAppointment } from 'src/sections/@dashboard/general/app';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments } from 'src/redux/slices/mergeTags';
import { getActivities } from 'src/redux/slices/activity';
import { Activities } from 'src/sections/@dashboard/leadDetail';
import { handleLatestData } from 'src/utils/getLeadLatestData';
import { localStorageRemoveItem } from 'src/utils/storage-available';
import { setLoader } from 'src/redux/slices/loader';
import { setCallScriptSection } from 'src/redux/slices/callScript';
import AssigneeField from 'src/pages/dashboard/tables/AllLeadTable/AssigneeField';
import { getAssignees } from 'src/utils/multiAsignees';
import { clearPracticeUserData } from 'src/redux/slices/practiceUser';
import { borderRadiosStyle, zIndexStyle } from 'src/styles/NewLead';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UnableToContact } from '../failedToAttendTabs';
import { OpenContactLeadPopup } from '../fullScreenPopUps';
import MediaDisplayComponent from './MediaDisplayComponent';
import { Iconify } from '..';
import { ArchivedOrRestorePopup, AssignTaskPopup } from '../dialogPopUp';

function isWithinLast24Hours(dateString) {
    // Parse the input date string as UK time
    const ukTime = new Date(dateString);

    // Check if the parsed date is valid
    if (Number.isNaN(ukTime.getTime())) {
        return false;
    }

    // Get the current time
    const now = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = now.getTime() - ukTime.getTime();

    // Check if the time difference is less than or equal to 24 hours (24 hours = 24 * 60 * 60 * 1000 milliseconds)
    const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;

    return isWithin24Hours;
}

function formatUKDate(dateString) {
    // Parse the input date string as UK time
    const ukTime = new Date(dateString);

    // Check if the parsed date is valid
    if (Number.isNaN(ukTime)) {
        throw new Error('Invalid date format');
    }

    // Get the current local time and the time offset in minutes
    const now = new Date();

    // Adjust the UK time by the local offset to get the local time
    const localTime = new Date(ukTime.getTime());

    // Define the start and end of today in local time
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const todayEnd = new Date(todayStart.getTime() + 24 * 60 * 60 * 1000 - 1);

    // Define the format options for today and not today
    const optionsToday = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const optionsNotToday = {
        day: '2-digit',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    // Format and return the date string based on whether it is today or not
    if (localTime >= todayStart && localTime <= todayEnd) {
        return `Today, ${localTime.toLocaleString('en-US', optionsToday)}`;
    }
    return localTime.toLocaleString('en-US', optionsNotToday);

}

const StyledBadge = styled(Badge)(({ theme, isError }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: isError ? '#FF5630' : '#44b700',
        color: isError ? '#FF5630' : '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function TimeLeft({ inputDateTime }) {
    const [timeLeft, setTimeLeft] = React.useState('');
    const theme = useTheme();
    React.useEffect(() => {
        const targetTime = new Date(inputDateTime);
        targetTime.setHours(targetTime.getHours() + 24);
        const calculateTimeLeft = () => {
            const now = new Date();
            const difference = targetTime - now;

            if (difference <= 0) {
                setTimeLeft('0 seconds left');
                return;
            }

            const minutesLeft = Math.floor(difference / (1000 * 60)) % 60;
            const hoursLeft = Math.floor(difference / (1000 * 60 * 60)) % 24;
            const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));

            let timeString = '';
            if (hoursLeft > 0) {
                timeString += `${hoursLeft} hours `;
            }
            else if (minutesLeft > 0) {
                timeString += `${minutesLeft} minutes `;
            }

            setTimeLeft(`${timeString.trim()} left`);
        };

        const timerId = setInterval(calculateTimeLeft, 1000);

        // Clean up the timer when the component is unmounted or inputDateTime changes
        return () => clearInterval(timerId);
    }, [inputDateTime]);

    return <Typography sx={{ fontSize: '0.5rem', color: theme.palette.text.disabled, whiteSpace: 'nowrap' }}>{timeLeft}</Typography>;
}

function MessageContainer(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { windowDimension, chatScreenData, setChatScreenData, changeBackground } = props;
    const styles = getStyles(theme);
    const dispatch = useDispatch();
    const [message, setMessage] = React.useState('');
    const [leadDetails, setLeadDetails] = React.useState(null);
    const [showRestore, setShowRestore] = React.useState(true);
    let nameAbv = `${chatScreenData?.name?.split(' ')?.[0]?.[0]?.toUpperCase() ?? ''}${chatScreenData?.name?.split(' ')?.[1]?.[0]?.toUpperCase() ?? ''
        }`;
    const isRegisteredNumber = chatScreenData?.leadId ?? false;
    const [leadData, setLeadData] = React.useState(null);
    const { toggle: openAddTreatment, onOpen: onOpenAddTreatment, onClose: onCloseAddTreatment } = useAddTreatment();
    const {
        toggle: openTreatmentEditPopup,
        onOpen: onopenTreatmentEditPopup,
        onClose: onCloseTreatmentEditPopup,
    } = useToggle();
    const {
        toggle: openContactLead,
        onOpen: onOpenContactLead,
        onClose: onCloseContactLead,
    } = useToggle();

    const { toggle: openAssignTask, onOpen: onOpenAssignTask, onClose: onCloseAssignTask } = useAssignTaskToggle();

    const { toggle: openArchiveLead, onOpen: onOpenArchiveLead, onClose: onCloseArchiveLead } = useArchivedToggle();
    const inputRef = React.useRef();

    if (!Number.isNaN(nameAbv?.[0])) nameAbv = '';
    const { practiceMetaData, whatsAppThreadData, messageNext, threads, threadLoading, whatsAppTemplates, practiceId, referral, lead, activityList } = useSelector(
        (state) => ({
            practiceMetaData: state?.whatsApp?.practiceMetaData,
            whatsAppThreadData: state?.whatsApp?.whatsAppThreadData,
            messageNext: state?.whatsApp?.messageNext,
            threads: state?.whatsApp?.threads,
            threadLoading: state?.whatsApp?.threadLoading,
            whatsAppTemplates: state?.whatsApp?.whatsAppTemplates,
            practiceId: state?.practiceTreatmentList?.practiceId,
            referral: state.schema?.referral ?? [],
            activityList: state.activity?.activityList,
            lead: state.lead
        }),
        shallowEqual
    );

    const [threadData, setThreadData] = React.useState([]);
    const [threadNext, setThreadNext] = React.useState(null);

    const [isNewChat, setNewChatFlag] = React.useState(false);
    const [enforceTemplate, setEnforceTemplate] = React.useState(false);
    const [isTemplateAnswerPending, setIsTemplateAnswerPending] = React.useState(false);
    const [useTemplate, setUseTemplate] = React.useState(false);
    const [isMessageSendProgress, setIsMessageSendProgress] = React.useState(false);
    const [showStickyAction, setShowStickyAction] = React.useState(0);
    const [anchorEditTreatment, setAnchorEditTreatment] = React.useState(null);
    const [anchorElAssign, setAnchorElAssign] = React.useState(null);
    const [addMediaControl, setAddMediaControl] = React.useState({
        "sample-url": {
            loading: false,
            success: false
        }
    });
    const [validStep, setValidStep] = React.useState(false);
    const [active, setActive] = React.useState(0);
    const [depositStatus, setDepositStatus] = React.useState(false);
    const {
        isAppointmentBooked,
        attendance,
        followUpLead,
        markAsComplete,
        contactLead,
        BookingAttendance,
        multiLead,
        taskTableData: { limit, offset, filter, assignUser },
        action,
        tableRowLead
    } = lead;
    const onSubmit = (data) => setValidStep(!!data);
    const handleClose = (status, data) => {
        dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails));
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateSteps(null));
        dispatch(updateFollowUpLead(null));
        onCloseArchiveLead();
        onCloseContactLead();
        dispatch(setCallScriptSection(null));
        setAnchorEditTreatment(null);
    };

    const STEPS = [
        {
            label: 'Contact',
            component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />,
        },
    ];
    const [leadStep, setLeadStep] = React.useState(STEPS);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const chatRef = React.useRef();
    const rowLead = React.useRef();
    const headerWidthRef = React.useRef();
    const headerInnerWidthRef = React.useRef();
    const headerRightInnerWidthRef = React.useRef();

    const calculateMaxWidth = () => {
        try {
            return `${headerWidthRef.current.offsetWidth - headerInnerWidthRef.current.offsetWidth - headerRightInnerWidthRef.current.offsetWidth}px`
        } catch (e) {
            return "30rem"
        }
    }

    React.useEffect(() => {
        setShowStickyAction(0);
        // const timer = setTimeout(() => {
        //     setShowStickyAction(true);
        // }, 2000); // Show popup after 5 seconds

        // return () => clearTimeout(timer); // Clean up timeout on component unmount
    }, [chatScreenData]);

    React.useEffect(() => {
        setThreadData([]);
        setThreadNext(null);
        setLeadData(null);
        setLeadDetails(null);
        const index = threads?.findIndex(e => e?.to_waid === chatScreenData?.phoneNumber || (chatScreenData?.leadId && e?.lead?.id === chatScreenData?.leadId));
        const phoneId = threads?.[index]?.id;
        if (chatScreenData?.leadId) {
            dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails));
        }
        getThreadMessages({ phoneNumber: chatScreenData?.phoneNumber, id: chatScreenData?.id ?? phoneId });

        if (!chatScreenData?.leadId) {
            dispatch(getLeadDataForPhoneNumber(chatScreenData?.phoneNumber, setLeadData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatScreenData, dispatch]);

    React.useEffect(() => {
        if (showStickyAction === 0 && ((leadDetails?.id && !leadDetails?.lead_treatments?.length > 0) || (chatScreenData?.threadInfo?.lead?.is_archived))) {
            setTimeout(() => {
                setShowStickyAction(true);
            }, 1000);
        }
    }, [leadDetails, showStickyAction]);

    React.useEffect(() => () => { setThreadData([]); setThreadNext(null) }, []);

    const handleSubmit = () => {
        if (message?.trim()?.length === 0) return;
        setThreadData((prv) =>
            prv?.length
                ? [...prv, { from_waid: -1, body: message, updated_at: 'NOW' }]
                : [{ from_waid: -1, body: message, updated_at: 'NOW' }]
        );
        dispatch(
            sendWhatsAppMessage({
                ...practiceMetaData,
                messageToSend: message,
                phoneNumber: chatScreenData?.phoneNumber,
                leadId: chatScreenData?.leadId,
                threadInfo: chatScreenData?.threadInfo
            })
        );
        setMessage('');
    };

    const getNextDataFromApi = () => {
        dispatch(getNextMessages({ phoneNumber: chatScreenData?.phoneNumber }))
    }

    const handleMapToLead = (leadDetails, phoneNumber) => {
        const payload = {
            ...practiceMetaData,
            phoneNumber,
            lead: leadDetails,
            id: chatScreenData?.id
        };
        dispatch(mapWhatsappNumberToLead(payload));
        setChatScreenData(null);
    };

    const handleTemplateClick = (template) => {
        const message = template?.components?.map?.((t) => t?.text)?.join('\n');
        setEnforceTemplate(false);
        setUseTemplate(false);
        setThreadData((prv) => prv?.length
            ? [...prv, { from_waid: -1, body: message, updated_at: 'NOW' }]
            : [{ from_waid: -1, body: message, updated_at: 'NOW' }]);
        dispatch(
            sendWhatsAppMessage({
                ...practiceMetaData,
                messageToSend: template?.name,
                phoneNumber: chatScreenData?.phoneNumber,
                messageType: 'template',
                leadId: chatScreenData?.leadId,
                threadInfo: chatScreenData?.threadInfo
            })
        );
    };
    const scrollableDiv = React.useRef(null);

    const getStatus = (treatment) => TAB_HEAD.map((e1) => {
        let latestData = '';
        if (treatment?.status === e1.value) {
            latestData = e1.label;
        }
        return latestData;
    });

    React.useEffect(() => {
        if (scrollableDiv?.current) {
            scrollableDiv.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [threadData]);
    const scrollToBottom = () => {
        scrollableDiv.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    React.useEffect(() => {
        if (scrollableDiv?.current) {
            scrollableDiv.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
        setThreadData(whatsAppThreadData?.[chatScreenData?.phoneNumber]);
        setThreadNext(messageNext?.[chatScreenData?.phoneNumber]);
        if (scrollableDiv?.current) {
            scrollableDiv.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [chatScreenData?.phoneNumber, showStickyAction, whatsAppThreadData, whatsAppThreadData?.chatScreenData?.phoneNumber]);

    React.useEffect(() => {
        if (!threadLoading && threadData?.length === 0) setNewChatFlag(true);
        else {
            setNewChatFlag(false);
            // if (threadLoading || !threadData || threadData?.length === 0) {
            //     setEnforceTemplate(false);
            //     return;
            // }
            const lastIndex = threadData?.length - 1;
            const thread = threadData?.[lastIndex];
            const date = thread?.created_at?.split(' ')?.[0]?.split('-');
            const time = thread?.created_at?.split(' ')?.[1];
            const newDateTimeString = `${date?.[1]}-${date?.[0]}-${date?.[2]} ${time}`;
            const isWithinLimit = isWithinLast24Hours(newDateTimeString);
            const shouldEnforceTemplate = !(isWithinLimit || thread?.updated_at === 'NOW');
            if (thread?.from_waid === -1) {
                setIsMessageSendProgress(true);
            }
            else {
                setIsMessageSendProgress(false);
            }
            if (enforceTemplate !== shouldEnforceTemplate && threadData?.length) {
                setEnforceTemplate(shouldEnforceTemplate);
            }
            if (thread?.message_type === 'template') {
                setIsTemplateAnswerPending(true);
            } else {
                setIsTemplateAnswerPending(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadLoading, threadData]);

    const arePhoneNumsEqual = (num1, num2) => {
        const firstPhoneNum = `${num1 ?? ''}`;
        const secondPhoneNum = `${num2 ?? ''}`;
        return firstPhoneNum?.slice(-10) === secondPhoneNum?.slice(-10);
    }

    const handleMediaSave = async (threadData) => {
        const mediaUrl = threadData?.media_file;
        const mediaId = threadData?.id;
        setAddMediaControl(prvObj => ({ ...prvObj, [mediaId]: { loading: true, success: false } }));
        try {
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads-media/`,
                'POST',
                {
                    "lead": chatScreenData?.leadId,
                    "remote_url": mediaUrl,
                    "media": mediaUrl,
                    "description": threadData?.whatsapp_media_mime_type,
                    "channel": "WHATSAPP",
                    "content_type": "whatsappmessage",
                    "object_id": threadData?.id
                }
            );
            setAddMediaControl(prvObj => ({ ...prvObj, [mediaId]: { loading: false, success: true } }));
        } catch (_) {
            setAddMediaControl(prvObj => ({ ...prvObj, [mediaId]: { loading: false, success: false } }));
        }
    }

    const handleOpenTreatment = (event, leadDetails) => {
        onOpenAddTreatment('openAddTreatment');
        setAnchorEditTreatment(event.currentTarget);
        dispatch(tableLead(leadDetails));
    }

    const handleOpenEditTreatment = (event, leadData) => {
        if (!leadData || !leadData?.lead_treatments?.length > 0) return;
        const latestData = handleLatestData(leadData, 'Whatsapp', 'All');
        const row = handleTableData(leadData, latestData);
        setSelectedRow(row);
        onopenTreatmentEditPopup();
        setAnchorEditTreatment(event.currentTarget);
        dispatch(tableLead(leadDetails));
    }

    const handleCloseAddTreatment = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseAddTreatment();
                setAnchorEditTreatment(null);
                return;
            }
            return;
        }
        onCloseAddTreatment();
        setAnchorEditTreatment(null);
        setShowStickyAction(false);
    };

    const handleCloseEditTreatment = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseTreatmentEditPopup();
                setAnchorEditTreatment(null);
                return;
            }
            return;
        }
        onCloseTreatmentEditPopup();
        setAnchorEditTreatment(null);
        setSelectedRow(null);
    };

    React.useEffect(() => {
        rowLead.current = leadDetails;
    }, [leadDetails]);

    React.useEffect(() => {
        if (markAsComplete) {
            switch (followUpLead) {
                case 'contactLead':
                    dispatch(updateLeadDetail(isAppointmentBooked, handleClose));
                    break;
                case 'consultationAttended':
                    dispatch(ConsultationAttended(handleClose));
                    break;
                case 'recordAttended':
                    dispatch(RecordAttended(handleClose));
                    break;
                case 'recordUnAttended':
                    dispatch(recordUnAttended(handleClose));
                    break;
                case 'archivedLead':
                    dispatch(ArchivedLeadDashBoard(handleClose));
                    break;
                case 'restoreArchived':
                    dispatch(restoreArchived(handleClose));
                    break;
                case 'StartTreatmentConfirmation':
                    dispatch(ConsultationAttended(handleClose));
                    break;
                default:
                    break;
            }
        }
    }, [markAsComplete, followUpLead]);

    React.useEffect(() => {
        if (contactLead) {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />,
                },
            ];
            setLeadStep(STEPS);
        } else {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <Activities activityList={activityList?.results} notAllow />,
                },
            ];
            setLeadStep(STEPS);
        }
    }, [contactLead]);

    React.useEffect(() => {
        if (BookingAttendance) {
            const steps = [...STEPS];
            const row = rowLead.current;
            steps.push({
                label: isAppointmentBooked,
                component:
                    isAppointmentBooked === 'Booking Details' ? (
                        <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                    ) : isAppointmentBooked === 'Unable to contact' ? (
                        <UnableToContact
                            ref={inputRef}
                            handleNext={onSubmit}
                            openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                            isOverview
                            completeFlow={handleClose}
                        />
                    ) : (
                        <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                    ),
            });
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [BookingAttendance]);


    React.useEffect(() => {
        if (attendance && isAppointmentBooked !== 'Appointment Not Booked') {
            const steps = [...STEPS];
            const row = rowLead.current;
            if (attendance === 'attendance') {
                steps.push({
                    label: isAppointmentBooked,
                    component:
                        isAppointmentBooked === 'Booking Details' ? (
                            <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        ) : isAppointmentBooked === 'Unable to contact' ? (
                            <UnableToContact
                                ref={inputRef}
                                handleNext={onSubmit}
                                openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                                isOverview
                                completeFlow={handleClose}
                            />
                        ) : (
                            <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                        ),
                });
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} isOverview />,
                });
            } else {
                steps.push({
                    label: isAppointmentBooked,
                    component:
                        isAppointmentBooked === 'Booking Details' ? (
                            <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        ) : isAppointmentBooked === 'Unable to contact' ? (
                            <UnableToContact
                                ref={inputRef}
                                handleNext={onSubmit}
                                openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                                isOverview
                                completeFlow={handleClose}
                            />
                        ) : (
                            <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                        ),
                });
                steps.push({
                    label: 'Personal Details',
                    component: <PersonalDetails ref={inputRef} handleNext={onSubmit} />,
                });
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} />,
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} type={'FollowUp'} row={selectedRow} />,
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    const { handleTableData } = useLeadTableHelper('Dashboard');

    const handleOpenContactPopup = leadData => {
        if (!leadData || !leadData?.lead_treatments?.length > 0) return;
        const latestData = handleLatestData(leadData, 'Whatsapp', 'All');
        const row = handleTableData(leadData, latestData);
        const payload = {
            lead_id: row?.id,
            first_name: row.first_name || '',
            last_name: row.last_name || '',
            treatmentData: row?.treatmentData?.find((ele) => ele.id === row?.treatment_id)
        };
        const id = row?.id;
        localStorageRemoveItem('localTreatmentData');
        const latestDataForTreat = handleLatestData({ lead_treatments: row?.treatmentData || [] }, 'Whatsapp', 'All');
        setDepositStatus(!latestData?.[0]?.is_treatment_fee_pending);
        dispatch(updateFollowUpLead('contactLead'));
        dispatch(fetchMergeTagValueForTreatments(id, row?.treatment_id ?? row?.lead_treatments?.[0]?.id ?? null));
        dispatch(setCurrentTreatmentDataGlobal(payload));
        dispatch(fetchMergeTagValueForLeads(id));
        dispatch(followUpSlice(id, practiceId));
        dispatch(tableLead(row));
        dispatch(getActivities(practiceId, id, 10, 0));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 50, 0, 'leadNotes', id, '', '', '', 'counter'));
        setSelectedRow(row);
        onOpenContactLead(row);
        dispatch(followUpAppointmentHandler('Booking Details'));
        dispatch(updateBookingSteps('Booking Details'));
    }

    const handleArchiveClose = () => {
        const index = threads?.findIndex(e => e?.to_waid === chatScreenData?.phoneNumber || (chatScreenData?.leadId && e?.lead?.id === chatScreenData?.leadId));
        const phoneId = threads?.[index]?.id;
        setChatScreenData(prv => ({ ...prv, threadInfo: { ...(prv?.threadInfo ?? {}), lead: { ...(prv?.threadInfo?.lead ?? {}), is_archived: false } } }))
        dispatch(refreshThreadMessages(phoneId));
        setShowStickyAction(false);
        setShowRestore(false);
        dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails));
        dispatch(tableLead(null));
        dispatch(selectLeads(null));
        onCloseArchiveLead();
    }

    const handleOpenArchive = (e, leadData) => {
        const row = { leadId: [chatScreenData?.leadId], type: "Restore" }
        dispatch(selectLeads(row));
        onOpenArchiveLead(row);
    }

    const OpenAssignTask = (_, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
        onCloseTreatmentEditPopup();
        const latestData = handleLatestData(leadDetails, 'Whatsapp', 'All');
        const row = handleTableData(leadDetails, latestData);
        setSelectedRow(row);
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const handleCloseAssignPopup = (isReload, data) => {
        if (isReload) {
            dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails));
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
    };

    return (
        <>
            <Box sx={styles.senderInfoContainer} ref={headerWidthRef}>
                <Box sx={styles.recentChatContainer} ref={headerInnerWidthRef}>
                    <Box>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            isError={!isRegisteredNumber}
                        >
                            <Avatar sx={{ width: '3rem', height: '3rem' }}>{nameAbv}</Avatar>
                        </StyledBadge>
                    </Box>
                    <Box sx={{ ...styles.recentChatInnerContainer, overflow: 'unset' }}>
                        {/** Name and timestamp component */}
                        <Box sx={{ ...styles.nameAndTimeContainer, overflow: 'unset' }}>
                            <Typography sx={{ ...styles.textFontChat, minWidth: '6.5rem' }}>{chatScreenData?.name}</Typography>
                            {!chatScreenData?.leadId && leadData && leadData?.success ? (
                                <Chip
                                    color="primary" size="small"
                                    icon={<Iconify icon="carbon:link" sx={{ width: '1rem', height: '1rem' }} />}
                                    label={`Link to lead (${leadData?.first_name} ${leadData?.last_name})`}
                                    clickable
                                    onClick={() => handleMapToLead(leadData, chatScreenData?.phoneNumber)} />
                            ) : null}
                            {!chatScreenData?.leadId && leadData && leadData?.success === false ? (
                                <Chip
                                    color="primary" size="small"
                                    icon={<Iconify icon="mingcute:add-line" sx={{ width: '1rem', height: '1rem' }} />}
                                    label="Create Lead"
                                    clickable
                                    onClick={() => {
                                        dispatch(saveNewLeadDetail({
                                            personalDetail: {
                                                first_name: chatScreenData?.name,
                                                lead_contact: {
                                                    phone: chatScreenData?.phoneNumber
                                                }
                                            }
                                        }));
                                        chatScreenData?.createLeadFn();
                                        setChatScreenData(null);
                                    }} />
                            ) : null}
                        </Box>
                        {/** Last Message Component */}

                        <Typography sx={styles.sendTextPreview}>{chatScreenData?.phoneNumber}</Typography>
                    </Box>
                </Box>
                {/* {leadDetails && windowDimension?.width > 600 ? (<Box sx={styles.leadmetaData}>
                    <Tooltip title={`Source: ${leadDetails?.lead_source?.referral?.[0]}${leadDetails?.lead_source?.referral?.substr(1)?.toLowerCase()}`} placement='left' arrow>
                        <Chip
                            sx={{ fontSize: "0.6rem", height: "1.2rem", width: "10rem" }}
                            color="secondary" size="small"
                            label={`Source: ${leadDetails?.lead_source?.referral?.[0]}${leadDetails?.lead_source?.referral?.substr(1)?.toLowerCase()}`}
                        />
                    </Tooltip>
                    {leadDetails?.lead_treatments?.map((treatment, index) => (
                        <Tooltip title={`${treatment?.treatment} Status : ${treatment?.status?.[0]}${treatment?.status?.substr(1)?.toLowerCase()}`} placement='bottom' arrow key={index}>
                            <Chip
                                sx={{ fontSize: "0.6rem", height: "1.2rem", width: "10rem" }}
                                color="secondary" size="small"
                                label={`${treatment?.treatment} Status : ${treatment?.status?.[0]}${treatment?.status?.substr(1)?.toLowerCase()}`}
                            />
                        </Tooltip>))}
                </Box>) : null} */}
                <Box sx={{ display: 'flex', alignItems: 'center' }} ref={headerRightInnerWidthRef}>
                    {chatScreenData?.leadId ? (<>
                        {windowDimension?.width > 600 && !changeBackground ? (<Box sx={styles.leadInfoBoxStyles}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={
                                    <Box>
                                        <Typography sx={styles.infoHeaderStyles}>Lead Source</Typography>
                                        {referral?.map((e1) => {
                                            let latestData = '';
                                            if (leadDetails?.lead_source?.referral === e1.value) {
                                                latestData = (
                                                    <Box sx={styles.leadSourceStyle}>
                                                        <Iconify icon={e1.icon} sx={styles.leadSourceIcon} />
                                                        {e1.label}
                                                    </Box>
                                                );
                                            }
                                            return latestData;
                                        })}
                                        <Typography sx={styles.infoHeaderStyles}>Treatment Status</Typography>
                                        {leadDetails?.lead_treatments?.map((treatment, index) => (
                                            <Typography sx={styles.statusTextSize} key={index}>{`${treatment?.treatment} Status : ${treatment?.status?.[0]}${treatment?.status?.substr(1)?.toLowerCase()}`}</Typography>
                                        )) ?? 'No Treatment Data Available'}
                                    </Box>} placement='bottom' arrow>
                                    <Iconify icon="lucide:info" sx={styles.docSearch} />
                                </Tooltip>
                            </Box>
                            <Box sx={{ ...styles.leadMetaData, maxWidth: calculateMaxWidth() }}>
                                {leadDetails?.lead_source?.referral && referral?.map((e1) => {
                                    let latestData = '';
                                    if (leadDetails.lead_source.referral === e1.value) {
                                        latestData = (
                                            <Tooltip placement='bottom' arrow title={'Lead Source'}>
                                                <span>
                                                    <Button sx={statusStyle('NEW')}><Iconify icon={e1.icon} width={24} height={24} />{e1.label}</Button>
                                                </span>
                                            </Tooltip>
                                        );
                                    }
                                    return latestData;
                                })}
                                {leadDetails?.lead_treatments?.map((ele, index) => (
                                    <Tooltip key={index} placement='bottom' arrow title={`Treatment Status for: ${ele?.treatment}`}>
                                        <span>
                                            <Button sx={statusStyle(ele.status)}><Iconify icon={ele.status === 'NEW' ? 'mdi:question-mark-circle-outline' : ele.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : ele.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : ele.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : ele.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : ele.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={24} height={24} /> {getStatus(ele)}</Button>
                                        </span>
                                    </Tooltip>
                                ))}
                            </Box>
                        </Box>) : null}
                        <Box
                            onClick={() => {
                                dispatch(leadPage({ name: 'All Leads', path: location.pathname }));
                                if (chatScreenData?.threadInfo?.lead?.is_archived)
                                    navigate(`/dashboard/practice/${practiceId}/archived/${chatScreenData?.leadId}`);
                                else navigate(`/dashboard/practice/${practiceId}/lead-detail/${chatScreenData?.leadId}`);
                            }}
                            sx={{ display: 'flex' }}
                        >
                            <Tooltip title="Open details page" placement='left' arrow>
                                <Iconify icon="ant-design:file-search-outlined" sx={styles.docSearch} />
                            </Tooltip>
                        </Box>
                    </>) : null}
                    <Box
                        onClick={() => { dispatch(whatsappMarkAsUnRead(chatScreenData?.threadInfo?.id)); }}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Tooltip title="Mark as unread" placement='bottom' arrow>
                            <Iconify icon="solar:chat-round-unread-broken" sx={styles.docSearch} />
                        </Tooltip>
                    </Box>
                    {!changeBackground ? <Box
                        onClick={() => {
                            setChatScreenData(null);
                        }}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Iconify icon="ion:close" sx={styles.closeChatContainer} />
                    </Box> : null}
                </Box>
            </Box >
            {/** Adding Template if No Message Has been Commited yet */}
            {
                isNewChat ? (
                    <Box sx={{ ...styles.messageContainer }} ref={chatRef}>
                        <Box sx={{ ...styles.messageContainer, width: "100%", ...(changeBackground ? { background: "white", width: "100%" } : {}) }}>
                            {whatsAppTemplates?.map((template) => {
                                const preview = template?.components?.map?.((t) => t?.text)?.join('\n');
                                const isDisabled = preview.includes('{{') && Object.keys(template?.template_context_mapping ?? {})?.length === 0;
                                if (isDisabled) {
                                    return (
                                        <Tooltip title="Template context mapping not configured" placement='right' key={template?.template_id} arrow>
                                            <Box
                                                sx={{ ...styles.quickMessageOptions, color: theme.palette.text.disabled }}
                                                key={template?.template_id}
                                            >
                                                {template?.name}
                                            </Box>
                                        </Tooltip>);
                                }
                                return (
                                    <Tooltip title={preview} placement='right' key={template?.template_id} arrow>
                                        <Box
                                            sx={styles.quickMessageOptions}
                                            key={template?.template_id}
                                            onClick={() => handleTemplateClick(template)}
                                        >
                                            {template?.name}
                                        </Box>
                                    </Tooltip>
                                );
                            })}
                            <Box sx={styles.userMessageComponent}>
                                <Typography sx={styles.messageTime}>Now</Typography>
                                <Box sx={{ ...styles.messageBoxUser, maxWidth: '100%', boxSizing: 'border-box' }}>
                                    Choose a Template below to start a conversation
                                </Box>
                            </Box>
                        </Box>
                    </Box >
                ) : (
                    <Box sx={{ ...styles.messageContainer, ...(changeBackground ? { background: "white", width: "100%" } : {}) }} ref={chatRef} id="threadmessages">
                        <Box ref={scrollableDiv}>
                            <Box sx={{ ...styles.stickyAction, ...(showStickyAction ? styles.stickyActionVisible : styles.stickyActionInvisible) }}>
                                <Box sx={styles.stickyContent}>
                                    {/** handleNext(params?.row, e) */}
                                    {chatScreenData?.threadInfo?.lead?.is_archived ? (
                                        <Box onClick={(e) => { handleOpenArchive(e, leadDetails) }} sx={{ ...tableCellInnerBoxStyle, background: "white", borderRadius: "0.5rem" }} disabled={false}>
                                            <Button
                                                color='primary'
                                                variant='outlined'
                                                disabled={false}
                                            >
                                                Restore Lead
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box onClick={(e) => { handleOpenTreatment(e, leadDetails) }} sx={{ ...tableCellInnerBoxStyle, background: "white", borderRadius: "0.5rem" }} disabled={false}>
                                            <Button
                                                color='primary'
                                                variant='outlined'
                                                disabled={false}
                                            >
                                                Select Treatment
                                            </Button>
                                        </Box>
                                    )}
                                    {openAddTreatment && (
                                        <TreatmentEditPopover
                                            handleReloadAPI={() => { dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails)) }}
                                            open={openAddTreatment}
                                            anchorEl={anchorEditTreatment}
                                            handleClickAwayLister={handleCloseAddTreatment}
                                            leadId={leadDetails?.id}
                                            isAddTreatment
                                            placement={windowDimension?.width > 600 ? "left-start" : "bottom-end"}
                                        />
                                    )}
                                    {openTreatmentEditPopup && (
                                        <TreatmentEditPopover
                                            handleReloadAPI={() => { dispatch(getLeadInfo(chatScreenData?.leadId, setLeadDetails)) }}
                                            treatmentData={selectedRow?.treatmentData}
                                            treatment={selectedRow?.treatment_name || ''}
                                            leadTreatmentSpecification={selectedRow?.leadTreatmentSpecification || null}
                                            open={openTreatmentEditPopup}
                                            anchorEl={anchorEditTreatment}
                                            handleClickAwayLister={handleCloseEditTreatment}
                                            treatmentId={selectedRow?.treatment_id}
                                            treatmentPrice={selectedRow?.price}
                                            treatmentCategory={selectedRow?.treatmentCategory}
                                            leadId={selectedRow?.lead_id}
                                            placement="left-start"
                                        />
                                    )}
                                </Box>
                                <Box sx={styles.stickyClose} onClick={() => setShowStickyAction(false)}> <Iconify icon="ion:close" sx={styles.closeChatContainer} /></Box>
                            </Box>
                            <InfiniteScroll
                                dataLength={threadData?.length ?? 0}
                                scrollableTarget="threadmessages"
                                scrollThreshold={0.9}
                                inverse
                                next={getNextDataFromApi}
                                hasMore={threadNext && threadData?.length > 0}
                                loader={<></>}
                                endMessage={
                                    <></>
                                }
                            >
                                {threadData?.map((thread, index) => {
                                    const message = thread?.body;
                                    const hasUserSend = arePhoneNumsEqual(thread?.from_waid, chatScreenData?.phoneNumber);
                                    const date = thread?.created_at?.split(' ')?.[0]?.split('-');
                                    const time = thread?.created_at?.split(' ')?.[1];
                                    const newDateTimeString = `${date?.[1]}-${date?.[0]}-${date?.[2]} ${time}`;
                                    const messageTime = formatUKDate(newDateTimeString);
                                    const emoji = thread?.whatsapp_message_reaction?.emoji || null;
                                    const dateUpdt = thread?.updated_at?.split(' ')?.[0]?.split('-');
                                    const timeUpdt = thread?.updated_at?.split(' ')?.[1];
                                    const newDateTimeStringUpdt = `${dateUpdt[1]}-${dateUpdt[0]}-${dateUpdt[2]} ${timeUpdt}`;
                                    const messageTimeUpdt = formatUKDate(newDateTimeStringUpdt);
                                    if (!hasUserSend) {
                                        return (
                                            <Box sx={styles.senderMessageContainer} key={index}>
                                                <Typography sx={styles.messageTime}>
                                                    {thread?.updated_at === 'NOW' ? 'Now' : messageTime}
                                                </Typography>
                                                <Box sx={{ ...styles.messageBoxSender, ...(changeBackground || windowDimension?.width < 600 ? { maxWidth: "85%" } : {}) }}>
                                                    {message}
                                                    <Box sx={styles.readIconsBox}>
                                                        {thread?.from_waid === -1 ?
                                                            <Box sx={styles.sendingInProgressContainer}>
                                                                <Typography sx={styles.sendingWhatsappStatus}>Sending</Typography>
                                                                <CircularProgress
                                                                    size={10}
                                                                />
                                                            </Box> : thread?.status === 'failed' ?
                                                                <Typography sx={styles.failedWhatsappStatus}>Failed</Typography> :
                                                                thread?.status === 'read' ?
                                                                    <Tooltip title={messageTimeUpdt} placement="bottom">
                                                                        <Iconify
                                                                            icon="charm:tick-double"
                                                                            sx={{
                                                                                color: '#34B7F1',
                                                                                width: '0.825rem',
                                                                                height: '0.825rem',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        />
                                                                    </Tooltip> :
                                                                    thread?.status === 'delivered' ?
                                                                        <Tooltip title={messageTimeUpdt} placement="bottom">
                                                                            <Iconify
                                                                                icon="charm:tick-double"
                                                                                sx={{
                                                                                    color: 'text.disabled',
                                                                                    width: '0.825rem',
                                                                                    height: '0.825rem',
                                                                                }}
                                                                            />
                                                                        </Tooltip> :
                                                                        <Tooltip title={messageTimeUpdt} placement="bottom">
                                                                            <Iconify
                                                                                icon="charm:tick"
                                                                                sx={{
                                                                                    color: 'text.disabled',
                                                                                    cursor: 'pointer',
                                                                                    width: '0.5rem',
                                                                                    height: '0.5rem',
                                                                                }}
                                                                            />
                                                                        </Tooltip>}
                                                    </Box>
                                                    {emoji && <Box sx={styles.reactions}>{`${emoji}`}</Box>}
                                                </Box>
                                            </Box>
                                        );
                                    }
                                    return (
                                        <Box key={index} sx={styles.userRecieveMessageContainer}>
                                            <Box sx={styles.userAvatar}>
                                                <Avatar sx={{ width: '2rem', height: '2rem' }}>{nameAbv}</Avatar>
                                            </Box>
                                            <Box sx={styles.userMessageComponent} key={index}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography sx={styles.messageTime}>{messageTime}</Typography>
                                                    {thread?.whatsapp_message_media ? (
                                                        <Button size="small" sx={{ mb: '0.5625rem', ml: "0.5rem", p: "0 0.5rem" }} onClick={() => {
                                                            if (!addMediaControl?.[thread?.whatsapp_message_media?.id]?.loading)
                                                                handleMediaSave(thread?.whatsapp_message_media)
                                                        }}>
                                                            {addMediaControl?.[thread?.whatsapp_message_media?.id]?.loading ? (
                                                                <CircularProgress
                                                                    size={10}
                                                                />
                                                            ) : addMediaControl?.[thread?.whatsapp_message_media?.id]?.success ? (
                                                                <Typography sx={{ ...styles.messageTime, mb: 0, color: "#00B8D9" }}>Media Added. Add Again?</Typography>
                                                            ) : (
                                                                <Typography sx={{ ...styles.messageTime, mb: 0, color: "#00B8D9" }}>Add Media to Lead</Typography>
                                                            )}
                                                        </Button>
                                                    ) : null}
                                                </Box>
                                                <Box sx={{ ...styles.messageBoxUser, ...(changeBackground || windowDimension?.width < 600 ? { maxWidth: "85%" } : {}) }}>{thread?.whatsapp_message_media ? <MediaDisplayComponent scrollToBottom={scrollToBottom} whatsAppMessageMedia={thread?.whatsapp_message_media ?? {}} /> : message}
                                                    {hasUserSend && index === threadData?.length - 1 ? (
                                                        <Box sx={styles.timerContainer}>
                                                            <Tooltip title="Please reply back before the time elapses, thereafter you will need to choose a template to restart conversation." placement="right" arrow>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Iconify
                                                                        icon="ic:twotone-alarm"
                                                                        onClick={() => setUseTemplate(prv => !prv)}
                                                                        sx={{
                                                                            color: 'text.disabled',
                                                                            width: '1rem',
                                                                            height: '1rem',
                                                                        }}
                                                                    />
                                                                    <TimeLeft inputDateTime={newDateTimeString} />
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                    ) : null}
                                                    {emoji && <Box sx={styles.reactions}>{`${emoji}`}</Box>}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                                {(enforceTemplate || useTemplate || isTemplateAnswerPending) && threadData?.length && !isMessageSendProgress ?
                                    <Box sx={{ ...styles.messageContainer, width: 'max-content', borderRadius: "0.5rem", maxWidth: changeBackground || windowDimension?.width < 600 ? '85%' : '60%', marginTop: changeBackground ? "1rem" : "0" }}>
                                        {whatsAppTemplates?.map((template) => {
                                            const preview = template?.components?.map?.((t) => t?.text)?.join('\n');
                                            const isDisabled = preview.includes('{{') && Object.keys(template?.template_context_mapping ?? {})?.length === 0;
                                            if (isDisabled) {
                                                return (
                                                    <Tooltip title="Template context mapping not configured" placement='right' key={template?.template_id} arrow>
                                                        <Box
                                                            sx={{ ...styles.quickMessageOptions, color: theme.palette.text.disabled }}
                                                            key={template?.template_id}
                                                        >
                                                            {template?.name}
                                                        </Box>
                                                    </Tooltip>);
                                            }
                                            if (!chatScreenData?.leadId && preview.includes('{{')) {
                                                return (
                                                    <Tooltip title="Please map the number to a lead to use this template" placement='right' key={template?.template_id} arrow>
                                                        <Box
                                                            sx={{ ...styles.quickMessageOptions, color: theme.palette.text.disabled }}
                                                            key={template?.template_id}
                                                        >
                                                            {template?.name}
                                                        </Box>
                                                    </Tooltip>);
                                            }
                                            return (
                                                <Tooltip title={template?.components?.map?.((t) => t?.text)?.join('\n')} placement='right' key={template?.template_id} arrow>
                                                    <Box
                                                        sx={styles.quickMessageOptions}
                                                        key={template?.template_id}
                                                        onClick={() => handleTemplateClick(template)}
                                                    >
                                                        {template?.name}
                                                    </Box>
                                                </Tooltip>
                                            );
                                        })}
                                        <Box sx={styles.userMessageComponent}>
                                            <Typography sx={styles.messageTime}>Now</Typography>
                                            <Box sx={{ ...styles.messageBoxUser, maxWidth: '100%', boxSizing: 'border-box' }}>
                                                {enforceTemplate ? 'Since the last message was sent more than 24 hours ago, please restart the conversation by sending a template message 😀' : isTemplateAnswerPending ? 'Since you’ve already sent a template message, you can choose to send another template or wait for a response.' : 'Choose a template from below'}
                                            </Box>
                                        </Box>
                                    </Box> : null}
                            </InfiniteScroll>
                        </Box>
                    </Box >
                )
            }
            <Box sx={styles.messageInputContainer}>
                {!changeBackground ? <Box sx={{ ...styles.actionBar, height: `${chatRef?.current?.offsetHeight}px` ?? "max-content", ...(changeBackground ? { background: "white" } : {}) }}>
                    <Tooltip title={!leadDetails ? "Operation not enabled for archived leads" : leadDetails?.lead_treatments?.length > 0 ? "Book an Appointment" :
                        "Add Treatment to book an Appointment"} placement='left' arrow>
                        <Box sx={{
                            ...styles.actionButtons, ...(!leadDetails || !leadDetails?.lead_treatments?.length > 0 ? {
                                background: '#F4F6F8',
                                "&:hover": {}
                            } : {})
                        }} onClick={() => {
                            handleOpenContactPopup(leadDetails)
                        }}>
                            <Iconify
                                icon="lsicon:work-order-appointment-outline"
                                sx={{
                                    color: !leadDetails || !leadDetails?.lead_treatments?.length > 0 ? '#C4CDD5' : '#075e54',
                                    cursor: 'pointer',
                                    width: '1.2rem',
                                    height: '1.2rem',
                                }}
                            />
                            <Typography sx={{ color: "#637381", fontSize: "0.4rem", mt: "0.2rem", whiteSpace: "nowrap" }}>Book Appointment</Typography>
                        </Box>
                    </Tooltip>
                    <Tooltip title={!leadDetails ? "Operation not enabled for archived leads" : leadDetails?.lead_treatments?.length > 0 ? "Edit Treatment" : "No Treatments Added"} placement='bottom' arrow>
                        <Box sx={{
                            ...styles.actionButtons, ...(!leadDetails || !leadDetails?.lead_treatments?.length > 0 ? {
                                background: '#F4F6F8',
                                "&:hover": {}
                            } : {})
                        }}
                            onClick={(e) => {
                                handleOpenEditTreatment(e, leadDetails)
                            }}
                        >
                            <Iconify
                                icon="hugeicons:note-edit"
                                sx={{
                                    color: !leadDetails || !leadDetails?.lead_treatments?.length > 0 ? '#C4CDD5' : '#075e54',
                                    cursor: 'pointer',
                                    width: '1.2rem',
                                    height: '1.2rem',
                                }}
                            />
                            <Typography sx={{ color: "#637381", fontSize: "0.4rem", mt: "0.2rem", whiteSpace: "nowrap" }}>Edit Treatment</Typography>
                        </Box>
                    </Tooltip>
                    {leadDetails ? (
                        <Box
                            sx={{ ...assignMainBoxStyle, ...styles.actionButtons }}
                            onClick={(e) => OpenAssignTask(leadDetails, e)}
                        >
                            <AssigneeField
                                rowData={leadDetails}
                                getAssignees={getAssignees}
                                whatsappView
                            />
                            <Typography sx={{ color: "#637381", fontSize: "0.4rem", mt: "0.2rem", whiteSpace: "nowrap" }}>Assigned To</Typography>
                        </Box>
                    ) : null}
                </Box > : null
                }
                <Box sx={styles.messageInputComponent}>
                    <Tooltip title="Add a message template" placement='right' arrow>
                        <Iconify
                            icon="bxs:message-square-add"
                            onClick={() => setUseTemplate(prv => !prv)}
                            sx={{
                                color: 'text.disabled',
                                cursor: 'pointer',
                                width: '1.5rem',
                                height: '1.5rem',
                                ml: '1.1875rem',
                                mr: '0.9375rem',
                            }}
                        />
                    </Tooltip>
                    <TextField
                        fullWidth
                        value={message}
                        onChange={(evt) => setMessage(evt?.target?.value)}
                        onKeyDown={(evt) => {
                            if (evt?.key === 'Enter') handleSubmit();
                        }}
                        variant="standard"
                        placeholder={isNewChat ? 'Select a template to start conversation' : enforceTemplate ? 'Select a template to continue the conversation' : isTemplateAnswerPending ? 'Select a template to continue the conversation' : 'Type a message'}
                        InputProps={{ disableUnderline: true }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '2.5rem',
                                width: '85%',
                                border: 'none',
                                outline: 'none',
                            },
                        }}
                        disabled={isNewChat || enforceTemplate || isTemplateAnswerPending}
                    />
                </Box>
                <Box
                    sx={styles.sendButtonContainer}
                    onClick={() => {
                        if (!isNewChat) handleSubmit();
                    }}
                >
                    <Iconify icon="ic:round-send" sx={{ color: 'white', cursor: 'pointer' }} />
                </Box>
            </Box >
            {
                openContactLead && (
                    <OpenContactLeadPopup
                        open={openContactLead}
                        headerButtons
                        onClose={handleClose}
                        openArchivePopup={onOpenArchiveLead}
                        user={selectedRow}
                        steps={leadStep}
                        type={'followup'}
                        validStep={validStep}
                        BookingAttendance={BookingAttendance}
                        updateStep={() => setValidStep(!validStep)}
                        inputRef={inputRef}
                        active={active}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        setActive={setActive}
                        handleReloadLeads={() => { }}
                        ContactedCount={
                            selectedRow &&
                            selectedRow?.treatmentData &&
                            selectedRow?.treatmentData[0]?.lead_contacted_counts?.length > 0 &&
                            selectedRow?.treatmentData[0]?.lead_contacted_counts[0]?.count
                        }
                        timeLineCount={activityList?.results?.length}
                        showWhatsapp={props}
                    />
                )
            }
            {openArchiveLead &&
                <ArchivedOrRestorePopup open={openArchiveLead} onClose={handleArchiveClose} handleClose={handleArchiveClose} />}
            {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement='left' sx={zIndexStyle}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={borderRadiosStyle}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={handleCloseAssignPopup}
                                        user={selectedRow}
                                        leadId={selectedRow?.lead_id}
                                        type={'leadAssign'}
                                        assignees={selectedRow?.assignees?.filter(
                                            (el) => el?.user?.first_name && !el?.user?.is_superuser)?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
        </>
    );
}

export default MessageContainer;