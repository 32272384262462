/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useDebounce } from 'use-lodash-debounce';
import { useForm } from 'react-hook-form';
// @mui
import {
    Box,
    MenuItem,
    ListItemAvatar,
    InputAdornment,
    TextField,
    Stack,
    Tabs, Tab,
    useTheme,
    Typography,
    Button
} from '@mui/material';
import { isEmpty } from 'lodash';
// components
import AddUserPopup from '../../../components/dialogPopUp/AddUserPopup';
import { FormProvider } from '../../../components/hook-form';
import { AssignUserAvatar } from '../../../components/custom-avatar';
import { Scrollbar, Iconify, Label } from '../../../components';
import { NormalLoading } from '../../../components/loading';
// store
import { useSelector, dispatch } from '../../../redux/store';
import { editReminder, updateAssignToUser, followUpSlice } from '../../../redux/slices/lead';
import { getPracticeUserListing, getPracticeUserListingCounter, updateCounter, clearPracticeUserData } from '../../../redux/slices/practiceUser';
// constants/hooks
import { USER_TABS, TCO_USER_TABS } from '../../../constants/MessageListConst';
import { useTabs } from '../../../hooks';
// style
import { addAssignListItem, searchTeamTextfieldStyle, primaryTypographyPropsStyle } from '../../../styles/LeadDetailView';

const AddAssignTeamMember = forwardRef(({ assignees, handleNext, reminder, type, leadId, setAssignTo, userSpecific }, ref) => {
    const theme = useTheme();
    const { usersData, practiceUserCounter, practiceUser } = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { isTasksCompleted } = useSelector((state) => state.lead);
    const { enqueueSnackbar } = useSnackbar();
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState(null);
    const debouncedValue = useDebounce(search, 500);
    const [users, setUsers] = useState(null);
    const tabCounter = (data) => practiceUserCounter?.filter(value => value.filterRole === data ? value.count : null);
    const [filterRoles, setFilterRoles] = useState('');
    const selectedUser = assignees && assignees?.length > 0 ? practiceUser?.results?.filter((el) => el.id === assignees[0])[0] : null;
    let selectedUserRole = 'TCO,USER';
    const inputRef = useRef(null);
    const [userOpen, setUserOpen] = useState(false);
    const [practiceUserData, setPracticeUserData] = useState(practiceUser?.results);

    if (selectedUser) {
        switch (selectedUser.role) {
            case 'TCO':
                selectedUserRole = 'TCO,USER';
                break;
            case 'Practitioner':
                selectedUserRole = 'Practitioner';
                break;
            case 'ADMIN':
                selectedUserRole = 'ADMIN';
                break;
            default:
                selectedUserRole = 'TCO,USER';
                break;
        }
    }

    const { currentTab: filterRole, onChangeTab: onChangeFilterRole } = useTabs(selectedUserRole || 'TCO,USER');

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeUserListing({ practiceId, role: filterRole }));
            dispatch(updateCounter([]));
            reloadCounter({ practiceId });
            setPracticeUserData(practiceUser?.results);
        }
    }, [practiceId, practiceUser?.results]);

    const reloadCounter = ({ searchQuery }) => {
        USER_TABS.forEach((data) => {
            dispatch(getPracticeUserListingCounter({ practiceId, role: data.value, searchQuery }));
        });
    };

    useEffect(() => {
        if (usersData) {
            setUsers(usersData?.results);
        }
    }, [usersData]);

    const defaultValues = {
        selectTeamMember: []
    };

    const methods = useForm({
        defaultValues
    });

    const {
        handleSubmit,
        getValues,
        setValue,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        [practiceUserData]
    );

    useEffect(() => {
        setTimeout(() => {
            inputRef?.current?.focus?.();
        }, 100);
        if (!isEmpty(assignees)) {
            setSelected(assignees);
            setValue('selectTeamMember', assignees?.map((ele) => ({ id: ele })));
        }
    }, []);

    const onSubmit = (data) => {
        const selectedIds = data?.selectTeamMember?.map((el) => el?.id);
        const assigneesIds = assignees?.map((ele) => ele);
        if (JSON.stringify(selectedIds) === JSON.stringify(assigneesIds)) {
            handleNext();
            dispatch(clearPracticeUserData());
            return;
        }
        if (type === 'AddReminder') {
            const selectTeamMemberUserIds = data?.selectTeamMember.map(member => member.id) || data?.selectTeamMember
                .filter(member => member.user && member.user.id)
                .map(member => member.user.id);
            const allIds = [...selectTeamMemberUserIds];
            const filteredUsers = [];
            practiceUserData?.forEach(user => {
                if (allIds.includes(user?.id)) {
                    filteredUsers.push(user);
                }
            });
            setAssignTo(filteredUsers);
            handleNext(true);
            return;
        }
        if (type === 'leadAssign') {
            const AssignData = {
                assignees: selectedIds || [],
            };
            dispatch(updateAssignToUser(AssignData, leadId, practiceId, handleSuccess));
        } else {
            const ReminderData = {
                datetime: reminder.datetime,
                assignees: selectedIds || [],
                is_completed: isTasksCompleted,
                group: 'FOLLOW_UP',
                lead: reminder.lead_treatment.lead,
                lead_treatment: reminder.lead_treatment.id
            };
            dispatch(editReminder(ReminderData, reminder.id, practiceId, handleSuccess));
        }
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        if (type === 'leadAssign') {
            dispatch(followUpSlice(leadId, practiceId, 'LeadDetail', handleClose));
            return;
        }
        handleNext(true);
        dispatch(clearPracticeUserData());
    };

    const handleClose = (data) => {
        handleNext(true, data);
        dispatch(clearPracticeUserData());
    };
    const searchbarHandler = (value) => setSearch(value);

    const handleSelect = (oldValues, newValue) => {
        if (oldValues?.some(item => item?.id === newValue?.id)) {
            return oldValues?.filter((el) => el?.id !== newValue?.id);
        }
        return [...(oldValues || []), newValue];
    };

    useEffect(() => {
        if (filterRoles) {
            const data = {
                practiceId,
                role: filterRoles,
                searchQuery: search
            };
            dispatch(getPracticeUserListing(data));
        }
    }, [filterRoles]);

    useEffect(() => {
        if (search !== null) {
            const data = {
                practiceId,
                role: filterRole,
                searchQuery: debouncedValue
            };
            dispatch(getPracticeUserListing(data));
            dispatch(updateCounter([]));
            reloadCounter(data);
        }
    }, [debouncedValue]);

    const userSelected = (value) => {
        setValue('selectTeamMember', handleSelect(getValues()?.selectTeamMember, value));
        setSelected((state) => {
            if (state?.includes(value.id)) {
                return state?.filter((el) => el !== value?.id);
            }
            return [...(state || []), value.id];
        });
    };

    const handleTabChange = (event, newValue) => {
        setFilterRoles(newValue);
        onChangeFilterRole(event, newValue);
    };

    const getStatusCount = (data, status) => {
        const response = tabCounter(status);
        return response[0]?.count || 0;
    };

    return (
        <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ width: '100%', pt: '0', textAlign: 'left' }}>
                    <NormalLoading tableData={users === null && 'No Found'} pageCount={users === null && 'No Found'} />
                    <Stack>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                            <TextField
                                fullWidth
                                onChange={(event) => searchbarHandler(event.target.value)}
                                value={search}
                                placeholder='Search team'
                                inputRef={inputRef}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={searchTeamTextfieldStyle}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            <Tabs
                                allowScrollButtonsMobile
                                variant='scrollable'
                                value={filterRole?.length > 0 && filterRole}
                                onChange={handleTabChange}
                                scrollButtons='auto'
                                aria-label='scrollable auto tabs example'
                                sx={{ '.MuiTabs-indicator': { bgcolor: 'primary.main' } }}
                            >
                                {userSpecific === 'TCOUsers' ?
                                    TCO_USER_TABS.map((tab, index) => (
                                        <Tab
                                            sx={{ flexDirection: 'row-reverse', fontWeight: 600, 'span': { ml: 1 } }}
                                            disableRipple
                                            key={index}
                                            label={tab.label}
                                            icon={
                                                <Label sx={{ bgcolor: 'common.lightGreyBG' }}> {getStatusCount(users, tab.value)} </Label>
                                            }
                                            value={tab.value}
                                        />
                                    ))
                                    :
                                    USER_TABS.map((tab, index) => (
                                        <Tab
                                            sx={{ flexDirection: 'row-reverse', fontWeight: 600, 'span': { ml: 1 } }}
                                            disableRipple
                                            key={index}
                                            label={tab.label}
                                            icon={
                                                <Label sx={{ bgcolor: 'common.lightGreyBG' }}> {getStatusCount(users, tab.value)} </Label>
                                            }
                                            value={tab.value}
                                        />
                                    ))
                                }
                                { }
                            </Tabs>
                            <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                                <Button variant='contained' sx={{ textTransform: 'none !important' }} onClick={() => { setUserOpen(true); }}>Add user</Button>
                            </Box>
                        </Box>
                        <Box sx={{ margin: '8px -8px 0' }}>
                            <Scrollbar sx={{ maxHeight: 60 * 4, minHeight: '56px' }}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {users?.map((row, index) => (
                                        <MenuItem onClick={() => userSelected(row)} value={row.value} key={index} sx={addAssignListItem(selected, row, theme)}>
                                            <ListItemAvatar sx={{ position: 'relative' }}>
                                                <AssignUserAvatar sx={{ width: '40px', height: '40px', bgcolor: 'primary.paperColor' }} src={row.user && row.user.avatar && row.user.avatar} name={`${row.user.first_name || ''} ${row.user.last_name || ''}`} role={row.role} />
                                            </ListItemAvatar>
                                            <Typography sx={primaryTypographyPropsStyle}>
                                                <span style={{ textTransform: 'capitalize', marginRight: row?.user?.salutation ? '4px' : '0' }}>{`${row?.user?.salutation || ''}`.toLowerCase()}</span>{`${row.user.first_name || ''} ${row.user.last_name || ''}`}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Box>
                            </Scrollbar>
                        </Box>
                    </Stack>
                </Box>
            </FormProvider>
            {userOpen && <AddUserPopup
                userOpen={userOpen}
                setUserOpen={setUserOpen}
            />}
        </Box>
    );
});

AddAssignTeamMember.propTypes = {
    handleNext: PropTypes.func,
    reminder: PropTypes.object,
    type: PropTypes.string,
    leadId: PropTypes.number,
    assignees: PropTypes.object,
    setAssignTo: PropTypes.func,
    userSpecific: PropTypes.string,
};

export default AddAssignTeamMember;