/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';

// -----------------------------------------------

const initialState = {
    isLoading: false,
    callScriptData: null,
    callScriptReusableData: null,
    scriptRowData: '',
    charFieldData: [],
    textFieldData: [],
    labelFieldData: [],
    leadsFieldData: [],
    treatmentFieldData: [],
    userFieldData: [],
    booleanFieldData: [],
    choiceFieldData: [],
    datetimeFieldData: [],
    submissionData: [],
    callScriptTab: 'Complete Scripts',
    openCallScriptFromLeadPage: false,
};

const slice = createSlice({
    name: 'autoReminder',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setCallScriptSection(state, action) {
            state.isLoading = false;
            state.callScriptData = action.payload;
        },
        setCallScriptReusableSection(state, action) {
            state.isLoading = false;
            state.callScriptReusableData = action.payload;
        },
        addScriptRowData(state, action) {
            state.isLoading = false;
            state.scriptRowData = action.payload;
        },
        setCharField(state, action) {
            state.isLoading = false;
            state.charFieldData = action.payload;
        },
        setTextField(state, action) {
            state.isLoading = false;
            state.textFieldData = action.payload;
        },
        setLabelField(state, action) {
            state.isLoading = false;
            state.labelFieldData = action.payload;
        },
        setLeadsField(state, action) {
            state.isLoading = false;
            state.leadsFieldData = action.payload;
        },
        setTreatmentField(state, action) {
            state.isLoading = false;
            state.treatmentFieldData = action.payload;
        },
        setUserField(state, action) {
            state.isLoading = false;
            state.userFieldData = action.payload;
        },
        setBooleanField(state, action) {
            state.isLoading = false;
            state.booleanFieldData = action.payload;
        },
        setChoiceField(state, action) {
            state.isLoading = false;
            state.choiceFieldData = action.payload;
        },
        setDatetimeField(state, action) {
            state.isLoading = false;
            state.datetimeFieldData = action.payload;
        },
        getSubmissionSection(state, action) {
            state.isLoading = false;
            state.submissionData = action.payload;
        },
        setCallScriptTabs(state, action) {
            state.isLoading = false;
            state.callScriptTab = action.payload;
        },
        setOpenCallScriptFromLeadPage(state, action) {
            state.openCallScriptFromLeadPage = action.payload;
        }
    }
});

export default slice.reducer;

export const { setCallScriptSection, reset, startLoading, addScriptRowData, setCharField, setTextField, setBooleanField, setChoiceField, setDatetimeField, getSubmissionSection, setCallScriptReusableSection, setCallScriptTabs, setOpenCallScriptFromLeadPage } = slice.actions;

export function getCallScriptSection(data) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}&fields={created_at,created_at_human,category,description,created_by,treatments,id,is_template,form_type,is_default,name,sections,updated_at,updated_at_human}` : '';
            const treatments = data?.treatment ? `&treatments__treatment_item__name=${data?.treatment}&fields={created_at,created_at_human,description,created_by,category,treatments,id,is_template,form_type,is_default,name,sections,updated_at,updated_at_human}` : '';
            const isTemplate = data?.isTemplate === false ? '&is_template=false' : data?.isTemplate ? `&is_template=${data?.isTemplate}` : '';
            const category = (data?.category === 'OUTBOUND' || data?.category === 'INBOUND' || data?.category === 'TCO') ? `&category=${data?.category}` : '';
            const filter = data.filter === 'inbound' ? '&category=INBOUND' : data.filter === 'outbound' ? '&category=OUTBOUND' : '';
            const param = `${limit}${offset}${treatments}${isTemplate}${category}${filter}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/forms/?ordering=-created_at${param}`);
            if (response.status === 200) {
                if (data?.isTemplate === true) {
                    dispatch(slice.actions.setCallScriptReusableSection(response.data));
                    return;
                }
                dispatch(slice.actions.setCallScriptSection(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function createCallScript(data, handleSuccess, status) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/forms/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Script created successfully' }));
                if (status === 'saveAndClose') {
                    handleSuccess({ success: true, status: 'saveAndClose' });
                    return;
                }
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                handleSuccess({ success: false });
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            handleSuccess({ success: false });
        }
    };
}

export function exportCallScript(data, listOfPractices) {
    return async () => {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of listOfPractices) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const response = await callApi(`/api/v1/practices/${item}/forms/`, 'post', { ...data, practice: Number(item) });
                if (response.status === 201) {
                    dispatch(updateError({ success: true, message: 'Script Copied to other practice successfully' }));
                } else {
                    dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                }
            } catch (error) {
                dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            }
        }
    };
}

export function getRowScript(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/forms/${data?.id}/`);
            if (response.status === 200) {
                const updateData = response?.data;
                updateData?.sections.sort((a, b) => a.order - b.order);
                dispatch(slice.actions.addScriptRowData(updateData));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function updateRowScript(id, data, handleSuccess, status) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/forms/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Script updated successfully' }));
                if (status === 'saveAndClose') {
                    handleSuccess({ success: true, status: 'saveAndClose' });
                    return;
                }
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteScript(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Script deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getCharFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/char-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setCharField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function getGenericFormFields(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/form-field-types/`);
            if (response.status === 200) {
                const resultParse = {
                    char: { results: [], setter: slice.actions.setCharField },
                    text: { results: [], setter: slice.actions.setTextField },
                    boolean: { results: [], setter: slice.actions.setBooleanField },
                    choice: { results: [], setter: slice.actions.setChoiceField },
                    label: { results: [], setter: slice.actions.setLabelField },
                    datetime: { results: [], setter: slice.actions.setDatetimeField },
                    lead_search_select: { results: [], setter: slice.actions.setLeadsField },
                    practice_treatment_search_select: { results: [], setter: slice.actions.setTreatmentField },
                    practice_user_search_select: { results: [], setter: slice.actions.setUserField }
                }
                response?.data?.results?.forEach(type => {
                    try {
                        resultParse[type?.field_type].results?.push(type);
                    } catch (e) {
                        console.error(`Error while setting field type = ${type}`);
                    }
                });
                Object.keys(resultParse)?.forEach(type => {
                    try {
                        const { results, setter } = resultParse?.[type];
                        dispatch(setter({ results }));
                    } catch (e) {
                        console.error(`Error while saving field type = ${type}`, e);
                    }
                })
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function createCharField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/char-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Char field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateCharField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/char-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Char field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteChartField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/char-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Char field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getTextFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/text-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setTextField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function getLabelFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/label-form-field-types/`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setLabelField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function getLeadsFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/lead-search-select-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setLeadsField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function updateLeadsField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/lead-search-select-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Lead field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteLeadField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/lead-search-select-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Lead field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getTreatmentFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/treatment-search-select-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setTreatmentField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function createTreatmentField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/treatment-search-select-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Treatment field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateTreatmentField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/treatment-search-select-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Treatment field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteTreatmentField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/treatment-search-select-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Treatment field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getUserAssignFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/user-search-select-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setUserField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function createUserAssignField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/user-search-select-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'User field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateUserAssignField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/user-search-select-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'User field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteUserAssignField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/user-search-select-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'User field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function createLeadsField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/lead-search-select-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Lead field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function createTextField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/text-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Text field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function createLabelField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/label-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Label field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateTextField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/text-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Text field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function updateLabelField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/label-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Label field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteTextField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/text-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Text field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteLabelField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/label-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Label field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getBooleanFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/boolean-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setBooleanField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function createBooleanField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/boolean-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Boolean field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateBooleanField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/boolean-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Boolean field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteBooleanField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/boolean-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Boolean field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getChoiceFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/choice-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setChoiceField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function createChoiceField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/choice-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Choice field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateChoiceField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/choice-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Choice field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteChoiceField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/choice-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Choice field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getDatetimeFields(data) {
    return async () => { }
    // return async () => {
    //     try {
    //         const response = await callApi(`/api/v1/practices/${data?.practiceId}/datetime-form-field-types/?ordering=name`);
    //         if (response.status === 200) {
    //             dispatch(slice.actions.setDatetimeField(response.data));
    //         } else {
    //             dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
    //         }
    //     } catch (error) {
    //         dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
    //     }
    // };
}

export function createDatetimeField(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/datetime-form-field-types/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Datetime field created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateDatetimeField(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/datetime-form-field-types/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Datetime field updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteDatetimeField(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/datetime-form-field-types/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Datetime field deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function createSubmission(data, handleSuccess, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/forms/${data?.formId}/submissions/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Form submissions created successfully' }));
                handleSuccess({ success: true, event: type });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function deleteScriptSubmission(practiceId, formId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/${formId}/submissions/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Form submissions deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function getFormSubmission(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/forms/${data?.formId}/submissions/${data?.submissionId}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getSubmissionSection(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateSubmission(practiceId, formId, submissionId, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/${formId}/submissions/${submissionId}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Submission updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}