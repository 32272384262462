/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// library
import {
    Popper,
    Grow,
    Paper,
    Grid,
    Container,
    Stack,
    Button,
    Box,
    InputAdornment,
    TextField,
    ClickAwayListener,
} from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// components
import { FormProvider } from '../../../../components/hook-form';
import { NormalLoading } from '../../../../components/loading';
import { Iconify } from '../../../../components';
import CustomConfirmationDialog from './CustomConfirmationDialog';
import TreatmentPopoverScrollbar from './TreatmentPopoverScrollbar';
// redux
import { dispatch, useSelector } from '../../../../redux/store';
import { getPracticeTreatmentListV2 } from '../../../../redux/slices/practiceTreatment';
import { addTreatment, editTreatment } from '../../../../redux/slices/lead';
// hooks/validations
import { useToggle } from '../../../../hooks';
import { inlineTreatmentEditSchema } from '../../../../validations/validations';
// style
import {
    reminderStackStyle,
    treatmentFooterStyle,
    cancelButtonStyle,
    saveButtonStyle,
    treatmentBoxStyle,
    searchTeamTextfieldStyle,
    customTextFieldStyle,
    customTextFieldStartIconStyle,
    paperZIndex,
    borderRadius,
    paperGridStyle,
    paperGridInnerContainerStyle,
    paperGridInnerBoxStyle,
} from '../../../../styles/TreatmentEditPopover';

export const CustomTextField = ({ search, searchbarHandler, isScript }) => {
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return <Box sx={customTextFieldStyle}>
        <TextField
            fullWidth
            onChange={(event) => searchbarHandler(event.target.value)}
            value={search}
            placeholder="Search treatments"
            disabled={isScript}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify
                            icon={'eva:search-fill'}
                            sx={customTextFieldStartIconStyle}
                        />
                    </InputAdornment>
                ),
            }}
            inputRef={inputRef}
            sx={searchTeamTextfieldStyle}
        />
    </Box>;
};

const TreatmentEditPopover = ({
    treatment,
    leadTreatmentSpecification,
    open,
    anchorEl,
    treatmentId,
    handleClickAwayLister,
    treatmentData,
    treatmentPrice,
    treatmentCategory,
    leadId,
    handleReloadAPI,
    isAddTreatment,
    isFlow,
    isScript,
    handleSaveTreatment,
    placement
}) => {
    const inputRef = useRef();
    const [search, setSearch] = useState(null);
    const [selected, setSelected] = useState('');
    const [treatments, setTreatments] = useState([]);
    const { treatmentsListV2 } = useSelector((state) => state.practiceTreatment);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const debouncedValue = useDebounce(search, 500);
    const [position, setPosition] = useState(0);
    const [callApi, setCallApi] = useState(null);
    const treatmentDebounce = useDebounce(callApi, 800);
    const listElem = useRef();
    const mounted = useRef();
    const dialogRef = useRef();

    const {
        toggle,
        onOpen,
        onClose,
    } = useToggle();

    const defaultValues = {
        selectedTreatment: '',
        treatmentPrice: 0.0,
        teethAmount: null,
        selectedTreatmentId: null,
        selectedTreatmentCategory: '',
    };

    const methods = useForm({
        resolver: yupResolver(inlineTreatmentEditSchema),
        defaultValues,
    });

    const {
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = methods;

    const values = watch();

    useEffect(() => {
        if (treatmentDebounce) {
            loadMoreData();
        }
    }, [treatmentDebounce]);

    useEffect(() => {
        if (treatmentsListV2) {
            setTreatments(treatmentsListV2?.results || []);
        }
    }, [treatmentsListV2]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 30,
                offset: 0,
            };
            dispatch(getPracticeTreatmentListV2(data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (treatment) {
            setValue('selectedTreatment', treatment);
            setSelected(treatment);
        }
        if (leadTreatmentSpecification?.teeth_count) {
            setValue('teethAmount', leadTreatmentSpecification.teeth_count);
        }
        if (treatmentPrice) {
            setValue('treatmentPrice', treatmentPrice);
        }
        if (treatmentCategory) {
            setValue('selectedTreatmentCategory', treatmentCategory);
        }
    }, []);

    useEffect(() => {
        if (search !== null) {
            const data = {
                limit: 30,
                offset: 0,
                treatment: debouncedValue,
            };
            dispatch(getPracticeTreatmentListV2(data));
        }
    }, [debouncedValue]);

    const loadMoreData = () => {
        if (treatmentsListV2?.count > treatmentsListV2?.results?.length) {
            const data = {
                limit: 30,
                offset: treatmentsListV2?.results?.length,
            };
            dispatch(getPracticeTreatmentListV2(data));
        }
    };

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    useImperativeHandle(
        inputRef,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        []
    );

    const treatmentSelected = (row) => {
        setSelected(row?.treatment_item?.name);
        setValue('selectedTreatment', row?.treatment_item?.name);
        setValue('selectedTreatmentId', row?.id);
        if (row?.treatment_item?.name === treatment) {
            setValue('treatmentPrice', treatmentPrice);
            if (leadTreatmentSpecification?.teeth_count) {
                setValue('teethAmount', leadTreatmentSpecification?.teeth_count);
            }
            return;
        }
        setValue('treatmentPrice', row?.price);
        setValue('selectedTreatmentCategory', row?.treatment_item?.treatment_category?.name);
        setValue('teethAmount', null);
    };

    const onSubmit = async (data) => {
        if (isFlow) {
            handleSaveTreatment(data);
            return;
        }
        onOpen();
    };

    const handleSuccess = (status) => {
        handleReloadAPI(status);
        handleClickAwayLister();
    };

    const treatmentWhichNeedsTeethCount = ['Veneers', 'Composite Bonding'];

    const isTeethFieldNeeded = (data) => (treatmentWhichNeedsTeethCount?.includes(selected) || data.selectedTreatmentCategory === 'Implants');

    const handleSubmitTreatmentChanges = async () => {
        onClose();
        if (isArray(treatmentData) && treatmentData.length > 0) {
            if (
                values.selectedTreatment !== treatment ||
                values.treatmentPrice !== treatmentPrice ||
                values.teethAmount !== leadTreatmentSpecification?.teeth_count
            ) {
                const updateTreatment = {
                    treatment: values.selectedTreatment,
                    custom_price: values.treatmentPrice,
                    lead: leadId,
                    id: treatmentId,
                };
                if (!isEmpty(leadTreatmentSpecification)) {
                    updateTreatment.leadtreatment_specification = {
                        teeth_count: values.teethAmount || 0
                    };
                } else {
                    updateTreatment.leadtreatment_specification = {
                        lead_treatment: treatmentId,
                        teeth_count: values.teethAmount || 0
                    };
                }
                dispatch(editTreatment(updateTreatment, handleSuccess, 'editTreatment'));
                return;
            }
            handleClickAwayLister();
            return;
        }
        const treatmentDetail = {
            treatment: values.selectedTreatment,
            custom_price: values.treatmentPrice,
            is_practice_notification_required: false,
        };
        dispatch(addTreatment(treatmentDetail, practiceId, leadId, handleSuccess, { needToUpdate: isTeethFieldNeeded(values), teeth: values.teethAmount || 0 }));
    };

    const searchbarHandler = (value) => {
        setSearch(value);
        setCallApi(null);
    };

    const handleChangeTeeth = (e) => {
        if (e.target.value === '') {
            setValue('teethAmount', null);
            return;
        }
        setValue('teethAmount', e.target.value);
    };

    const handleChangePrice = (value) => {
        setValue('treatmentPrice', value);
    };
    const handleScroll = (event) => {
        const listboxNode =
            event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (listboxNode) {
            setPosition(event.target.scrollTop + event.target.clientHeight);
            setCallApi(listboxNode);
        } else {
            setCallApi(null);
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={(e) => handleClickAwayLister(e, dialogRef)}>
                <Popper open={open} anchorEl={anchorEl} transition placement={placement} sx={paperZIndex}>
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper sx={borderRadius}>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={treatmentBoxStyle}>
                                        <Stack sx={reminderStackStyle}>
                                            <Grid container spacing={1} sx={paperGridStyle}>
                                                <Container maxWidth={'xl'} sx={paperGridInnerContainerStyle}>
                                                    <Box sx={paperGridInnerBoxStyle}>
                                                        <NormalLoading
                                                            tableData={treatmentsListV2 === null && 'No Found'}
                                                            pageCount={treatmentsListV2 === null && 'No Found'}
                                                        />
                                                        <Stack>
                                                            <CustomTextField search={search} searchbarHandler={searchbarHandler} isScript={isScript} />
                                                            <TreatmentPopoverScrollbar
                                                                treatments={treatments}
                                                                values={values}
                                                                errors={errors}
                                                                selected={selected}
                                                                handleScroll={handleScroll}
                                                                handleTreatmentSelect={treatmentSelected}
                                                                handleChangeTeeth={handleChangeTeeth}
                                                                handleChangePrice={handleChangePrice}
                                                                isScript={isScript}
                                                            />
                                                        </Stack>
                                                    </Box>
                                                </Container>
                                            </Grid>
                                        </Stack>
                                        <Box sx={treatmentFooterStyle}>
                                            <Button color="inherit" sx={cancelButtonStyle} onClick={(e) => handleClickAwayLister(e, dialogRef)}>
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={isEmpty(selected)}
                                                color="primary"
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={saveButtonStyle}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </FormProvider>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ClickAwayListener>
            <CustomConfirmationDialog
                toggle={toggle}
                handleClose={(e) => { e.stopPropagation(); onClose(); }}
                handleSubmit={handleSubmitTreatmentChanges}
                message={`Are you sure you want to ${isAddTreatment ? 'create' : 'update'} treatment?`}
                style={{ zIndex: '9999999' }}
                dialogRef={dialogRef}
            />
        </>
    );
};

export default TreatmentEditPopover;

TreatmentEditPopover.defaultProps = {
    placement: "left-start"
}

TreatmentEditPopover.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.any,
    handleClickAwayLister: PropTypes.func,
    treatment: PropTypes.string,
    leadTreatmentSpecification: PropTypes.any,
    treatmentData: PropTypes.any,
    treatmentPrice: PropTypes.number,
    leadId: PropTypes.number,
    treatmentId: PropTypes.string,
    treatmentCategory: PropTypes.string,
    handleReloadAPI: PropTypes.func,
    isAddTreatment: PropTypes.bool,
    isFlow: PropTypes.bool,
    isScript: PropTypes.bool,
    handleSaveTreatment: PropTypes.func,
    placement: PropTypes.string
};
CustomTextField.propTypes = {
    search: PropTypes.string,
    searchbarHandler: PropTypes.func,
    isScript: PropTypes.bool,
};
