/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';
import {
    Box,
    Button,
    Chip,
    DialogTitle,
    DialogContent,
    TextField,
    Dialog,
    DialogContentText,
    DialogActions,
    Autocomplete,
    useTheme,
    createFilterOptions
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../hook-form';
// redux
import { useSelector, dispatch } from '../../redux/store';
import {
    getAllTagItem,
    createTagItem,
    deleteTagItem,
    getTagItem,
    createCustomTagItem,
    startLoading
} from '../../redux/slices/tagsCreate';
// validation
import { AutocompleteTagsSchema } from '../../validations/validations';
// style
import { common } from '../../theme/palette';
import {
    SelectedColor,
    ColorBoxStyle,
    tagsBoxStyle,
    InputTags,
    searchTagsStyle
} from '../../styles/AutocompleteTags';

const filter = createFilterOptions();

const ArchivedAutocomplete = ({ user }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const listElem = useRef();
    const mounted = useRef();
    const [inputValue, setInputValue] = useState('');
    const inputValueDebounce = useDebounce(inputValue, 800);

    const { getAllTag: { results = [], count = 0 } = {}, isLoading = false } = useSelector((state) => state.tagsCreate || []);
    const { getTag } = useSelector((state) => state.tagsCreate || []);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const [position, setPosition] = useState(0);
    const [open, toggleOpen] = useState(false);

    const leadId = user?.lead_id || user?.lead?.id || user?.id;

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    useEffect(() => {
        if (practiceId) {
            dispatch(getTagItem(practiceId, leadId));
            dispatch(getAllTagItem(practiceId, 30, 0));
        }
    }, [practiceId]);

    const defaultValues = {
        tags: []
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AutocompleteTagsSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
    } = methods;

    const values = watch();

    const handleDelete = (chipToDelete) => {
        dispatch(deleteTagItem(chipToDelete, practiceId, leadId, handleReload));
    };

    const handleTags = (event, newValue) => {
        const data = newValue.filter(value => typeof value === 'string');
        if (data.length > 0) {
            toggleOpen(true);
            setValue('tagName', data.toString());
            return;
        }
        if (data?.length === 0 && event.key !== 'Backspace') {
            if (newValue && newValue?.length > 0 && newValue[newValue?.length - 1].tag?.inputValue) {
                toggleOpen(true);
                setValue('tagName', newValue[newValue?.length - 1].tag?.inputValue);
            } else {
                setValue('tags', newValue[newValue?.length - 1].tag?.name);
                const data = {
                    tag: newValue[newValue.length - 1].id
                };
                dispatch(createTagItem(data, practiceId, leadId, handleReload));
            }
        }
    };

    const optionLabel = (option) => {
        if (typeof option === 'string') {
            return option?.tag?.name;
        }
        if (option.inputValue) {
            return option?.inputValue;
        }
        return option?.tag?.name;
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (filtered.length <= 0 && params?.inputValue?.trim() && !isLoading) {
            filtered.push({
                tag: {
                    inputValue: params?.inputValue,
                    name: `Add "${params?.inputValue}"`,
                }
            });
        }
        return filtered;
    };

    const onSubmit = () => {
        const tagData = getValues();
        const data = {
            tag: {
                name: tagData.tagName,
                colour: tagData.colour,
                tagId: tagData.tagId
            }
        };
        setValue('tags', [
            ...getValues('tags'),
            {
                title: tagData.tagName,
                color: tagData.colour,
                tagId: tagData.tagId
            },
        ]);
        dispatch(createCustomTagItem(data, practiceId, leadId, handleReload));
    };

    const handleReload = (data) => {
        dispatch(getTagItem(practiceId, leadId));
        toggleOpen(false);
        if (data === 'custom') {
            toggleOpen(false);
            dispatch(getAllTagItem(practiceId, 30, 0));
            dispatch(getTagItem(practiceId, leadId));
        }
    };

    const leadMoreData = () => {
        if (count > results?.length) {
            dispatch(getAllTagItem(practiceId, 30, results?.length));
        }
    };

    const leadMorePosition = (event) => {
        const listboxNode = event?.target?.scrollHeight <= event?.target?.scrollTop + event?.target?.clientHeight;
        if (listboxNode) {
            setPosition(event?.target?.scrollTop + event?.target?.clientHeight);
            leadMoreData();
        }
    };

    useEffect(() => {
        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    tag__name__icontains: inputValueDebounce,
                };
                dispatch(getAllTagItem(practiceId, 30, 0, data));
            } else {
                dispatch(getAllTagItem(practiceId, 30, 0));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
    };

    return (
        <Box sx={tagsBoxStyle}>
            <Controller
                name="tags"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            value={getTag || []}
                            onChange={handleTags}
                            id="free-solo-dialog-demo"
                            options={results || []}
                            noOptionsText={isLoading ? 'Loading...' : 'No options'}
                            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
                            getOptionLabel={optionLabel}
                            filterOptions={(options, params) => filterOptions(options, params)}
                            selectOnFocus
                            handleHomeEndKeys
                            ListboxProps={{
                                ref: listElem,
                                onScroll: leadMorePosition
                            }}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        sx={InputTags(option?.tag?.tag?.colour)}
                                        key={option?.tag?.id}
                                        label={option?.tag?.tag?.name}
                                        onDelete={() => handleDelete(option)}
                                        size="small"
                                        color="info"
                                        variant="soft"
                                    />
                                ))
                            }
                            sx={searchTagsStyle('')}
                            renderInput={(params) => <TextField {...params} label="Tags" sx={{ 'label': { background: isLight ? `${common.white} !important` : `${common.slateGray} !important` } }} />}
                        />
                        <Dialog open={open} onClose={() => toggleOpen(false)}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <DialogTitle>Add a new tag</DialogTitle>
                                <DialogContent>
                                    <DialogContentText sx={{ mb: 3 }}>
                                        Did you miss any tag in our list? Please, add it!
                                    </DialogContentText>
                                    <RHFTextField
                                        autoFocus
                                        margin="dense"
                                        value={getValues('tagName')}
                                        name="tagName"
                                        label="Name"
                                        placeholder="Tag name"
                                        onChange={(event) => setValue('tagName', event.target.value)}
                                    />
                                    <ColorBoxStyle >
                                        <RHFTextField
                                            margin="dense"
                                            type="color"
                                            name="colour"
                                            label="Colour"
                                            placeholder="#000000"
                                        />
                                        <SelectedColor>{values.colour}</SelectedColor>
                                    </ColorBoxStyle>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => toggleOpen(false)}>Cancel</Button>
                                    <Button type='button' onClick={onSubmit}>Add</Button>
                                </DialogActions>
                            </FormProvider>
                        </Dialog>
                    </Box>
                )}
            />
        </Box>
    );
};

export default ArchivedAutocomplete;

ArchivedAutocomplete.propTypes = {
    user: PropTypes.object,
};
