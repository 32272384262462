import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector } from 'react-redux';
import { Box, Typography, Tooltip, Zoom, IconButton } from '@mui/material';
import { Iconify } from '../../../../components';
import { AssignUserAvatar } from '../../../../components/custom-avatar';
import { dispatch } from '../../../../redux/store';
import { appointmentUserSlice, addAppointment } from '../../../../redux/slices/lead';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith
} from '../../../../utils/allTableTab';
import { getAssignees } from '../../../../utils/multiAsignees';
import { getMoreAssignees } from '../../../../utils/leadTableUtils';
import {
    tableCellInnerBoxStyle,
    datePickerBoxStyle,
    datePickerTextFieldStyle,
    bookedWithBoxStyle
} from '../../../../styles/NewLeadTableRowStyle';

const BookedWithField = ({ rowData: rd, OpenAssignTask, tableType, updateLeadPage, reloadCounter }) => {
    const rowData = { ...rd, id: Number(rd?.id?.toString()?.split("-")?.[0]) };
    const { enqueueSnackbar } = useSnackbar();
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { appointmentUserValue } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const updatedAssignees = rowData?.appointments?.[0]?.assignees?.filter(assignee =>
        practiceUser?.results?.some(user => user.id === assignee.id)
    ) || [];
    const assigneeIds = updatedAssignees?.map(assignee => assignee.id) || [];
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [resetKey, setResetKey] = useState(0);
    const perPage = TablePerPage(tableType);
    const pageNumber = TablePageNumber(tableType);
    const status = TableStatus(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const contactCount = TabCount(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const datePickerRef = useRef();
    const [selectedId, setSelectedId] = useState('');

    const handleChange = (e) => {
        if (rowData?.appointments?.[0]?.datetime_human) {
            OpenAssignTask(rowData, assigneeIds, e, rowData?.appointments?.[0]?.id);
        } else {
            dispatch(appointmentUserSlice({ type: 'selectUser' }));
            OpenAssignTask(rowData, '', datePickerRef.current);
        }
    };

    useEffect(() => {
        if (appointmentUserValue?.assignees?.length > 0 && appointmentUserValue.type === 'selectUser') {
            setOpenCalendar(true);
        }
        if (appointmentUserValue?.lead_id && appointmentUserValue.type === 'selectUser') {
            setSelectedId(appointmentUserValue?.lead_id);
        }
    }, [appointmentUserValue]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const appointmentDetail = {
            datetime: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            method: 'IN_PRACTICE',
            contacted_via: 'PHONE',
            lead_treatment: rowData?.treatment_id,
            type: 'TREATMENT',
            assignees: appointmentUserValue?.assignees
        };
        dispatch(addAppointment(appointmentDetail, practiceId, (rowData?.lead_id || rowData?.id), handleSuccess, 'Reschedule'));
        setTimeout(() => {
            setResetKey(prevKey => prevKey + 1);
        }, 200);
    };

    const handleSuccess = (data) => {
        setOpenCalendar(false);
        setSelectedId('');
        if (data.success) {
            enqueueSnackbar(data.message || '', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
        dispatch(appointmentUserSlice(null));
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: status,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTagsValue,
            referral,
            category,
            bookedWith
        };
        updateLeadPage(reloadPayLoad);
        reloadCounter(reloadPayLoad);
    };

    const calendarCloseEvent = () => {
        setSelectedId('');
        setOpenCalendar(false);
    };

    const updatedRowData = {
        ...rowData,
        appointments: [{
            ...rowData?.appointments?.[0],
            assignees: updatedAssignees
        }]
    };

    return (
        <Box sx={{ ...tableCellInnerBoxStyle, position: 'relative', p: '0 !important' }} >
            <Box sx={bookedWithBoxStyle} ref={datePickerRef} onClick={(e) => handleChange(e)} onMouseDown={(e) => handleChange(e)}>
                <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-start' }}>
                    <Typography variant='subtitle2'>
                        <Tooltip title={getAssignees({ assignees: updatedRowData?.appointments?.[0]?.assignees })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }} style={{ display: 'flex', alignItems: 'center' }}>
                            {updatedRowData?.appointments?.[0]?.assignees?.[0]?.user?.first_name && <AssignUserAvatar
                                sx={{ cursor: 'pointer', mr: 1, bgcolor: 'primary.paperColor' }}
                                src={updatedRowData?.appointments?.[0]?.assignees?.[0]?.user?.avatar || ''}
                                name={`${updatedRowData?.appointments?.[0]?.assignees?.[0]?.user?.first_name || ''} ${updatedRowData?.appointments?.[0]?.assignees?.[0]?.user?.last_name || ''}`}
                                role={updatedRowData?.appointments?.[0]?.assignees?.[0]?.role}
                            />}
                            {rowData?.appointments?.[0]?.datetime_human ?
                                (`${getMoreAssignees(updatedRowData?.appointments?.[0])}`)
                                :
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                                    <IconButton
                                        aria-label='add appointment'
                                        component='label'
                                        sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}
                                    >
                                        <Iconify
                                            icon='material-symbols:add-circle'
                                            sx={{ color: 'common.mintyZest', mr: '0' }}
                                            width={20}
                                            height={20}
                                        />
                                    </IconButton>
                                </Box>
                            }
                        </Tooltip>
                    </Typography>
                </Box>
            </Box>
            {!(rowData?.appointments?.[0]?.datetime_human) && (rowData?.id === selectedId) && <Box sx={datePickerBoxStyle}>
                <DateTimePicker
                    key={resetKey}
                    closeOnSelect={false}
                    orientation='landscape'
                    label=''
                    open={openCalendar}
                    onClose={() => calendarCloseEvent()}
                    name='DueDatetime'
                    value={selectedDate}
                    inputFormat={'dd/MM/yyyy HH:mm'}
                    onAccept={(newValue) => handleDateChange(newValue)}
                    ampm={false}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            margin: 'normal',
                            sx: datePickerTextFieldStyle,
                            onKeyDown: (e) => e.preventDefault()
                        },
                        actionBar: { actions: ['cancel', 'accept'] }
                    }}
                />
            </Box>}
        </Box>
    );
};

BookedWithField.propTypes = {
    rowData: PropTypes.array,
    OpenAssignTask: PropTypes.func,
    tableType: PropTypes.string,
    updateLeadPage: PropTypes.func,
    reloadCounter: PropTypes.func,
};

export default BookedWithField;