/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import { TableCell, TableRow, Typography } from '@mui/material';
// hook-form
import { RHFTextField } from '../hook-form';
// constants
import { targetInputs } from '../../constants/LeadDetailConst';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { addTargetData, getAllTargets } from '../../redux/slices/analytics';

// --------------------------------------------------------------------

const TargetSettingRow = ({ data, anotherTarget }) => {
    const [consultationTarget, setConsultationTarget] = useState(0);
    const [goAheadTarget, setGoAheadTarget] = useState(0);
    const [state, setState] = useState(false);
    const consultDebounced = useDebounce(consultationTarget, 1200);
    const goAheadDebounced = useDebounce(goAheadTarget, 1200);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (data && anotherTarget) {
            setConsultationTarget(anotherTarget?.filter((el) => el?.treatment_id === data?.id)[0]?.consultationTarget);
            setGoAheadTarget(anotherTarget?.filter((el) => el?.treatment_id === data?.id)[0]?.goAheadTarget);
        }
    }, [data, anotherTarget]);

    const handleFieldUpdates = (e) => {
        if (e?.target?.name === 'ConsultationTargets') {
            setConsultationTarget(e?.target?.value);
        }
        if (e?.target?.name === 'goAheadTarget') {
            setGoAheadTarget(e?.target?.value);
        }
        setState(true);
    };

    useEffect(() => {
        if (state) {
            if (consultDebounced) {
                const updateData = {
                    treatment: data?.id,
                    lead_status: 'CONSULTATION_BOOKED',
                    target_count: Number(consultationTarget)
                };
                dispatch(addTargetData(updateData, practiceId, updateData.treatment, handleSuccess));
            }
        }
    }, [consultDebounced]);

    useEffect(() => {
        if (state) {
            if (goAheadDebounced) {
                const updateData = {
                    treatment: data?.id,
                    lead_status: 'TREATMENT_STARTED',
                    target_count: Number(goAheadTarget)
                };
                dispatch(addTargetData(updateData, practiceId, updateData.treatment, handleSuccess));
            }
        }
    }, [goAheadDebounced]);

    const handleSuccess = (data) => {
        dispatch(getAllTargets(practiceId));
        setState(false);
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    return (
        <TableRow hover sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)', p: 0 }}>
            <TableCell>
                <Typography> {data?.treatment_item?.name} </Typography>
            </TableCell>
            <TableCell>
                <RHFTextField
                    name={'ConsultationTargets'}
                    sx={{ maxWidth: '110px', 'input': { padding: '10px 14px' } }}
                    onChange={handleFieldUpdates}
                    value={consultationTarget || 0}
                    onKeyDown={(evt) => (targetInputs.includes(evt.key) || (/^[0-9]*$/).test(evt.key)) ? evt : evt.preventDefault()}
                />
            </TableCell>
            <TableCell>
                <RHFTextField
                    name={'goAheadTarget'}
                    sx={{ maxWidth: '110px', 'input': { padding: '10px 14px' } }}
                    onChange={handleFieldUpdates}
                    value={goAheadTarget || 0}
                    onKeyDown={(evt) => (targetInputs.includes(evt.key) || (/^[0-9]*$/).test(evt.key)) ? evt : evt.preventDefault()}
                />
            </TableCell>
        </TableRow>
    );
};

TargetSettingRow.propTypes = {
    data: PropTypes.object,
    anotherTarget: PropTypes.array
};

export default TargetSettingRow;
