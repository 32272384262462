/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Input,
    Button,
    Divider,
    IconButton,
    Typography,
    FormControlLabel,
    OutlinedInput,
    Switch,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    InputAdornment,
    FormControl,
    CircularProgress,
    Autocomplete,
    TextField
} from '@mui/material';
// hooks
import { isEmpty } from 'lodash';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { getMediaImages, setBasicBody, setFileUploadError } from '../../../redux/slices/campaign';
import { useToggle, useConsentToggle } from '../../../hooks';
//
import SendMessageDialog from './SendMessageDialog';
// components
import { Iconify } from '../../../components';
import Editor from '../../../components/editor/Editor';
import { RHFTextField, FormProvider, RHFAutocomplete } from '../../../components/hook-form';
import { SearchNotFound } from '../../../components/search-not-found';
import { OpenDialogPopup } from '../../../components/fullScreenPopUps';
import { AttendedAppointment } from '../../../components/Attended';
// sections
import { PersonalDetails, TreatmentAndAppointment, PaymentOption, MessageAndReminder } from '../general/app';
// constants
import { addCategory, BeforeComa, AfterComa } from '../../../constants/EmailMessageComposeConst';
// Store
import { useSelector, dispatch } from '../../../redux/store';
import { clearTaskLead, searchLead, createNewLead, clearLead, saveConsentLead } from '../../../redux/slices/lead';
import { updateError } from '../../../redux/slices/user';
// style
import {
    imgLoaderBoxStyle,
    formMainBoxStyle,
    allBoxTypeStyle,
    formControlStyle,
    multipleChipStyle,
    textFieldStyle,
    boxStyle,
    iconBtnStyle,
    stackStyle,
    inputStyle,
    formControlLabelStyle,
    editorBoxStyle,
    editorBoxBtnStyle,
    RHFTextFieldStyle,
    saveBtnStyle,
    selectMenuItemStyle,
    typographyStyle,
    commaSpanStyle
} from '../../../styles/EmailMessageComposeStyle';
// util
import { getDelayLabel } from '../../../utils/formatTime';
import { uploadImage } from '../../../redux/slices/practiceMessage';
import CustomMediaLibrary from './CustomMediaLibrary';

// ----------------------------------------------------------------------

const InputStyle = styled(Input)(({ theme }) => ({
    padding: theme.spacing(0.5, 3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    ' input:focus::placeholder': {
        color: 'transparent'
    }
}));

// ----------------------------------------------------------------------
export default function EmailMessageComposeWithoutPopup({
    openEmailCompose,
    onCloseEmailCompose,
    composeDetail,
    isEdit,
    type,
    handleSaveMessage,
    messageType,
    practiceId,
    OpenTemplate,
    useOrSend,
    setTemplate,
    setTemplateOpen,
    handleSendEmail,
    leadDetail,
    sendTemplate,
    screen,
    campaign
}) {

    const defaultValues = {
        leadName: ''
    };

    const methods = useForm({
        defaultValues
    });
    const EditorMessage = useRef();
    const inputRef = useRef();

    // eslint-disable-next-line no-nested-ternary
    const initialIsUnLayer = campaign ? !isEmpty(composeDetail) ? composeDetail?.use_unlayer : true : false;

    const [body, setBody] = useState(composeDetail?.body || '');
    const [unLayerHtml, setUnLayerHtml] = useState(composeDetail?.unlayer_html || '');
    const [templateJson, setTemplateJson] = useState(composeDetail?.unlayer_template || {});
    const [fullScreen, setFullScreen] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [user, setUser] = useState({});
    const [validStep, setValidStep] = useState(false);
    const [active, setActive] = useState(0);
    const [base, setBase] = useState('');
    const [attachment, setAttachment] = useState([]);
    const [fileSize, setFileSize] = useState({});
    const [isUnlayer, setIsUnlayer] = useState(initialIsUnLayer);
    const [customMergeTags, setCustomMergeTags] = useState({});
    const [inputFiles, setInputFiles] = useState(null);

    const { imageLoader } = useSelector((state) => state.practiceMessage);
    const { mediaImages, basicBody } = useSelector((state) => state.campaign);
    const { createLeadForConsent, mergeTags } = useSelector((state) => state.lead);
    const {
        taskSearchLead,
        isLoading,
        attendance,
        markAsComplete,
        followUpLead,
    } = useSelector((state) => state.lead);

    const getNameList = (start, end) => mergeTags?.slice(start, end);

    const editor = document.getElementsByClassName('ql-editor')[0] && document.getElementsByClassName('ql-editor')[0].innerHTML;

    const debouncedValue = useDebounce(searchQuery, 1000);
    const autoSaveFunction = useDebounce(unLayerHtml, 5000);
    const [isAutoSaveOn, setIsAutoSaveOn] = useState(false);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();
    const {
        toggle: openCustomLib,
        onOpen: onOpenCustomLib,
        onClose: onCloseCustomLib
    } = useToggle();

    const {
        toggle: ConsentBuilder,
        onOpen: onOpenConsent,
        onClose: onCloseConsent
    } = useConsentToggle();

    const {
        control,
        setValue,
        clearErrors,
        getValues,
    } = methods;

    const onSubmit = (data) => {
        setValidStep(!!data);
    };

    const [compose, setCompose] = useState({
        subject: composeDetail?.subject || '',
        is_enabled: composeDetail?.is_enabled,
        delay: composeDetail?.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
        personName: composeDetail?.personName || [],
        category: composeDetail?.category || '',
        channel: composeDetail?.channel || 'EMAIL',
        name: composeDetail?.name || ''
    });

    useEffect(() => {
        if (practiceId) {
            dispatch(getMediaImages({ practiceId }));
        }
    }, [practiceId]);

    useEffect(() => {
        if (!isEmpty(basicBody)) {
            setBody(basicBody);
        }
    }, [basicBody]);

    useEffect(() => {
        setUser(createLeadForConsent);
        setValue('lead_id', createLeadForConsent?.id);
        setSearchQuery(`${createLeadForConsent?.first_name || ''} ${createLeadForConsent?.last_name || ''}`);
    }, [createLeadForConsent]);

    useEffect(() => {
        if (Object.keys(composeDetail).length > 0) {
            if (Object.keys(composeDetail).length > 0) {
                setCompose({
                    subject: composeDetail.subject || '',
                    is_enabled: composeDetail.is_enabled,
                    delay: composeDetail.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
                    personName: composeDetail.personName || [],
                    category: composeDetail.category || '',
                    channel: composeDetail.channel || 'EMAIL',
                    name: composeDetail.name || ''
                });
                setBody(composeDetail.body || '');
                setUnLayerHtml(composeDetail.unlayer_html || '');
                setAttachment(composeDetail);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [composeDetail]);

    useEffect(() => {
        if (markAsComplete && !followUpLead) {
            dispatch(createNewLead(onCloseCreateLead));
        }
    }, [markAsComplete, followUpLead]);

    useEffect(() => {
        if (searchQuery.trim().length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'taskLead'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    useEffect(() => {
        if (sendTemplate && sendTemplate) {
            setEditForm(true);
        }
    }, [sendTemplate]);

    useEffect(() => {
        if (editor && (editor !== composeDetail.body)) {
            setEditForm(true);
        }
    }, [editor, composeDetail]);

    const STEPS = [
        {
            label: 'Personal details',
            component: <PersonalDetails ref={inputRef} handleNext={onSubmit} active={active} />
        },
        {
            label: 'Treatment & Appointment',
            component: <TreatmentAndAppointment type={'CreatLead'} ref={inputRef} handleNext={onSubmit} active={active} />
        }
    ];

    const [leadStep, setLeadStep] = useState(STEPS);

    useEffect(() => {
        if (attendance) {
            const steps = [...STEPS];
            if (attendance === 'attendance') {
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} active={active} type={'CreatedLead'} />
                });
            } else {
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} active={active} />
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} active={active} />
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendance]);

    const handleClose = () => {
        onCloseEmailCompose();
        setFullScreen(false);
        if (screen !== 'consent') {
            setCompose({
                subject: '',
                is_enabled: composeDetail.is_enabled,
                delay: composeDetail.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
                personName: composeDetail.personName || [],
                category: '',
                channel: 'EMAIL',
                name: ''
            });
            setBody('');
            dispatch(setBasicBody(''));
            setUnLayerHtml('');
        }
    };

    const onCloseCreateLead = () => {
        onCloseConsent();
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
    };

    useEffect(() => {
        if (mergeTags) {
            const modifiedData = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const mergeTag of mergeTags) {
                modifiedData[mergeTag.tag] = {
                    name: mergeTag.tag,
                    sample: mergeTag.tag,
                    value: `{{${mergeTag.tag}}}`,
                };
            }
            setCustomMergeTags(modifiedData);
        }
    }, [mergeTags]);

    const isLoaded = useRef(true);

    const handleChangeEvent = (value, type) => {
        setEditForm(true);
        setIsAutoSaveOn(true);
        if (campaign && isUnlayer) {
            EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
            EditorMessage?.current?.editor?.registerCallback('image', (file, done) => {
                const reader = new FileReader();
                reader.readAsDataURL(file?.accepted[0]);
                reader.onload = () => {
                    const detail = {
                        image: reader.result
                    };
                    dispatch(uploadImage(detail, (data) => {
                        if (data.success) {
                            const { image } = data.data;
                            if (image) {
                                done({ progress: 100, url: image });
                            }
                            return;
                        }
                        dispatch(updateError({ success: false, message: data.message.image.toString() }));
                    }));
                };
            });
            EditorMessage?.current?.editor?.exportHtml((data) => {
                const { html, design } = data;
                setUnLayerHtml(html);
                setTemplateJson(design);
            }, {
                minify: true,
            });
            if (type === 'subject' || type === 'name' || type === 'category') {
                setCompose({
                    ...compose,
                    [type]: value,
                });
            }
            return;
        }
        if (type === 'body') {
            setBody(value);
            return;
        }
        setCompose({
            ...compose,
            [type]: value,
        });
    };

    const handleSave = (isSaveAndClose) => {
        if (!isUnlayer && body === '<p><br></p>') {
            dispatch(updateError({ success: false, message: 'Template should be not empty' }));
            return;
        }
        if (messageType === 'Practice_Message' || messageType === 'OnBoarding') {
            const detail = {
                delay: compose.delay.second || 0,
                subject: compose.subject || '',
                use_unlayer: isUnlayer,
                is_enabled: compose.is_enabled,
            };
            if (campaign && isUnlayer) {
                detail.unlayer_html = unLayerHtml || '';
                detail.unlayer_template = templateJson || {};
            } else {
                detail.body = editor || body || '';
            }
            handleSaveMessage(detail, '', isSaveAndClose);
            return;
        }
        const detail = {
            practice: practiceId,
            name: compose.name || '',
            subject: compose.subject || '',
            use_unlayer: isUnlayer,
            channel: 'EMAIL',
            category: compose.category || '',
        };
        if (campaign && isUnlayer) {
            detail.unlayer_html = unLayerHtml || '';
            detail.unlayer_template = templateJson || {};
        } else {
            detail.body = editor || body || '';
        }
        if (compose.category === 'COMMUNICATION') {
            detail.base_template = import.meta.env.VITE_REACT_APP_COMMUNICATION_ID || 3;
        }
        if (compose.category === 'MARKETING') {
            detail.base_template = import.meta.env.VITE_REACT_APP_MARKETING_ID || 5;
        }
        if (compose.category === 'PROMOTION') {
            detail.base_template = import.meta.env.VITE_REACT_APP_PROMOTION_ID || 6;
        }

        if (base) {
            detail.attachments = base.files;
        }
        handleSaveMessage(detail, composeDetail.id, isSaveAndClose);
        if (isSaveAndClose) {
            handleClose();
        }
        setBase('');
    };

    const checkValidationForAutoSave = () => !isEmpty(compose.name) && !isEmpty(compose.subject) && !isEmpty(compose.category);

    useEffect(() => {
        const handleAutoSave = () => {
            if (isAutoSaveOn && !isEmpty(composeDetail) && checkValidationForAutoSave() && campaign && isUnlayer && autoSaveFunction) {
                setIsAutoSaveOn(false);
                handleSave();
            }
        };
        handleAutoSave();
    }, [autoSaveFunction]);

    if (!openEmailCompose) {
        document.body.style.overflowY = 'auto';
        return null;
    }

    const handleTime = (sendTime) => {
        setEditForm(true);
        setCompose({
            ...compose,
            delay: sendTime,
        });
        onCloseContacts();
    };

    const handleChange = (e, value) => {
        if (value) {
            const data = `$${value}`;
            if (EditorMessage.current) {
                const editor = EditorMessage?.current?.getEditor();
                const index = editor?.getSelection() ? editor?.getSelection()?.index : editor?.getLength();
                editor.insertText(index, data, 'link');
            }
        }
    };

    const disabledTemplate = () => {
        if ((messageType === 'Practice_Message' || messageType === 'OnBoarding') && compose.subject.trim() && editForm) {
            return false;
        }

        if (compose.name.trim() && compose.category.trim() && compose.subject.trim() && Object.values(fileSize).length === 0 && editForm) {
            return false;
        }
        if (messageType === 'LeadTemplate' && screen !== 'consent') {
            if (compose?.subject?.trim() && editForm && Object.values(fileSize).length === 0) {
                return false;
            }
        }
        return true;
    };

    const disabledConsentCompose = () => {
        if (compose?.subject?.trim() && (user && Object.values(user)?.length !== 0) && editForm) {
            return false;
        }
        return true;
    };

    const handleSend = async () => {
        const base64Array = [];

        if (composeDetail?.attachments?.length > 0) {
            await handlePromiseData(composeDetail.attachments, base64Array);
        }

        const detail = {
            name: compose.name || '',
            subject: compose.subject || '',
            use_unlayer: isUnlayer,
            channel: 'EMAIL',
            lead: getValues('lead_id'),
            attachments: base.files || base64Array
        };
        if (campaign && isUnlayer) {
            detail.unlayer_html = unLayerHtml || '';
            detail.unlayer_template = templateJson || {};
        } else {
            detail.body = editor || body || '';
        }
        handleSendEmail(detail, user);
    };

    const searchLeadHandler = (value, reason) => {
        if (reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            dispatch(clearTaskLead());
        }
    };

    const noOptionsHandler = () => {
        if (searchQuery.trim() === '' || taskSearchLead && taskSearchLead.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} type={screen} onClick={handleCreateLead} />;
        }
        return '';
    };

    const handleCreateLead = () => {
        onOpenConsent();
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
    };

    const handleSearchLead = (e, value) => {
        if (value) {
            setValue('leadName', `${value?.first_name} ${value?.last_name}`);
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setUser(value);
            setValue('lead_id', value?.id);
            dispatch(saveConsentLead(value));
        }
        clearErrors('leadName');
        setUser(value !== null ? value : {});
    };

    const fetchData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const handlePromiseData = async (data, base64Array) => {
        await Promise.all(
            data.map(async (obj) => ({ file: await fetchData(obj.file) })),
        ).then((value) => {
            value.map(data => base64Array.push(data));
        });
    };

    const handleAttachment = async (event, value, perform) => {
        const base64Array = [];

        const newFiles = perform === 'clear' ? [] : event;

        if (perform === 'clear') {
            const data = attachment.attachments.filter(res => res.file !== value);
            setAttachment({ ...attachment, attachments: data });
            handlePromiseData(data, base64Array);
            handlePromiseData(event, base64Array);

            setBase({
                files: base64Array,
            });
            setEditForm(true);
            return;
        }

        if (attachment?.attachments?.length > 0) {
            handlePromiseData(attachment.attachments, base64Array);
        }

        const largerFile = newFiles.filter(value => value.size > 20971520);

        if (largerFile?.length > 0 && newFiles.length > 0) {
            setFileSize({ file: largerFile[0]?.name, size: largerFile[0]?.size, });
            setEditForm(false);
        } else {
            setFileSize({});
            setEditForm(true);
        }

        newFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                base64Array.push({ file: reader.result });
            };
            reader.onerror = (error) => {
                dispatch(updateError({
                    success: false,
                    message: JSON.stringify(error)
                }));
            };
        });

        setBase({
            files: base64Array,
        });
        setEditForm(true);
    };

    const handleIsUnlayerChange = (event) => {
        if (event.target.checked) {
            isLoaded.current = true;
        }
        setIsUnlayer(event.target.checked);
    };

    const handleImageLibrary = (data, done) => {
        const handleClick = (e) => {
            if (e.target.classList.contains('library-image') && e.target.closest('#mediaLibrary')) {
                if (e.target.getAttribute('src')) {
                    onCloseCustomLib();
                    setInputFiles(null);
                    done({ url: e.target.getAttribute('src') });
                    dispatch(getMediaImages({ practiceId, limit: 50, offset: 0, shouldReset: true }));
                }
                // Remove the event listener after handling the click
                document.removeEventListener('click', handleClick);
            }
        };

        onOpenCustomLib();

        // Attach the event listener
        document.addEventListener('click', handleClick);
    };

    const handleCloseMediaLibrary = () => {
        onCloseCustomLib();
        dispatch(getMediaImages({ practiceId, limit: 50, offset: 0, shouldReset: true, }));
        setInputFiles(null);
        dispatch(setFileUploadError(''));
    };

    return (
        <>
            {imageLoader &&
                <Box sx={imgLoaderBoxStyle}>
                    <CircularProgress size={56} thickness={4} sx={{ opacity: 0.48 }} />
                </Box>
            }
            <FormProvider fullScreenForm={fullScreen} methods={methods}>
                <Box sx={formMainBoxStyle(messageType, screen)}>
                    {messageType !== 'LeadTemplate' && <Box sx={allBoxTypeStyle(messageType, screen)}>
                        {(messageType === 'Practice_Message' || messageType === 'OnBoarding') && <Iconify icon={'eva:email-fill'} width={24} height={24} mr={1} />}
                        {(messageType === 'Template') &&
                            <FormControl sx={formControlStyle}>
                                <Iconify sx={{ color: '#637381', marginRight: '5px' }} icon={'bi:filter'} width={20} height={20} />
                                <InputLabel id="select-multiple-chip" sx={{ fontWeight: 600 }}>
                                    Add to category
                                </InputLabel>
                                <Select
                                    labelId="select-multiple-chip"
                                    id="multiple-chip"
                                    value={compose.category}
                                    onChange={(event) => handleChangeEvent(event.target.value, 'category')}
                                    placeholder="Add to category"
                                    label="Add to category"
                                    input={
                                        <OutlinedInput
                                            placeholder="Add to category"
                                            inputProps={{ opacity: '1' }}
                                            id="select-multiple-chip"
                                            sx={{ opacity: '0' }}
                                            style={{ opacity: '1' }}
                                            label="Add to category"
                                        />
                                    }
                                    sx={multipleChipStyle}
                                >
                                    {addCategory.map((name, index) => (
                                        <MenuItem
                                            sx={selectMenuItemStyle}
                                            key={index}
                                            value={name}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        {(messageType === 'Template') && <RHFTextField
                            name="name"
                            placeholder="Add template name"
                            variant="standard"
                            value={compose.name}
                            sx={textFieldStyle}
                            onChange={(event) => handleChangeEvent(event.target.value, 'name')}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />}
                        <Typography variant="h6" sx={typographyStyle}>
                            {isEdit
                                ? composeDetail?.message && `${composeDetail?.message?.lead_status} Email Message`
                                : 'New Email Message'}
                        </Typography>
                    </Box>}
                    {(useOrSend && useOrSend === 'send' && screen === 'consent') &&
                        <Controller
                            name={'leadName'}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    {...field}
                                    autoFocus
                                    name={'leadName'}
                                    inputValue={searchQuery.trim()}
                                    value={searchQuery.trim()}
                                    clearIcon={false}
                                    options={taskSearchLead && taskSearchLead?.length > 0 ? taskSearchLead : []}
                                    filterOptions={(option) => option}
                                    loading={isLoading}
                                    sx={{ width: '400px' }}
                                    onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                                    noOptionsText={noOptionsHandler()}
                                    onChange={(e, value) => handleSearchLead(e, value)}
                                    isOptionEqualToValue={(option, value) => option?.first_name === value}
                                    getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                                    renderOption={(props, option) => {
                                        const { first_name: firstName, last_name: lastName } = option;
                                        return (
                                            <Box component="li" {...props} key={option.id} >
                                                {firstName || ''} {lastName || ''}
                                            </Box>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!error}
                                            helperText={(searchQuery?.length === 0 && error) && 'This field is required'}
                                            placeholder="Search lead name or email"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify icon="uil:search" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    }
                    <Box sx={boxStyle(useOrSend, screen)}>
                        {useOrSend && useOrSend === 'send' &&
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#212B36' }}>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {(leadDetail || user)?.first_name} {(leadDetail || user)?.last_name}
                                </span>
                                <span style={commaSpanStyle}>
                                    {BeforeComa}{(leadDetail || user)?.lead_contact && (leadDetail || user)?.lead_contact?.email}{AfterComa}
                                </span>
                            </Typography>}
                        {useOrSend && useOrSend === 'use' &&
                            <Typography>
                                <IconButton onClick={handleClose}>
                                    <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                                </IconButton> {compose.name}
                            </Typography>}
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '5px 0' }}>
                            <IconButton onClick={fullScreen ? () => setFullScreen(false) : () => setFullScreen(true)} sx={{ ...iconBtnStyle, display: 'none' }}>
                                <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} width={20} height={20} />
                            </IconButton>
                            <IconButton onClick={handleClose}>
                                <Iconify icon={'eva:close-fill'} width={20} height={20} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Stack sx={stackStyle}>
                    <InputStyle disabled={useOrSend === 'use'} type="text" placeholder="Add a subject" value={compose.subject} onChange={(event) => handleChangeEvent(event.target.value, 'subject')} sx={inputStyle} />
                    {(messageType === 'Practice_Message' || messageType === 'OnBoarding') && <FormControlLabel
                        sx={formControlLabelStyle}
                        control={<Switch checked={compose.is_enabled} onChange={(event) => handleChangeEvent(event.target.checked, 'is_enabled')} />}
                        label={compose.is_enabled ? 'Published' : 'Unpublished'}
                    />}
                </Stack>
                <Editor
                    id="compose-mail"
                    value={body}
                    EditorMessage={EditorMessage}
                    fullScreen={fullScreen}
                    type={type}
                    readOnly={useOrSend === 'use'}
                    onChange={(value) => { handleChangeEvent(value, 'body'); }}
                    handleAttachment={handleAttachment}
                    file={attachment}
                    screenType={messageType}
                    bounds={'.app'}
                    placeholder="Type a message"
                    fileSize={fileSize}
                    setFileSize={setFileSize}
                    campaign={campaign}
                    onLoad={() => {
                        if (isLoaded?.current) {
                            isLoaded.current = false;
                            EditorMessage?.current?.editor?.registerCallback('selectImage', handleImageLibrary);
                            if (!isEmpty(composeDetail?.unlayer_template)) {
                                EditorMessage?.current?.editor?.loadDesign(composeDetail?.unlayer_template);
                                EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
                            }
                            if (!isEmpty(templateJson)) {
                                EditorMessage?.current?.editor?.loadDesign(templateJson);
                                EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
                            }
                        }
                    }}
                    sx={{
                        borderColor: 'transparent',
                        flexGrow: 1,
                        height: '100%',
                    }}
                    useUnlayer={isUnlayer}
                    handleIsUnlayerChange={handleIsUnlayerChange}
                    practiceId={practiceId}
                    composeDetail={composeDetail}
                />
                <Divider />
                <Box sx={editorBoxStyle}>
                    {useOrSend && useOrSend !== 'use' && useOrSend !== 'consent' &&
                        <Button onClick={OpenTemplate} sx={editorBoxBtnStyle}>
                            <Iconify sx={{ color: 'grey.700', marginRight: '10px' }} icon={'material-symbols:calendar-view-month'} width={20} height={20} /> Templates
                        </Button>}
                    {!isUnlayer && (useOrSend !== 'use' || useOrSend === undefined) &&
                        <Box sx={{ display: 'flex', width: '30%' }}>
                            <RHFAutocomplete
                                name={'more'}
                                size="small"
                                control={control}
                                options={getNameList()?.map((name) => name.tag)}
                                onChange={handleChange}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tags"
                                    />
                                )}
                            />
                        </Box>
                    }
                    {messageType !== 'LeadTemplate' ?
                        <Box sx={{ flex: 1, textAlign: 'right' }}>
                            {(messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
                                <RHFTextField
                                    name="Send"
                                    label="Send"
                                    readOnly
                                    value={compose.delay && compose.delay.label || ''}
                                    onClick={() => { onOpenContacts(); }}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <Iconify icon={'material-symbols:send'} width={24} height={24} />
                                            </InputAdornment>,
                                    }}
                                    sx={RHFTextFieldStyle}
                                />}
                            {(messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
                                <Button
                                    sx={saveBtnStyle}
                                    variant="contained"
                                    onClick={handleSave}
                                    disabled={disabledTemplate()}
                                >
                                    Save
                                </Button>
                            }
                            {(messageType !== 'Practice_Message' && messageType !== 'OnBoarding') &&
                                <>
                                    <Button
                                        sx={{ ...saveBtnStyle, mr: 2 }}
                                        variant="contained"
                                        onClick={() => handleSave(false)}
                                        disabled={disabledTemplate()}
                                    >
                                        Save Template
                                    </Button>
                                    <Button
                                        sx={saveBtnStyle}
                                        variant="contained"
                                        onClick={() => handleSave(true)}
                                        disabled={disabledTemplate()}
                                    >
                                        Save & close
                                    </Button>
                                </>
                            }
                        </Box> :
                        <Box sx={{ flex: 1, textAlign: 'right' }}>
                            {(useOrSend === 'send' || useOrSend === 'consent') &&
                                <Button
                                    sx={saveBtnStyle}
                                    variant="contained"
                                    onClick={handleSend}
                                    disabled={screen !== 'consent' ? disabledTemplate() : disabledConsentCompose()}
                                >
                                    Send Email
                                </Button>
                            }
                            {useOrSend === 'use' &&
                                <Button
                                    sx={saveBtnStyle}
                                    variant="contained"
                                    onClick={() => { setTemplate(composeDetail); setTemplateOpen(false); }}
                                >
                                    Use Template
                                </Button>
                            }
                        </Box>
                    }
                </Box>
            </FormProvider>
            <SendMessageDialog
                open={openContacts}
                handleTime={handleTime}
                onClose={onCloseContacts}
                composeDetail={composeDetail}
            />
            {ConsentBuilder && <OpenDialogPopup
                open={ConsentBuilder}
                onClose={onCloseCreateLead}
                steps={leadStep}
                type={'createLead'}
                validStep={validStep}
                updateStep={(type) => setValidStep(type)}
                inputRef={inputRef}
                title='Create Lead'
                headerButtons
                setActive={setActive}
            />}
            <CustomMediaLibrary practiceId={practiceId} setInputFiles={setInputFiles} inputFiles={inputFiles} mediaLibraryImage={mediaImages} open={openCustomLib} handleClose={handleCloseMediaLibrary} />
        </>
    );
}

EmailMessageComposeWithoutPopup.propTypes = {
    openEmailCompose: PropTypes.bool,
    onCloseEmailCompose: PropTypes.func,
    composeDetail: PropTypes.any,
    isEdit: PropTypes.bool,
    handleSaveMessage: PropTypes.func,
    type: PropTypes.string,
    messageType: PropTypes.string,
    practiceId: PropTypes.string,
    OpenTemplate: PropTypes.any,
    useOrSend: PropTypes.string,
    setTemplate: PropTypes.any,
    setTemplateOpen: PropTypes.any,
    handleSendEmail: PropTypes.func,
    leadDetail: PropTypes.any,
    sendTemplate: PropTypes.bool,
    screen: PropTypes.string,
    campaign: PropTypes.any,
};