import React from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
    useRoutes,
} from "react-router-dom";
import { wrapUseRoutes } from "@sentry/react";

import * as Sentry from "@sentry/react";

// Flag to check if Sentry is initialized
let isSentryInitialized = false;

// Conditionally initialize Sentry for production
if (window.location.origin === "https://dental-sem.co.uk") {
    Sentry.init({
        dsn: "https://6e2eb0942fb174e880057e2406184025@o1361470.ingest.us.sentry.io/4508273023582208",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: false, // Disable masking of all text
                maskTextSelector: null, // Ensure no specific selectors are masked
                blockAllMedia: false, // Disable blocking of media,
                networkDetailAllowUrls: [] // Allow tracking of all network calls without restrictions
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^\//, /^https:\/\/api\.dental-sem\.co\.uk/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
    isSentryInitialized = true;
}

// Use Sentry routes wrapper only if Sentry is initialized
export const useSentryRoutes = isSentryInitialized
    ? wrapUseRoutes(useRoutes)
    : useRoutes;