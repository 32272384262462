import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Button, AppBar, Typography, Slide } from '@mui/material';
// components
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { SingleConsentPreview } from '../scriptListing';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
} from '../../styles/ScheduleReminder';
import { scriptStackStyle } from '../../styles/CallScripts';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

const OpenConsentPreviewPopup = ({ open, onClose, headerButtons }) => {

    const handleClose = () => {
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        onClose();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle()}
        >
            <AppBar sx={commonDialogHeaderStyle}>
                <Typography
                    sx={reminderTitleStyle}
                    variant='h6'
                    component='div'
                >
                    Consent Preview
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color='inherit'
                            variant='outlined'
                            sx={{ borderColor: 'common.white', color: 'common.white' }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={{ ...scriptStackStyle, padding: '30px 30px 150px' }}>
                <SingleConsentPreview onClose={onClose} />
            </Stack>
        </Dialog>
    );
};

OpenConsentPreviewPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    headerButtons: PropTypes.bool,
};

export default OpenConsentPreviewPopup;