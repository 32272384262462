/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { callApi } from 'src/utils/apiCall';
import TreatmentTabList from 'src/sections/@dashboard/lead/TreatmentTabList';
import { setOpenCallScriptFromLeadPage } from 'src/redux/slices/callScript';

// libraries
import { Box, Stack, Card, Dialog, Grid, Button, AppBar, Typography, Tabs, Tab, Grow, Popper, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { AccountSocialLinks } from 'src/sections/@dashboard/user/account';
import OverviewTab from 'src/sections/@dashboard/lead/OverviewTab';
import NotesTab from 'src/sections/@dashboard/lead/NotesTab';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
// components
import Iconify from '../Iconify';
import StartTreatmentPopup from '../dialogPopUp/StartTreatmentPopup';
import SimilarLeadWarningPopup from '../../sections/@dashboard/leadDetail/SimilarLeadWarningPopup';
import { RenderActiveStep, Transition } from '../dialogPopUp';
import { ToDoList, ActivityList } from '../../sections/@dashboard/lead';
import CustomCompleteTreatmentDialog from '../../pages/dashboard/tables/AllLeadTable/CustomCompleteTreatmentDialog';
import ButtonContainer from './ButtonContainer';
// store
import { useSelector, dispatch } from '../../redux/store';
import { updateSteps, updateBookingSteps, contactLeadSlice, updateActiveStep, updateFollowUpLead, setAttendedActiveTab, setCurrentTreatmentData, setDepositStatus, setTodoListFilter, leadTaskListCounter, UpdateDepositPendingStatus, setTreatmentLoading, setAction, leadFlowReminderData, getLeadNotes, getLeadReminderData } from '../../redux/slices/lead';
import { updateShowSubjectList } from '../../redux/slices/practiceMessage';
import { getPracticeId } from '../../redux/slices/clincheck';
// hooks
import { useResponsive, useToggle } from '../../hooks';
// style
import { menuListStyle } from '../../styles/NewLeadTableRowStyle';
import { contactLeadDialogStyle, contactLeadTitleStyle, listHeaderBoxStyle, popupMainStackStyle, contactLeadCancelButtonStyle, commonDialogHeaderStyle, activeStepTitleStyle, activeStepTextStyle, cancelBoxStyle, rightBoxInnerStyle, leftBoxInnerStyle, timeLineTypoV2, offerContentStyle, offerMainBoxStyle, offerBoxStyle, tabContentBoxStyle, tabsMainBoxStyle, tabButtonStyle, listCounterBoxStyle, footerButtonBoxStyle, cardMainAreaWrapper } from '../../styles/ContactLeadPopup';
import { menuItemStyle } from '../../styles/DialoguePopup';
import { NormalLoading } from '../loading';
import MessageContainer from '../whatsappComponents/MessageContainer';


const OpenContactLeadPopup = ({ handleReloadLeads, open, openArchivePopup, onClose, steps, type = 'lead', inputRef, user, updateStep, validStep, headerButtons, onboaradHeaderButtons, setActive, BookingAttendance, ContactedCount, showWhatsapp, setOpenContactFromCall }) => {
    const [activeStep, setActiveStep] = useState(0);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const [outcome, setOutcome] = useState('Contact Lead');
    const { activeTab } = useSelector(state => state.singlePractice);
    const { isLoader } = useSelector((state) => state.loaderReducer);
    const { practice } = useSelector(state => state.practice);
    const { createLead, isAppointmentBooked, archivedLead, leadFlowReminder, taskFlowCounter, tableRowLead, newLeadDetail, leadNotes, leadNotesArr } = useSelector((state) => state.lead);
    const [value, setValue] = useState(showWhatsapp ? 'whatsapp' : 'overview');
    const [expandLayout, setExpandLayout] = useState(false);
    const isDesktop = useResponsive('down', 'sm');
    const [depositPaidStatus, setDepositPaidStatus] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [leadUserData, setLeadUserData] = useState([])
    const [NotesLength, setNotesLength] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);

    const { toggle: isOpenNextAction, onOpen: onOpenNextAction, onClose: onCloseNextAction } = useToggle();
    const { toggle: isOpenStartTreatment, onOpen: onOpenStartTreatment, onClose: onCloseStartTreatment } = useToggle();
    const anchorMoreMenuRef = useRef(null);

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    const handleChange = (event, newValue) => setValue(newValue);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(getLeadNotes(user?.id || user?.lead_id, practiceId, handleSuccess));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, 'leadNotes', user?.id || user?.lead_id, '', '', '', 'counter', '', false));
    }, [user]);

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
    };

    useEffect(() => {
        const combineNotes = [];
        if (leadNotes && leadNotes.length > 0) {
            combineNotes.push(...leadNotes.filter((el) => el.additional_notes !== ''));
        }
        if (leadNotesArr && leadNotesArr?.results?.length > 0) {
            combineNotes.push(...leadNotesArr.results.filter((el) => el.note !== ''));
        }
        // if (leadFlowReminder?.results && leadFlowReminder?.results?.length > 0) {
        //     combineNotes.push(...leadFlowReminder?.results.filter((el) => el.note !== ''));
        // }

        if (combineNotes) {
            setNotesLength(
                combineNotes.sort(
                    (a, b) =>
                        new Date(moment(a.updated_at, 'DD-MM-YYYY HH:mm:ss')) -
                        new Date(moment(b.updated_at, 'DD-MM-YYYY HH:mm:ss'))
                )
            );
        }
    }, [user, leadNotes, leadNotesArr]);

    const treatmentShow = () => {
        if (treatmentData && treatmentData?.length > 0) {
            let treatmentToShow;
            if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) {
                treatmentToShow = treatmentData?.filter((el) => el.id === currentTreatmentDataGlobal?.treatmentData?.id);
                return treatmentToShow
            }

            return treatmentData?.filter((el) => el.id === tableRowLead?.treatment_id);
        }
        return [];
    }

    const TABS = [
        {
            icon: 'grommet-icons:overview',
            label: 'Overview',
            value: 'overview',
            component: <OverviewTab treatmentTabData={treatmentShow()} leadId={user?.lead_id || user?.id} setExpandLayout={setExpandLayout} handleChange={handleChange} expandLayout={expandLayout} leadReminder={leadFlowReminder} user={user} LeadNotes={NotesLength} />,
            width: 24,
            height: 24
        },
        {
            icon: 'solar:clock-circle-bold',
            label: 'Activity',
            value: 'activity',
            component: <ActivityList handleChange={handleChange} leadId={user?.lead_id || user?.id} notAllow setExpandLayout={setExpandLayout} expandLayout={expandLayout} />,
            width: 20,
            height: 20
        },
        {
            icon: 'bi:check-circle-fill',
            label: 'To-do',
            value: 'to-do',
            component: <ToDoList setExpandLayout={setExpandLayout} handleChange={handleChange} expandLayout={expandLayout} leadReminder={leadFlowReminder} user={user} />,
            width: 18,
            height: 18
        },
        {
            icon: 'solar:chat-round-dots-bold',
            label: 'Notes',
            value: 'notes',
            component: <NotesTab LeadNotes={NotesLength} isForTab userDetails={user} handleChange={handleChange} />,
            width: 20,
            height: 20
        },
    ];
    if (showWhatsapp) {
        TABS.push({
            icon: 'ri:whatsapp-fill',
            label: 'Whatsapp',
            value: 'whatsapp',
            component: <Box sx={{
                display: 'grid',
                gridTemplateColumns: '100%',
                gridTemplateRows: '4.5rem auto 3.5rem',
                height: '100%',
                background: 'white',
                borderRadius: '1rem',
                width: "100%",
            }}>
                <MessageContainer {...showWhatsapp} changeBackground />
            </Box>,
            width: 20,
            height: 20
        },)
    }

    if (treatmentShow().length > 0) {
        TABS.splice(1, 0, {
            icon: 'healthicons:health-alt',
            label: 'Treatment',
            value: 'treatment',
            component: <TreatmentTabList treatmentTabData={treatmentShow()} handleChange={handleChange} />,
            width: 24,
            height: 24
        })
    }

    useEffect(() => {
        dispatch(setAttendedActiveTab(''));
        dispatch(setCurrentTreatmentData(null));
    }, []);

    // useEffect(() => {
    //     if (user) {
    //         dispatch(leadTaskListCounter(getPracticeId(), 'LeadDetail', 'NEW', user?.lead_id, '', '', undefined, true));
    //     }
    // }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            if (practiceId) {

                try {
                    const respContextPractice = await callApi(
                        `/api/v1/practices/${practiceId}/leads/${user.lead_id}/treatments`,
                        'GET'
                    );
                    setLeadUserData(() => respContextPractice?.data?.results?.[0] ?? [])
                    setTreatmentData(respContextPractice?.data?.results ?? [])

                } catch (error) {
                    console.error('Error fetching context practice:', error);
                }
            }
        };

        fetchData();
    }, [practiceId, dispatch]);



    useEffect(() => {
        if (validStep) {
            if (activeStep !== steps.length - 1) {
                dispatch(updateActiveStep(activeStep + 1));
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setTimeout(() => {
                    updateStep(false);
                }, 1000);
            } else {
                updateStep(false);
                handleClose();
            }
        }
    }, [validStep]);

    useEffect(() => {
        if (BookingAttendance) {
            dispatch(updateActiveStep(activeStep + 1));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
        }
        setActiveStep(0);
    }, [BookingAttendance]);

    useEffect(() => {
        if (setActive) setActive(activeStep);
    }, [activeStep]);

    const handleBack = () => {
        if (type === 'onboarad') {
            if (inputRef.current && inputRef.current.handleBack) {
                inputRef.current.handleBack();
                return;
            }
            dispatch(updateActiveStep(activeStep - 1));
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            return;
        }
        if ((type === 'createLead' && activeStep < 2) || (type === 'followup' && activeStep <= 1)) {
            dispatch(updateSteps(null));
        }
        if (type === 'followup' && activeStep < 2) {
            dispatch(updateBookingSteps(null));
        }
        dispatch(updateActiveStep(activeStep - 1));
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = async (status, button, e) => {
        if (activeStep === 1 && button === 'Change Booking Status') {
            onOpenNextAction();
            anchorMoreMenuRef.current = e.currentTarget;
            return;
        }
        if (isAppointmentBooked === 'Unable to contact') {
            await inputRef.current?.submit?.(status);
            return;
        }
        if (status === 'Archive') {
            status = 'Archive';
            await inputRef.current.submit(status);
            return;
        }
        if (activeStep === (steps.length - 1)) {
            status = 'finish';
        }
        await inputRef.current.submit(status);
    };

    const handleArchivePopup = () => {
        openArchivePopup();
        dispatch(updateFollowUpLead('archivedLead'));
    };

    const handleClose = () => {
        setTimeout(() => {
            const htmlElement = document.querySelector('body');
            htmlElement.classList.remove('overflow-hidden');
            onClose();
            dispatch(updateActiveStep(0));
            setActiveStep(0);
            dispatch(contactLeadSlice(true));
            setOutcome('Contact Lead');
            dispatch(setTodoListFilter(''));
            dispatch(leadFlowReminderData([]));
            if (typeof setOpenContactFromCall === 'function') {
                setOpenContactFromCall(false);
            }
            dispatch(setOpenCallScriptFromLeadPage(false));
        }, 200);
    };

    const getTitle = (value) => {
        switch (value) {
            case 'followup':
                if (activeStep === 0) {
                    return `Contact ${user && user.first_name} ${user && user.last_name}`;
                }
                switch (isAppointmentBooked) {
                    case 'Booking Details':
                        return `Booking for ${user && user.first_name} ${user && user.last_name}`;
                    case 'Unable to contact':
                        return `Unable to contact ${user && user.first_name} ${user && user.last_name}`;
                    default:
                        return `Contact ${user && user.first_name} ${user && user.last_name}`;
                }
            default:
                return '';
        }
    };

    const getBtnTitle = () => {
        if (BookingAttendance === 'Unable to contact' || BookingAttendance === 'Appointment Not Booked') {
            if (archivedLead) return 'Archive';
            return 'Finish';
        }
        return 'Continue';
    };

    const tabCounter = (value) => {
        if (value === 'to-do') {
            return taskFlowCounter?.[0]?.count || 0;
        }
        return 0;
    };

    const labelCount = (data) => {
        if (data.label === 'To-do') {
            return (
                <Box>
                    {data.label}
                    <Box sx={listCounterBoxStyle}>
                        {tabCounter(data.value)}
                    </Box>
                </Box>
            );
        }
        return data.label;
    };

    const buttonHandleClose = () => {
        onCloseNextAction();
        anchorMoreMenuRef.current = null;
    };

    const handleDepositPendingChange = (e) => {
        setDepositPaidStatus(e.target.checked);
        dispatch(setDepositStatus(e.target.checked));
        if (type === 'createLead') return;
        const treatment = currentTreatmentDataGlobal?.treatmentData?.treatment || tableRowLead && tableRowLead.treatment_name || '';
        const id = currentTreatmentDataGlobal?.treatmentData?.id || tableRowLead && tableRowLead.treatment_id || '';
        const data = {
            leadId: createLead?.id,
            treatmentId: id,
            payload: {
                treatment,
                is_treatment_fee_pending: !e.target.checked
            }
        };
        dispatch(setTreatmentLoading(true));
        dispatch(UpdateDepositPendingStatus(data, handleReloadLeads));
    };

    const handleCloseStartTreatment = () => onCloseStartTreatment(false);

    const handleStartTreatment = () => {
        dispatch(setAction('startTreatment'));
        handleNext('finish');
    };

    const handleCompleteTreatment = () => {
        dispatch(setAction('completeTreatment'));
        handleNext('finish');
    };

    const startTreatment = () => {
        onOpenStartTreatment(); onCloseNextAction();
        const id = currentTreatmentDataGlobal?.treatmentData?.id || tableRowLead && tableRowLead.treatment_id || '';
        let isTreatmentFeePending = true;
        if (isArray(tableRowLead?.treatmentData)) {
            isTreatmentFeePending = tableRowLead?.treatmentData?.find((ele) => ele.id === id)?.is_treatment_fee_pending;
        } else {
            isTreatmentFeePending = tableRowLead?.treatmentData?.lead_treatment?.is_treatment_fee_pending;
        }
        dispatch(setDepositStatus(isTreatmentFeePending || false));
        setDepositPaidStatus(!isTreatmentFeePending || false);
    };

    const completeTreatment = () => { setConfirmation(true); onCloseNextAction(); };

    return (
        <>
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                id='component-drawer'
                sx={contactLeadDialogStyle(type)}>
                <AppBar sx={commonDialogHeaderStyle}>
                    <Typography sx={contactLeadTitleStyle} variant='h5' component='div'>
                        {user && getTitle(type)} {type === 'followup' && <span> {user?.lead_treatments__lead_contacted_counts__count === 0 ? 'for the first time' : ''}</span>}
                    </Typography>
                    {(headerButtons || onboaradHeaderButtons) &&
                        <Box sx={cancelBoxStyle(onboaradHeaderButtons)}>
                            <Button color='inherit' variant='outlined' sx={contactLeadCancelButtonStyle} onClick={() => handleClose('close')}>
                                Cancel
                            </Button>
                        </Box>
                    }
                </AppBar>
                <Stack sx={{ ...popupMainStackStyle(value), mb: 0 }} onClick={() => { dispatch(updateShowSubjectList(false)); }}>
                    <Box sx={leftBoxInnerStyle(expandLayout, activeStep)}>
                        {type === 'followup' && activeStep === 0 && <Box><SimilarLeadWarningPopup type={'DialogPopup'} dialogClose={() => handleClose('close')} /></Box>}
                        {steps[activeStep] && steps[activeStep].title ?
                            <Box sx={activeStepTitleStyle}>
                                <Typography variant='h3'>{steps[activeStep].title}</Typography>
                            </Box>
                            : ''}
                        {steps[activeStep] && steps[activeStep].bodyText ?
                            <Box sx={activeStepTextStyle}>
                                <Typography variant='body'>{steps[activeStep].bodyText}</Typography>
                            </Box>
                            : ''}
                        <Grid item xs={12} md={12} sx={{ ...cardMainAreaWrapper(activeStep, expandLayout) }} ariaLabel='popup-form-container' >
                            {steps[activeStep] && RenderActiveStep({ component: steps[activeStep].component, inputRef })}
                        </Grid>
                        {type === 'followup' && activeStep === 0 ? null : <Box
                            className='buttons-box'
                            sx={footerButtonBoxStyle}
                        >
                            <ButtonContainer
                                ContactedCount={ContactedCount}
                                activeStep={activeStep}
                                activeTab={activeTab}
                                getBtnTitle={getBtnTitle}
                                handleArchivePopup={handleArchivePopup}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                outcome={outcome}
                                steps={steps}
                                type={type} />
                        </Box>}
                    </Box>


                    <Box sx={rightBoxInnerStyle(expandLayout, value, activeStep)}>
                        <NormalLoading tableData={treatmentData?.length === 0 && 'No Found'} pageCount={treatmentData?.length === 0 && 'No Found'} />
                        {TABS.map((tab) => {
                            const isMatched = tab.value === value;
                            return isMatched && <Box key={tab.value} sx={tabContentBoxStyle}>{tab.component}</Box>;
                            // <Box >
                            //     {leadUserData?.treatment_data?.offers.length > 0 &&
                            //         <Card sx={offerMainBoxStyle} >
                            //             <Box sx={listHeaderBoxStyle}>
                            //                 <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Offers</Typography>

                            //             </Box>

                            //             <Box sx={offerBoxStyle} >

                            //                 {leadUserData?.treatment_data?.offers?.map((data) => (
                            //                     <Box key={data?.id}>
                            //                         <Box sx={offerContentStyle}  >
                            //                             <Typography sx={{ fontSize: '15px', fontWeight: 700 }}>{capitalizeFirstLetter(data?.name)}</Typography>

                            //                             <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>{capitalizeFirstLetter(data?.offer)}</Typography>
                            //                             <Typography variant='body2' sx={timeLineTypoV2}>
                            //                                 {moment(data?.created_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').format('DD MMM YYYY')} at {moment(data?.created_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').format('hh:mm a')}
                            //                             </Typography>

                            //                         </Box>

                            //                     </Box>

                            //                 ))}


                            //             </Box>
                            //         </Card>
                            //     }
                            //     <Box key={tab.value} sx={tabContentBoxStyle}>{tab.component}</Box>;
                            // </Box>

                        })}
                        <Box sx={tabsMainBoxStyle}>
                            <Tabs orientation='vertical' variant='fullWidth' value={value} onChange={handleChange} aria-label='icon label tabs example' sx={{ '.MuiTabs-flexContainer': { gap: 1 } }}>
                                {TABS.map((tab, index) => (
                                    <Tab icon={<Iconify icon={tab.icon} width={tab.width} height={tab.height} sx={{ mr: '0 !important', mb: '4px' }} />} key={index} label={labelCount(tab)} value={tab.value} sx={tabButtonStyle} />
                                ))}
                            </Tabs>
                        </Box>
                    </Box>
                    <Popper
                        sx={{ zIndex: 1399, marginLeft: '10px !important' }}
                        open={isOpenNextAction}
                        anchorEl={anchorMoreMenuRef.current}
                        transition
                        placement={isDesktop ? 'left-start' : 'right-end'}
                    >
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps} >
                                <Paper>
                                    <ClickAwayListener onClickAway={buttonHandleClose}>
                                        <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                            <MenuItem
                                                key='Start Treatment'
                                                onClick={startTreatment}
                                                sx={menuItemStyle}
                                            >
                                                Move to Started Treatment
                                            </MenuItem>
                                            <MenuItem
                                                key='Complete Treatment'
                                                onClick={completeTreatment}
                                                sx={menuItemStyle}
                                            >
                                                Move to Completed Treatment
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <StartTreatmentPopup
                        isOpen={isOpenStartTreatment}
                        handleCloseStartTreatment={handleCloseStartTreatment}
                        isPaymentMandatory={isPaymentMandatory}
                        depositStatus={depositPaidStatus}
                        handleDepositPendingChange={handleDepositPendingChange}
                        startTreatmentAPI={handleStartTreatment}
                    />
                    <CustomCompleteTreatmentDialog handleClose={() => setConfirmation(false)} handleYes={() => handleCompleteTreatment()} isLoader={isLoader} open={confirmation} />
                </Stack>
            </Dialog >
        </>
    );
};

export default OpenContactLeadPopup;

OpenContactLeadPopup.propTypes = {
    open: PropTypes.bool,
    openArchivePopup: PropTypes.func,
    onClose: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    inputRef: PropTypes.any,
    user: PropTypes.object,
    BookingAttendance: PropTypes.any,
    updateStep: PropTypes.func,
    validStep: PropTypes.bool,
    headerButtons: PropTypes.bool,
    onboaradHeaderButtons: PropTypes.bool,
    setActive: PropTypes.func,
    ContactedCount: PropTypes.any,
    handleReloadLeads: PropTypes.any,
    showWhatsapp: PropTypes.any,
    setOpenContactFromCall: PropTypes.func,
};