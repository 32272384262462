import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { capitalCase } from 'change-case';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import { Tab, Box, Tabs, Container } from '@mui/material';
import { useSettingsContext } from 'src/components/settings/context';
import { useLocation } from 'react-router';
import { updateSelectedPracticeForMenu } from 'src/redux/slices/mergeTags';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import { useTabs } from '../../../hooks';
// components
import { HeaderBreadcrumbs, Iconify } from '../../../components';
// sections
import { Practice, PracticeSocialLinks, OpenHours, PracticeBranding } from '../../../sections/@dashboard/practiceUser';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { clearMessage } from '../../../redux/slices/practiceUser';
import { getPracticeItem } from '../../../redux/slices/singlePractice';
import { settingsMainBoxStyle, settingsContentBoxStyle } from '../../../styles/SettingsMenuStyle';

// ----------------------------------------------------------------------

export default function PracticeDetails() {

    const settings = useSettingsContext();
    const { enqueueSnackbar } = useSnackbar();

    const { currentTab, onChangeTab } = useTabs('Practice Details');

    const practiceData = useSelector((state) => state.singlePractice);

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { message, error } = useSelector((state) => state.practiceUser);

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeItem(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (message) {
            enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
        if (error) {
            enqueueSnackbar(error, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
    }, [message, error, enqueueSnackbar]);

    const PROFILE_TABS = [
        {
            value: 'Practice Details',
            icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
            component: <Practice practiceDetails={practiceData.practice} />,
        },
        {
            value: 'Opening Hours',
            icon: <Iconify icon={'solar:clock-circle-bold'} width={20} height={20} />,
            component: <OpenHours hoursList={practiceData.practice.opening_hours} practiceId={practiceId} />,
        },
        {
            value: 'Social Links',
            icon: <Iconify icon={'eva:share-outline'} width={20} height={20} />,
            component: <PracticeSocialLinks socialLinks={practiceData.practice.social_medias} practiceId={practiceId} />,
        },
        {
            value: 'Branding',
            icon: <Iconify icon={'tabler:brand-ubuntu'} width={20} height={20} />,
            component: <PracticeBranding branding={practiceData.practice.branding} practiceId={practiceId} />,
        },
    ];

    const location = useLocation()

    useEffect(() => {
        dispatch(updateSelectedPracticeForMenu("practice_details"))
    }, [location.pathname])

    return (
        <>
            <Helmet>
                <title> Practice Details | Dental SEM CRM </title>
            </Helmet>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                <Box sx={settingsMainBoxStyle}>
                    <Box sx={settingsContentBoxStyle}>
                        <HeaderBreadcrumbs
                            heading="Practice Details"
                            links={[
                                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                                { name: 'Practice details', href: PATH_DASHBOARD.user.root },
                            ]}
                        />
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentTab}
                            onChange={onChangeTab}
                        >
                            {Object.keys(practiceData.practice).length > 0 && PROFILE_TABS.map((tab) => (
                                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
                            ))}
                        </Tabs>
                        {Object.keys(practiceData.practice).length > 0 && PROFILE_TABS.map((tab) => {
                            const isMatched = tab.value === currentTab;
                            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                        })}
                    </Box>
                </Box>
            </Container>
        </>
    );
}
