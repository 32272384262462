/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { shallowEqual, useSelector } from "react-redux";
import { callApi } from "src/utils/apiCall";
import { enqueueSnackbar } from "notistack";
import MediaDisplayComponent from "./whatsappComponents/MediaDisplayComponent";
import { Iconify } from ".";
import { NotFound } from "./empty-content";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Attachments = (props) => {
    const { leadDetail } = props;
    const [loading, setLoading] = React.useState(false);
    const [upload, setUpload] = React.useState(false);
    const { practiceId } = useSelector(
        (state) => ({
            practiceId: state?.practiceTreatmentList?.practiceId,
        }),
        shallowEqual
    );

    const [data, setData] = React.useState({
        data: [],
        next: null,
        total: 0
    });

    const getDataFromApi = async (reset) => {
        try {
            const url = data?.next ?? "";
            const result = url.match(/api\/v1.*/);
            const extractedPath = result ? result[0] : null;
            const response = data?.next && !reset ? await callApi(
                `/${extractedPath}`,
                'GET',
            ) : await callApi(
                `/api/v1/practices/${practiceId}/leads-media/?lead__in=${[leadDetail?.id]}&limit=10&offset=0&ordering=-created_at`,
                'GET'
            );
            if (response?.status === 200) {
                const { count, next, results } = response?.data ?? {}
                setData(prvObj => ({
                    data: reset ? [...(results ?? [])] : [...(prvObj?.data ?? []), ...(results ?? [])],
                    next,
                    total: count
                }))
            }
        } catch (_) {
            console.error("Error in Attachments Tab", _);
        }
        setLoading(false);
    }

    const handleFileUpload = (event) => {
        setUpload(true);
        const file = event.target.files?.[0]; // Get the uploaded file
        if (file) {
            const reader = new FileReader(); // FileReader to read the file content
            reader.onload = async () => {
                try {
                    const response = await callApi(
                        `/api/v1/practices/${practiceId}/leads-media/`,
                        'POST',
                        {
                            "lead": leadDetail?.id,
                            "media": reader.result,
                            "description": file.type,
                            "channel": "OTHER",
                            "content_type": "whatsappmessage"
                        }
                    );
                    if (response?.data?.id) {
                        enqueueSnackbar(`${file?.name} added successfully`, {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                            }
                        });
                        setLoading(true);
                        getDataFromApi(true);
                    }
                    setUpload(false);
                } catch (e) {
                    enqueueSnackbar(`Unable to add attachment. Please try after sometime`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    });
                    setUpload(false);
                }
            };
            reader.readAsDataURL(file);
        } else {
            setUpload(false);
        }
    };


    React.useEffect(() => {
        setLoading(true);
        getDataFromApi();
    }, []);

    const handleDelete = async (el) => {
        setLoading(true);
        try {
            await callApi(
                `/api/v1/practices/${practiceId}/leads-media/${el?.id}`,
                'DELETE'
            );
        } catch (_) {
            console.error("error happended while deleting attachments", _);
        }
        getDataFromApi(true);
    }

    if (loading) {
        return <Box sx={{ overflow: "auto", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={40} />
        </Box>
    }

    if (!loading && !data?.total > 0) {
        return <Box sx={{ overflow: "auto", height: "70vh", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
            <Button
                disabled={upload}
                component="label"
                role={undefined}
                tabIndex={-1}
                endIcon={upload ? <CircularProgress size={10} /> : <Iconify icon="hugeicons:upload-02" sx={{ width: '1rem', height: '1rem' }} />} variant="contained">
                {upload ? "Uploading" : "Add Attachments"}
                <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept="*" />
            </Button>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%", width: "100%" }}>
                <NotFound />
            </Box>
        </Box>
    }


    return <Box sx={{ height: "70vh" }}>
        <Box sx={{ height: "10%", overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
            <Button
                disabled={upload}
                component="label"
                role={undefined}
                tabIndex={-1}
                endIcon={upload ? <CircularProgress size={10} /> : <Iconify icon="hugeicons:upload-02" sx={{ width: '1rem', height: '1rem' }} />} variant="contained">
                {upload ? "Uploading" : "Add Attachments"}
                <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept="*" />
            </Button>
        </Box>
        <Box id="attachmentReel" sx={{ height: "90%", width: "100%", overflow: "auto" }}>
            <InfiniteScroll
                scrollableTarget="attachmentReel"
                dataLength={data?.data?.length ?? 0}
                next={getDataFromApi}
                hasMore={data?.next}
                loader={<CircularProgress
                    size={20}
                />}
                endMessage={
                    <></>
                }
            >
                {data?.data?.map(e => (
                    <Box key={e?.object_id} sx={{ display: "flex", alignItems: "center", mb: "1rem" }}>
                        <Box sx={{ maxHeight: "20rem", width: "max-content", maxWidth: "50%", p: "0.5rem", background: "white", borderRadius: "1rem", boxSizing: 'content-box', display: "flex", alignItems: "center", overflow: "hidden" }}>
                            <MediaDisplayComponent maxHeight="20rem" whatsAppMessageMedia={{ whatsapp_media_mime_type: e?.description, media_file: e?.remote_url || e?.media }} />
                        </Box>
                        <Box sx={{ ml: "0.5rem" }}>
                            <Iconify
                                onClick={() => handleDelete(e)}
                                icon="fluent:delete-48-regular"
                                sx={{
                                    color: '#FF5630',
                                    width: '0.825rem',
                                    height: '0.825rem',
                                    cursor: 'pointer'
                                }}
                            />
                        </Box>
                    </Box>))}
            </InfiniteScroll>
        </Box>
    </Box>
}

Attachments.propTypes = {
    leadDetail: PropTypes.object.isRequired
}

export default Attachments;