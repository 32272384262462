const getStyles = (theme, windowSize = {}) => ({
    containerStyle: {
        border: '1px solid rgba(145, 158, 171, 0.12)',
        boxShadow:
            theme.palette.mode === 'light'
                ? '0px 0px 2px 0px rgba(145, 158, 171, 0.2)'
                : '0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
        background: theme.palette.mode === 'light' ? 'transparent' : 'rgba(33, 43, 54, 1)',
        borderRadius: '1rem',
        height: '70vh',
    },
    initPageStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    wrapperStyle: {
        display: 'grid',
        gridTemplateColumns: windowSize?.width > 1000 ? '29.62% auto' : '100%',
        gridTemplateRows: '100%',
        height: '100%',
        position: 'relative',
        background: 'white',
        borderRadius: '1rem'
    },
    selectedFilterChip: {
        ml: '0.2rem',
        fontSize: '0.8rem',
        color: '#C8FACD',
        background: '#075e54',
        fontWeight: 600
    },
    unSelectedFilterChip: {
        ml: '0.2rem',
        fontSize: '0.8rem',
    },
    allChatContainer: {
        boxShadow: '-1px 0px 0px 0px rgba(145, 158, 171, 0.24) inset',
        width: '100%',
        height: '100%',
    },
    allChatContainerView2: {
        background: 'white',
        position: 'absolute',
        top: 0,
        height: '100vh',
        width: '85%',
    },
    allChatsWrapper: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '8rem auto',
        height: '100%',
        width: '100%',
    },
    leftHeaderComponent: {
        padding: '1rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
    profileContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftBodyComponent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
    },
    recentChatContainer: {
        display: 'grid',
        gridTemplateColumns: '3rem auto',
        gridTemplateRows: '100%',
        alignItems: 'center',
        height: '4.5rem',
        cursor: 'pointer',
        borderRadius: '1rem',
        padding: '0 1.5rem',
        transition: 'background .3s',
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    recentChatInnerContainer: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '50%',
        height: '3rem',
        pl: '1rem',
        overflow: 'hidden'
    },
    sendTextPreview: {
        color: theme.palette.text.disabled,
        fontSize: '0.875rem',
        lineHeight: '1.028125rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        width: '90%'
    },
    sendTextPreviewSelected: {
        color: '#25D366',
        fontSize: '0.875rem',
        lineHeight: '1.028125rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        width: '90%'
    },
    sendTextPreviewBlack: {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
        lineHeight: '1.028125rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        width: '90%'
    },
    nameAndTimeContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
    },
    leadmetaData: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.2rem',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '60%',
        width: 'max-content',
        maxWidth: '100%',
        overflow: 'hidden'
    },
    textFontChat: {
        fontSize: '0.875rem',
        lineHeight: '1.028125rem',
        color: theme.palette.text.primary,
        fontWeight: '600',
        whiteSpace: 'nowrap',
        mr: '0.5rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%'
    },
    textFontChatTime: {
        fontSize: '0.75rem',
        lineHeight: '0.88125rem',
        fontWeight: '600',
        color: theme.palette.text.disabled,
        whiteSpace: 'nowrap',
    },
    textFontChatTimeUnread: {
        fontSize: '0.75rem',
        lineHeight: '0.88125rem',
        color: '#25D366',
        whiteSpace: 'nowrap',
        fontWeight: 900,
    },
    unreadCountBadge: {
        background: '#25D366',
        color: 'white',
        borderRadius: '10rem',
        width: '1rem',
        height: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.5rem',
    },
    singleChatContainer: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '4.5rem auto 3.5rem',
    },
    readIconsBox: {
        position: 'absolute',
        bottom: '0',
        right: '0.25rem'
    },
    singleChatContainerDetail: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '4.5rem auto 3.5rem',
        height: '100%',
        background: 'white',
        borderRadius: '1rem',
        maxHeight: '86vh'
    },
    newChatConatiner: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '4.5rem auto',
    },
    backgroundEmptyArea: {
        background: theme.palette.background.default,
        borderRadius: '0 0 1rem 0'
    },
    recipientContainer: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 -0.0625rem 0 0 #919EAB3D inset',
    },
    senderInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 -0.0625rem 0 0 #919EAB3D inset',
        justifyContent: 'space-between',
    },
    messageInputContainer: {
        alignItems: 'center',
        boxShadow: '0 0.0625rem 0 0 #919EAB3D inset',
        display: 'grid',
        gridTemplateColumns: 'auto 3.43rem',
        position: "relative"
    },
    messageInputComponent: {
        display: 'flex',
        alignItems: 'center',
    },
    sendButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        background: 'rgba(0, 171, 85, 1)',
        borderRadius: '0 0 1rem 0',
        borderLeft: `0.0625rem solid ${theme.palette.divider}`,
    },
    textLabelTo: {
        pl: '1.5rem',
        pr: '1rem',
        color: theme.palette.text.disabled,
    },
    newChatContactListContainer: {
        width: '17.5rem',
        maxHeight: '21rem',
        boxShadow:
            theme.palette.mode === 'light' ? '-1.25rem 1.25rem 2.5rem -0.25rem #919EAB3D' : '0 1.5rem 3rem 0 #00000029',
        position: 'absolute',
        background: theme.palette.background.default,
        borderRadius: '1rem',
        top: '3rem',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    profileSearchContainer: {
        position: 'relative',
    },
    eachNewContact: {
        display: 'flex',
        alignItems: 'center',
        height: '3rem',
        cursor: 'pointer',
        transition: 'background .3s',
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    textStyles: {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
    },
    facebookButtonStyles: {
        background: '#1877f2',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: '#1877f2',
        },
    },
    openTagStyles: {
        background: 'rgba(0, 171, 85, 1)',
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        borderRadius: '0 0.8rem 0.8rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '6.5rem',
        cusrsor: 'pointer',
    },
    accountModalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.background.default,
        width: '22rem',
        borderRadius: '1rem',
        outline: 'none',
        overflowY: 'auto',
        padding: '0.2rem 0.1rem',
    },
    mergetagContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.background.default,
        width: '42rem',
        borderRadius: '1rem',
        outline: 'none',
        overflow: 'hidden',
        padding: '1rem',
        height: '50vh'
    },
    mergeTags: {
        paddingTop: '0.5rem',
        display: 'flex',
        flexWrap: 'wrap'
    },
    mergeChips: {
        marginRight: '0.2rem',
        height: '1.5rem',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipses',
        marginBottom: '0.2rem',
        fontSize: '0.625rem'
    },
    mergeTableCont: {
        paddingTop: '1rem',
        width: '100%',
        height: '93%',
        display: 'grid',
        gridTemplateColumns: '33.33% auto 33.33%',
        gridTemplateRows: '100%',
        gap: '0.2rem',
        overflowY: 'auto'
    },
    profileImageContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    closeChatContainer: {
        transition: 'transform .5s',
        color: 'text.disabled',
        cursor: 'pointer',
        width: '1.5rem',
        height: '1.5rem',
        mr: '1.5rem',
        '&:hover': {
            transform: 'rotate(180deg)',
        },
    },
    docSearch: {
        color: 'text.disabled',
        cursor: 'pointer',
        width: '1.25rem',
        height: '1.25rem',
        mr: '1.5rem',
    },
    leadSourceStyle: {
        color: 'white',
        cursor: 'pointer',
        height: '1.25rem',
        mr: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.5rem'
    },
    infoHeaderStyles: {
        textDecoration: 'underline',
        fontSize: '0.5rem'
    },
    leadSourceIcon: {
        color: 'white',
        cursor: 'pointer',
        width: '1rem',
        height: '1rem',
        mr: '0.1rem'
    },
    statusTextSize: {
        color: 'white',
        fontSize: '0.6rem'
    },
    leadInfoBoxStyles: {
        cursor: 'pointer',
        position: 'relative'
    },
    leadMetaData: {
        position: 'absolute',
        right: '4rem',
        zIndex: 1,
        top: '50%',
        transform: 'translate(0,-50%)',
        height: '2rem',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        overflow: 'auto',
        gap: '0.5rem'
    },
    messageContainer: {
        padding: '1.5rem',
        overflow: 'auto',
        scrollbarGutter: 'stable',
        display: 'flex',
        flexDirection: 'column-reverse',
        background: theme.palette.background.default,
        position: "relative",
        width: "calc(100% - 4.2rem)",
    },
    actionBar: {
        position: "absolute",
        bottom: "100%",
        right: 0,
        width: "4.2rem",
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        background: theme.palette.background.default,
        borderLeft: `1px solid ${theme.palette.action.hover}`
    },
    actionButtons: {
        width: "3.8rem",
        height: "3.8rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "0.5rem",
        marginTop: "0.5rem",
        "&:hover": {
            background: "#C8FACD",
            border: "1px solid rgba(0, 171, 85, 1)"
        }
    },
    stickyAction: {
        position: "sticky",
        top: "-1rem",
        zIndex: "1",
        background: "rgba( 255, 255, 255, 0.25 )",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 4px )",
        borderRadius: "0.625rem",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        padding: "0.5rem",
        transition: "top 0.5s ease-in-out",
        opacity: 0, // Initially transparent,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    stickyClose: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    stickyActionVisible: {
        top: "-1rem", // Slide into view
        opacity: 1, // Fully visible
    },
    stickyActionInvisible: {
        top: "-100%", // Move off-screen
        opacity: 0, // Transparent
    },
    senderMessageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '2.875rem',
    },
    userRecieveMessageContainer: {
        display: 'flex',
        marginTop: '2.875rem',
        width: '100%',
    },
    messageBoxSender: {
        borderRadius: '0.5rem',
        background: '#C8FACD',
        maxWidth: '60%',
        width: 'max-content',
        padding: '0.75rem',
        fontSize: '0.875rem',
        color: '#212B36',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'content-box',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        position: 'relative',
        minWidth: '2rem',
    },
    failedWhatsappStatus: {
        fontSize: '0.5rem',
        color: theme.palette.error.main
    },
    sendingInProgressContainer: {
        display: 'flex',
        alignItems: 'center',
        mb: '0.2rem'
    },
    sendingWhatsappStatus: {
        fontSize: '0.5rem',
        color: theme.palette.text.disabled,
        mr: '0.2rem'
    },
    messageTime: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: '0.75rem',
        marginBottom: '0.5625rem',
    },
    userAvatar: {
        marginRight: '1rem',
    },
    userMessageComponent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
    },
    quickMessageOptions: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#179AFE',
        width: '100%',
        borderRadius: '0.5rem',
        padding: '0.5rem 0.75rem',
        fontSize: '0.875rem',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        margin: '0.2rem 0',
        cursor: 'pointer',
        overflow: "hidden",
        textOverflow: "ellipsis",
        minHeight: "2rem"
    },
    messageBoxUser: {
        borderRadius: '0.5rem',
        background: theme.palette.mode === 'light' ? '#DFE3E8' : 'rgba(145, 158, 171, 0.16)',
        maxWidth: '60%',
        width: 'max-content',
        padding: '0.75rem',
        fontSize: '0.875rem',
        color: theme.palette.mode === 'light' ? '#212B36' : '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'content-box',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        position: 'relative'
    },
    reactions: {
        background: "#454F5B",
        padding: "0.01rem 0.25rem",
        borderRadius: "1rem",
        position: "absolute",
        right: "0",
        bottom: "-0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.6rem"
    },
    syncAccount: {
        fontSize: '0.652rem',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.disabled,
        whiteSpace: 'nowrap'
    },
    timerContainer: {
        position: 'absolute',
        left: '100%',
        marginLeft: '0.3rem',
        top: '0',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    switchAccountContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mergetagHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        fontWeight: 700,
        fontSize: '1.2rem'
    },
    enabledButton: {
        background: '#FFAB00',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        p: '0.25rem 2rem',
    },
    disabledButton: {
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        p: '0.25rem 1.5rem',
        cursor: 'pointer',
        '&:hover': {
            background: '#DFE3E8',
        },
    },
    unsupportedMedia: {
        color: theme.palette.text.disabled,
        fontSize: "0.875rem"
    },
    imageLoadingBox: {
        width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"
    },
    mediaIcon: {
        color: theme.palette.text.disabled,
        mr: "0.5rem"
    },
    disabledMediaText: {
        color: theme.palette.text.disabled,
        fontSize: "0.875rem"
    }
});

export default getStyles;