/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// @mui
import { Autocomplete, TextField } from '@mui/material';
// redux
import { useSelector } from '../../redux/store';

// -------------------------------------------------------------------------------------------

const ReferralDropdown = ({ name, value, onChange, type, ...other }) => {
    const { referral, practiceUsers } = useSelector((state) => ({
        referral: state.schema?.referral || [],
        practiceUsers: state.mergeTags?.practiceUsers || []
    }));

    const options = [
        ...referral.map((item) => ({ ...item, group: "Default" })),
        ...practiceUsers.map((user) => ({ ...user, group: "Clinicians" }))
    ];

    return (
        <Autocomplete
            name={name}
            value={value || ''}
            onChange={onChange}
            groupBy={(option) => option.group}
            options={options}
            renderInput={(params) => <TextField {...params} label="Referral" />}
            sx={{ '.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': { display: type === 'search' && 'none' } }}
            {...other}
        />);
};

ReferralDropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

export default ReferralDropdown;
