import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import React from "react";

export default function useCustomSnackbar() {
    const { enqueueSnackbar: defaultSnackbar, closeSnackbar } = useSnackbar();

    const enqueueSnackbar = React.useCallback((message, config, preventSentryUpdate = false) => {
        if (config?.variant === 'error' && !preventSentryUpdate) {
            const customError = new Error(message || "Custom Error");
            customError.name = "UI Popup Error";
            Sentry.captureException(customError, {
                tags: {
                    feature: "Handled Exception - Possible UI Snackbar",
                },
                extra: {
                    debugInfo: JSON.stringify(message || "Custom Error"),
                },
            });
        }
        defaultSnackbar(message, config);
    }, [defaultSnackbar]);
    return { enqueueSnackbar, closeSnackbar };
}