
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// use @mui
import {
    Dialog,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';

// use form
import { Controller, useForm } from 'react-hook-form';
// use components
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function EditAdditionalInfoDialog({
    openEditInfo,
    handleCloseInfoDialog,
    isAddingOffer,
    setIsAddingOffer,
    activeIndex,
    inputValue,
    setInputValue,
}) {

    const validationSchema = Yup.object().shape({
        text: Yup.string().required('Text is required'),
        categories: Yup.string().required("Category is required"),
        custom_identifier: Yup.string().required('Custom identifier is required'),
    });

    const methodsEditInfo = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            text: '',
            categories: '',
            custom_identifier: '',
            is_enabled: true
        },
    });
    const {
        control: controlEditInfo,
        handleSubmit: handleSaveEditInfo,
        reset
    } = methodsEditInfo;

    useEffect(() => {
        if (openEditInfo) {
            if (isAddingOffer) {
                reset({
                    text: '',
                    categories: '',
                    custom_identifier: '',
                    is_enabled: true
                });
            } else {
                const currentInfo = inputValue?.additionalInfo?.[activeIndex];
                reset({
                    text: currentInfo?.text || '',
                    categories: currentInfo?.categories?.toString() || '',
                    custom_identifier: currentInfo?.custom_identifier || '',
                    is_enabled: currentInfo?.is_enabled ?? true
                });
            }
        }
    }, [openEditInfo, activeIndex, inputValue, isAddingOffer, reset]);

    const onSubmitEditInfo = (data) => {
        const formattedData = {
            ...data,
           categories: data.categories.split(',').map((category) => category.trim()),
        };

        if (isAddingOffer) {
            setInputValue((prevState) => ({
                ...prevState,
                additionalInfo: [
                    ...prevState.additionalInfo,
                    {
                        text: formattedData.text,
                        categories: formattedData.categories,
                        custom_identifier: formattedData.custom_identifier,
                        is_enabled: Boolean(formattedData.is_enabled),
                    },
                ],
            }));
        } else {
            setInputValue((prevState) => {
                const updatedInfo = [...prevState.additionalInfo];
                updatedInfo[activeIndex] = {
                    ...updatedInfo[activeIndex],
                    text: formattedData.text,
                    categories: formattedData.categories,
                    custom_identifier: formattedData.custom_identifier,
                    is_enabled: Boolean(formattedData.is_enabled),
                };
                return { ...prevState, additionalInfo: updatedInfo };
            });
        }
        handleCloseInfoDialog();
        setIsAddingOffer(false);
    };



    return (
        <Dialog fullWidth maxWidth="xs" open={openEditInfo} onClose={handleCloseInfoDialog} >
            <Typography sx={{ fontWeight: "500", fontSize: "18px", p: 1, m: 2 }}>New Info</Typography>
            <FormProvider methods={methodsEditInfo} onSubmit={handleSaveEditInfo(onSubmitEditInfo)}>
                <Box sx={{ px: 2, mt: 0 }} >
                    <RHFTextField
                        sx={{ width: '100%' }}
                        name="text"
                        label="Text"
                        multiline
                        minRows={2}
                        control={controlEditInfo}
                    />
                    <RHFTextField
                        sx={{ width: '100%', mt: 2 }}
                        name="categories"
                        label="Categories"
                        control={controlEditInfo}
                    />
                    <RHFTextField
                        sx={{ width: '100%', mt: 2 }}
                        name="custom_identifier"
                        label="Custom Identifier"
                        control={controlEditInfo}
                    />
                    <Box sx={{ mt: 2, mx: 2 }}>
                        <Controller
                            name="is_enabled"
                            control={controlEditInfo}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label="Is Enabled"
                                />
                            )}
                        />
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center" }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleCloseInfoDialog}
                    >
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{ ml: 2 }}
                    >
                        Edit
                    </Button>
                </Box>
            </FormProvider>
        </Dialog>
    );
}

EditAdditionalInfoDialog.propTypes = {
    openEditInfo: PropTypes.bool,
    handleCloseInfoDialog: PropTypes.func,
    isAddingOffer: PropTypes.bool,
    setIsAddingOffer: PropTypes.func,
    activeIndex: PropTypes.number,
    inputValue: PropTypes.object,
    setInputValue: PropTypes.func,
};