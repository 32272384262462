/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import { lazy } from "react";
import * as Sentry from "@sentry/react";

export const lazyWithRetries = (importer) => {

    let viteManifest = null;

    const fetchViteManifest = async () => {
        if (!viteManifest) {
            try {
                const basePath = import.meta.env.VITE_REACT_ENV_BASE_URL || '/';
                console.error("Base Path :: ", basePath);
                const manifestPath = `${basePath}.vite/manifest.json`;
                const response = await fetch(manifestPath); // Adjust path if needed
                if (!response.ok) {
                    throw new Error('Failed to fetch Vite manifest');
                }
                viteManifest = await response.json();
            } catch (error) {
                console.error('Error fetching Vite manifest:', error);
                throw error;
            }
        }
        return viteManifest;
    };

    const resolveFromManifest = async (fileName) => {
        console.error("FileName Value :: ", fileName);
        const manifest = await fetchViteManifest();

        // Strip the hash and extension from the outdated file name n
        const baseName = fileName.replace(/(-[a-zA-Z0-9]+)?\.[a-z]+$/, '')?.split(".")?.[0];

        // Search the manifest for the correct entry
        for (const [key, value] of Object.entries(manifest)) {
            if (key.startsWith(`_${baseName}`) || key.includes(baseName)) {
                return value.file; // Return the resolved file path
            }
        }

        throw new Error(`File ${fileName} not found in manifest`);
    };

    const getUpdatedImporter = async () => {
        const originalImporter = importer.toString();
        console.error('Original Importer:', originalImporter);

        // Extract module path
        const match = originalImporter.match(/['"](.*)['"]/);
        console.error("Match Value :: ", match);
        if (!match) {
            throw new Error('Unable to extract module path from importer');
        }

        const cleanPath = match[1];
        const fileName = cleanPath.split('/').pop(); // Extract file name from path

        // Resolve the correct file using the manifest
        let resolvedPath = await resolveFromManifest(fileName);

        console.error('Resolved Path:', resolvedPath, `With Timestamp: ${resolvedPath}?t=${Date.now()}`);
        resolvedPath = resolvedPath.replace("assets/", "./");
        console.error('New Resolved Path :: ', resolvedPath);
        return import(`${resolvedPath}?t=${Date.now()}`);
    };

    const retryImport = async () => {
        const retries = 5;
        for (let attempt = 0; attempt < retries; attempt += 1) {
            try {
                const importFunc = attempt === 0 ? importer : getUpdatedImporter;
                const module = await importFunc();
                return module; // Return the successfully loaded module
            } catch (error) {
                if (attempt < retries - 1) {
                    console.error(`Retry ${attempt + 1}: Retrying after failure...`);
                    const customError = new Error(`Retry ${attempt + 1}: Retrying after failure...`);
                    customError.name = "UI Popup Test Retry";
                    Sentry.captureException(customError, {
                        tags: {
                            feature: "Handled Exception - Possible UI Snackbar",
                        },
                        extra: {
                            debugInfo: JSON.stringify(error),
                        },
                    });
                    // Wait with exponential backoff: 2, 4, 8, etc., seconds
                    const delay = 1000 * (2 ** attempt);
                    await new Promise((resolve) => setTimeout(resolve, delay));
                    // Retry again with the updated importer
                    continue; // Proceed to next iteration with the new updated importer
                } else {
                    console.error("Max retries reached. Throwing error...");
                    throw error; // If all retries fail, throw the original error
                }
            }
        }
        return null;
    };

    return lazy(retryImport);
};