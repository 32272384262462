export const dataForLoadingApis = ['All', 'TODO', 'NEW', 'CONSULTATION_BOOKED', 'CONSULTATION_ATTENDED', 'TREATMENT_STARTED', 'FAILED_TO_ATTEND_CONSULTATION', 'CLOSED'];
export const allowedTitles = ['Dashboard', 'Leads', 'Financial tracker', 'Tasks', 'Analytics', 'Patient feedback', 'Campaigns Manager', 'Facebook', 'Whatsapp', 'SMS', 'Emails', 'QR Codes', 'Website support'];

export const allowedTitlesVertical = ['Leads', 'Dashboard', 'Financial tracker', 'Tasks', 'Analytics', 'Patient feedback', 'Campaigns Manager', 'Facebook', 'Whatsapp', 'SMS', 'Messaging', 'Emails', 'QR Codes', 'Website support'];

export const allowedTitlesMini = ['Dashboard', 'Leads', 'Financial tracker', 'Tasks', 'Analytics', 'Patient feedback', 'Campaigns Manager', 'Campaigns', 'Facebook', 'Whatsapp', 'SMS', 'Emails', 'QR Codes', 'Website support'];

export const emailTabs = ['Incoming', 'Outgoing', 'Drafts', 'Starred', 'Label'];
export const emailRoutes = ['Incoming', 'Outgoing', 'Drafts', 'Starred'];

export const sampleCsv = 'https://dentalsem-media-prod.ams3.digitaloceanspaces.com/dentalsem-crm-email-images/sample.csv';


export const tabs = [
    {
        title: 'Whatsapp',
        value: 'Whatsapp',
        icon: 'ic:round-whatsapp',
    },
    {
        title: 'Email',
        value: 'Incoming',
        icon: 'ion:mail',
    },
    {
        title: 'Chatbot',
        value: 'Chatbot',
        icon: 'fluent:bot-20-filled',
    },
    {
        title: 'Call Script',
        value: 'CallScript',
        icon: 'tabler:script',
    },
    {
        title: 'Attachments',
        value: 'Attachments',
        icon: 'fluent:attach-32-filled',
    },
    {
        title: 'Automations',
        value: 'Automations',
        icon: 'fad:automation-4p',
    },
];