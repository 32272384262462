import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Grid, Card, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Iconify } from '../../../components';
// hook-form
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// redux
import { dispatch } from '../../../redux/store';
import { changeUserDetails } from '../../../redux/slices/practiceUser';
// Constants
import { socialLink } from '../../../constants/socialLink';

// ----------------------------------------------------------------------

export default function PracticeSocialLinks({ socialLinks, practiceId }) {
    const { enqueueSnackbar } = useSnackbar();
    const [socialData, setSocialData] = useState(socialLinks);

    /**
     * Update the Social link Data
     */
    useEffect(() => {
        if (socialLinks) {
            const data = [];
            socialLinks?.forEach((el) => {
                data.push({
                    name: el.name,
                    value: el.value,
                    id: el.id,
                });
            });
            const filterLinks = socialLink?.filter(item => !socialLinks?.find((value) => item?.value === value?.name));

            filterLinks?.map(val => data.push({ name: val.value, value: '' }));
            setSocialData(data);
            return;
        }
        setSocialData([]);
    }, [socialLinks]);

    const methods = useForm();

    /**
     * Social Link Form Data Submit to API
     */
    const onSubmit = async () => {
        try {
            const data = [];
            socialData.forEach((el => {
                if (el.name && el.value) {
                    const detail = {
                        name: el.name,
                        value: el.value
                    };
                    if (el.id) {
                        detail.id = el.id;
                    }
                    data.push(detail);
                }
            }));
            dispatch(changeUserDetails({ social_medias: data }, practiceId, 'social'));
        }
        catch (error) {
            enqueueSnackbar(typeof error.message === 'string' && error.message || 'Something went wrong',
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                }
            );
        }
    };

    /**
     * Social link input onchange event
     */
    const handleEvent = (event, index, key) => {
        const selected = socialData[index];
        selected[key] = event.target.value;
        socialData[index] = selected;
        setSocialData([
            ...socialData,
        ]);
    };

    const UpperToLower = (data) => data?.charAt(0).toUpperCase() + data.slice(1).replace(/_/g, ' ').toLowerCase();

    return (
        <Card sx={{ p: 3, mt: 4 }}>
            <FormProvider methods={methods}>
                <Grid container spacing={2} gap={1} sx={{ mt: 1, ml: 0 }}>
                    {socialData.map((link, index) => (
                        <Grid
                            md={12}
                            item
                            key={index}
                            container
                            spacing={2}
                            sx={{
                                flexDirection: { xs: 'column', sm: 'row' },
                                mb: { xs: 2, md: 2 },
                                alignItems: 'center',
                            }}
                        >
                            <Grid item md={5.5} sm={12}>
                                <RHFTextField
                                    name={'name'}
                                    label='Name'
                                    value={UpperToLower(link.name)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Iconify icon={link.icon} width={24} height={24} />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item md={5.5} sm={12}>
                                <RHFTextField
                                    name={'value'}
                                    value={link.value}
                                    label="Social link value"
                                    onChange={(e) => handleEvent(e, index, 'value')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Iconify icon={link.icon} width={24} height={24} />
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </FormProvider>
            <Stack
                sx={{
                    mt: 3,
                    columnGap: 2,
                    rowGap: 3,
                }}
                alignItems="flex-end"
            >
                <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={() => onSubmit()}
                >
                    Save Changes
                </LoadingButton>
            </Stack>
        </Card>
    );
}

PracticeSocialLinks.propTypes = {
    socialLinks: PropTypes.array,
    practiceId: PropTypes.any,
};