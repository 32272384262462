/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import {
    Box,
    Tab,
    Tabs,
    Card,
    Table,
    Switch,
    Divider,
    TableBody,
    Container,
    TableContainer,
    TablePagination,
    FormControlLabel,
} from '@mui/material';
// components
import { useLocation } from 'react-router';
import { updateSelectedPracticeForMenu } from 'src/redux/slices/mergeTags';
import { useSettingsContext } from 'src/components/settings/context';
import { HeaderBreadcrumbs, Scrollbar } from '../../../components';
import { TableHeadCustom, TableNoData } from '../../../components/table';
import { TableLoading } from '../../../components/loading';
// constants
import { isEditData, noEditData, practiceTabs } from '../../../constants/PracticeTreatmentsCategoryConst';
// hooks
import {
    useTabs,
    useAuth,
    useToggle,
    useTable,
} from '../../../hooks';
// redux
import { dispatch, useSelector } from '../../../redux/store';
import { getPracticeUserList } from '../../../redux/slices/practiceUser';
import { deletePracticeTreatment, getPracticeTreatmentList, getTreatmentCategory } from '../../../redux/slices/practiceTreatment';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// sections
import { UserTableToolbar } from '../../../sections/@dashboard/practiceTreatment/list';
import { PracticeTreatmentTableRow, PracticeEditDialog } from '../../../sections/@dashboard/practiceTreatment';
// style
import { tabStyle, tableContainerStyle, formControlStyle } from '../../../styles/PracticeTreatmentListStyle';
import { settingsMainBoxStyle, settingsContentBoxStyle } from '../../../styles/SettingsMenuStyle';

//-------------------------------------------------------------------------

export default function PracticeTreatmentList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const settings = useSettingsContext();
    const { user } = useAuth();
    const listElem = useRef();
    const mounted = useRef();
    const [position, setPosition] = useState(0);
    const [adminUser, setAdminUser] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterRole, setFilterRole] = useState('All');
    const [treatments, setTreatments] = useState([]);
    const [tableData, setTableData] = useState([]);
    const debouncedValue = useDebounce(filterName, 1000);
    const practiceUser = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsList, categoryList } = useSelector((state) => state.practiceTreatment);
    const isEdit = adminUser && adminUser.role === 'ADMIN';
    const TABLE_HEAD = isEdit ? isEditData : noEditData;
    const {
        currentTab: filterStatus,
        onChangeTab: onChangeFilterStatus
    } = useTabs('Enable');

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 10,
                offset: 0
            };
            dispatch(getPracticeUserList(practiceId));
            dispatch(getTreatmentCategory(data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
                category: filterRole !== 'All' && filterRole,
                treatment: debouncedValue,
                type: 'practiceTreatment',
                enable: filterStatus
            };
            dispatch(getPracticeTreatmentList(practiceId, data, true));
        }
    }, [debouncedValue, practiceId]);

    useEffect(() => {
        if (treatmentsList && treatmentsList?.results) {
            setTableData(treatmentsList.results);
            const currentUser = user.email;
            setTreatments(treatmentsList?.results);
            setAdminUser(practiceUser.practiceUser.results.find((users) => users.user.email === currentUser));
        } else {
            setTableData([]);
        }
    }, [treatmentsList]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteRow = (id) => {
        const deleteRow = tableData.filter((row) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);
        dispatch(deletePracticeTreatment(id, handleSuccess));
    };

    const handleSuccess = () => {
        const data = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleResetFilter = () => {
        setFilterName('');
        setFilterRole('All');
    };

    const handleOnPage = (e, newValue) => {
        onChangePage(e, newValue);
        const data = {
            limit: rowsPerPage,
            offset: newValue * rowsPerPage,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleOnChangeRowsPerPage = (e) => {
        onChangeRowsPerPage(e);
        const data = {
            limit: e.target.value,
            offset: page,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleFilter = (e) => {
        setPage(0);
        setFilterRole(e.target.value);
        const data = {
            limit: rowsPerPage,
            offset: 0,
            category: e.target.value !== 'All' && e.target.value,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const leadMoreData = () => {
        if ((categoryList?.count + 1) > categoryList?.results?.length) {
            const data = {
                limit: 10,
                offset: categoryList?.results?.length - 1
            };
            dispatch(getTreatmentCategory(data));
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleTabs = (e, value) => {
        const data = {
            limit: rowsPerPage,
            offset: 0,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: value
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
        onChangeFilterStatus(e, value);
    };
    
    const location = useLocation()

    useEffect(()=>{
        dispatch(updateSelectedPracticeForMenu("practice_treatments"))
    }, [location.pathname])

    return (
        <>
            <Helmet>
                <title> Practice Treatments | Dental SEM CRM </title>
            </Helmet>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                <Box sx={settingsMainBoxStyle}>
                    <Box sx={settingsContentBoxStyle}>
                        <HeaderBreadcrumbs
                            heading="Practice Treatments"
                            links={[
                                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                                { name: 'Practice Treatments' }
                            ]}
                        />
                        <Card>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={filterStatus}
                                onChange={handleTabs}
                                sx={{ px: 2, bgcolor: 'background.neutral' }}
                            >
                                {practiceTabs.map((el) => <Tab
                                    sx={tabStyle}
                                    disableRipple
                                    key={el.value}
                                    label={el.label}
                                    value={el.value}
                                />)}
                            </Tabs>
                            <Divider />
                            <UserTableToolbar
                                filterName={filterName}
                                filterRole={filterRole}
                                onFilterName={handleFilterName}
                                onFilterRole={handleFilter}
                                optionsRole={tableData}
                                onResetFilter={handleResetFilter}
                                categoryList={categoryList}
                                leadMoreData={leadMoreData}
                                setPosition={setPosition}
                            />
                            <Scrollbar>
                                <TableContainer sx={tableContainerStyle}>
                                    <Table size={dense ? 'small' : 'medium'} sx={{ '.MuiTableCell-root': { boxShadow: 'none !important', borderRadius: 0 } }}>
                                        <TableHeadCustom
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={tableData.length}
                                            numSelected={selected.length}
                                            onSort={onSort}
                                            onSelectAllRows={(checked) =>
                                                onSelectAllRows(
                                                    checked,
                                                    tableData.map((row) => row.id)
                                                )
                                            }
                                        />
                                        <TableBody>
                                            <TableLoading pageCount={treatmentsList?.count === undefined && 'No Found'} tableData={treatmentsList?.results?.length === 0 && 'No Found'} />
                                            {treatmentsList !== undefined && treatments.map((row) => (
                                                <PracticeTreatmentTableRow
                                                    key={row.id}
                                                    row={row}
                                                    selected={selected.includes(row.id)}
                                                    onSelectRow={() => onSelectRow(row.id)}
                                                    onDeleteRow={() => handleDeleteRow(row.id)}
                                                    onClick={() => onOpenContacts(row.id)}
                                                    isEdit={isEdit}
                                                    reloadData={handleSuccess}
                                                />
                                            ))}
                                            {treatmentsList.count !== undefined && treatmentsList.results.length === 0 &&
												<TableNoData isNotFound={treatments?.length === 0} />
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                            <Box sx={{ position: 'relative' }}>
                                {treatmentsList?.count > 10 && <TablePagination
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    count={treatmentsList?.count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(e, newValue) => handleOnPage(e, newValue)}
                                    onRowsPerPageChange={handleOnChangeRowsPerPage}
                                />}

                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label="Dense"
                                    sx={formControlStyle}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Container>
            <PracticeEditDialog open={openContacts} onClose={onCloseContacts} />
        </>
    );
}
