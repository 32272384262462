import { sendEngagementTestMail } from 'src/redux/slices/campaign';
import { dispatch } from 'src/redux/store';
import { addTemplateList, getPracticeMessageDetail, getUserTemplateDetails, sendTestMail } from 'src/redux/slices/practiceMessage';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { deleteSequenceMessage, deleteTemplate } from 'src/redux/api/practiceMessageApis';
import { updateError } from 'src/redux/slices/user';
import { applyStylesToTags } from './applyStylesToTags ';

export const callback = (data) => {
    if (data.success) {
        dispatch(updateError(data));
        return;
    }
    Object.values(data.message)?.forEach((value) => {
        dispatch(updateError({ message: value.toString(), success: false }));
    });
};

export const onSendTestMail = ({ sequenceId, row, value, campaign, type, handleSuccess }) => {
    const practiceId = getPracticeId();
    if (campaign || type === 'Template') {
        dispatch(sendEngagementTestMail({ practiceId, tempLateId: row.id, detail: value, callback }));
        return;
    }
    const content = row && row?.body || '';
    const modifiedContent = applyStylesToTags(content);
    const detail = {
        subject: row && row.subject || '',
        body: modifiedContent,
        delay: row && row.delay,
        is_enabled: row && row.is_enabled || true
    };
    if (Object.keys(value).includes('email')) {
        detail.email = value.email;
    }
    if (Object.keys(value).includes('phoneNumber')) {
        let search = value.phoneNumber;
        if (Number(value.phoneNumber) && !value.phoneNumber.toString().includes('+')) {
            search = `+44${value.phoneNumber}`;
        }
        detail.phoneNumber = search;
    }
    dispatch(sendTestMail({ sequenceId, messageId: row.id, detail, callback: handleSuccess }));
};

export const handleDuplicate = ({ row, handleSuccess, preventDefaultBaseTemplateAndID }) => {
    const practiceId = getPracticeId();
    const detail = {
        practice: practiceId,
        name: row.name || '',
        subject: row.subject ? `Copy of ${row.subject}` : '',
        unlayer_html: row.unlayer_html || '',
        unlayer_template: row.unlayer_template || {},
        use_unlayer: row.use_unlayer || false,
        channel: row.channel,
        category: row.category || '',
    };
    if (row.body) {
        detail.body = row.body || '';
    }
    if (!preventDefaultBaseTemplateAndID) {
        switch (row.category) {
            case 'COMMUNICATION':
                detail.base_template = import.meta.env.VITE_REACT_APP_COMMUNICATION_ID || 3;
                break;
            case 'MARKETING':
                detail.base_template = import.meta.env.VITE_REACT_APP_MARKETING_ID || 5;
                break;
            case 'PROMOTION':
                detail.base_template = import.meta.env.VITE_REACT_APP_PROMOTION_ID || 6;
                break;
            default:
                break;
        }
    }
    dispatch(addTemplateList({ id: practiceId, details: detail, callback: handleSuccess, isDuplicate: true }));
};

export const onDeleteRow = ({ row, type, sequenceId, handleSuccess }) => {
    const practiceId = getPracticeId();
    switch (type) {
        case 'Practice_Message':
        case 'OnBoarding':
            dispatch(deleteSequenceMessage(practiceId, sequenceId, row.id, handleSuccess));
            return;
        default:
            dispatch(deleteTemplate(practiceId, row.id, handleSuccess));
            break;
    }
};

export const handleSelect = ({
    row,
    type,
    sequenceId,
    setOpenEmailCompose,
    setIsNewTemplate,
    setOpenSMSCompose,
    setOpenWhatsappCompose,
    handleSuccess
}) => {
    const practiceId = getPracticeId();
    switch (type) {
        case 'Practice_Message':
        case 'OnBoarding':
            dispatch(getPracticeMessageDetail(practiceId, sequenceId, row?.id));
            switch (row.message.type.toLowerCase()) {
                case 'email':
                    setOpenEmailCompose(true);
                    setIsNewTemplate(false);
                    break;
                case 'sms':
                    setOpenSMSCompose(true);
                    setIsNewTemplate(false);
                    break;
                case 'whatsapp':
                    setOpenWhatsappCompose(true);
                    setIsNewTemplate(false);
                    break;
                default:
                    break;
            }
            break;
        default:
            dispatch(getUserTemplateDetails(practiceId, row?.id, handleSuccess));
            switch (row?.channel) {
                case 'EMAIL':
                    setOpenEmailCompose(true);
                    setIsNewTemplate(false);
                    break;
                case 'SMS':
                    setOpenSMSCompose(true);
                    setIsNewTemplate(false);
                    break;
                default:
                    break;
            }
            break;
    }
};