import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Typography, Button, useTheme } from '@mui/material';
import AddButton from './AddButton';
import { Label } from '../../../../components';
import { dispatch, useSelector } from '../../../../redux/store';
import { updateTransitionTime } from '../../../../redux/slices/lead';
import { useBoolean } from '../../../../hooks';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith,
} from '../../../../utils/allTableTab';
import { dateColor, dateFindColor } from '../../../../utils/leadTableUtils';
import {
    datePickerBoxStyle,
    datePickerTextFieldStyle,
    dateTimeTypoStyle,
    dateOfAppointmentLabelStyle,
    tableCellRelativeBoxStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const StartedDateField = ({
    rowData: rd,
    typed,
    showTransitionDate,
    updateLeadPage,
    reloadCounter,
    tableType,
    getTransitionDate,
}) => {
    const rowData = { ...rd, id: Number(rd?.id?.toString()?.split("-")?.[0]) };
    const theme = useTheme();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { consultationDateFormat } = useSelector((state) => state.lead);
    const { enqueueSnackbar } = useSnackbar();
    const filteredTreatmentData = rowData?.treatmentData?.find((ele) => ele?.id === rowData?.treatment_id);
    const [selectedDate, setSelectedDate] = useState(
        moment(
            getTransitionDate(filteredTreatmentData?.state_transition_logs || []) ||
            rowData?.treatmentData?.[0]?.state_transition_logs?.[0]?.timestamp,
            'DD/MM/YYYY hh:mm:ss'
        ).format('ddd[,] D MMMM [at] hh:mm A')
    );
    const openCalendar = useBoolean();
    const perPage = TablePerPage(tableType);
    const pageNumber = TablePageNumber(tableType);
    const status = TableStatus(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const contactCount = TabCount(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const detail = {
            timestamp: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            lead: rowData?.id,
            id: filteredTreatmentData?.id || rowData?.treatmentData?.[0]?.id,
            state: filteredTreatmentData?.status || rowData?.treatmentData?.[0]?.status,
        };
        dispatch(updateTransitionTime(detail, practiceId, handleSuccess));
    };

    const handleSuccess = (data) => {
        openCalendar.onFalse();
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: status,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTagsValue,
            referral,
            category,
            bookedWith,
        };
        updateLeadPage(reloadPayLoad);
        reloadCounter(reloadPayLoad);
    };

    const handleClick = () => {
        if (filteredTreatmentData?.state_transition_logs?.[0]?.timestamp) {
            openCalendar.onTrue();
        }
    };

    return (
        <Box sx={tableCellRelativeBoxStyle} onClick={handleClick}>
            {showTransitionDate(consultationDateFormat, rowData) ? (
                <Button sx={{ px: 0, fontWeight: '600', '&:hover': { background: 'none' } }} disableRipple>
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={'success'}
                        sx={dateOfAppointmentLabelStyle(
                            dateColor(getTransitionDate(filteredTreatmentData?.state_transition_logs || [])),
                            dateFindColor(rowData),
                            typed
                        )}
                    >
                        <Typography
                            variant='05 Others/Label'
                            dangerouslySetInnerHTML={{ __html: showTransitionDate(consultationDateFormat, rowData) }}
                            sx={dateTimeTypoStyle}
                        />
                    </Label>
                </Button>
            ) : (
                <AddButton />
            )}
            <Box sx={datePickerBoxStyle}>
                <DateTimePicker
                    orientation='landscape'
                    closeOnSelect={false}
                    label=''
                    open={openCalendar.value}
                    onClose={openCalendar.onFalse}
                    name='DueDatetime'
                    value={selectedDate}
                    maxDateTime={new Date()}
                    inputFormat={'dd/MM/yyyy HH:mm'}
                    onAccept={(newValue) => handleDateChange(newValue)}
                    ampm={false}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            margin: 'normal',
                            sx: datePickerTextFieldStyle,
                            onKeyDown: (e) => e.preventDefault(),
                        },
                        actionBar: { actions: ['cancel', 'accept'] },
                    }}
                />
            </Box>
        </Box>
    );
};

StartedDateField.propTypes = {
    rowData: PropTypes.object,
    typed: PropTypes.string,
    showTransitionDate: PropTypes.func,
    updateLeadPage: PropTypes.func,
    reloadCounter: PropTypes.func,
    tableType: PropTypes.string,
    getTransitionDate: PropTypes.func,
};

export default StartedDateField;
