import { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// layouts
import { useSentryRoutes } from 'src/sentryInstrument';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import { CompactLayout } from '../layouts/compact';
// guards
import { GuestGuard, AuthGuard } from '../guards';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import { LoadingScreen } from '../components/loading';
import PracticeDetails from '../pages/dashboard/practices/PracticeDetails';
import PracticeTreatmentList from '../pages/dashboard/practices/PracticeTreatment';
import PracticeUserList from '../pages/dashboard/practices/PracticeUser';
import { ConfirmEmail } from '../pages/auth';
import { lazyWithRetries } from './lazyRetry';

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

// AUTHENTICATION
const Login = Loadable(lazyWithRetries(() => import('../pages/auth/Login')));
const Register = Loadable(lazyWithRetries(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazyWithRetries(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazyWithRetries(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazyWithRetries(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazyWithRetries(() => import('../pages/dashboard/GeneralApp')));
const LeadDetailView = Loadable(lazyWithRetries(() => import('../pages/dashboard/LeadDetailView')));
const ArchiveDetailView = Loadable(lazyWithRetries(() => import('../pages/dashboard/ArchiveDetailView')));
const StripeAuthorized = Loadable(lazyWithRetries(() => import('../components/StripeAuthorized')));
const FacebookAuthorized = Loadable(lazyWithRetries(() => import('../components/FacebookAuthorized')));

// Tasks
const Tasks = Loadable(lazyWithRetries(() => import('../pages/dashboard/TasksPage')));

// Campaigns
const Campaigns = Loadable(lazyWithRetries(() => import('../pages/dashboard/CampaignsPage')));
const TemplateList = Loadable(lazyWithRetries(() => import('../pages/dashboard/TemplateListPage')));

// FacebookComment
const FacebookComment = Loadable(lazyWithRetries(() => import('../pages/dashboard/ChatPage')));

// Whatsapp
const Whatsapp = Loadable(lazyWithRetries(() => import('../pages/dashboard/Whatsapp')));

// Sms
const Sms = Loadable(lazyWithRetries(() => import('../pages/dashboard/Sms')));

// EMAIL
const Emails = Loadable(lazyWithRetries(() => import('../pages/dashboard/mail/mail')));

// Create Stripe Payment
const CreateStripePayment = Loadable(lazyWithRetries(() => import('../pages/dashboard/CreateStripePaymentPage')));

// ANALYTICS
const GeneralAnalytics = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/GeneralAnalytics')));
const TreatmentSummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/TreatmentSummary')));
const CategorySummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/CategorySummary')));
const ContactSummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/ContactSummary')));
const SourceSummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/SourceSummary')));
const PractitionerSummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/PractitionerSummary')));
const ReportsSetting = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/ReportsSetting')));
const ArchiveSummary = Loadable(lazyWithRetries(() => import('../pages/dashboard/analytics/ArchiveSummary')));

// TABLE
const AllLeadTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/AllLeadTable/AllLeadsTable')));
const NoTaskTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/AllLeadTable/NoTaskTable')));
const NewLeadTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/NewLeadTable')));
const NHSLeadTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/NHSLeadTable')));
const ArchiveTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/ArchiveLeadTable')));
const ConsultantAttendTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/ConsultantAttend')));
const ConsultantBookedTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/ConsultantBooked')));
const TreatmentStartedTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/TreatmentStarted')));
const FailedToAttendTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/FailedToAttendTable')));
const ClosedTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/ClosedTable')));
const AlignerApproveTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/AlignerApprove')));

// USER
const Message = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/MessageDetails')));

// USER
const UserProfile = Loadable(lazyWithRetries(() => import('../pages/dashboard/UserProfile')));
const UserCreate = Loadable(lazyWithRetries(() => import('../pages/dashboard/UserCreate')));
const InviteUser = Loadable(lazyWithRetries(() => import('../pages/dashboard/InviteUserCreate')));

// APP
const Calendar = Loadable(lazyWithRetries(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazyWithRetries(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazyWithRetries(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const Maintenance = Loadable(lazyWithRetries(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazyWithRetries(() => import('../pages/Page500')));
const Page403 = Loadable(lazyWithRetries(() => import('../pages/Page403')));
const Page404 = Loadable(lazyWithRetries(() => import('../pages/Page404')));
const ComingSoonPage = Loadable(lazyWithRetries(() => import('../pages/ComingSoon')));
const PrivacyPolicy = Loadable(lazyWithRetries(() => import('../pages/PrivacyPolicy')));
const AppointmentRating = Loadable(lazyWithRetries(() => import('../pages/AppointmentRating')));

// PAYMENT
const Integration = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/practiceIntegration')));

// CONSENT
const Consent = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/Consent')));

// LOAN
const Loan = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/Loan')));

// NOTIFICATION
const Notification = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/NotificationSetting')));
const NotificationPage = Loadable(lazyWithRetries(() => import('../pages/dashboard/NotificationTable')));

// EXPORT TO CSV
const ExportCsv = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/ExportCsv')));

// PRACTICE CHATBOT
const PracticeChatbot = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/PracticeChatbot')));

// PRACTICE PREFERENCE
const PracticePreferences = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/PracticePreferences')));
const AutoRemindersTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/AutoRemindersTable')));
const CreateAutoReminder = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/CreateAutoReminder')));
const EditAutoReminder = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/EditAutoReminder')));
const CallScripts = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/CallScripts')));
const CallScriptsV2 = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/callScriptV2/CallScriptV2')));
const CreateScript = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/CreateScript')));
const ChatBotForm = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/ChatBot/ChatBotForm')));
const EditCallScript = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/EditCallScript')));
const ConsentBuilder = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/ConsentBuilder')));
const CreateConsent = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/CreateConsent')));
const EditConsent = Loadable(lazyWithRetries(() => import('../pages/dashboard/practices/EditConsent')));

// SUPER ADMIN
const EngagementTemplates = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/ListTemplate')));
const EmailEngagementTemplates = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/EmailTemplate')));
const SmsEngagementTemplates = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/SmsTemplate')));
const BulkAutoReminders = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/AutoReminderTemplate')));
const CreateBulkAutoReminder = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/CreateAutoReminderTemplate')));
const EditBulkAutoReminder = Loadable(lazyWithRetries(() => import('../pages/superAdmin/Engagement/EditAutoReminderTemplate')));
const DuplicateLeadTable = Loadable(lazyWithRetries(() => import('../pages/dashboard/tables/DuplicateLeads')));
const AudienceManager = Loadable(lazyWithRetries(() => import('../pages/dashboard/AudienceManager')));
const CreateAudience = Loadable(lazyWithRetries(() => import('../pages/dashboard/CreateAudience')));
const EditAudience = Loadable(lazyWithRetries(() => import('../pages/dashboard/EditAudience')));
const ImportLeads = Loadable(lazyWithRetries(() => import('../pages/dashboard/ImportLeads')));
const ImportLeadsCreate = Loadable(lazyWithRetries(() => import('../pages/dashboard/ImportLeadsCreate')));
const ReviewPage = Loadable(lazyWithRetries(() => import('../pages/dashboard/ReviewPage')));

// Financial
const Financial = Loadable(lazyWithRetries(() => import('../pages/dashboard/FinancialTracker')));
const QrCode = Loadable(lazyWithRetries(() => import('../pages/dashboard/QRCodeWrapper')));
const BlankRedirect = Loadable(lazyWithRetries(() => import('../pages/BlankRedirect')));

const WebsiteSupport = Loadable(lazyWithRetries(() => import('../pages/dashboard/WebsiteSupportWrapper')));
export default function Router() {
    return useSentryRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    children: [
                        { element: <GuestGuard><Register /></GuestGuard>, index: true },
                        { path: 'account-confirm-email/:keyId', element: <ConfirmEmail /> }
                    ]
                },
                {
                    path: 'login-unprotected',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    )
                },
                {
                    path: 'register-unprotected',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    )
                },
                {
                    element: <CompactLayout />,
                    children: [
                        { path: 'reset-password', element: <ResetPassword /> },
                        { path: 'verify', element: <VerifyCode /> },
                    ]
                }
            ],
        },
        {
            path: 'qr/:shortURI',
            element: (
                <BlankRedirect />
            ),
        },
        // Dashboard Routes
        {
            path: 'stripe',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: 'authorize/callback/', element: <StripeAuthorized /> },
            ]
        },
        {
            path: 'facebook',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: 'authorize/callback/', element: <FacebookAuthorized /> },
            ]
        },
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'app', element: <GeneralApp /> },
                { path: 'practice/:practiceId/lead-detail/:id/:tab?', element: <LeadDetailView /> },
                { path: 'practice/:practiceId/archived/:id/', element: <ArchiveDetailView /> },
                { path: 'stripe/authorize/callback/', element: <StripeAuthorized /> },
                { path: 'facebook/authorize/callback/', element: <FacebookAuthorized /> },
                { path: 'tasks', element: <Tasks /> },
                { path: 'whatsapp', element: <Whatsapp /> },
                { path: 'sms', element: <Sms /> },
                { path: 'emails', element: <Emails /> },
                { path: 'payment-links/create', element: <CreateStripePayment /> },
                {
                    path: 'analytics',
                    children: [
                        { element: <Navigate to="/dashboard/analytics/reports" replace />, index: true },
                        { path: 'reports', element: <GeneralAnalytics /> },
                        { path: 'treatment-summary', element: <TreatmentSummary /> },
                        { path: 'category-summary', element: <CategorySummary /> },
                        { path: 'contact-summary', element: <ContactSummary /> },
                        { path: 'source-summary', element: <SourceSummary /> },
                        { path: 'practitioner-summary', element: <PractitionerSummary /> },
                        { path: 'reports-setting', element: <ReportsSetting /> },
                        { path: 'archived-summary', element: <ArchiveSummary /> },
                    ]
                },
                {
                    path: 'tables',
                    children: [
                        { element: <Navigate to="/dashboard/tables/all-lead-table" replace />, index: true },
                        { path: 'all-lead-table', element: <AllLeadTable /> },
                        { path: 'no-task-assigned-table', element: <NoTaskTable /> },
                        { path: 'new-lead-table', element: <NewLeadTable /> },
                        { path: 'nhs-lead-table', element: <NHSLeadTable /> },
                        { path: 'archive-table', element: <ArchiveTable /> },
                        { path: 'consultant-attended-table', element: <ConsultantAttendTable /> },
                        { path: 'consultant-booked-table', element: <ConsultantBookedTable /> },
                        { path: 'treatment-started-table', element: <TreatmentStartedTable /> },
                        { path: 'aligner-approve-table', element: <AlignerApproveTable /> },
                        { path: 'failed-to-attend-table', element: <FailedToAttendTable /> },
                        { path: 'completed-table', element: <ClosedTable /> },
                        { path: 'duplicate-lead-table', element: <DuplicateLeadTable /> },
                    ],
                },
                {
                    path: 'user',
                    children: [
                        { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
                        { path: 'profile', element: <UserProfile /> },
                        { path: 'practice', element: <PracticeDetails /> },
                        { path: 'practice/:id', element: <PracticeDetails /> },
                        { path: 'practice-treatment', element: <PracticeTreatmentList /> },
                        { path: 'practice-user', element: <PracticeUserList /> },
                        { path: 'new', element: <InviteUser /> },
                        { path: ':id/edit', element: <UserCreate /> },
                        { path: 'message', element: <Message /> },
                        { path: 'integration', element: <Integration /> },
                        { path: 'practice-consent', element: <Consent /> },
                        { path: 'practice-loan', element: <Loan /> },
                        { path: 'notification-settings', element: <Notification /> },
                        { path: 'notification-table', element: <NotificationPage /> },
                        { path: 'practice-preferences', element: <PracticePreferences /> },
                        { path: 'export-csv', element: <ExportCsv /> },
                        { path: 'practice-chatbot', element: <PracticeChatbot /> },
                        { path: 'auto-reminders', element: <AutoRemindersTable /> },
                        { path: 'create-reminder', element: <CreateAutoReminder /> },
                        { path: ':id/edit-reminder', element: <EditAutoReminder /> },
                        { path: 'facebook', element: <FacebookComment /> },
                        { path: 'call-scripts', element: <CallScripts /> },
                        { path: 'call-scripts-v2', element: <CallScriptsV2 /> },
                        { path: 'create-script', element: <CreateScript /> },
                        { path: 'create-chatbot', element: <ChatBotForm title='Create chatbot' /> },
                        { path: ':id/edit-script', element: <EditCallScript /> },
                        { path: 'edit-chatbot/:id', element: <ChatBotForm title='Edit chatbot' /> },
                        { path: 'consent', element: <ConsentBuilder /> },
                        { path: 'create-consent', element: <CreateConsent /> },
                        { path: ':id/edit-consent', element: <EditConsent /> },
                    ],
                },
                { path: 'calendar', element: <Calendar /> },
                { path: 'kanban', element: <Kanban /> },
                { path: 'permission-denied', element: <PermissionDenied /> },
                {
                    path: 'super-admin',
                    children: [
                        { element: <Navigate to="/dashboard/super-admin/engagement_templates" replace />, index: true },
                        { path: 'engagement_templates', element: <EngagementTemplates /> },
                        { path: 'email_template/:templateId?', element: <EmailEngagementTemplates /> },
                        { path: 'sms_template/:templateId?', element: <SmsEngagementTemplates /> },
                        { path: 'auto_reminder', element: <BulkAutoReminders /> },
                        { path: 'create_reminder', element: <CreateBulkAutoReminder /> },
                        { path: ':id/edit_reminder', element: <EditBulkAutoReminder /> },
                        { path: 'similar-duplicate-lead-table', element: <DuplicateLeadTable /> },
                        { path: 'import', element: <ImportLeads /> },
                        { path: 'create_import_lead', element: <ImportLeadsCreate /> },
                    ]
                },
                { path: 'patient-review', element: <ReviewPage /> },
                { path: 'financial-tracker', element: <Financial /> },
                { path: 'qr-code', element: <QrCode /> },
                {
                    path: 'campaigns-manager',
                    children: [
                        { element: <Navigate to="/dashboard/campaigns-manager/campaigns" replace />, index: true },
                        { path: 'campaigns', element: <Campaigns /> },
                        { path: 'audience_manager', element: <AudienceManager /> },
                        { path: 'create_audience', element: <CreateAudience /> },
                        { path: ':id/edit_audience', element: <EditAudience /> },
                        { path: 'templates', element: <TemplateList /> },
                    ],
                },
                { path: 'website-support', element: <WebsiteSupport /> },
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <CompactLayout />,
            children: [
                { path: 'maintenance', element: <Maintenance /> },
                { path: 'reset/:uid/:token', element: <NewPassword /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <Page404 /> },
                { path: '403', element: <Page403 /> },
                { path: 'coming-soon', element: <ComingSoonPage /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'appointments/feedback', element: <AppointmentRating /> },
    ]);
}
