import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { ConsultationAttendedAppointment } from '../Attended';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    onBoardDialogHeader,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
    attendedStackStyle
} from '../../styles/ScheduleReminder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenConsultationPopup({ selectedTreatmentData, handleReloadLeads, open, onClose, type = 'lead', user, inputRef, headerButtons, startTreatment, OpenAssignTask, editDetailHandler, handleCloseAndReload }) {
    const settings = useSettingsContext();
    const handleClose = async (action) => {
        if (action === 'tco') {
            return;
        }
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        onClose();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
            disableEnforceFocus
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
                    Attended consultation for <span style={{ textTransform: 'capitalize' }}>{user?.first_name}</span>
                </Typography>
                {headerButtons &&
                    <Box
                        sx={cancelBoxStyle}
                    >
                        <Button
                            color="inherit"
                            variant='outlined'
                            style={{ borderColor: '#FFFFFF', color: '#fff' }}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={attendedStackStyle}>
                <Grid container spacing={1} sx={{ maxWidth: '1088px', margin: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                    >
                        <ConsultationAttendedAppointment
                            selectedTreatmentData={selectedTreatmentData}
                            handleReloadLeads={handleReloadLeads}
                            handleCloseAndReload={handleCloseAndReload}
                            handleClose={handleClose}
                            user={user}
                            type='Starting treatment'
                            inputRef={inputRef}
                            startTreatment={startTreatment}
                            OpenAssignTask={OpenAssignTask}
                            editDetailHandler={editDetailHandler} />
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

OpenConsultationPopup.propTypes = {
    handleReloadLeads: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object,
    inputRef: PropTypes.any,
    headerButtons: PropTypes.bool,
    startTreatment: PropTypes.string,
    OpenAssignTask: PropTypes.any,
    editDetailHandler: PropTypes.any,
    handleCloseAndReload: PropTypes.func,
    selectedTreatmentData: PropTypes.any,
};