import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Typography, Button, IconButton, useTheme } from '@mui/material';
import { Iconify, Label } from '../../../../components';
import { appointmentDateSlice, updateAppointmentDetail } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import { useBoolean } from '../../../../hooks';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith,
} from '../../../../utils/allTableTab';
import { dateColor, dateFindColor, showAppointmentDate } from '../../../../utils/leadTableUtils';
import {
    datePickerBoxStyle,
    datePickerTextFieldStyle,
    dateTimeTypoStyle,
    dateOfAppointmentLabelStyle,
    tableCellRelativeBoxStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const AppointmentDateField = ({
    rowData,
    typed,
    updateLeadPage,
    reloadCounter,
    tableType,
    OpenAssignTask,
}) => {
    const theme = useTheme();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { dateAppointmentFormat } = useSelector((state) => state.lead);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedDate, setSelectedDate] = useState(
        moment(rowData?.appointments?.[0]?.datetime, 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A')
    );
    const openCalendar = useBoolean();
    const perPage = TablePerPage(tableType);
    const pageNumber = TablePageNumber(tableType);
    const status = TableStatus(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const contactCount = TabCount(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const buttonRef = useRef();
    const [resetKey, setResetKey] = useState(0);

    const handleDateChange = (date) => {
        if (rowData?.appointments?.[0]?.datetime_human && rowData?.appointments?.[0]?.status !== 'CANCELLED') {
            setSelectedDate(date);
            const detail = {
                datetime: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            };
            dispatch(
                updateAppointmentDetail(practiceId, rowData?.lead_id, rowData?.appointments?.[0]?.id, detail, handleSuccess, '')
            );
            setTimeout(() => {
                openCalendar.onFalse();
            }, 200);
        } else {
            const payload = {
                apptDate: date,
                treatmentId: rowData?.treatment_id,
            };
            if (typed === 'treatmentStarted' || typed === 'treatmentStartedWithInvisalign') {
                payload.isTreatmentStartedTable = true;
            }
            dispatch(appointmentDateSlice(payload));
            OpenAssignTask(rowData, '', buttonRef.current);
            setTimeout(() => {
                openCalendar.onFalse();
                setSelectedDate(null);
                setResetKey((prevKey) => prevKey + 1);
            }, 200);
        }
    };

    const handleSuccess = (data) => {
        openCalendar.onFalse();
        if (data.message) {
            enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        }
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: status,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTagsValue,
            referral,
            category,
            bookedWith,
        };
        updateLeadPage(reloadPayLoad);
        reloadCounter(reloadPayLoad);
    };

    const renderDate = () => {
        if (rowData?.appointments?.[0]?.status === 'CANCELLED') {
            return (
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={'success'}
                    sx={{ ...dateOfAppointmentLabelStyle('', '', typed), flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    <Typography variant='05 Others/Label' sx={dateTimeTypoStyle}>
                        Cancelled
                        <IconButton
                            aria-label='add appointment'
                            component='label'
                            sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}
                        >
                            <Iconify
                                icon='material-symbols:add-circle'
                                sx={{ color: 'common.mintyZest', ml: 1 }}
                                width={20}
                                height={20}
                            />
                        </IconButton>
                    </Typography>
                    <Typography
                        variant='05 Others/Label'
                        dangerouslySetInnerHTML={{ __html: showAppointmentDate(dateAppointmentFormat, rowData) }}
                        sx={dateTimeTypoStyle}
                    />
                </Label>
            );
        }
        if (rowData?.appointments?.[0]?.datetime_human) {
            return (
                <Button sx={{ px: 0, fontWeight: '600', '&:hover': { background: 'none' } }} disableRipple>
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={'success'}
                        sx={dateOfAppointmentLabelStyle(
                            dateColor(rowData?.appointments?.[0]?.datetime),
                            dateFindColor(rowData),
                            typed
                        )}
                    >
                        <Typography
                            variant='05 Others/Label'
                            dangerouslySetInnerHTML={{ __html: showAppointmentDate(dateAppointmentFormat, rowData) }}
                            sx={dateTimeTypoStyle}
                        />
                    </Label>
                </Button>
            );
        }
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                <IconButton aria-label='add appointment' component='label' sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}>
                    <Iconify
                        icon='material-symbols:add-circle'
                        sx={{ color: 'common.mintyZest', mr: '0' }}
                        width={20}
                        height={20}
                    />
                </IconButton>
            </Box>
        );
    };

    return (
        <Box
            sx={tableCellRelativeBoxStyle}
            onClick={() => {
                openCalendar.onTrue();
            }}
            ref={buttonRef}
        >
            {renderDate()}
            <Box sx={datePickerBoxStyle}>
                <DateTimePicker
                    key={resetKey}
                    closeOnSelect={false}
                    orientation='landscape'
                    label=''
                    open={openCalendar.value}
                    onClose={openCalendar.onFalse}
                    minDateTime={new Date()}
                    name='DueDatetime'
                    value={selectedDate}
                    inputFormat={'dd/MM/yyyy HH:mm'}
                    onAccept={(newValue) => handleDateChange(newValue)}
                    ampm={false}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            margin: 'normal',
                            sx: datePickerTextFieldStyle,
                            onKeyDown: (e) => e.preventDefault(),
                        },
                        actionBar: { actions: ['cancel', 'accept'] },
                    }}
                />
            </Box>
        </Box>
    );
};

AppointmentDateField.propTypes = {
    rowData: PropTypes.array,
    typed: PropTypes.string,
    updateLeadPage: PropTypes.func,
    reloadCounter: PropTypes.func,
    tableType: PropTypes.string,
    OpenAssignTask: PropTypes.func,
};

export default AppointmentDateField;
