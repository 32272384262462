import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Tab, Tabs } from '@mui/material';
// components
import { Label } from '../../../components';
// constant/hooks
import { CAMPAIGN_STATUS } from '../../../constants/MessageListConst';
import { useTabs } from '../../../hooks';

const CampaignToolbar = ({ messageData, filterData, campaignCounter }) => {
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('All' || '');

    const tabCounter = (data) => campaignCounter?.filter(value => value.filterStatus === data ? value.count : null);

    const getStatusCount = (data, status) => {
        const response = tabCounter(status);
        return response[0]?.count || 0;
    };

    const handleChangeTab = (event, newValue) => {
        filterData(newValue);
        onChangeFilterStatus(event, newValue);
    };

    const getColor = (type, defaultColor) => {
        switch (type) {
            case 'Scheduled':
            case 'Cancelled':
                return 'error';
            case 'Sent':
                return 'primary';
            case 'Draft':
                return 'warning';
            case 'Paused':
                return 'info';
            default:
                return defaultColor;
        }
    };

    const getLabel = (tab, defaultColor) => <Label color={getColor(tab.label, defaultColor)} > {getStatusCount(messageData, tab.value)} </Label>;

    return (
        <Box
            mb={2}
        >
            <Tabs
                allowScrollButtonsMobile
                variant='scrollable'
                value={filterStatus.length > 0 && filterStatus}
                onChange={handleChangeTab}
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                sx={{ px: 2, bgcolor: 'background.neutral', ' .MuiButtonBase-root:first-of-type.Mui-disabled ': { display: 'none' } }}
            >
                {CAMPAIGN_STATUS.map((tab, index) => (
                    <Tab
                        sx={{ 'span': { mr: 1 } }}
                        disableRipple
                        key={index}
                        label={tab.label}
                        icon={filterStatus === tab ? getLabel(tab, 'primary') : getLabel(tab, 'info')}
                        value={tab.value}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default CampaignToolbar;

CampaignToolbar.propTypes = {
    messageData: PropTypes.array,
    filterData: PropTypes.func,
    campaignCounter: PropTypes.array,
};