/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import * as Sentry from "@sentry/react";
// redux
import { dispatch, useSelector } from '../redux/store';
import { updateError } from '../redux/slices/user';
import { leadPage } from '../redux/slices/lead';
//
import { Iconify } from '.';

export default function ErrorHandler() {
    const { error } = useSelector(state => state.user);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const navigate = useNavigate();

    const handleViewLeadDetail = (key, id) => {
        closeSnackbar(key);
        dispatch(leadPage({}));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${id}`, { state: { shouldRedirectToOverview: true } });
    };

    useEffect(() => {
        if (error !== null) {
            if (error?.success !== 'success' && !error?.success) {
                const customError = new Error(error.message?.detail || error.message);
                customError.name = "UI Popup Error";
                Sentry.captureException(customError, {
                    tags: {
                        feature: "Handled Exception - Possible UI Snackbar",
                    },
                    extra: {
                        debugInfo: JSON.stringify(error),
                    },
                });
            }
            enqueueSnackbar(error.message?.detail || error.message, {
                variant: error?.success ? 'success' : 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: error.timeout, id: 'success-notification',
                action: (key) =>
                (error.lead ? <>
                    <Button onClick={() => handleViewLeadDetail(key, error.lead)} variant='outlined' size="small"  >
                        Show lead <Iconify icon={'ep:user-filled'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                    </Button>
                    <Iconify onClick={() => closeSnackbar(key)} icon={'radix-icons:cross-2'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                </> :
                    <Iconify onClick={() => closeSnackbar(key)} icon={'radix-icons:cross-2'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                )
            }, true);
            setTimeout(() => {
                dispatch(updateError(null));
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    return (
        <></>
    );
}