import PropTypes from 'prop-types';
import React, { forwardRef, useState, useEffect } from 'react';
// @mui
import { Box, Stack, Dialog, Button, AppBar, Typography, Slide, Card, List, ListItem, ListItemText } from '@mui/material';
// components
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { DefaultScriptView } from '../scriptListing';
import { Iconify } from '..';
import { NormalLoading } from '../loading';
import { clearLead } from '../../redux/slices/lead';
import { dispatch, useSelector } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
} from '../../styles/ScheduleReminder';
import { scriptStackStyle, callScriptListItemStyle } from '../../styles/CallScripts';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

const OpenCallScriptPopup = ({ open, onClose, type, headerButtons }) => {
    const { callScriptReusableData } = useSelector((state) => state.callScript);
    const scriptList = callScriptReusableData?.results?.filter(item => item.is_default && item.category === 'INBOUND');
    const [callScriptActive, setCallScriptActive] = useState(false);
    const [scriptData, setScriptData] = useState([]);

    useEffect(() => {
        if (scriptList?.length === 1) {
            setScriptData({
                sections: scriptList?.[0]?.sections || [],
                formId: scriptList?.[0]?.id || null,
            });
            setCallScriptActive(true);
        }
    }, [scriptList]);

    const handleClose = () => {
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        onClose();
    };

    const handleScriptClick = (value) => {
        const sorting = [...(value?.sections || [])].sort((a, b) => a.order - b.order);
        setScriptData({
            sections: sorting,
            formId: value?.id || null,
        });
        setCallScriptActive(true);
    };

    const backButtonClick = () => {
        setScriptData([]);
        setCallScriptActive(false);
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
        >
            <AppBar sx={commonDialogHeaderStyle}>
                <Typography
                    sx={reminderTitleStyle}
                    variant='h6'
                    component='div'
                >
                    Call Script
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color='inherit'
                            variant='outlined'
                            sx={{ borderColor: 'common.white', color: 'common.white' }}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={scriptStackStyle}>
                <Box sx={{ position: 'relative' }}>
                    <NormalLoading tableData={callScriptReusableData === null && 'No Found'} pageCount={callScriptReusableData === null && 'No Found'} type={'treatment'} />
                    {!callScriptActive ?
                        <Card sx={{ width: '750px', mx: 'auto', boxShadow: 'none' }}>
                            <Box sx={{ p: '15px 16px', borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)', position: 'relative' }}>
                                <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center' }}>Inbound Scripts</Typography>
                            </Box>
                            <Box sx={{ p: '24px' }}>
                                <Box>
                                    <List sx={{ m: '0', p: '0' }}>
                                        {scriptList?.map((value, index) => (
                                            <ListItem key={index} sx={callScriptListItemStyle()} onClick={() => handleScriptClick(value)}>
                                                <ListItemText primary={`${value.name}`} sx={{ '.MuiTypography-root': { fontWeight: '700' } }} />
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Iconify icon={'akar-icons:chevron-right'} width={'20px'} height={'20px'} />
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Card>
                        :
                        <DefaultScriptView onClose={onClose} scriptData={scriptData} backButtonClick={backButtonClick} />
                    }
                </Box>
            </Stack>
        </Dialog>
    );
};

OpenCallScriptPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    headerButtons: PropTypes.bool,
};

export default OpenCallScriptPopup;