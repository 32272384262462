import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { ReScheduleAppointmentPopup } from '../dialogPopUp';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import { commonDialogHeaderStyle, onBoardDialogHeader, reminderDialogStyle, reminderTitleStyle, cancelBoxStyle, reminderStackStyle } from '../../styles/ScheduleReminder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenReSchedulePopup({ open, onClose, inputRef, headerButtons, treatmentData, appoinmentID, appoinmentSelectData, pastAppointment }) {
    const settings = useSettingsContext();

    const handleClose = async () => {
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        onClose();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle}
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader }>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
					Appointment reschedule
                </Typography>
                {headerButtons &&
					<Box sx={cancelBoxStyle}>
					    <Button
					        color="inherit"
					        variant='outlined'
					        style={{ borderColor: '#FFFFFF', color: '#fff' }}
					        onClick={() => {handleClose('close');}}
					    >
							Cancel
					    </Button>
					</Box>
                }
            </AppBar>
            <Stack spacing={2} sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ maxWidth: '1088px', margin: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                    >
                        <ReScheduleAppointmentPopup handleClose={handleClose} inputRef={inputRef} appoinmentID={appoinmentID} treatmentData={treatmentData} appoinmentSelectData={appoinmentSelectData} pastAppointment={pastAppointment} />
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

OpenReSchedulePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    inputRef: PropTypes.any,
    headerButtons: PropTypes.bool,
    treatmentData: PropTypes.any,
    appoinmentID: PropTypes.any,
    appoinmentSelectData: PropTypes.any,
    pastAppointment: PropTypes.any,
};