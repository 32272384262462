import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
// use @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Avatar } from '@mui/material';
// use routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// use hooks
import { useAuth, useIsMountedRef } from '../../../hooks';
// use components
import { MenuPopover } from '../../../components';
// redux
import { dispatch } from '../../../redux/store';
import { updatePracticeId } from '../../../redux/slices/practiceTreatmentList';
// utils
import clearStore from '../../../utils/clearStore';
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';

// ----------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Profile',
        linkTo: PATH_DASHBOARD.user.profile,
    }
];

// ----------------------------------------------------------

export default function AccountPopover() {

    const navigate = useNavigate();

    const { user, logout } = useAuth();

    const [open, setOpen] = useState(null);

    const isMountedRef = useIsMountedRef();

    const { enqueueSnackbar } = useSnackbar();

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
            dispatch(updatePracticeId(null));
            localStorage.removeItem('practiceId');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('dateFormat');
            localStorage.clear();
            navigate(PATH_AUTH.login, { replace: true });
            clearStore();
            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            enqueueSnackbar('Unable to logout!', {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            },
            );
        }
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
                id='account-popover'
            >
                <Avatar
                    src={user?.avatar}
                    alt={user?.first_name}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                />
            </IconButton>


            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                id='account-menu-popover'
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.salutation && `${capitalizeFirstLetter(user?.salutation)}.`} {user?.first_name} {user?.last_name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            id={option.label}
                            to={option.linkTo}
                            component={RouterLink}
                            onClick={handleClose}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    );
}
