import { isEmpty } from 'lodash';
import { createTreatmentPayment, deleteTreatmentPayment, editTreatmentPayment, setDepositLoading } from 'src/redux/slices/deposit';
import { dispatch, store } from 'src/redux/store';

export const handleDeletePaymentMethods = (data, handleDepositPaymentOnclose) => {
    const paymentData = data?.deposit_payment_data;
    if (isEmpty(paymentData)) return;

    const leadId = data?.lead_id;
    const treatmentId = paymentData?.[0]?.treatment;

    dispatch(setDepositLoading(true));

    dispatch(deleteTreatmentPayment({
        treatmentId,
        leadId,
        paymentId: paymentData?.[0]?.id,
        handleSuccess: () => {
            if (paymentData.length > 1) {
                dispatch(deleteTreatmentPayment({
                    treatmentId,
                    leadId,
                    paymentId: paymentData?.[1]?.id,
                    handleSuccess: () => handleDepositPaymentOnclose(leadId)
                }));
                return;
            }
            handleDepositPaymentOnclose(leadId);
        }
    }));
};

export const handleGetPaymentTypeData = (data) => {
    const depositData = data?.deposit_payment_data;

    if (isEmpty(depositData)) return '';
    if (depositData.length > 1) {
        switch (depositData?.[1]?.payment_processor?.payment_processor) {
            case 'GOCARDLESS':
                return 'DEPOSIT_AND_GOCARDLESS';
            case 'STRIPE':
                return 'DEPOSIT_AND_STRIPE';
            default:
                return depositData?.[1]?.payment_type === "TREATMENT" ? 'DEPOSIT_AND_BALANCE' : 'DEPOSIT_AND_FINANCE';
        }
    }

    switch (depositData?.[0]?.payment_type) {
        case 'FINANCE':
            return 'FINANCE_ONLY';
        case 'TREATMENT':
            return 'FULL_PAYMENT';
        default:
            return '';
    }
};

export const getPaymentType = (paymentMethod) => {
    switch (paymentMethod) {
        case 'DEPOSIT_AND_GOCARDLESS':
        case 'DEPOSIT_AND_STRIPE':
        case 'DEPOSIT_AND_FINANCE':
            return 'TREATMENT_DEPOSIT';
        case 'FULL_PAYMENT':
        case 'DEPOSIT_AND_BALANCE':
            return 'TREATMENT';
        default:
            return 'FINANCE';
    }
};

export const clickAwayListener = (handleClickAwayLister, leadId) => {
    if (handleClickAwayLister) handleClickAwayLister(leadId);
};

export const handleCreateProcessorPayment = (args) => {
    const { data, depositPaymentData, isEdit, treatmentId, leadId, handleClickAwayLister } = args;
    const { practice } = store.getState().practice;
    const paymentProcessorId = practice?.payment_processors?.find((ele) => data?.paymentMethod?.includes(ele?.payment_processor))?.id || practice?.payment_processors?.[0]?.id;
    const payload = {
        body: { payment_type: 'TREATMENT_DEPOSIT', payment_processor: paymentProcessorId || 0, amount: data.additionalAmount, paid_at: data.paymentDate || null },
        treatmentId,
        leadId,
        handleSuccess: () => clickAwayListener(handleClickAwayLister, leadId)
    };
    if (isEdit) {
        dispatch(editTreatmentPayment({ ...payload, paymentId: depositPaymentData?.[1]?.id }));
        return;
    }
    dispatch(createTreatmentPayment({ ...payload }));
};

export const handleCreatePayment = (args) => {
    const { data, isEdit, depositPaymentData, treatmentId, leadId, handleClickAwayLister, paymentType } = args;
    const body = { payment_type: paymentType, amount: data.additionalAmount, paid_at: data.paymentDate || null };
    if (isEdit) {
        dispatch(editTreatmentPayment({
            body: { ...body, payment_processor: null, }, treatmentId, leadId,
            paymentId: depositPaymentData?.[1]?.id,
            handleSuccess: () => clickAwayListener(handleClickAwayLister, leadId)
        }));
        return;
    }
    dispatch(createTreatmentPayment({
        body, treatmentId, leadId,
        handleSuccess: () => clickAwayListener(handleClickAwayLister, leadId)
    }));
};

export const handleEditPaymentSuccess = (args) => {
    const { data, depositPaymentData, treatmentId, leadId, handleClickAwayLister } = args;
    const { paymentMethod } = data;
    const hasMultiplePayments = depositPaymentData?.length > 1;
    const payload = { data, depositPaymentData, isEdit: hasMultiplePayments, treatmentId, leadId, handleClickAwayLister };

    const deletePayment = () => {
        dispatch(deleteTreatmentPayment({
            treatmentId,
            leadId,
            paymentId: depositPaymentData?.[1]?.id,
            handleSuccess: () => clickAwayListener(handleClickAwayLister, leadId)
        }));
    };

    switch (paymentMethod) {
        case 'FINANCE_ONLY':
        case 'FULL_PAYMENT':
            if (hasMultiplePayments) {
                deletePayment();
            } else {
                clickAwayListener(handleClickAwayLister, leadId);
            }
            break;
        case 'DEPOSIT_AND_GOCARDLESS':
        case 'DEPOSIT_AND_STRIPE':
            handleCreateProcessorPayment({ ...payload });
            break;
        case 'DEPOSIT_AND_BALANCE':
            handleCreatePayment({ ...payload, paymentType: "TREATMENT" });
            break;
        case 'DEPOSIT_AND_FINANCE':
            handleCreatePayment({ ...payload, paymentType: "FINANCE" });
            break;
        default:
            clickAwayListener(handleClickAwayLister, leadId);
    }
};