import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import {
    Avatar,
    Box,
    MenuItem,
    ListItemText,
    ListItemAvatar
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
// hook
// components
import { Scrollbar } from '../../../components';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// hooks
import { useAuth } from '../../../hooks';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// redux
import { getPracticeTreatmentListData } from '../../../redux/slices/practiceTreatmentList';
import { getPracticeUserList } from '../../../redux/slices/practiceUser';
import { dispatch, useSelector } from '../../../redux/store';
import { addNotes } from '../../../redux/slices/lead';
// validation
import { UserPasswordSchema } from '../../../validations/validations';
// style
import { textFieldStyle } from '../../../styles/SwitchUserPopupStyle';

// ----------------------------------------------------------------------------------------------

const SwitchUserlogin = forwardRef(({ Users, handleNext, type }, ref) => {
    const navigate = useNavigate();
    const { login, isAuthenticated, user } = useAuth();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = {
        password: ''
    };

    const methods = useForm({
        resolver: yupResolver(UserPasswordSchema),
        defaultValues
    });

    const {
        handleSubmit,
        setValue
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onSubmit = async (data) => {
        if (type === 'LeadDetail') {
            const Details = {
                lead: Users.id,
                additional_notes: data.password,
            };
            if (Users?.lead_treatments?.length > 0) {
                Details.treatment = Users?.lead_treatments[0]?.id;
            }
            dispatch(addNotes(Details, practiceId, handleClose, 'leadNote'));
            return;
        }
        try {
            await login(Users?.user?.email, data.password);
            if (isAuthenticated) {
                handleNext(true);
                await dispatch(getPracticeTreatmentListData());
                dispatch(getPracticeUserList(practiceId));
                navigate(PATH_DASHBOARD.root);
            }
            setValue('password', '');
        } catch (error) {
            Object.entries(error).forEach(([, value]) => {
                enqueueSnackbar(value[0], {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
            });
        }
    };

    const handleClose = (status) => {
        if (status === 'finish') {
            handleNext();
        }
    };

    const getData = (value) => {
        if (value === 'primary') {
            return type === 'LeadDetail' ? `${user?.salutation || ''} ${user?.first_name || ''} ${user?.last_name || ''}` : `${Users?.user?.salutation || ''} ${Users?.user?.first_name || ''} ${Users?.user?.last_name || ''}` || '';
        }
        if (value === 'email') {
            return type === 'LeadDetail' ? user?.email : Users?.user?.email || '';
        }
        if (value === 'avatar') {
            return type === 'LeadDetail' ? user?.avatar : Users?.user?.avatar || '';
        }
        return '';
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: '0 -8px' }}>
                <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                    <MenuItem sx={{ mb: '8px', padding: '8px', borderRadius: '8px' }}>
                        <ListItemAvatar sx={{ position: 'relative' }}>
                            <Avatar sx={{ width: '48px', height: '48px' }} src={getData('avatar')} />
                        </ListItemAvatar>
                        <ListItemText
                            primaryTypographyProps={{ typography: 'subtitle2', mb: '4px' }}
                            secondaryTypographyProps={{ typography: 'caption', fontSize: '14px' }}
                            primary={getData('primary')}
                            secondary={getData('email')}
                        />
                    </MenuItem>
                    <RHFTextField
                        sx={textFieldStyle}
                        name='password'
                        multiline={type === 'LeadDetail'}
                        label={type === 'LeadDetail' ? 'Add note' : 'Password'}
                        placeholder={type === 'LeadDetail' ? 'Leave a note..' : 'Enter Password'}
                        InputLabelProps={{ shrink: true }}
                    />
                </Scrollbar>
            </Box>
        </FormProvider>
    );
});

SwitchUserlogin.propTypes = {
    Users: PropTypes.any,
    handleNext: PropTypes.func,
    type: PropTypes.string,
};

export default SwitchUserlogin;
