/* eslint-disable no-nested-ternary */
export const recepientsRadioStyle = {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0 0',
    gap: 1.5
};
export const recepientsSaveButtonStyle = (value) => ({
    width: '114px',
    marginTop: 3,
    display: 'block',
    bgcolor: 'transparent !important',
    border: value === '' ? '1px solid rgba(145, 158, 171, 0.24)' : '1px solid rgba(0, 171, 85, 0.48)',
    borderRadius: '8px',
    color: value !== '' && '#00AB55',
    boxShadow: 'none'
});
export const chipLabelStyle = (isLight) => ({
    mr: '16px',
    height: '24px',
    bgcolor: 'rgba(145, 158, 171, 0.16)',
    color: isLight ? '#212B36' : '#fff',
    fontSize: '14px',
    mt: '3px',
    mb: '3px',
    '&:hover': {
        bgcolor: 'rgba(145, 158, 171, 0.16)'
    }
});
export const statusTypoStyle = (status) => ({
    bgcolor: status === 'DRAFT' ? 'rgba(145, 158, 171, 0.16)' : status === 'SCHEDULED' ? 'rgba(255, 193, 7, 0.16)' : status === 'SENT' ? 'rgba(84, 214, 44, 0.16)' : status === 'CANCELLED' ? 'rgba(255, 72, 66, 0.16)' : status === 'SENDING' ? 'rgba(255, 193, 7, 0.16)' : status === 'PAUSED' ? 'rgba(24, 144, 255, 0.16)' : 'rgba(24, 144, 255, 0.16)',
    padding: '5px 10px',
    color: status === 'DRAFT' ? '#637381' : status === 'SCHEDULED' ? '#B78103' : status === 'SENT' ? '#229A16' : status === 'CANCELLED' ? '#B72136' : status === 'SENDING' ? '#B78103' :  '#0C53B7',
    lineHeight: '20px',
    borderRadius: '6px',
    fontWeight: 600,
    textTransform: 'capitalize'
});
export const noDataTypoStyle = (isLight) => ({
    textAlign: 'center',
    width: '100%',
    color: isLight ? '#212B36' : '#fff',
    fontSize: '18px',
    fontWeight: '700',
    mb: '8px',
    mt: '10px'
});

export const statusTypoCampaignStyle = (status) => ({
    bgcolor: status === 'DRAFT' ? 'rgba(145, 158, 171, 0.16)' : status === 'SCHEDULED' ? 'rgba(255, 193, 7, 0.16)' : status === 'SENT' ? 'rgba(84, 214, 44, 0.16)' : status === 'CANCELLED' ? 'rgba(255, 72, 66, 0.16)' : status === 'SENDING' ? 'rgba(255, 193, 7, 0.16)' : 'rgba(24, 144, 255, 0.16)',
    padding: '5px 10px',
    color: status === 'DRAFT' ? '#637381' : status === 'SCHEDULED' ? '#B78103' : status === 'SENT' ? '#229A16' : status === 'CANCELLED' ? '#B72136' : status === 'SENDING' ? '#B78103' : '#0C53B7',
    borderRadius: '6px',
    textTransform: 'capitalize',
    lineHeight: '26px',
    fontSize: '14px',
    fontWeight: 700
});

export const dotStyle = (status) => ({
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    backgroundColor: status === 'DRAFT' ? '#637381' : status === 'SCHEDULED' ? '#B78103' : status === 'SENT' ? '#229A16' : status === 'CANCELLED' ? '#B72136' : status === 'SENDING' ? '#B78103' : '#0C53B7',
    display: 'inline-block',
    marginRight: '8px'
});

export const previewButtonBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '16px 24px 0',
};

export const tagChipStyle = {
    marginRight: '8px',
    fontSize: '14px',
    fontWeight: 400,
    bgcolor: 'rgba(145, 158, 171, 0.16)',
    border: 0,
    height: '24px'
};

export const editDurationCardStyle = {
    boxShadow: 'none', 
    p: 0, 
    bgcolor: 'transparent', 
    borderRadius: 0, 
    mb: 0
};

export const dateTimeTextStyle = (type) => ({
    fontSize: '18px', 
    fontWeight: '700', 
    mb: '12px', 
    display: type === 'CampaignPreviewPopup' ? 'none' : 'block'
});

export const targetAudienceTextStyle = {
    color: '#637381',
    fontSize: '14px',
    marginRight: '8px',
    fontWeight: '700'
};