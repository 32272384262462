import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Button, AppBar, Typography, Slide } from '@mui/material';
// components
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { SingleScriptPreview } from '../scriptListing';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
} from '../../styles/ScheduleReminder';
import { scriptStackStyle } from '../../styles/CallScripts';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

export default function OpenScriptPopup({ open, onClose, type, headerButtons, dataArray, onOpenAppointmentNotBooked, setLeadId, onOpenAppointmentBooked, setTreatmentId, saveCancelEvent, setSaveCancelEvent }) {

    const handleClose = () => {
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        onClose();
    };

    const handleCancelEvent = () => {
        handleClose();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
        >
            <AppBar sx={commonDialogHeaderStyle}>
                <Typography
                    sx={reminderTitleStyle}
                    variant='h6'
                    component='div'
                >
                    Call Script Preview
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color='inherit'
                            variant='outlined'
                            sx={{ borderColor: 'common.white', color: 'common.white' }}
                            onClick={() => handleCancelEvent()}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={scriptStackStyle}>
                <SingleScriptPreview scriptData={dataArray} onClose={onClose} onOpenAppointmentNotBooked={onOpenAppointmentNotBooked} setLeadId={setLeadId} onOpenAppointmentBooked={onOpenAppointmentBooked} setTreatmentId={setTreatmentId} saveCancelEvent={saveCancelEvent} setSaveCancelEvent={setSaveCancelEvent} />
            </Stack>
        </Dialog>
    );
}

OpenScriptPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    headerButtons: PropTypes.bool,
    dataArray: PropTypes.any,
    onOpenAppointmentNotBooked: PropTypes.any,
    setLeadId: PropTypes.any,
    onOpenAppointmentBooked: PropTypes.any,
    setTreatmentId: PropTypes.any,
    saveCancelEvent: PropTypes.any,
    setSaveCancelEvent: PropTypes.any,
};