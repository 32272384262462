import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Select, MenuItem, Zoom, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { Iconify } from 'src/components';
import { dispatch, useSelector } from '../../../../redux/store';
import { followUpSlice, updateAppointmentDetail } from '../../../../redux/slices/lead';
import { capitalizeFirstWord } from '../../../../utils/capitalizedFunc';
import {
    TableTreatment,
} from '../../../../utils/allTableTab';
import { handleAppointmentTypeIcon } from '../../../../utils/timelineIcon';
import {
    tableCellInnerBoxStyle,
    apptMethodSelectStyle
} from '../../../../styles/NewLeadTableRowStyle';

const ApptMethodField = ({ rowData, tableType, handleSetTableData }) => {
    const theme = useTheme();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { appointmentMethod } = useSelector((state) => state.schema);
    const [value, setValue] = useState(rowData?.appointments?.[0]?.method || '');
    const { enqueueSnackbar } = useSnackbar();
    const treatmentValue = TableTreatment(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    useEffect(() => {
        if (rowData?.appointments?.[0]?.method) {
            setValue(rowData.appointments[0].method);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        const detail = {
            method: newValue
        };
        dispatch(updateAppointmentDetail(practiceId, rowData?.lead_id, rowData?.appointments?.[0]?.id, detail, handleSuccess, ''));
        setValue(newValue);
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        dispatch(followUpSlice(rowData?.lead_id, practiceId, 'LeadDetail', handleClose));
    };

    const handleClose = (data) => {
        handleSetTableData(data);
    };

    return (
        <Box sx={tableCellInnerBoxStyle}>
            <Tooltip
                title={`Appointment method: ${capitalizeFirstWord(value) || 'not found'}`}
                placement='bottom'
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
            >
                {rowData?.appointments?.[0]?.method &&
                    <Select
                        value={value}
                        onChange={handleChange}
                        renderValue={(value) => (
                            <ListItemIcon sx={{ mr: 0 }}>
                                <Iconify
                                    icon={handleAppointmentTypeIcon(value)}
                                    sx={{ color: theme.palette.common.grey, p: '3px' }}
                                    width={24}
                                    height={24}
                                />
                            </ListItemIcon>
                        )}
                        IconComponent={() => null}
                        sx={apptMethodSelectStyle}
                    >
                        {appointmentMethod?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={capitalizeFirstWord(option.label)} />
                            </MenuItem>
                        ))}
                    </Select>
                }
            </Tooltip>
        </Box>
    );
};

ApptMethodField.propTypes = {
    rowData: PropTypes.object,
    tableType: PropTypes.string,
    handleSetTableData: PropTypes.func,
};

export default ApptMethodField;
