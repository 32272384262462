import { createSlice } from '@reduxjs/toolkit';
//
import { uniqBy } from 'lodash';
import moment from 'moment';
import { dispatch, store } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';

// -----------------------------------------------

const initialState = {
    isLoading: false,
    isLoadingFinish: false,
    loadMoreLoading: false,
    campaignsData: null,
    newCampaign: {
        campaignName: null,
        engagement_template_tab: 'All',
        audienceSelected: null
    },
    selectedCampaign: {},
    conditionList: [],
    markAsComplete: false,
    campaignReport: null,
    campaignLeadData: {
        results: []
    },
    campaignData: {},
    campaignCounter: [],
    fileUploadError: '',
    finalCampaignData: {},
    isIncludeArchive: false,
    mediaImages: {
        results: [],
        count: 0
    },
    basicBody: '',
    tempcampaignData: {},
    currentActiveTab: 'Existing audience',
    usedIn: ''
};

const slice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoadingData(state, action) {
            state.isLoadingFinish = action.payload;
        },
        clearNewCampaignData(state, action) {
            state.markAsComplete = false;
            if (!action.payload?.isPreview) {
                state.selectedCampaign = {};
                state.newCampaign = {
                    campaignName: null,
                    engagement_template_tab: 'All'
                };
            }
            state.conditionList = [];
            state.finalCampaignData = {};
            state.isIncludeArchive = false;
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setCampaignListing(state, action) {
            state.isLoading = false;
            state.campaignsData = action.payload;
        },
        saveNewCampaign(state, action) {
            state.isLoading = false;
            state.newCampaign = {
                ...state.newCampaign,
                ...action.payload
            };
        },
        updateCampaignStep(state, action) {
            state.markAsComplete = action.payload;
        },
        updateSelectedCampaign(state, action) {
            state.selectedCampaign = action.payload;
        },
        updateConditionList(state, action) {
            state.conditionList = action.payload;
        },
        setCampaignReport(state, action) {
            state.isLoading = false;
            state.campaignReport = action.payload;
        },
        setCampaignLeadData(state, action) {
            state.isLoading = false;
            state.campaignLeadData = action.payload;
        },
        setCampaignData(state, action) {
            state.isLoading = false;
            state.campaignData = action.payload;
        },
        updateCounter(state, action) {
            state.campaignCounter = action.payload;
        },
        setMediaImages(state, action) {
            state.mediaImages.results = action.payload?.results;
            state.mediaImages.count = action.payload?.count;
        },
        clearMediaImages(state) {
            state.mediaImages.results = [];
            state.mediaImages.count = 0;
        },
        setLoadMoreLoading(state, action) {
            state.loadMoreLoading = action.payload;
        },
        setFileUploadError(state, action) {
            state.fileUploadError = action.payload;
        },
        setFinalCampaignData(state, action) {
            state.finalCampaignData = action.payload;
        },
        setIncludeArchive(state, action) {
            state.isIncludeArchive = action.payload;
        },
        setBasicBody(state, action) {
            state.basicBody = action.payload;
        },
        setTempcampaignData(state, action) {
            state.tempcampaignData = action.payload;
        },
        setCurrentTab(state, action) {
            state.currentActiveTab = action.payload;
        },
        setUsedIn(state, action) {
            state.usedIn = action.payload;
        },
    }
});

export default slice.reducer;

export const { setCampaignListing, reset, startLoading, saveNewCampaign, updateCampaignStep, updateSelectedCampaign, clearNewCampaignData, updateConditionList, setCampaignReport, startLoadingData, setCampaignLeadData, setCampaignData, updateCounter, setMediaImages, setLoadMoreLoading, setFileUploadError, clearMediaImages, setFinalCampaignData, setIncludeArchive, setBasicBody, setTempcampaignData, setCurrentTab, setUsedIn } = slice.actions;

export function getCampaignListing(data, handleClose) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const status = data.status ? `&status__in=${data?.status}` : '';
            const searchQuery = data.searchQuery ? `&name__icontains=${data?.searchQuery}` : '';
            const param = `${limit}${offset}${status}${searchQuery}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/?ordering=-created_at${param}`);
            if (response.status === 200) {
                dispatch(slice.actions.setCampaignListing(response.data));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getCampaignListingCounter(data, handleClose) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const status = data.status && data.status !== 'All' ? `&status__in=${data?.status}` : '';
            const searchQuery = data.searchQuery ? `&name__icontains=${data?.searchQuery}` : '';
            const param = `${limit}${offset}${status}${searchQuery}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/?ordering=-created_at${param}&fields={}`);
            if (response.status === 200) {
                const { campaignCounter } = store.getState().campaign;
                dispatch(slice.actions.updateCounter([...campaignCounter, { filterStatus: data.status, count: response.data.count }]));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

// export function getCounterCampaign(data) {
//     return async () => {
//         try {
//             const limit = data?.limit ? `&limit=${data?.limit}` : '';
//             const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
//             const status = data.status ? `&lead_treatments__status__in=${data?.status}` : '';
//             const search = `${limit}${offset}${status}`;
//             const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/?ordering=-created_at${search}&fields={}`);
//             if (response.status === 200) {
//                 const { counter } = store.getState().lead;
//             } else {
//                 dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
//             }
//         } catch (error) {
//             dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
//         }
//     }
// }

export function deleteCampaign(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data.id}/`, 'delete');
            if (response.status === 204) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Campaign deleted successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function createCampaign(data, handleClose, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Campaign created successfully' || 'Something went wrong' }));
                dispatch(updateSelectedCampaign(response.data));
                dispatch(slice.actions.setCampaignData(response.data));
                if (data?.audience) {
                    dispatch(startLoadingData(false));
                    dispatch(updateCampaignStep(false));
                }
                if (type === 'duplicateEvent') {
                    handleClose();
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                if (data?.audience) {
                    dispatch(startLoadingData(false));
                    dispatch(updateCampaignStep(false));
                }
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function previewRecepientsCampaign(data, id, fieldParams) {
    return async () => {
        try {
            const limit = fieldParams?.limit ? `&limit=${fieldParams?.limit}` : '';
            const offset = (fieldParams?.offset === 0 || fieldParams?.offset) ? `&offset=${fieldParams?.offset}` : '';
            const param = `${limit}${offset}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${id}/preview_recepients/?ordering=-created_at${param}`, 'post', data);
            if (response.status === 200) {
                dispatch(slice.actions.setCampaignLeadData(response.data));
                dispatch(startLoadingData(false));
                dispatch(updateCampaignStep(false));
            } else {
                dispatch(startLoadingData(false));
                dispatch(updateCampaignStep(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateCampaign(data, id, handleClose, handleSaveClose, sendCampaign) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Campaign updated successfully' || 'Something went wrong' }));
                dispatch(startLoadingData(false));
                dispatch(updateCampaignStep(false));
                if (data?.audience) {
                    dispatch(startLoadingData(false));
                }
                if (handleSaveClose) {
                    handleClose(sendCampaign, response?.data?.id);
                } else {
                    handleClose(response?.data);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                dispatch(startLoadingData(false));
                dispatch(updateCampaignStep(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function cancelCampaign(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data.id}/cancel/`, 'post');
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Campaign cancelled successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function scheduleCampaign(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data.id}/schedule/`, 'post');
            if (response.status === 200) {
                handleClose('schedule');
                dispatch(updateError({ success: true, message: 'Campaign scheduled successfully', id: data.id }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getCampaignReport(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data?.id}/report/`);
            if (response.status === 200) {
                dispatch(slice.actions.setCampaignReport(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function sendEngagementTestMail({ practiceId, tempLateId, detail, callback }) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-templates/${tempLateId}/send_test/`, 'post', detail);
            if (response.status === 200) {
                callback({ success: true, message: 'Test email send successfully' });
            } else {
                callback({ success: false, message: response.data });
            }
        } catch (error) {
            dispatch(updateError(error || 'Something want wrong'));
        }
    };
}

export function getMediaImages({ practiceId, shouldReset, limit = 50, offset = 0 }) {
    return async () => {
        try {
            dispatch(setLoadMoreLoading(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/media/?offset=${offset}&limit=${limit}&ordering=-created_at`);
            const { mediaImages } = store.getState().campaign;
            if (response.status === 200) {
                if (shouldReset) {
                    dispatch(slice.actions.setMediaImages({ results: response.data.results, count: response.data?.count }));
                    dispatch(setLoadMoreLoading(false));
                    return;
                }
                dispatch(setLoadMoreLoading(false));
                const images = [...mediaImages.results, ...response.data.results];
                const imagesList = uniqBy(images, 'id');
                const sortedImages = imagesList?.sort((a, b) => moment(b.created_at, 'DD-MM-YYYY HH:mm:ss') - moment(a.created_at, 'DD-MM-YYYY HH:mm:ss'));
                dispatch(slice.actions.setMediaImages({ results: sortedImages, count: response.data?.count }));
            } else {
                dispatch(setLoadMoreLoading(false));
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(setLoadMoreLoading(false));
            dispatch(updateError(error || 'Something want wrong'));
        }
    };
}

export function deleteImage({ practiceId, id }) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/media/${id}`, 'delete');
            if (response.status === 204) {
                dispatch(getMediaImages({ practiceId, limit: 50, offset: 0, shouldReset: true }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError(error || 'Something want wrong'));
        }
    };
}

export function pauseCampaign(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data.id}/pause/`, 'post');
           
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Campaign paused successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}


export function resumeCampaign(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/campaigns/${data.id}/resume/`, 'post');
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Campaign resumed successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
