/* eslint-disable no-nested-ternary */
import { AddTaskReminders } from 'src/constants/AddTaskReminder';
import { LEAD_REFERRAL_TYPES, LEAD_SOURCE_TYPES } from '../constants/LeadSourceConst';

export const getReferralData = (referral) => {
    const filteredReferralTypes = LEAD_REFERRAL_TYPES.filter(item => referral?.includes(item.value));
    const missingValues = referral.filter(value => !LEAD_REFERRAL_TYPES.some(item => item.value === value));
    missingValues.forEach(value => {
        filteredReferralTypes.push({ label: value, value, icon: null });
    });
    return filteredReferralTypes;
};

export const getSourceTypeData = (type) => {
    const filteredReferralTypes = LEAD_SOURCE_TYPES.filter(item => type?.includes(item.value));
    const missingValues = type?.filter(value => !LEAD_SOURCE_TYPES.some(item => item.value === value));
    missingValues?.forEach(value => {
        filteredReferralTypes.push({ label: value, value, icon: null });
    });
    return filteredReferralTypes;
};

export const getReminderMethodData = (referral) => {
    const filteredReferralTypes = AddTaskReminders.filter(item => referral?.includes(item.value));
    const missingValues = referral.filter(value => !AddTaskReminders.some(item => item.value === value));
    missingValues.forEach(value => {
        filteredReferralTypes.push({ label: value, value, icon: null });
    });
    return filteredReferralTypes;
};

export const getListData = (enumValues) => {
    const data = enumValues?.map((value) => {
        const label = generateLabel(value);
        return { value, label };
    });
    return data;

};

export function generateLabel(value) {
    const words = value.toLowerCase().split('_');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}

export const getReasonsListData = (enumValues) => {

    const filteredValues = enumValues?.filter(value => value !== 'UNABLE_TO_CONTACT');
    const sortedValues = filteredValues.sort();
    const data = sortedValues.map(value => ({
        value,
        label: value === 'NHS_WAITING_LIST' ? 'NHS waiting list' : value === 'WANT_NHS' ? 'Want NHS' : generateReasonsLabel(value)
    }));
    return data;
};
export const getSalutationOptions = (enumValues) => {
    const data = enumValues.map(value => ({
        value,
        label: generateReasonsLabel(value)
    }));
    return [{ value: '', label: '\u00A0' }, ...data];
};

export function generateReasonsLabel(value) {
    const words = value.toLowerCase().split('_');
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ');
}

export const getContactMethodListData = (enumValues) => {
    const desiredOrder = ['EMAIL', 'PHONE', 'SMS'];
    const specifiedOptions = [];
    const otherOptions = [];
    enumValues?.forEach(value => {
        if (desiredOrder?.includes(value)) {
            specifiedOptions.push(value);
        } else {
            otherOptions.push(value);
        }
    });
    const sortedSpecifiedOptions = specifiedOptions.sort((a, b) =>
        desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
    );
    const data = [...sortedSpecifiedOptions, ...otherOptions].map(value => ({
        value,
        label: generateContactMethodLabel(value)
    }));
    return data;
};

function generateContactMethodLabel(value) {
    if (value === 'SMS') {
        return 'SMS';
    }
    const words = value.toLowerCase().split('_');
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ');
}

export const getFinanceTermOptions = (enumValues) => {
    const data = enumValues.map(value => ({
        value,
        label: `${value} months`
    }));
    return data;
};
