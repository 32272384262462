import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Card, Typography, Button, Box, ButtonGroup, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { isEmpty } from 'lodash';
import { generateReasonsLabel } from 'src/utils/getReferralData';
import { tableCellStyle, tableHeaderStyle } from 'src/styles/MetaSourceStyle';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { useSelector, dispatch } from '../../../redux/store';
import { followUpAppointmentHandler, updateBookingSteps, clearLead } from '../../../redux/slices/lead';
import { getCallScriptSection } from '../../../redux/slices/callScript';
import { FormProvider } from '../../../components/hook-form';
import { CallScriptListing } from '../../../components/followup-callScript';
import {
    OpenAppointmentNotBookedPopup,
    OpenAppointmentBookedPopup
} from '../../../components/fullScreenPopUps';
import { useResponsive, useToggle } from '../../../hooks';
import { Iconify } from '../../../components';
import { PhoneIconButton, followUpBoxStyle, followUpTitleStyle, followUpSubTitleStyle, followUpCardStyle, numberLinkStyle, numberTextStyle, callScriptButtonStyle, textStyle, buttonGroupStyle, unableContactButtonStyle, appointmentButtonStyle, bookingButtonStyle } from '../../../styles/FollowUp';

const FollowUp = forwardRef(({ handleNext, handleClose, followLeadForScript }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead, tableRowLead } = useSelector((state) => state.lead);
    const [outcome, setOutcome] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [callScriptActive, setCallScriptActive] = useState(false);
    const { callScriptData } = useSelector((state) => state.callScript);
    const metaData = followLead && followLead?.lead_metadata;
    const leadSourceMetaData = followLead && followLead?.lead_source && followLead?.lead_source?.metadata;
    const [leadId, setLeadId] = useState('');
    const [treatmentId, setTreatmentId] = useState('');
    const [callScriptReloadPayLoad, setCallScriptReloadPayLoad] = useState({});
    const defaultValues = {
        state: 0
    };

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const { openCallScriptFromLeadPage } = useSelector((state) => state.callScript);

    useEffect(() => {
        if (practiceId) {
            dispatch(getCallScriptSection({ practiceId, treatment: getTreatmentName(tableRowLead && tableRowLead.treatment_name) || '', isTemplate: false, category: 'OUTBOUND' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    const methods = useForm({
        defaultValues,
    });

    const onSubmit = (data) => {
        if (data.state > 0) {
            handleNext(true);
        }
        if (data.state < 1) {
            enqueueSnackbar('Please click one of outcome', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    };

    const {
        handleSubmit,
        setValue,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const isMobile = useResponsive('up', 'sm');

    const appoimentHandler = (data) => {
        setValue('state', 1);
        dispatch(followUpAppointmentHandler(data));
        setOutcome(data);
        dispatch(updateBookingSteps(data));
    };

    useEffect(() => {
        if (openCallScriptFromLeadPage && callScriptData?.results?.length > 0) {
            setCallScriptActive(true);
        }

    }, [openCallScriptFromLeadPage, callScriptData]);

    const openCallScripts = () => {
        setCallScriptActive(true);
    };

    const {
        toggle: isAppointmentNotBooked,
        onOpen: onOpenAppointmentNotBooked,
        onClose: onCloseAppointmentNotBooked
    } = useToggle();

    const {
        toggle: isAppointmentBooked,
        onOpen: onOpenAppointmentBooked,
        onClose: onCloseAppointmentBooked
    } = useToggle();

    const onOpenAppointmentNotBookedEvent = (data) => {
        onOpenAppointmentNotBooked();
        setCallScriptReloadPayLoad(data);
    };

    const onOpenAppointmentBookedEvent = (data) => {
        onOpenAppointmentBooked();
        setCallScriptReloadPayLoad(data);
    };

    const appointmentNotBookedClose = () => {
        onCloseAppointmentNotBooked();
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        handleClose('finish', callScriptReloadPayLoad);
    };

    const onAppointmentNotBookClose = () => {
        onCloseAppointmentNotBooked();
    };

    const appointmentBookedClose = () => {
        onCloseAppointmentBooked();
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        handleClose('finish', callScriptReloadPayLoad);
    };

    const onAppointmentBookClose = () => {
        onCloseAppointmentBooked();
    };

    const getTreatmentName = (name) => {
        if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.treatment;
        return name;
    };

    return (
        <Box sx={followUpBoxStyle}>
            <FormProvider methods={methods}>
                <Typography
                    variant="h3"
                    sx={followUpTitleStyle}
                >
                    Call <span style={{ textTransform: 'capitalize' }}>{followLead && followLead.first_name || ''} {followLead && followLead.last_name || ''}</span> to book an appointment for {getTreatmentName(tableRowLead && tableRowLead.treatment_name) || ''}
                </Typography>
                {!isEmpty(followLead?.lead_contact?.note) && <Typography
                    variant="p"
                    sx={followUpSubTitleStyle}
                >
                    {`“${followLead.lead_contact.note}”`}
                </Typography>}
            </FormProvider>
            {((metaData && metaData.length !== 0 && metaData.some(el => el.key && el.key.endsWith('?'))) || (leadSourceMetaData && Object.entries(leadSourceMetaData)?.length !== 0 && Object.entries(leadSourceMetaData).some(([key]) => key && key.endsWith('?')))) && <Card sx={{ mb: 3, p: '12px 24px' }}>
                <List sx={{ maxWidth: '100%', m: '0 0 -24px', p: 0 }}>
                    {metaData && metaData?.map((el, index) => {
                        const { key, value } = el;
                        if (key.endsWith('?')) {
                            return (
                                <ListItem alignItems="flex-start" key={index} sx={{ p: '8px 0' }}>
                                    <ListItemAvatar sx={{ mt: '1px' }}>
                                        <Iconify icon={'charm:info'} color={'#1890FF'} width={24} height={24} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={key}
                                        secondary={`— ${value}`}
                                        sx={{ ...textStyle, textAlign: 'left' }}
                                    />
                                </ListItem>
                            );
                        }
                        return null;
                    })}
                    {leadSourceMetaData && Object.entries(leadSourceMetaData).map(([key, value], index) => {
                        if (key.endsWith('?')) {
                            return (
                                <ListItem alignItems="flex-start" key={index} sx={{ p: '8px 0' }}>
                                    <ListItemAvatar sx={{ mt: '1px' }}>
                                        <Iconify icon={'charm:info'} color={'#3366FF'} width={24} height={24} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={key}
                                        secondary={`— ${value}`}
                                        sx={{ ...textStyle, textAlign: 'left' }}
                                    />
                                </ListItem>
                            );
                        }
                        return null;
                    })}
                </List>
            </Card>}
            <Card sx={{ ...followUpCardStyle, width: callScriptActive ? '100%' : 'auto' }} >
                <a
                    href={`tel: ${followLead && followLead.lead_contact && followLead.lead_contact.phone}`}
                    style={numberLinkStyle}
                >
                    <PhoneIconButton>
                        <Iconify style={{ color: '#54D62C' }} icon={'bxs:phone'} />
                    </PhoneIconButton>
                    {!isEmpty(followLead?.lead_contact?.phone) && <Typography variant="h1" color={isLight ? 'grey.800' : '#fff'}
                        sx={numberTextStyle}
                    >
                        {followLead.lead_contact.phone}
                    </Typography>}
                </a>
                {!callScriptActive ? <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Box sx={{ mb: '24px', display: callScriptData?.results?.length > 0 ? 'flex' : 'none !important' }}>
                        <Button sx={callScriptButtonStyle} onClick={() => openCallScripts()}>
                            <Iconify style={{ marginRight: '10px', width: 24, height: 24 }} icon={'eva:menu-2-fill'} /> Open Call Scripts
                        </Button>
                    </Box>

                    {metaData && metaData.length !== 0 && <Card sx={{ width: "100%", mb: 3 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={tableHeaderStyle(isLight)}>Lead Data</Typography>
                            <Typography sx={tableHeaderStyle(isLight)}>Value</Typography>
                        </Box>
                        {metaData && metaData?.map((el) => (
                            <Box sx={{ display: 'flex' }} key={el?.key}>
                                <Typography sx={tableCellStyle}>{el?.key ? generateReasonsLabel(el.key) : ""}</Typography>
                                <Typography sx={tableCellStyle}>{el?.value ? generateReasonsLabel(el.value) : ""}</Typography>
                            </Box>
                        ))}
                    </Card>}

                    <Typography variant="h6" sx={textStyle}>Select an outcome from the call </Typography>
                    <ButtonGroup orientation={!isMobile ? 'vertical' : 'horizontal'} sx={buttonGroupStyle}>
                        <Button
                            name={'state'}
                            onClick={() => { appoimentHandler('Unable to contact'); }}
                            sx={unableContactButtonStyle(outcome)}
                        ><Iconify style={{ marginRight: '10px', width: 20, height: 20 }} icon={'eva:close-circle-fill'} /> Unable to contact</Button>
                        <Button
                            onClick={() => { appoimentHandler('Appointment Not Booked'); }}
                            sx={appointmentButtonStyle(outcome)}
                        ><Iconify style={{ marginRight: '10px', width: 20, height: 20 }} icon={'ant-design:calendar-filled'} /> Appointment not booked</Button>
                        <Button
                            name={'state'}
                            onClick={() => { appoimentHandler('Booking Details'); }}
                            sx={bookingButtonStyle(outcome)}
                        ><Iconify style={{ marginRight: '10px', width: 20, height: 20 }} icon={'akar-icons:circle-check-fill'} /> Book an appointment</Button>
                    </ButtonGroup>
                </Box> :
                    <Box sx={{ width: '100%' }}>
                        <CallScriptListing scriptList={callScriptData?.results} setCallScriptActive={setCallScriptActive} onClose={handleClose} followLead={followLeadForScript || followLead} onOpenAppointmentNotBooked={onOpenAppointmentNotBookedEvent} onOpenAppointmentBooked={onOpenAppointmentBookedEvent} setLeadId={setLeadId} setTreatmentId={setTreatmentId} />
                    </Box>}
            </Card>
            {isAppointmentNotBooked && <OpenAppointmentNotBookedPopup
                open={isAppointmentNotBooked}
                headerButtons
                onClose={appointmentNotBookedClose}
                leadId={leadId}
                onAppointmentNotBookClose={onAppointmentNotBookClose}
            />}
            {isAppointmentBooked && <OpenAppointmentBookedPopup
                open={isAppointmentBooked}
                headerButtons
                onClose={appointmentBookedClose}
                leadId={leadId}
                treatmentId={treatmentId}
                onAppointmentBookClose={onAppointmentBookClose}
            />}
        </Box>
    );
});

FollowUp.propTypes = {
    handleNext: PropTypes.func,
    handleClose: PropTypes.func,
    followLeadForScript: PropTypes.any,
};

export default FollowUp;
