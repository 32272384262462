import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { Box, Typography, Table, TableBody, TableContainer, TableCell, TableRow, MenuItem, Dialog, DialogTitle, DialogActions, Button, Zoom, Tooltip, Rating } from '@mui/material';
// components
import { TableHeadCustom, TableMoreMenu } from './table';
import { OpenReSchedulePopup, OpenAttachmentsPopup } from './fullScreenPopUps';
import NoAppointmentComponent from './NoAppointmentComponent';
// redux
import { updateError } from '../redux/slices/user';
import { useSelector, dispatch } from '../redux/store';
import { cancelAppointment } from '../redux/slices/lead';
import { generatingFeedbackUrl, updateGeneratedUrl } from '../redux/slices/feedbackReview';
// hooks/constants/utils
import { useTable, useToggle, useCopyToClipboard } from '../hooks';
import { attendedAppointmentsLabels } from '../constants/LeadDetailConst';
import { getAssignees } from '../utils/multiAsignees';
// styles
import { grey } from '../theme/palette';
import { cancelBtnStyle, yesBtnStyle, dateTextStyle, treatmentTextStyle, nameTextStyle, commentTextStyle, dialogTitle, dialogActions, normalWhiteSpace, appointmentTableContainer, appointmentTableHead } from '../styles/UpcommingAppointments';

const CustomMenuItem = ({ onClick, label, handleCopy, isButton }) => <MenuItem onClick={onClick} sx={normalWhiteSpace}>
    {label} {isButton && <Button variant='contained' onClick={handleCopy} sx={{ ml: 1, fontSize: '13px', minWidth: '48px' }}>Copy</Button>}
</MenuItem>;

const AttendedAppointmentsOverview = ({ leadAppointment, treatmentData, leadDetail, treatments }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [tableData, setTableData] = useState(leadAppointment);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { generatedUrl } = useSelector((state) => state.feedbackReview);
    const rowData = [];
    const upcomingRef = useRef();
    const [openMenu, setOpenMenuActions] = useState(null);
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [appoinmentID, setAppoinmentID] = useState(null);
    const [appoinmentSelectData, setAppoinmentSelectData] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef();
    const { copy } = useCopyToClipboard();
    const now = moment().format();

    useEffect(() => {
        if (leadAppointment) {
            leadAppointment?.map((item) => {
                const latestData = item;
                rowData.push({
                    assignees: item?.assignees || [],
                    Date: moment(latestData?.datetime, 'DD-MM-YYYY').format('ddd, D MMM'),
                    Time: moment(latestData?.datetime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A'),
                    Treatment: treatments?.find((ele) => ele.id === item?.lead_treatment)?.treatment || treatmentData?.treatment,
                    method: latestData?.method,
                    Practioner: latestData?.assignees?.length > 0 ? practiceUser?.results?.filter(el => latestData?.assignees?.some((ele) => ele === el.id) || latestData?.assignees?.some((ele) => ele?.id === el.id)) : [],
                    Status: latestData?.status,
                    appointment_id: latestData?.id,
                    datetime: latestData?.datetime,
                    contacted_via: latestData?.contacted_via,
                    type: latestData?.type || '',
                    note: latestData?.note || '',
                    selectedTreatmentData: treatments?.find((ele) => ele.id === item?.lead_treatment) || treatmentData,
                    appointmentFeedback: latestData?.appointment_feedback,
                });
                return item;
            });
            setTableData(rowData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadAppointment]);

    const {
        onSelectAllRows,
    } = useTable();

    const handleOpenMenu = (event, row) => {
        dispatch(updateGeneratedUrl(''));
        setOpenMenuActions(event.currentTarget);
        upcomingRef.current = row?.appointment_id;
    };

    const cancelAppointmentAPI = () => {
        dispatch(cancelAppointment(practiceId, leadDetail?.id, appoinmentID, handleSuccess));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setStartConfirmation(false);
    };

    const {
        toggle: openReSchedule,
        onOpen: onOpenReSchedule,
        onClose: onCloseReSchedule
    } = useToggle();

    const {
        toggle: openAttachments,
        onOpen: onOpenAttachment,
        onClose: onCloseAttachment
    } = useToggle();

    useEffect(() => {
        const htmlElement = document.querySelector('body');
        if (openAttachments) {
            htmlElement.classList.add('overflow-hidden');
        } else {
            htmlElement.classList.remove('overflow-hidden');
        }
    }, [openAttachments]);

    const handlePopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenReSchedule();
            setOpenMenuActions(null);
        }, 800);
    };

    const attachmentPopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenAttachment();
            setOpenMenuActions(null);
        }, 800);
    };

    const handleGenerateUrl = (appointmentId) => {
        const params = {
            practiceId,
            leadId: leadDetail?.id,
            id: appointmentId,
        };
        dispatch(generatingFeedbackUrl(params));
    };

    const handleCopy = (e) => {
        e.stopPropagation();
        dispatch(updateError({ success: true, message: 'Feedback link copied successfully' }));
        copy(generatedUrl);
    };

    const handleSelectAllRows = (checked) => onSelectAllRows(checked);

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>Attended appointments</Typography>
            </Box>
            <Box>
                {leadAppointment && leadAppointment?.length > 0 ?
                    <TableContainer sx={appointmentTableContainer}>
                        <Table>
                            <TableHeadCustom
                                headLabel={attendedAppointmentsLabels}
                                rowCount={tableData?.length}
                                sx={appointmentTableHead}
                                onSelectAllRows={handleSelectAllRows}
                            />
                            <TableBody>
                                {tableData !== undefined && tableData && tableData?.map((row, index) => (
                                    <TableRow key={index} selected={false} sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.12)', ' .MuiTableCell-body': { p: '16px 12px !important' } }}>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={dateTextStyle}>
                                                    {row?.Date}
                                                    <span style={{ color: grey[700], display: 'block' }}>{row?.Time}</span>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={treatmentTextStyle}>
                                                    {row?.Treatment}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={nameTextStyle}>
                                                    <Tooltip title={getAssignees({ assignees: row?.Practioner })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                        <span style={{ textTransform: 'capitalize', marginRight: row?.Practioner?.[0]?.user?.salutation ? '4px' : '0' }}>{`${row?.Practioner?.[0]?.user?.salutation || ''}`.toLowerCase()}</span> {row.Practioner?.[0]?.user?.first_name} {row.Practioner?.[0]?.user?.last_name} {row?.Practioner?.length > 1 ? `+ ${row?.Practioner?.length - 1}` : ''}
                                                    </Tooltip>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {row?.appointmentFeedback?.rating && <Box sx={{ mb: '4px' }}>
                                                <Rating name='size-small' defaultValue={row?.appointmentFeedback?.rating} size='small' readOnly />
                                            </Box>}
                                            <Typography variant='span' sx={commentTextStyle}>
                                                {row?.appointmentFeedback?.comment || ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right' >
                                            {row.Status !== 'CANCELLED' && <TableMoreMenu
                                                open={upcomingRef.current === row.appointment_id && openMenu}
                                                type={'AppointmentRow'}
                                                onOpen={(e) => handleOpenMenu(e, row)}
                                                onClose={() => setOpenMenuActions(null)}
                                                actions={
                                                    <Box>
                                                        {row.Status !== 'CANCELLED' &&
                                                            <CustomMenuItem label='Edit' onClick={() => handlePopup(row.appointment_id, row)} />
                                                        }
                                                        <CustomMenuItem label='View detail' onClick={() => attachmentPopup(row.appointment_id, row)} />
                                                        {moment(row?.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now
                                                            && <CustomMenuItem label={'Generate feedback url'} onClick={() => handleGenerateUrl(row.appointment_id)} isButton={generatedUrl !== ''} handleCopy={handleCopy} />}
                                                    </Box>
                                                }
                                            />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <NoAppointmentComponent />
                }
            </Box>
            <Dialog open={startConfirmation} onClose={() => setStartConfirmation(false)}>
                <DialogTitle sx={dialogTitle}>Are you sure you want to cancel the appointment ?</DialogTitle>
                <DialogActions sx={dialogActions}>
                    <Button sx={cancelBtnStyle} onClick={() => setStartConfirmation(false)}>Cancel</Button>
                    <Button type='submit' sx={yesBtnStyle} onClick={() => cancelAppointmentAPI()}>Yes</Button>
                </DialogActions>
            </Dialog>
            {openReSchedule && <OpenReSchedulePopup
                open={openReSchedule}
                headerButtons
                onClose={() => onCloseReSchedule()}
                treatmentData={treatmentData}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
                appoinmentSelectData={appoinmentSelectData}
                pastAppointment={'pastAppointment'}
            />}
            {openAttachments && <OpenAttachmentsPopup
                open={openAttachments}
                headerButtons
                onClose={() => onCloseAttachment()}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
            />}
        </Box>
    );
};

AttendedAppointmentsOverview.propTypes = {
    leadAppointment: PropTypes.array,
    treatmentData: PropTypes.array,
    leadDetail: PropTypes.object,
    treatments: PropTypes.array,
};
CustomMenuItem.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    handleCopy: PropTypes.func,
    isButton: PropTypes.bool,
};

export default AttendedAppointmentsOverview;