/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-return */
/* eslint-disable no-console */
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { filter, isEmpty } from 'lodash';
import { leadFields, leadFlatFields, reminderFields } from 'src/constants/fields';
import { current } from 'immer';
import { parseV2ToV1 } from 'src/utils/fieldUtils';
import { dispatch, store } from '../store';
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';
import { errorHandler } from '../../utils/errorHandler';
import { localStorageRemoveItem } from '../../utils/storage-available';
import { getCurrentTreatmentIdV2, getPracticeId } from './clincheck';
import { creteTreatmentPaymentFlow } from './deposit';
import { setLoader } from './loader';

const initialState = {
    isLoading: false,
    isLoadingFinish: false,
    loadingApis: [],
    isLoadingDepositPending: false,
    loadingForLead: false,
    loadingForClincheckLead: false,
    loadingForExport: false,
    archivedLoading: false,
    error: '',
    message: '',
    createLead: null,
    createLeadForConsent: {},
    newLeadDetail: {
        personalDetail: null
    },
    lead: {
        results: []
    },
    newDashLead: {
        results: []
    },
    newStatusLead: {
        results: []
    },
    consultantAttendStatusLead: {
        results: []
    },
    consultantClinCheckStatusLead: {
        results: []
    },
    consultantBookedStatusLead: {
        results: []
    },
    failedToAttendStatusLead: {
        results: []
    },
    closedStatusLead: {
        results: []
    },
    treatmentStartedStatusLead: {
        results: []
    },
    consentLink: null,
    followLead: null,
    isAppointmentBooked: null,
    searchedLead: null,
    attendance: null,
    BookingAttendance: null,
    leadDetail: null,
    tableRowLead: null,
    archivedLead: null,
    leadNotes: null,
    leadNotesWithFilter: null,
    leadReminder: null,
    leadFlowReminder: {
        results: [],
        count: 0,
    },
    activeTab: 0,
    followUpLead: null,
    markAsComplete: false,
    allReminders: null,
    taskPageData: null,
    contactLead: true,
    archivedList: [],
    appointmentNotes: '',
    taskTableData: {
        limit: 10,
        offset: 0,
        filter: '',
        assignUser: '',
        tasks: {
            limit: 10,
            offset: 0,
            filter: '',
            assignUser: '',
        }
    },
    isAssigned: false,
    upComingData: null,
    isLeadArchived: false,
    paymentComplete: [],
    checkoutListing: [],
    leadSummaryListing: [],
    leadNotesArr: null,
    leadStatusSummaryListing: [],
    autoMatedMessages: [],
    dashNewContact: {
        count: '0',
        labelCounter: 0,
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'NEW',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    dashNewContactForWidget: {
        date: [],
    },
    dashContact: {
        count: 'All',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'All',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    alignerTable: {
        count: 'All',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'All',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: [],
        bookedWith: [],
    },
    noTaskTableData: {
        count: 'All',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'All',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    newContact: {
        count: '0',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'NEW',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    attendContact: {
        count: '0',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'CONSULTATION_ATTENDED',
        tag: [],
        filterTags: [],
        referral: '',
        bookedWith: [],
        depositPendingStatus: '',
        assignee: []
    },
    clincheckContact: {
        count: '0',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'CONSULTATION_ATTENDED',
        tag: [],
        filterTags: [],
        referral: '',
        bookedWith: [],
        depositPendingStatus: '',
        assignee: []
    },
    bookedContact: {
        count: 'All',
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'CONSULTATION_BOOKED',
        tag: [],
        filterTags: [],
        referral: '',
        bookedWith: [],
        depositPendingStatus: '',
        assignee: []
    },
    failedToAttendContact: {
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'FAILED_TO_ATTEND_CONSULTATION',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    closedContact: {
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'CLOSED',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: []
    },
    treatmentStarted: {
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'created_at',
        search: '',
        treatment: [],
        date: [],
        status: 'TREATMENT_STARTED',
        tag: [],
        filterTags: [],
        referral: '',
        category: '0',
        categoryName: '',
        bookedWith: [],
        consultationBookedWith: [],
        apptType: '',
        assignee: []
    },
    archivedContact: {
        pageNumber: 0,
        perPage: 10,
        order: 'desc',
        orderValue: 'archive_at',
        search: '',
        treatment: [],
        date: [],
        status: 'archive',
        tag: [],
        filterTags: [],
        referral: '',
        assignee: [],
        archiveReason: ''
    },
    leadTaskCounter: 0,
    leadTaskTab: '',
    counter: [],
    taskCounter: [],
    taskLeadCounter: [],
    taskFlowCounter: [],
    separateTaskCounter: [],
    taskSearchLead: null,
    archiveLeadTags: [],
    contactedLead: null,
    leadTreatmentsListing: {
        results: []
    },
    dashboardTaskPageFilter: 'Today',
    leadDetailTaskPageFilter: 'Today',
    taskPageFilter: 'Today',
    taskDetail: {},
    isTasksCompleted: false,
    dateFormat: (localStorage.getItem('dateFormat') || 'human_readable'),
    dateArchiveFormat: (localStorage.getItem('dateArchiveFormat') || 'human_readable'),
    dateAppointmentFormat: (localStorage.getItem('appointmentFormat') || 'human_readable'),
    consultationDateFormat: (localStorage.getItem('consultationDateFormat') || 'human_readable'),
    dateLastContactFormat: (localStorage.getItem('lastContactFormat') || 'human_readable'),
    dateAttendedFormat: (localStorage.getItem('attendedFormat') || 'human_readable'),
    multiLead: {
        leadId: [],
        type: '',
        loading: false
    },
    tableType: '',
    duplicateMultiLead: {
        leadId: [],
        type: '',
        loading: false
    },
    mergeTags: (localStorage.getItem('MergeTagsData') && JSON.parse(localStorage.getItem('MergeTagsData')) || []),
    pageDetail: {},
    currentTreatmentData: null,
    attendedActiveTab: '',
    treatmentDepositStatus: false,
    treatmentLoading: false,
    todoListFilter: '',
    chatbotConversation: null,
    appointmentDateValue: null,
    action: null,
    unassignedUser: false,
    appointmentUserValue: null,
    sendReminderDuringLeadCreate: false

};

export const slice = createSlice({
    name: 'lead',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        newLead(state, action) {
            state.lead = action.payload;
        },
        leadPage(state, action) {
            state.pageDetail = action.payload;
        },
        startLoading(state) {
            state.isLoading = true;
        },
        startLoadingData(state, action) {
            state.isLoadingFinish = action.payload;
        },
        setLoadingApis(state, action) {
            state.loadingApis = action.payload;
        },
        setLoadingForExport(state, action) {
            state.loadingForExport = action.payload;
        },
        setLoadingForLead(state, action) {
            state.loadingForLead = action.payload;
        },
        setLoadingForClincheckLead(state, action) {
            state.loadingForClincheckLead = action.payload;
        },
        startArchivedLoading(state, action) {
            state.archivedLoading = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload || 'Something went wrong';
        },
        saveLead(state, action) {
            state.isLoading = false;
            state.createLead = action.payload;
        },
        saveConsentLead(state, action) {
            state.isLoading = false;
            state.createLeadForConsent = action.payload;
        },
        saveTreatment(state, action) {
            state.isLoading = false;
            state.createLead = {
                ...state.createLead,
                lead_treatment: action.payload
            };
        },
        saveNewLeadDetail(state, action) {
            state.isLoading = false;
            state.newLeadDetail = {
                ...state.newLeadDetail,
                ...action.payload
            };
        },
        savePersonalLeadDetails(state, action) {
            state.newLeadDetail = {
                ...state.newLeadDetail,
                personalDetail: {
                    ...(state.newLeadDetail?.personalDetail ?? {}),
                    ...action.payload
                }
            }
        },
        saveAppointment(state, action) {
            state.isLoading = false;
            state.createLead = {
                ...state.createLead,
                lead_appointment: action.payload
            };
        },
        clearLead(state) {
            state.createLead = {
                personalDetail: null
            };
            state.newLeadDetail = {
                personalDetail: null
            };
            state.markAsComplete = false;
            state.followLead = null;
            state.BookingAttendance = null;
            state.activeTab = 0;
            state.followUpLead = null;
        },
        clearConsentData(state) {
            state.createLeadForConsent = {};
            state.consentLink = null;
        },
        clearTableData(state) {
            state.lead = {
                results: []
            };
            state.newDashLead = {
                results: []
            };
            state.newStatusLead = {
                results: []
            };
            state.consultantAttendStatusLead = {
                results: []
            };
            state.consultantClinCheckStatusLead = {
                results: []
            };
            state.consultantBookedStatusLead = {
                results: []
            };
            state.treatmentStartedStatusLead = {
                results: []
            };
            state.archivedList = [];
        },
        updateLead(state, action) {
            state.lead.results = action.payload.results;
            state.lead.count = action.payload.count;
        },
        updateDashNewLead(state, action) {
            const currentState = current(state.newDashLead);
            const newState = { results: action.payload.results, count: action.payload.count }

            if (shouldUpdateState(currentState, newState)) {
                state.newDashLead.results = action.payload.results;
                state.newDashLead.count = action.payload.count;
            }
        },
        followUpLead(state, action) {
            state.followLead = action.payload;
            state.newLeadDetail.personalDetail = action.payload;
        },
        isAppointment(state, action) {
            state.isLoading = false;
            state.isAppointmentBooked = action.payload;
        },
        searchAPIdata(state, action) {
            state.isLoading = true;
            state.searchedLead = action.payload;
            state.isLoading = false;
        },
        addTaskSearchLead(state, action) {
            state.isLoading = true;
            state.taskSearchLead = action.payload;
            state.isLoading = false;
        },
        clearApiData(state) {
            state.searchedLead = null;
        },
        clearTaskLead(state) {
            state.taskSearchLead = null;
        },
        updateSteps(state, action) {
            state.attendance = action.payload;
        },
        updateBookingSteps(state, action) {
            state.BookingAttendance = action.payload;
        },
        saveLeadDetail(state, action) {
            state.leadDetail = action.payload;
        },
        followUpAppointment(state, action) {
            state.isLoading = false;
            state.followLead = {
                ...state.followLead,
                lead_appointment: action.payload
            };
        },
        tableLead(state, action) {
            state.tableRowLead = action.payload;
        },
        archivedLeadSlice(state, action) {
            state.archivedLead = action.payload;
        },
        leadNote(state, action) {
            state.leadNotes = action.payload;
        },
        leadNoteWithFilter(state, action) {
            state.leadNotesWithFilter = action.payload;
        },
        getAllReminderData(state, action) {
            const currentState = current(state);

            if (shouldUpdateState(currentState.allReminders, action.payload)) {
                state.allReminders = action.payload;
            }
        },
        getTasksPageData(state, action) {
            state.taskPageData = action.payload;
        },
        clearAllReminderData(state) {
            state.allReminders = null;
        },
        leadReminderData(state, action) {
            state.leadReminder = action.payload;
        },
        leadFlowReminderData(state, action) {
            state.leadFlowReminder = action.payload;
        },
        leadFlowReminderMoreData(state, action) {
            state.leadFlowReminder.results = [
                ...state.leadFlowReminder.results,
                ...action.payload.results
            ];
            state.leadFlowReminder.count = action.payload.count;
        },
        leadStatusNew(state, action) {
            state.newStatusLead = action.payload;
        },
        leadStatusConsultantAttended(state, action) {
            state.consultantAttendStatusLead = action.payload;
        },
        leadStatusConsultantClinCheckAttended(state, action) {
            state.consultantClinCheckStatusLead = action.payload;
        },
        leadStatusConsultantBooked(state, action) {
            state.consultantBookedStatusLead = action.payload;
        },
        leadStatusFailedToAttend(state, action) {
            state.failedToAttendStatusLead = action.payload;
        },
        leadStatusClosed(state, action) {
            state.closedStatusLead = action.payload;
        },
        updateActiveStep(state, action) {
            state.activeTab = action.payload;
        },
        updateCompleteStatus(state, action) {
            state.markAsComplete = action.payload;
        },
        updateFollowUpLead(state, action) {
            state.followUpLead = action.payload;
        },
        clearReminder(state) {
            state.leadReminder = null;
        },
        contactLeadSlice(state, action) {
            state.contactLead = action.payload;
        },
        archiveLead(state, action) {
            state.archivedList = action.payload;
        },
        appointmentNotesSlice(state, action) {
            state.appointmentNotes = action.payload;
        },
        addTaskTableData(state, action) {
            state.taskTableData = action.payload;
        },
        clearLeadDetail(state) {
            state.leadDetail = null;
        },
        paymentCompleteSlice(state, action) {
            state.paymentComplete = action.payload;
        },
        checkoutListingSlice(state, action) {
            state.checkoutListing = action.payload;
        },
        refundListingSlice(state, action) {
            state.refundListing = action.payload;
        },
        setAssigned(state, action) {
            state.isAssigned = action.payload;
        },
        upComingTask(state, action) {
            state.upComingData = action.payload;
        },
        leadSummaryListingSlice(state, action) {
            state.leadSummaryListing = action.payload;
        },
        AddLeadNotes(state, action) {
            state.leadNotesArr = action.payload;
        },
        leadStatusSummaryListingSlice(state, action) {
            state.leadStatusSummaryListing = action.payload;
        },
        addDashboardStatusesSummery(state, action) {
            state.dashboardStatusesSummery = action.payload;
        },
        clearDashboardStatusesSummery(state) {
            state.dashboardStatusesSummery = [];
        },
        leadArchivedSummaryListingSlice(state, action) {
            state.leadArchivedSummaryListing = action.payload;
        },
        leadTreatmentsListingSlice(state, action) {
            state.leadTreatmentsListing = action.payload;
        },
        addAutoMatedMessages(state, action) {
            state.autoMatedMessages = action.payload;
        },
        addLeadNotes(state, action) {
            state.leadNotes = [
                ...state.leadNotes,
                action.payload
            ];
        },
        addTaskCounter(state, action) {
            state.leadTaskCounter = action.payload;
        },
        addLeadTaskTab(state, action) {
            state.leadTaskTab = action.payload;
        },
        updateCounter(state, action) {
            const currentState = current(state.counter);

            if (shouldUpdateState(currentState, action.payload)) {
                state.counter = action.payload;
            }
        },
        updateTaskCounter(state, action) {
            state.taskCounter = action.payload;
        },
        cleanTaskCounter(state) {
            state.taskCounter = [];
        },
        updateLeadTaskCounter(state, action) {
            state.taskLeadCounter = action.payload;
        },
        updateFlowTaskCounter(state, action) {
            state.taskFlowCounter = action.payload;
        },
        updateSeparateTaskCounter(state, action) {
            state.separateTaskCounter = action.payload;
        },
        clearSeparateTaskCounter(state) {
            state.separateTaskCounter = [];
        },
        updateTableTags(state, action) {
            state.tableTag = action.payload;
        },
        leadStatusTreatmentStarted(state, action) {
            state.treatmentStartedStatusLead = action.payload;
        },
        updateDashboardTable(state, action) {
            state.dashContact = {
                ...state.dashContact,
                ...action.payload
            };
        },
        updateNoTaskTable(state, action) {
            state.noTaskTableData = {
                ...state.noTaskTableData,
                ...action.payload
            };
        },
        updateAlignerTable(state, action) {
            state.alignerTable = {
                ...state.alignerTable,
                ...action.payload
            };
        },
        updateDashNewLeadsTable(state, action) {
            state.dashNewContact = {
                ...state.dashNewContact,
                ...action.payload
            };
        },
        updateDashNewLeadsWidgetTable(state, action) {
            state.dashNewContactForWidget = {
                ...state.dashNewContactForWidget,
                ...action.payload
            };
        },
        updateNewTable(state, action) {
            state.newContact = {
                ...state.newContact,
                ...action.payload
            };
        },
        updateBookedTable(state, action) {
            state.bookedContact = {
                ...state.bookedContact,
                ...action.payload
            };
        },
        updateFailedToAttendTable(state, action) {
            state.failedToAttendContact = {
                ...state.failedToAttendContact,
                ...action.payload
            };
        },
        updateClosedTable(state, action) {
            state.closedContact = {
                ...state.closedContact,
                ...action.payload
            };
        },
        updateAttendTable(state, action) {
            state.attendContact = {
                ...state.attendContact,
                ...action.payload
            };
        },
        updateClincheckTable(state, action) {
            state.clincheckContact = {
                ...state.clincheckContact,
                ...action.payload
            };
        },
        updateTreatmentStartedTable(state, action) {
            state.treatmentStarted = {
                ...state.treatmentStarted,
                ...action.payload
            };
        },
        updateArchiveTable(state, action) {
            state.archivedContact = {
                ...state.archivedContact,
                ...action.payload
            };
        },
        setArchivedLeadTags(state, action) {
            state.archiveLeadTags = action.payload;
        },
        addContactedLead(state, action) {
            state.contactedLead = action.payload;
        },
        updateConsentLink(state, action) {
            state.consentLink = action.payload;
        },
        setDashboardTaskPageFilter(state, action) {
            state.dashboardTaskPageFilter = action.payload;
        },
        setLeadDetailTaskPageFilter(state, action) {
            state.leadDetailTaskPageFilter = action.payload;
        },
        setTaskPageFilter(state, action) {
            state.taskPageFilter = action.payload;
        },
        getTaskDetails(state, action) {
            state.taskDetail = action.payload;
        },
        taskCompletedUser(state, action) {
            state.isTasksCompleted = action.payload;
        },
        updateDateFormat(state, action) {
            state.dateFormat = action.payload;
        },
        updateArchiveDateFormat(state, action) {
            state.dateArchiveFormat = action.payload;
        },
        updateAppointmentDateFormat(state, action) {
            state.dateAppointmentFormat = action.payload;
        },
        updateconsultationDateFormat(state, action) {
            state.consultationDateFormat = action.payload;
        },
        updateLastContactDateFormat(state, action) {
            state.dateLastContactFormat = action.payload;
        },
        updateAttendedDateFormat(state, action) {
            state.dateAttendedFormat = action.payload;
        },
        selectLeads(state, action) {
            state.multiLead = action.payload;
        },
        updateTableType(state, action) {
            state.tableType = action.payload;
        },
        duplicateSelectLeads(state, action) {
            state.duplicateMultiLead = action.payload;
        },
        setMergeTags(state, action) {
            state.mergeTags = action.payload;
        },
        startLoadingDepositPending(state, action) {
            state.isLoadingDepositPending = action.payload;
        },
        setCurrentTreatmentData(state, action) {
            state.currentTreatmentData = action.payload;
        },
        setAttendedActiveTab(state, action) {
            state.attendedActiveTab = action.payload;
        },
        setDepositStatus(state, action) {
            state.treatmentDepositStatus = action.payload;
        },
        setTreatmentLoading(state, action) {
            state.treatmentLoading = action.payload;
        },
        setTodoListFilter(state, action) {
            state.todoListFilter = action.payload;
        },
        chatbotConversationSlice(state, action) {
            state.chatbotConversation = action.payload;
        },
        appointmentDateSlice(state, action) {
            state.appointmentDateValue = action.payload;
        },
        setAction(state, action) {
            state.action = action.payload;
        },
        taskUnassignedUser(state, action) {
            state.unassignedUser = action.payload;
        },
        appointmentUserSlice(state, action) {
            state.appointmentUserValue = action.payload;
        },
        updateSendReminderDuringLeadCreate(state, action) {
            state.sendReminderDuringLeadCreate = action.payload;
        }
    },
});

export const {
    contactLeadSlice, updateBookingSteps, newLead, clearLead, updateCounter, updateSteps, clearApiData, tableLead, archivedLeadSlice, updateCompleteStatus, addTaskSearchLead,
    saveNewLeadDetail, savePersonalLeadDetails, updateActiveStep, clearReminder, updateFollowUpLead, archiveLead, addTaskTableData, startLoadingData, clearLeadDetail, AddLeadNotes, saveLeadDetail,
    setAssigned, upComingTask, leadSummaryListingSlice, addLeadTaskTab, updateTaskCounter, updateLeadTaskCounter, clearTaskLead, leadStatusTreatmentStarted, getTaskDetails, updateTableType, startLoading, startArchivedLoading,
    updateDashboardTable, updateNewTable, updateBookedTable, updateFailedToAttendTable, updateClosedTable, updateAttendTable, updateClincheckTable, updateTreatmentStartedTable, updateArchiveTable, cleanTaskCounter, clearAllReminderData,
    updateDashNewLeadsTable, updateDashNewLeadsWidgetTable, clearDashboardStatusesSummery, updateDashNewLead, setArchivedLeadTags, clearTableData, checkoutListingSlice, refundListingSlice, appointmentNotesSlice, updateConsentLink, selectLeads,
    clearConsentData, reset, updateSeparateTaskCounter, clearSeparateTaskCounter, saveConsentLead, setDashboardTaskPageFilter, setLeadDetailTaskPageFilter, setTaskPageFilter, taskCompletedUser, updateDateFormat,
    updateconsultationDateFormat,
    updateAttendedDateFormat, updateAppointmentDateFormat, updateLastContactDateFormat, updateArchiveDateFormat, duplicateSelectLeads, setLoadingForExport, setLoadingForLead, leadPage, startLoadingDepositPending,
    setCurrentTreatmentData,
    setAttendedActiveTab,
    setDepositStatus,
    setTreatmentLoading,
    setTodoListFilter,
    leadFlowReminderData,
    leadFlowReminderMoreData,
    appointmentDateSlice,
    setAction,
    setLoadingApis,
    taskUnassignedUser,
    updateNoTaskTable,
    updateAlignerTable,
    appointmentUserSlice,
    updateSendReminderDuringLeadCreate
} = slice.actions;

export default slice.reducer;

const shouldUpdateState = (currentState, newState) => JSON.stringify(currentState) !== JSON.stringify(newState);


export const getDepositPendingStatus = (status) => status === 'NO';
export const getCurrentLeadAppointmentId = (id) => {
    const { currentTreatmentDataGlobal } = store.getState().multiTreatment;
    if (!isEmpty(currentTreatmentDataGlobal?.treatmentData?.appointments)) return currentTreatmentDataGlobal?.treatmentData?.appointments?.[0]?.id;
    return id;
};

const handleLoading = (type, state) => {
    if (type === 'ConsultantClinCheckAttend') {
        if (state === false) {
            setTimeout(() => {
                dispatch(slice.actions.setLoadingForClincheckLead(state));
            }, 500);
            return;
        }
        dispatch(slice.actions.setLoadingForClincheckLead(state));
    } else if (state === false) {
        setTimeout(() => {
            dispatch(slice.actions.setLoadingForLead(state));
        }, 500);
    } else {
        dispatch(slice.actions.setLoadingForLead(state));
    }
};

const getQuery = (data) => {
    let query = '';
    if (data?.isNoTaskTable) {
        query += '&reminders__not_is_completed=false';
    }
    return query;
};

export const getNewLead = ({ id, limit, offset, filterStatus, order, orderId, searchQuery, treatment, startDate, endDate, contactedCount, tags, referral, type, category, bookedWith, multiStatus, depositPendingStatus, apptType, consultationBookedWith, isNoTaskTable, currentTab, isArchived, isCacheApi, nhsProviderStatus, assigneeIds, forceV1API, isAnalyticsEnabled }) =>
    async () => {
        if (!isAnalyticsEnabled) {
            try {
                handleLoading(type, true);
                let healthCareProviderQuery = '';
                let healthCareProvider = isArchived ? '' : "&healthcare_provider=PRIVATE";
                if (nhsProviderStatus) {
                    healthCareProvider = "&healthcare_provider=NHS";
                    healthCareProviderQuery = nhsProviderStatus === "WAITING" ? '&lead_note__note__in=NHS_WAITING_LIST' : nhsProviderStatus === 'NONWAITING' ? '&lead_note__note__not_in=NHS_WAITING_LIST' : '&is_registered_patient=true';
                }
                const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
                const queryParams = (filterStatus === 'All' || (filterStatus === 'NEW' && contactedCount === '0') || isAnalyticsEnabled) ? `limit=${limit}&offset=${offset}` : `lead_treatments__status=${filterStatus === 'FOLLOW_UP' ? 'NEW' : filterStatus}&limit=${limit}&offset=${offset}`;
                const customStartDate = `${startDate} 00:00:00`;
                const customEndDate = `${endDate} 23:59:00`;
                const date = (startDate && endDate) ?
                    ((filterStatus === 'CONSULTATION_BOOKED' || filterStatus === 'CONSULTATION_ATTENDED' || filterStatus === 'FAILED_TO_ATTEND_CONSULTATION') && !isAnalyticsEnabled
                        ? `&lead_treatments__appointments__datetime__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` :
                        (isAnalyticsEnabled && filterStatus === 'All') ? `&state_transition_params={"timestamp_gte":"${customStartDate}","timestamp_lte":"${customEndDate}"}` :
                            (filterStatus === 'TREATMENT_STARTED' || isAnalyticsEnabled ? `&state_transition_params={"state":"${filterStatus}","timestamp_gte":"${customStartDate}","timestamp_lte":"${customEndDate}"}`
                                : `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}`)) : '';

                const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';

                const newLead = ((type === 'NewLeadTable' || type === 'ConsultantAttend' || type === 'ConsultantClinCheckAttend' || type === 'DashNewLeadTable' || (type === 'Dashboard' && (filterStatus === 'NEW' || filterStatus === 'FOLLOW_UP'))) && contactedCount !== '100000') ? (contactedCount <= 2 ? filterStatus === 'FOLLOW_UP' ? `&lead_treatments__lead_contacted_counts__count__gte=${contactedCount}` : `&lead_treatments__lead_contacted_counts__count=${contactedCount}` : `&lead_treatments__lead_contacted_counts__count__gte=${contactedCount}`) : null;
                const BlankCountnewLead = (nhsProviderStatus === 'EXISTING' || filterStatus === 'TREATMENT_STARTED' || filterStatus === 'CLOSED' || filterStatus === 'FAILED_TO_ATTEND_CONSULTATION' || contactedCount === '100000') ? '' : filterStatus === 'NEW' ? '&lead_treatments__lead_contacted_counts__isnull=true&has_new_or_null_treatment=true' : '&lead_treatments__lead_contacted_counts__isnull=true';
                const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
                const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';

                const bookedWithFilter = (bookedWith && bookedWith.length) ? (filterStatus === 'TREATMENT_STARTED' ? `&appointments__assignees__user__id__in=${bookedWith}&appointments__type__in=TREATMENT` : `&lead_treatments__appointments__assignees__user__id__in=${bookedWith}`) : '';

                const consultationBookedWithFilter = (consultationBookedWith && consultationBookedWith.length) ? (filterStatus === 'TREATMENT_STARTED' ? `&appointments__assignees__user__id__in=${consultationBookedWith}&appointments__type__in=CONSULTATION` : `&lead_treatments__appointments__assignees__user__id__in=${consultationBookedWith}`) : '';

                const clinCheckStatusQuery = contactedCount === '100000' ? '&lead_treatments__leadtreatment_clincheck__isnull=false' : '';
                const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${treatmentQuery}${date}${(contactedCount === '0' || contactedCount === 0) ? BlankCountnewLead : (newLead || '')}${filterTags}${leadReferral}${bookedWithFilter}` : `?${queryParams}${orderingLead}${treatmentQuery}${date}${(contactedCount === '0' || contactedCount === 0) ? BlankCountnewLead : (newLead || '')}${filterTags}${leadReferral}${bookedWithFilter}`;
                const treatmentCategory = (category !== '0' && category) ? filterStatus === 'TREATMENT_STARTED' ? `&treatment__treatment_item__treatment_category__in=${category}` : `&lead_treatments__treatment__treatment_item__treatment_category__in=${category}` : '';
                const consultationBookedMethod = (type === 'ConsultantBooked' && contactedCount !== 'All') ? `&lead_treatments__appointments__method__in=${contactedCount}` : '';
                const multiStatusQuery = multiStatus ? `&lead_treatments__status__in=${multiStatus}` : '';
                const depositPendingStatusQuery = depositPendingStatus ? `&lead_treatments__is_treatment_fee_pending=${getDepositPendingStatus(depositPendingStatus)}` : '';
                // const appointmentTypeQuery = apptType ? `lead_treatments__appointments__type__in=${apptType}` : '';
                const isDataArchived = isArchived ? `&is_archived=true` : '&is_archived=false';
                const currentTabQuery = currentTab === 'Completed' ? `&lead_treatments__lead_treatment_attributes__key__in=Approval Status&lead_treatment_attributes__contains=${JSON.stringify([{ value: 'Arrived' }])}` : currentTab === 'invisalignFit' ? '&has_null_lead_treatment_attributes_or_not_arrived=true' : currentTab === 'invisalignAdditional' ? '&lead_treatment_attributes__cycle__in=2,3,4,5,6,7' : '';


                const assigneeIdsFilter = filterStatus === 'TREATMENT_STARTED' && !forceV1API ? (assigneeIds ? `&lead__assignees__user__id__in=${assigneeIds}` : '') : (assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '')


                let apiQuery = filterStatus === 'TREATMENT_STARTED' && !forceV1API ? `/api/v1/practices/${id}/lead-treatments-flat/${search}${clinCheckStatusQuery}${treatmentCategory}${consultationBookedMethod}${multiStatusQuery}${depositPendingStatusQuery}${isDataArchived}&${leadFlatFields}&${consultationBookedWithFilter}${getQuery({ isNoTaskTable })}${currentTabQuery}${healthCareProvider}${healthCareProviderQuery}${assigneeIdsFilter}` :
                    `/api/v1/practices/${id}/leads/${search}${clinCheckStatusQuery}${treatmentCategory}${consultationBookedMethod}${multiStatusQuery}${depositPendingStatusQuery}${isDataArchived}&${leadFields}&${consultationBookedWithFilter}${getQuery({ isNoTaskTable })}${currentTabQuery}${healthCareProvider}${healthCareProviderQuery}${assigneeIdsFilter}`;
                if (filterStatus === 'TREATMENT_STARTED' && !forceV1API) {
                    apiQuery = apiQuery.replaceAll("lead_treatments__", "");
                    apiQuery = apiQuery.replaceAll("lead_tags__tag__tag__name__in", "lead__lead_tags__tag__tag__name__in");
                    apiQuery = apiQuery.replaceAll("lead_source__referral__in", "lead__lead_source__referral__in");
                }
                const response = await callApi(apiQuery);
                dispatch(slice.actions.setTreatmentLoading(false));

                if (response.status === 200) {
                    let result = response.data;
                    if (filterStatus === 'TREATMENT_STARTED' && !forceV1API)
                        result = { ...result, results: result?.results?.map((v2Data, index) => parseV2ToV1(v2Data ?? {}, `${index}-${category}`)) }


                    if (type === 'DashNewLeadTable') {
                        dispatch(slice.actions.updateDashNewLead(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'NewLeadTable') {
                        dispatch(slice.actions.leadStatusNew(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'ConsultantAttend') {
                        dispatch(slice.actions.leadStatusConsultantAttended(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'ConsultantClinCheckAttend') {
                        dispatch(slice.actions.leadStatusConsultantClinCheckAttended(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'ConsultantBooked') {
                        dispatch(slice.actions.leadStatusConsultantBooked(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'treatmentStarted') {
                        dispatch(slice.actions.leadStatusTreatmentStarted(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'FailedToAttend') {
                        dispatch(slice.actions.leadStatusFailedToAttend(result));
                        dispatch(startLoadingData(false));
                        return;
                    }
                    if (type === 'Closed') {
                        dispatch(slice.actions.leadStatusClosed(result));
                        dispatch(startLoadingData(false));
                        return;
                    }

                    dispatch(slice.actions.newLead(result));

                }
                else {
                    dispatch(slice.actions.setLoadingForExport(false));
                    handleLoading(type, false);
                    dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
                    return
                }

            } catch (error) {
                dispatch(slice.actions.setTreatmentLoading(false));
                dispatch(slice.actions.setLoadingForExport(false));
                dispatch(slice.actions.hasError(error.message));
            }
            finally {
                handleLoading(type, false);
            }
        } else {
            try {
                handleLoading(type, true);
                const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
                const customStartDate = `${startDate} 00:00:00`;
                const customEndDate = `${endDate} 23:59:00`;
                const status = filterStatus === "NEW" ? `&lead_treatments__status=${filterStatus}` : ``;
                const date = (startDate && endDate) ?
                    ((filterStatus === 'CONSULTATION_BOOKED' || filterStatus === 'CONSULTATION_ATTENDED' || filterStatus === 'FAILED_TO_ATTEND_CONSULTATION') && !isAnalyticsEnabled
                        ? `&lead_treatments__appointments__datetime__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` :
                        (isAnalyticsEnabled && (filterStatus === 'All' || filterStatus === 'NEW')) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` :
                            (filterStatus === 'TREATMENT_STARTED' || isAnalyticsEnabled ? `&state_transition_params={"state":"${filterStatus}","timestamp_gte":"${customStartDate}","timestamp_lte":"${customEndDate}"}`
                                : `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}`)) : '';

                const treatmentCategory = (category !== '0' && category) ? filterStatus === 'TREATMENT_STARTED' ? `treatment__treatment_item__treatment_category__in=${category}` : `lead_treatments__treatment__treatment_item__treatment_category__in=${category}` : '';
                const isDataArchived = isArchived ? `&is_archived=true&is_archived_included=true` : '&is_archived=false';

                let result;

                // result = await isCacheValid(cacheNewLead, cacheKey, cacheTimer, currentTime, isCacheApi);

                let apiQuery = filterStatus === 'TREATMENT_STARTED' && !forceV1API ? `/api/v1/practices/${id}/lead-treatments-flat/?limit=${limit}&offset=${offset}${orderingLead}&${treatmentCategory}${date}${isDataArchived}&${leadFlatFields}` :
                    `/api/v1/practices/${id}/leads/?limit=${limit}&offset=${offset}${status}${orderingLead}&${treatmentCategory}${date}${isDataArchived}&${leadFields}`;

                if (filterStatus === 'TREATMENT_STARTED' && !forceV1API) {
                    apiQuery = apiQuery.replaceAll("lead_treatments__", "");
                    apiQuery = apiQuery.replaceAll("lead_tags__tag__tag__name__in", "lead__lead_tags__tag__tag__name__in");
                    apiQuery = apiQuery.replaceAll("lead_source__referral__in", "lead__lead_source__referral__in");
                }
                const response = await callApi(apiQuery);

                if (response.status === 200) {
                    result = response.data;
                    if (filterStatus === 'TREATMENT_STARTED' && !forceV1API)
                        result = { ...result, results: result?.results?.map((v2Data, index) => parseV2ToV1(v2Data ?? {}, `${index}-${category}`)) }
                }
                else {
                    dispatch(slice.actions.setLoadingForExport(false));
                    handleLoading(type, false);
                    dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
                    return
                }

                dispatch(slice.actions.setTreatmentLoading(false));

                if (type === 'DashNewLeadTable') {
                    dispatch(slice.actions.updateDashNewLead(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'NewLeadTable') {
                    dispatch(slice.actions.leadStatusNew(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'ConsultantAttend') {
                    dispatch(slice.actions.leadStatusConsultantAttended(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'ConsultantClinCheckAttend') {
                    dispatch(slice.actions.leadStatusConsultantClinCheckAttended(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'ConsultantBooked') {
                    dispatch(slice.actions.leadStatusConsultantBooked(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'treatmentStarted') {
                    dispatch(slice.actions.leadStatusTreatmentStarted(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'FailedToAttend') {
                    dispatch(slice.actions.leadStatusFailedToAttend(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'Closed') {
                    dispatch(slice.actions.leadStatusClosed(result));
                    dispatch(startLoadingData(false));
                    return;
                }

                dispatch(slice.actions.newLead(result));

            } catch (error) {
                dispatch(slice.actions.setTreatmentLoading(false));
                dispatch(slice.actions.setLoadingForExport(false));
                dispatch(slice.actions.hasError(error.message));
            }
            finally {
                handleLoading(type, false);
            }
        }
    };

export const getBlankTreatmentLeads = ({ id, limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, type, nhsProviderStatus, assigneeIds }) =>
    async () => {
        try {
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = `limit=${limit}&offset=${offset}&lead_treatments__isnull=${true}`;
            const date = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}` : `?${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}`;

            let healthCareProviderQuery = '';
            let healthCareProvider = "";
            if (nhsProviderStatus) {
                healthCareProvider = "&healthcare_provider=NHS";
                healthCareProviderQuery = nhsProviderStatus === "WAITING" ? '&lead_note__note__in=NHS_WAITING_LIST' : nhsProviderStatus === 'NONWAITING' ? '&lead_note__note__not_in=NHS_WAITING_LIST' : '&is_registered_patient=true';
            }
            const assigneeIdsFilter = assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '';

            const response = await callApi(`/api/v1/practices/${id}/leads/${search}${healthCareProvider}${healthCareProviderQuery}&is_archived=false&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}${assigneeIdsFilter}`);
            if (response.status === 200) {
                if (type === 'DashNewLeadTable') {
                    dispatch(slice.actions.updateDashNewLead(response.data));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'NewLeadTable') {
                    dispatch(slice.actions.leadStatusNew(response.data));
                    dispatch(startLoadingData(false));
                }
            } else {
                dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };

export const getNHSLeads = ({ id, limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, type, healthCareProvider, assigneeIds }) =>
    async () => {
        try {
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = `lead_treatments__status=NEW&limit=${limit}&offset=${offset}`;
            const date = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const nhsLead = healthCareProvider ? `&healthcare_provider=${healthCareProvider}` : '';
            const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${nhsLead}${date}${filterTags}${leadReferral}${treatmentQuery}` : `?${queryParams}${orderingLead}${nhsLead}${date}${filterTags}${leadReferral}${treatmentQuery}`;

            const assigneeIdsFilter = assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '';

            const response = await callApi(`/api/v1/practices/${id}/leads/${search}&is_archived=false&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}&lead_note__note__not_in=NHS_WAITING_LIST${assigneeIdsFilter}`);

            if (response.status === 200) {
                const result = response.data;
                if (type === 'DashNewLeadTable') {
                    dispatch(slice.actions.updateDashNewLead(result));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'NewLeadTable') {
                    dispatch(slice.actions.leadStatusNew(result));
                    dispatch(startLoadingData(false));
                }

            } else {
                dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };

export const getNoTreatmentCounter = ({ id, limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, nhsProviderStatus, assigneeIds }) =>
    async () => {
        try {
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = `limit=${limit}&offset=${offset}&lead_treatments__isnull=${true}`;
            const date = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}` : `?${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}`;

            let healthCareProviderQuery = '';
            let healthCareProvider = "";
            if (nhsProviderStatus) {
                healthCareProvider = "&healthcare_provider=NHS";
                healthCareProviderQuery = nhsProviderStatus === "WAITING" ? '&lead_note__note__in=NHS_WAITING_LIST' : nhsProviderStatus === 'NONWAITING' ? '&lead_note__note__not_in=NHS_WAITING_LIST' : '&is_registered_patient=true';
            }
            const assigneeIdsFilter = assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '';

            const response = await callApi(`/api/v1/practices/${id}/leads/${search}&is_archived=false${healthCareProvider}${healthCareProviderQuery}&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}${assigneeIdsFilter}`);
            if (response.status === 200) {
                const result = response.data;
                const { counter } = store.getState().lead;
                dispatch(slice.actions.updateCounter([...counter, { filterStatus: 'No Treatment', count: result?.count }]));

            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }

        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
        finally {
            dispatch(startLoadingData(false));
        }
    };

export const getNHSCounter = ({ id, limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, healthCareProvider, assigneeIds }) =>
    async () => {
        try {
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = `lead_treatments__status=NEW&limit=${limit}&offset=${offset}`;
            const date = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const nhsLead = healthCareProvider ? `&healthcare_provider=${healthCareProvider}` : '';
            const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${nhsLead}${date}${filterTags}${leadReferral}${treatmentQuery}` : `?${queryParams}${orderingLead}${nhsLead}${date}${filterTags}${leadReferral}${treatmentQuery}`;
            const assigneeIdsFilter = assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '';

            const response = await callApi(`/api/v1/practices/${id}/leads/${search}&is_archived=false&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}&lead_note__note__not_in=NHS_WAITING_LIST${assigneeIdsFilter}`);

            if (response.status === 200) {
                const result = response.data;
                const { counter } = store.getState().lead;
                dispatch(slice.actions.updateCounter([...counter, { filterStatus: 'NHS', count: result?.count }]));

            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }

        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
        finally {
            dispatch(startLoadingData(false));
        }
    };

export function getAwaitingLead({ id, type, data, limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, nhsProviderStatus, assigneeIds }) {
    return async () => {
        try {

            let healthCareProviderQuery = '';
            let healthCareProvider = '';
            if (nhsProviderStatus) {
                healthCareProvider = "&healthcare_provider=NHS";
                healthCareProviderQuery = nhsProviderStatus === "WAITING" ? '&lead_note__note__in=NHS_WAITING_LIST' : nhsProviderStatus === 'NONWAITING' ? '&lead_note__note__not_in=NHS_WAITING_LIST' : '&is_registered_patient=true';
            }
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = `limit=${limit}&offset=${offset}&lead_notes__note__in=AWAITING_RESPONSE`;
            const date = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const filterTags = tags !== undefined && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const filterData = '&lead_treatments__status=NEW';
            const search = (searchQuery && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}${filterData}` : `?${queryParams}${orderingLead}${treatmentQuery}${date}${filterTags}${leadReferral}${filterData}`;
            const assigneeIdsFilter = assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '';

            const response = await callApi(`/api/v1/practices/${id}/leads/${search}&is_archived=false${healthCareProvider}${healthCareProviderQuery}&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}${assigneeIdsFilter}`);

            if (response.status === 200) {
                const result = response.data;
                const { counter } = store.getState().lead;
                dispatch(slice.actions.updateCounter([...counter, { key: 'AWAITING_RESPONSE', filterStatus: 'AWAITING_RESPONSE', count: result?.count }]));

                if (data === 'DashNewLeadTable') {
                    dispatch(slice.actions.updateDashNewLead(result));
                    return;
                }
                if (data === 'NewLeadTable' && type === 'AddData') {
                    dispatch(slice.actions.leadStatusNew(result));
                }

            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }

        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
        finally {
            dispatch(startLoadingData(false));
        }
    };
}

export const getCounter = ({ id, filterStatus, order, orderId, searchQuery, treatment, startDate, endDate, contactedCount, tags, referral, type, bookedWith, depositPendingStatus, isNoTaskTable, multiStatus, category, isArchived, nhsProviderStatus, assigneeIds, forceV1API }) =>
    async () => {
        try {

            let healthCareProviderQuery = '';
            let healthCareProvider = isArchived ? '' : "&healthcare_provider=PRIVATE";
            if (nhsProviderStatus) {
                healthCareProvider = "&healthcare_provider=NHS";
                healthCareProviderQuery = nhsProviderStatus === "WAITING" ? '&lead_note__note__in=NHS_WAITING_LIST' : nhsProviderStatus === 'NONWAITING' ? '&lead_note__note__not_in=NHS_WAITING_LIST' : '&is_registered_patient=true';
            }
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const queryParams = (filterStatus === 'All' || (filterStatus === 'NEW' && contactedCount === '0')) ? `limit=${1}&offset=${0}` : `lead_treatments__status=${filterStatus === 'FOLLOW_UP' ? 'NEW' : filterStatus}&limit=${1}&offset=${0}`;
            const customStartDate = `${startDate} 00:00:00`;
            const customEndDate = `${endDate} 23:59:00`;
            const date = (startDate && endDate) ? (filterStatus === 'CONSULTATION_BOOKED' || filterStatus === 'CONSULTATION_ATTENDED' || filterStatus === 'FAILED_TO_ATTEND_CONSULTATION' ? `&lead_treatments__appointments__datetime__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : (filterStatus === 'TREATMENT_STARTED' ? `&state_transition_params={"state":"${filterStatus}","timestamp_gte":"${customStartDate}","timestamp_lte":"${customEndDate}"}` : `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}`)) : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const newLead = ((type === 'NewLeadTable' || type === 'ConsultantAttend' || type === 'ConsultantClinCheckAttend' || type === 'DashNewLeadTable' || type === 'Dashboard') && contactedCount !== '100000') ? (contactedCount <= 2 ? filterStatus === 'FOLLOW_UP' ? `&lead_treatments__lead_contacted_counts__count__gte=${contactedCount}` : `&lead_treatments__lead_contacted_counts__count=${contactedCount}` : `&lead_treatments__lead_contacted_counts__count__gte=${contactedCount}`) : null;
            const BlankCountnewLead = (filterStatus === 'TREATMENT_STARTED' || filterStatus === 'CLOSED' || filterStatus === 'FAILED_TO_ATTEND_CONSULTATION' || contactedCount === '100000') ? '' : filterStatus === 'NEW' ? '&lead_treatments__lead_contacted_counts__isnull=true&has_new_or_null_treatment=true' : '&lead_treatments__lead_contacted_counts__isnull=true';
            const filterTags = tags && tags.length > 0 ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const bookedWithFilter = (bookedWith && bookedWith.length) ? (filterStatus === 'TREATMENT_STARTED' ? `&appointments__assigned_to__user__id__in=${bookedWith}&appointments__type__in=TREATMENT` : `&lead_treatments__appointments__assignees__user__id__in=${bookedWith}`) : '';

            const clinCheckStatusQuery = contactedCount === '100000' ? '&lead_treatments__leadtreatment_clincheck__isnull=false' : '';
            const depositPendingStatusQuery = depositPendingStatus ? `&lead_treatments__is_treatment_fee_pending=${getDepositPendingStatus(depositPendingStatus)}` : '';
            const multiStatusQuery = multiStatus ? `&lead_treatments__status__in=${multiStatus}` : '';
            const treatmentCategory = (category !== '0' && category) ? `&lead_treatments__treatment__treatment_item__treatment_category__in=${category}` : '';
            const search = (searchQuery && searchQuery !== null && searchQuery.length > 0) ? `search/?query=${searchQuery}&${queryParams}${orderingLead}${treatmentQuery}${date}${(contactedCount === '0' || contactedCount === 0) ? BlankCountnewLead : (newLead || '')}${filterTags}${leadReferral}${bookedWithFilter}` : `?${queryParams}${orderingLead}${treatmentQuery}${date}${(contactedCount === '0' || contactedCount === 0) ? BlankCountnewLead : (newLead || '')}${filterTags}${leadReferral}${bookedWithFilter}${clinCheckStatusQuery}`;
            const isDataArchived = isArchived ? `&is_archived_included=true` : '&is_archived=false';

            const assigneeIdsFilter = filterStatus === 'TREATMENT_STARTED' ? (assigneeIds ? `&lead__assignees__user__id__in=${assigneeIds}` : '') : (assigneeIds ? `&assignees__user__id__in=${assigneeIds}` : '')

            let apiQuery = filterStatus === 'TREATMENT_STARTED' && !forceV1API ? `/api/v1/practices/${id}/lead-treatments-flat/${search}${depositPendingStatusQuery}${getQuery({ isNoTaskTable })}&${multiStatusQuery}&fields={}${isDataArchived}${healthCareProvider}${healthCareProviderQuery}${assigneeIdsFilter}`
                : `/api/v1/practices/${id}/leads/${search}${depositPendingStatusQuery}${getQuery({ isNoTaskTable })}&${multiStatusQuery}&fields={}${isDataArchived}${healthCareProvider}${healthCareProviderQuery}${assigneeIdsFilter}`;
            if (filterStatus === 'TREATMENT_STARTED' && !forceV1API) {
                apiQuery = apiQuery.replaceAll("lead_treatments__", "");
                apiQuery = apiQuery.replaceAll("lead_source__referral__in", "lead__lead_source__referral__in");
                apiQuery = apiQuery.replaceAll("lead_tags__tag__tag__name__in", "lead__lead_tags__tag__tag__name__in");
            }

            const response = await callApi(apiQuery);

            if (response.status === 200) {
                const result = response.data;
                const { counter } = store.getState().lead;
                if (type === 'DashNewLeadTable') {
                    dispatch(slice.actions.updateCounter([...counter, { filterStatus: contactedCount, count: result?.count }]));
                    return;
                }
                if (type === 'NewLeadTable') {
                    dispatch(slice.actions.updateCounter([...counter, { key: contactedCount, filterStatus: contactedCount, count: result?.count }]));

                    return;
                }
                if (type === 'ConsultantAttend') {
                    dispatch(slice.actions.updateCounter([...counter, { filterStatus: contactedCount, count: result?.count }]));
                    return;
                }
                if (type === 'ConsultantClinCheckAttend') {
                    dispatch(slice.actions.updateCounter([...counter, { filterStatus: contactedCount, count: result?.count }]));
                    return;
                }
                dispatch(slice.actions.updateCounter([...counter, { filterStatus, count: result?.count }]));

            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }


        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
        finally {
            dispatch(startLoadingData(false));
        }
    };

export function dashNewTableCounter(id) {
    return async () => {

        const response = await callApi(`/api/v1/practices/${id}/leads/?lead_treatments__lead_contacted_counts__isnull=true&has_new_or_null_treatment=true&fields={}`);
        try {
            if (response.status === 200) {
                const { dashNewContact } = store.getState().lead;
                dispatch(updateDashNewLeadsTable({ ...dashNewContact, labelCounter: response.data.count }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function savePersonalDetails(data, practiceId, handleClose) {
    return async () => {
        try {
            const { lead, activeTab, newLeadDetail, sendReminderDuringLeadCreate } = store.getState().lead;

            const response = await callApi(`/api/v1/practices/${practiceId}/leads/`, 'post', data);
            if (response.status === 201) {
                if (sendReminderDuringLeadCreate) {
                    const addReminderObj = { ...newLeadDetail?.lead_reminder, lead: response?.data?.id }
                    dispatch(addReminder(addReminderObj, practiceId, '', 'createLead'));
                }
                dispatch(slice.actions.saveConsentLead(response?.data));
                dispatch(slice.actions.saveLead(response?.data));
                const results = [response?.data, ...lead?.results];
                const count = lead?.count + 1;
                dispatch(slice.actions.updateLead({ results, count }));
                if (activeTab >= 1) {
                    const treatmentDetail = {
                        ...newLeadDetail.lead_treatment, is_practice_notification_required: false
                    };
                    dispatch(addTreatment(treatmentDetail, practiceId, response?.data?.id, handleClose, { needToUpdate: treatmentDetail.teethAmount || treatmentDetail.teethAmount === 0, teeth: treatmentDetail.teethAmount || 0 }));
                }
                // handleNext({ success: true, message: 'Personal Details added Successfully' });
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
                // handleNext({ success: false, message: response.data || 'Something went wrong' });
                // dispatch(slice.actions.hasError(response.data || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function updatePersonalDetail(data, practiceId, handleClose, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data.id}/`, 'patch', data);
            if (response.status === 200) {
                const { activeTab, newLeadDetail, attendance, tableRowLead, newLeadDetail: { archiveLead } } = store.getState().lead;
                if (type === 'leadDetail') {
                    dispatch(slice.actions.saveLeadDetail(response.data));
                    dispatch(followUpSlice(data.id, practiceId, 'LeadDetail'));
                    dispatch(updateError({ success: true, message: 'Personal details updated successfully' }));
                    handleClose('finish', data.id);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (type === 'personalDetail') {
                    dispatch(slice.actions.saveLead(response.data));
                }
                if (attendance === 'payment' && activeTab > 2 && newLeadDetail.payment.payment_type !== 'No') {
                    dispatch(leadTreatmentPayment(practiceId, tableRowLead.lead_id, tableRowLead.treatment_id, newLeadDetail.payment, handleClose));
                    return;
                }
                if (type === 'archived') {
                    dispatch(archivedLeadAPI(archiveLead.archiveData, practiceId, handleClose, data.id));
                    return;
                }
                if (type === 'leadDataGrid') {
                    dispatch(updateError({ success: true, message: 'Personal details updated successfully' }));
                    return;
                }
                if (type === 'leadTableEdit') {
                    handleClose({ success: true, message: 'Personal details updated successfully', data: response.data });
                    return;
                }
                if (type === 'EditPersonalDetail') {
                    dispatch(slice.actions.saveLead(response.data));
                    dispatch(updateError({ success: true, message: 'Personal details updated successfully' }));
                    handleClose();
                    dispatch(followUpSlice(data.id, practiceId));
                    dispatch(getAppointment(practiceId, data.id));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Personal details updated successfully' }));
                dispatch(updateCompleteStatus(true));
                if (type === 'leadDetail') {
                    handleClose(true);
                    return;
                }
                handleClose('finish', data.id);
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
            dispatch(startLoadingData(false));
        }
    };
}

export function addTreatment(detail, practiceId, id, handleClose, teethData) {
    return async () => {
        try {
            const { lead, newLeadDetail } = store.getState().lead;
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/treatments/`, 'post', detail);
            if (response.status === 201) {
                const results = [];
                const { data } = response;
                lead?.results.forEach(el => {
                    const detail = { ...el };
                    if (el?.id === data?.lead) {
                        detail.lead_treatments = [data];
                    }
                    results.push(detail);
                });
                dispatch(slice.actions.updateLead({ results, count: lead.count }));
                dispatch(slice.actions.saveTreatment(response.data));
                if (newLeadDetail?.addAppointment) {
                    const appointmentDetail = {
                        ...newLeadDetail.lead_appointment,
                        lead_treatment: response.data.id
                    };
                    dispatch(addAppointment(appointmentDetail, practiceId, id, handleClose, 'CreatLead'));
                    if (newLeadDetail?.attentendAppoiment?.activeTab === 'Starting treatment') return;
                    if (!teethData?.needToUpdate) return;
                }
                if (teethData?.needToUpdate) {
                    const updateTreatment = {
                        ...detail,
                        lead: id,
                        id: data.id,
                    };
                    updateTreatment.leadtreatment_specification = {
                        lead_treatment: data.id,
                        teeth_count: teethData?.teeth
                    };
                    dispatch(editTreatment(updateTreatment, handleClose, 'editTreatment'));
                } else {
                    handleClose('finish', id);
                    dispatch(startLoadingData(false));
                }
                dispatch(updateError({ message: 'Treatment added successfully', success: true }));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function UpdateTreatmentV2(data, practiceId, handleClose, status) {
    return async () => {
        try {
            let detail = {
                ...data
            };
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/treatments/${data.id}/`);
            if (response.status === 200) {
                if (response?.data?.leadtreatment_specification?.id) {
                    detail = {
                        ...detail,
                        leadtreatment_specification: {
                            teeth_count: detail?.teethAmount || 0
                        }
                    };
                } else {
                    detail = {
                        ...detail,
                        leadtreatment_specification: {
                            lead_treatment: data.id,
                            teeth_count: detail?.teethAmount || 0
                        }
                    };
                }
                dispatch(editTreatment(detail, handleClose, status));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function UpdateTreatmentV3(data, type, handleSuccess) {
    return async () => {
        try {
            let detail = {
                ...data.detail
            };
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead_id}/treatments/${data.treatment_id}/`);
            if (response.status === 200) {
                if (response?.data?.leadtreatment_specification?.id) {
                    detail = {
                        ...detail,
                        leadtreatment_specification: {
                            teeth_count: detail?.teethAmount || 0
                        }
                    };
                } else {
                    detail = {
                        ...detail,
                        leadtreatment_specification: {
                            lead_treatment: data.treatment_id,
                            teeth_count: detail?.teethAmount || 0
                        }
                    };
                }
                dispatch(updateTreatmentDetail(practiceId, data?.lead_id, data?.treatment_id, detail, handleSuccess, type));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function UpdateTreatment(data, practiceId, handleClose, status) {
    return async () => {
        try {
            const detail = {
                ...data
            };
            delete detail.lead;
            delete detail.id;

            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/treatments/${data.id}/`, 'put', detail);
            if (response.status === 200) {
                const { newLeadDetail: { startTreatment, lead_appointment: leadAppointments }, createLead, tableRowLead } = store.getState().lead;
                if (status === 'RescheduleTreatment') {
                    if (tableRowLead?.status === 'FAILED_TO_ATTEND_CONSULTATION' || tableRowLead?.status === 'FAILED_TO_ATTEND') {
                        dispatch(addAppointment(startTreatment?.appointmentDetail, practiceId, createLead.id, handleClose, 'Reschedule'));
                        return;
                    }
                    dispatch(UpdateAppointment(startTreatment?.appointmentDetail, practiceId, handleClose, 'Reschedule'));
                    return;
                }
                if (status === 'updateLeadDetail') {
                    const appointmentDetail = {
                        ...leadAppointments,
                        lead_treatment: createLead.lead_treatment.id,
                    };
                    dispatch(addAppointment(appointmentDetail, practiceId, createLead.id, handleClose, 'followUp'));
                    return;
                }
                if (status === 'editTreatment') {
                    dispatch(updateError({ success: true, message: 'Treatment updated successfully' }));
                    dispatch(startLoadingData(false));
                    handleClose('finish', data?.lead);
                    return;
                }
                const { practice } = store.getState().practice;
                const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;
                if (isPaymentMandatory && data.depositStatus) return;
                const startTreatments = {
                    ...startTreatment.updatedTreatment,
                    lead: data?.lead,
                    id: data.id
                };
                dispatch(StartingTreatment(startTreatments, practiceId, handleClose, status));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data || response.detail), success: false }));
            dispatch(startLoadingData(false));
            document.body.classList.remove('points-event');
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function editTreatment(data, handleClose, status) {
    return async () => {
        try {
            const detail = {
                ...data
            };
            delete detail.lead;
            delete detail.id;
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/treatments/${data.id}/`, 'PATCH', detail);
            if (response.status === 200) {
                const { newLeadDetail: { startTreatment, lead_appointment: leadAppointments }, createLead, tableRowLead } = store.getState().lead;
                if (status === 'RescheduleTreatment') {
                    if (tableRowLead?.status === 'FAILED_TO_ATTEND_CONSULTATION' || tableRowLead?.status === 'FAILED_TO_ATTEND') {
                        dispatch(addAppointment(startTreatment?.appointmentDetail, practiceId, createLead.id, handleClose, 'Reschedule'));
                        return;
                    }
                    dispatch(UpdateAppointment(startTreatment?.appointmentDetail, practiceId, handleClose, 'Reschedule'));
                    return;
                }
                if (status === 'updateLeadDetail') {
                    const appointmentDetail = {
                        ...leadAppointments,
                        lead_treatment: createLead.lead_treatment.id,
                    };
                    dispatch(addAppointment(appointmentDetail, practiceId, createLead.id, handleClose, 'followUp'));
                    return;
                }
                if (status === 'editTreatment') {
                    dispatch(updateError({ success: true, message: 'Treatment updated successfully' }));
                    dispatch(startLoadingData(false));
                    handleClose('finish', data?.lead);
                    return;
                }
                const { practice } = store.getState().practice;
                const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;
                if (isPaymentMandatory && data.depositStatus) return;
                const startTreatments = {
                    ...startTreatment.updatedTreatment,
                    lead: data?.lead,
                    id: data.id
                };
                dispatch(StartingTreatment(startTreatments, practiceId, handleClose, status));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data || response.detail), success: false }));
            dispatch(startLoadingData(false));
            dispatch(setLoader(false));
            document.body.classList.remove('points-event');
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(startLoadingData(false));
            dispatch(setLoader(false));
        }
    };
}

export function CompleteTreatment(data, leadId, id, practiceId, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${id}/treatment_closed/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Treatment completed successfully' }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish', leadId);
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(setLoader(false));
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateCompleteStatus(false));
            errorHandler(error, '', updateError);
            dispatch(startLoadingData(false));
        }
    };
}

export function StartingTreatment(data, practiceId, handleClose, status) {
    return async () => {
        try {
            const treatmentDetail = {
                ...data,
            };
            delete treatmentDetail.lead;
            delete treatmentDetail.id;
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/treatments/${getCurrentTreatmentIdV2(data.id)}/treatment_started/`, 'post', treatmentDetail);
            if (response.status === 200) {
                if (status === 'startingTreatmentFinish') {
                    dispatch(updateError({ success: true, message: 'Treatment started successfully' }));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', getCurrentTreatmentIdV2(data.id));
                    dispatch(startLoadingData(false));
                    return;
                }
                const {
                    newLeadDetail: {
                        startTreatment,
                    },
                    createLead
                } = store.getState().lead;
                const appointmentDetail = {
                    ...startTreatment.appointmentDetail,
                    lead_treatment: createLead.lead_treatment.id,
                };
                if (appointmentDetail.datetime) {
                    dispatch(addAppointment(appointmentDetail, practiceId, data?.lead, handleClose, status));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Treatment started successfully' }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish', data?.lead);
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(setLoader(false));
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            dispatch(slice.actions.hasError(response.data || 'Something went wrong'));
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function addAppointment(data, practiceId, id, handleClose, status) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/appointments/`, 'post', data);
            if (response.status === 201) {
                dispatch(slice.actions.saveAppointment(response.data));
                const { activeTab, newLeadDetail, attendance, createLead, tableRowLead, action } = store.getState().lead;

                const { depositData } = store.getState().deposit;
                if (depositData?.isDepositFlow) {
                    dispatch(creteTreatmentPaymentFlow());
                }
                if (status && status === 'finish') {
                    dispatch(updateError({ success: true, message: 'Treatment started successfully' }));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', id);
                    dispatch(startLoadingData(false));
                    return;
                }

                const { currentTreatmentDataGlobal } = store.getState().multiTreatment;
                if (action === 'startTreatment') {
                    let startTreatments = {
                        lead: data?.lead || newLeadDetail?.personalDetail?.id,
                        id: currentTreatmentDataGlobal?.treatmentData?.id || newLeadDetail?.lead_treatment?.id || tableRowLead?.treatment_id
                    };
                    if (newLeadDetail?.lead_treatment) {
                        startTreatments = {
                            ...startTreatments,
                            ...newLeadDetail?.lead_treatment,
                        };
                    }
                    dispatch(StartingTreatment(startTreatments, practiceId, handleClose, 'startingTreatmentFinish'));
                    return;
                }
                if (action === 'completeTreatment') {
                    const completeTreatment = {
                        ...newLeadDetail?.lead_treatment
                    };
                    dispatch(CompleteTreatment(completeTreatment, data?.lead || newLeadDetail?.personalDetail?.id, currentTreatmentDataGlobal?.treatmentData?.id || newLeadDetail?.lead_treatment?.id || tableRowLead?.treatment_id, practiceId, handleClose));
                    return;
                }
                if (attendance === 'attendance' && activeTab === 2) {
                    const { noteDetail } = newLeadDetail.attentendAppoiment;
                    dispatch(addAppointmentNotes(practiceId, id, response.data.id, noteDetail, handleClose));
                    return;
                }
                if (attendance === 'payment' && (status && status === 'followUp') && activeTab >= 2) {
                    dispatch(updatePersonalDetail({ ...newLeadDetail.personalDetail, id: tableRowLead.lead_id }, practiceId, handleClose, 'personalDetail'));
                    return;
                }

                if (attendance === 'payment' && (status && status === 'CreatLead') && activeTab >= 2 && newLeadDetail.payment.payment_type !== 'No') {
                    dispatch(leadTreatmentPayment(practiceId, createLead.lead_treatment.lead, createLead.lead_treatment.id, newLeadDetail.payment, handleClose));
                    return;
                }

                // if (attendance === 'payment' && activeTab >= 2) {
                // 	// const reminderDetail = {
                // 	// 	...newLeadDetail.lead_reminder,
                // 	// 	lead : createLead.id,
                // 	// 	lead_treatment : createLead.lead_treatment ? createLead.lead_treatment.id : tableRowLead.treatment_id,
                // 	// 	lead_appointment : createLead.lead_appointment ? createLead.lead_treatment.id : 0
                // 	// }
                // 	// dispatch(addReminder(reminderDetail, practiceId, handleClose));
                // 	dispatch(updateError({ success: true, message: 'Treatment and Appointment Added successfully' }));
                // 	dispatch(updateCompleteStatus(false));
                // 	handleClose('finish');
                // 	return;
                // }
                if (status === 'RescheduleReminder') {
                    dispatch(updateError({ success: true, message: 'Appointment added successfully' }));
                    dispatch(getAppointment(practiceId, id));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', id);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'AddAppointment') {
                    dispatch(updateError({ success: true, message: 'Appointment added successfully' }));
                    dispatch(getAppointment(practiceId, id));
                    dispatch(followUpSlice(id, practiceId, 'LeadDetail'));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', id);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'Reschedule') {
                    dispatch(updateError({ success: true, message: 'Appointment added successfully' }));
                } else {
                    dispatch(updateError({ success: true, message: 'Treatment and Appointment added successfully' }));
                }
                dispatch(updateCompleteStatus(false));
                handleClose('finish', id);
                dispatch(startLoadingData(false));

            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
                dispatch(setLoader(false));
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function getAppointment(practiceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/appointments/`);
            if (response.status === 200) {
                dispatch(slice.actions.followUpAppointment(response.data.results));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function UpdateAppointment(data, practiceId, handleNext, status) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/appointments/${data.id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(slice.actions.saveAppointment(response.data));
                if (status === 'RescheduleReminder') {
                    handleNext('finish', data?.lead);
                    dispatch(getAppointment(practiceId, data?.lead));
                    dispatch(updateError({ success: true, message: 'Appointment updated successfully' }));
                }
                if (status === 'Reschedule') {
                    handleNext('finish', data?.lead);
                    dispatch(updateError({ success: true, message: 'Appointment updated successfully' }));
                }
                handleNext({ success: true, message: 'Appointment updated successfully' });
            }
            dispatch(startLoadingData(false));
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleNext({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function UpdateAssignMember(data, practiceId, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/appointments/${data.id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(getAppointment(practiceId, data?.lead));
                handleSuccess({ success: true, message: 'Appointment practitioner updated successfully' });
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleSuccess({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function FailedToAttendAppointment(data, leadId, id, practiceId, handleClose, activeTab) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/failed_to_attend/`, 'post', data);
            const { newLeadDetail: { archiveLead }, tableRowLead } = store.getState().lead;
            if (response.status === 200) {
                if (activeTab === 'failedAttendTreatment') {
                    handleClose({ success: true });
                    dispatch(startLoadingData(false));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Appointment failed successfully' }));
                if (activeTab === 'Unable to contact') {
                    dispatch(unableToContact(handleClose));
                    return;
                }
                if (activeTab === 'Archive') {
                    const archiveNote = {
                        ...archiveLead.notes,
                        lead: tableRowLead.lead_id
                    };
                    dispatch(addNotes(archiveNote, practiceId, handleClose));
                    return;
                }
                if (activeTab === 'Reschedule') {
                    dispatch(Reschedule(handleClose));
                }
            } else {
                errorHandler(response.data, handleClose, slice.actions.hasError);
            }
        } catch (error) {
            errorHandler(error, handleClose, slice.actions.hasError);
        }
    };
}

export function followUpSlice(id, practiceId, LeadDetail, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/`);
            if (response.status === 200) {
                if (handleClose) {
                    handleClose(response.data);
                }
                if (LeadDetail === 'LeadDetail') {
                    dispatch(slice.actions.saveLeadDetail(response.data));
                    dispatch(slice.actions.followUpLead(response.data));
                    return;
                }
                dispatch(getAppointment(practiceId, id));
                dispatch(slice.actions.followUpLead(response.data));
                const newCreateLead = {
                    id: response.data.id,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    salutation: response.data.salutation,
                    dob: response.data.dob,
                    address: response.data?.lead_address === null ? '' : response.data?.lead_address,
                    lead_contact: {
                        email: response.data?.lead_contact.email,
                        note: response.data?.lead_contact.note,
                        phone: response.data?.lead_contact.phone,
                        preferred_contact_method: response.data?.lead_contact.preferred_contact_method,
                    }
                };
                dispatch(slice.actions.saveLead(newCreateLead));
            } else {
                dispatch(slice.actions.saveLeadDetail(null));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error?.message));
        }
    };
}

export function followUpAppointmentHandler(appointmentData) {
    return dispatch(slice.actions.isAppointment(appointmentData));
}

export function addNotes(data, practiceId, handleClose, status, followLead) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/notes/`, 'post', data);

            if (response.status === 201) {
                const { newLeadDetail, createLead, tableRowLead, newLeadDetail: { archiveLead } } = store.getState().lead;

                if (status === "createLead") return

                if (status === 'UNABLE_TO_CONTACT') {
                    dispatch(updateError({ message: 'Note added successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    dispatch(startLoadingData(false));
                    handleClose();
                    return;
                }
                if (status === 'unable') {
                    if (newLeadDetail.addReminder) {
                        const reminderDetail = {
                            ...newLeadDetail.addReminder,
                            lead_treatment: getCurrentTreatmentIdV2(createLead.lead_treatment ? createLead.lead_treatment.id : 0),
                        };
                        dispatch(addReminder(reminderDetail, practiceId, handleClose, 'unable'));
                        return;
                    }
                    dispatch(updateError({ message: 'Note added successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', data?.lead);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'attended') {
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', data?.lead);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'archive' && newLeadDetail.archiveLead.callReminder && newLeadDetail.archiveLead.customReminder === 'no-reminder') {
                    dispatch(updateError({ message: 'Note added successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', data?.lead);
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'archive' && newLeadDetail.archiveLead.callReminder) {
                    const addReminderObj = {
                        ...newLeadDetail.archiveLead.addReminderObj,
                        lead: tableRowLead.lead_id || followLead.id,
                        lead_treatment: getCurrentTreatmentIdV2(tableRowLead?.treatment_id || followLead?.lead_treatments[0]?.id)
                    };
                    dispatch(addReminder(addReminderObj, practiceId, handleClose));
                    return;
                }
                if (status === 'leadNote') {
                    if (handleClose) {
                        handleClose('finish', data?.lead);
                    }
                    dispatch(slice.actions.addLeadNotes(response?.data?.results || response?.data));;
                    dispatch(updateError({ message: 'Note added successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'leadNotePopup') {
                    if (handleClose) {
                        handleClose('finish', data?.lead);
                    }
                    dispatch(updateError({ message: 'Note added successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    dispatch(startLoadingData(false));
                    return;
                }
                if (status === 'nhs') {
                    dispatch(updateError({ message: 'Add to the NHS waiting list successfully', success: true }));
                    dispatch(updateCompleteStatus(false));
                    handleClose('finish', data?.lead);
                    dispatch(startLoadingData(false));
                    return
                }
                const archivedData = { ...archiveLead.openCommunication, id: (archiveLead.openCommunication.id ? archiveLead.openCommunication.id : createLead.id) };
                dispatch(updatePersonalDetail(archivedData, practiceId, handleClose, 'archived'));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data || 'Something went wrong'), success: false }));
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message || 'Something went wrong'), success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function addReminder(data, practiceId, handleClose, value) {
    return async () => {
        try {

            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/`, 'post', data);
            if (response.status === 201) {

                if (value === "createLead") return;

                dispatch(updateCompleteStatus(false));
                if (value === 'unable') {
                    dispatch(updateError({ success: true, message: 'Note and Reminder added successfully', reminder: false }));
                } else {
                    dispatch(updateError({ success: true, message: 'Reminder added successfully', reminder: false }));
                }
                if (value === 'LeadDetail') {
                    handleClose();
                    return;
                }
                handleClose('finish', data?.lead);
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
        }
    };
}

export function searchLead(data, practiceId, type, status, treatmentId) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/search/?query=${data}${type === 'callScriptPreview' ? '&lead_treatments__status=NEW&is_archived=false' : type === 'FinancialTracker' ? `&is_archived=false&lead_treatments__isnull=false${status ? `&lead_treatments__status__in=${status}` : ''}` : type === 'taskLead' ? '&is_archived=false' : type === 'AlignerApprove' ? `&is_archived=false&lead_treatments__isnull=false&lead_treatments__status=TREATMENT_STARTED&lead_treatments__treatment__in=${treatmentId}&lead_treatment_attributes__contains=${JSON.stringify([{ value: 'Arrived' }])}` : ''}${type === 'ReviewLink' ? '&lead_treatments__appointments__status__in=ATTENDED&is_archived=false&lead_treatments__isnull=false' : ''}&fields={archived_at,archived_at_human,assignees,created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,lead_treatments,salutation,updated_at,updated_at_human}`);
            if (response.status === 200) {
                switch (type) {
                    case 'AlignerApprove':
                    case 'ReviewLink':
                    case 'FinancialTracker':
                    case 'taskLead':
                    case 'callScriptPreview':
                        dispatch(slice.actions.addTaskSearchLead(response.data.results));
                        break;
                    default:
                        dispatch(slice.actions.searchAPIdata(response.data.results));
                        break;
                }
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data || 'Something went wrong'), success: false }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function appointmentsAttended(practiceId, leadId, id, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/attended/`, 'post', data);
            if (response.status === 200) {
                handleClose({ success: true });
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
            dispatch(updateError({ message: JSON.stringify(response.data || 'Something went wrong'), success: false }));
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message || 'Something went wrong'), success: false }));
        }
    };
}

export function addAppointmentNotes(practiceId, leadId, id, data, handleClose) {
    return async () => {
        try {
            const {
                newLeadDetail: {
                    attentendAppoiment,
                },
                appointmentNotes
            } = store.getState().lead;
            if (!attentendAppoiment.callAppointmentNote) {
                dispatch(attenedAppoimentNotes(practiceId, leadId, handleClose));
                return;
            }
            if (appointmentNotes.length === 0) {
                const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/appointment_notes/`, 'post', data);
                if (response.status === 201) {
                    dispatch(attenedAppoimentNotes(practiceId, leadId, handleClose));
                    dispatch(updateError({ success: true, message: 'Appointment notes added successfully' }));
                    return;
                }
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            } else {
                dispatch(updateAppointmentNotes(practiceId, leadId, id, appointmentNotes, data, handleClose));
            }
            dispatch(updateCompleteStatus(false));
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: error.message, success: false }));
        }
    };
}

export function getAppointmentNotes(practiceId, leadId, id, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/appointment_notes/`);
            if (response.status === 200) {
                dispatch(slice.actions.appointmentNotesSlice(response.data.results));
            } else {
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                });
                handleNext({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleNext({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function updateAppointmentNotes(practiceId, leadId, id, appointmentNotes, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/appointment_notes/${appointmentNotes[0].id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(attenedAppoimentNotes(practiceId, leadId, handleClose));
                dispatch(updateError({ success: true, message: 'Appointment notes updated successfully' }));
                return;
            }
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleClose({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function archivedLeadAPI(data, practiceId, handleClose, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/archive/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: 'Lead will archived in 5 minutes.', success: true }));
                handleClose('finish', true);
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(response.data) || 'Something went wrong', success: false }));
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function getLeadNotes(id, practiceId, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/notes/?fields={additional_notes,contacted_via,id,lead,note,treatment,created_by,created_at,updated_at}&ordering=-updated_at`);
            if (response.status === 200) {
                dispatch(slice.actions.leadNote(response.data.results || response.data));;
            } else {
                errorHandler(response.data, handleNext, slice.actions.hasError);
            }
        } catch (error) {
            errorHandler(error, handleNext, slice.actions.hasError);
        }
    };
}

export function getLeadNotesWithFilter(id, practiceId, handleNext) {
    return async () => {
        try {
            const notes = ['AWAITING_RESPONSE', 'CHANGED_MIND', 'CREDIT_CHECK_FAILED', 'FOLLOW_UP', 'OTHER', 'TOO_EXPENSIVE'];
            const notesQueryParam = encodeURIComponent(notes.join(','));
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/notes/?fields={}&note__in=${notesQueryParam}`);
            if (response.status === 200) {
                dispatch(slice.actions.leadNoteWithFilter(response.data));;
            } else {
                errorHandler(response.data, handleNext, slice.actions.hasError);
            }
        } catch (error) {
            errorHandler(error, handleNext, slice.actions.hasError);
        }
    };
}

export function getLeadReminderData(practiceId, data, limit, offset, filter, id, assigned, handleNext, grater, counter, additionalfilters, tasksCompletedUser, isLeadReminderFlow, taskDateRange, taskUnassignUser) {
    return async () => {
        try {
            dispatch(startLoadingData(true));
            const dateFilter = taskDateRange ? `&datetime__date__range=${taskDateRange}` : '';
            const unassignedUserFilter = taskUnassignUser ? '&assignees__isnull=true' : '';
            const param = `&limit=${limit}&offset=${offset}${id && `&lead=${id}`}${assigned && `&assignees__user__id__in=${assigned}`}&ordering=datetime${filter !== 'TODO' && filter !== 'leadNotes' && filter !== '' && filter !== "CALLBACK" ? `&lead_treatment__status=${filter}` : ''}${additionalfilters === 'Overdue' ? `&datetime__date__lte=${moment(new Date().setDate(new Date().getDate() - 1)).format('YYYY-MM-DD')}` : (grater === 'Graterthan' || grater === 'firstTime') && !additionalfilters ? `&datetime__date__gte=${moment(new Date()).format('YYYY-MM-DD')}` : additionalfilters === 'Upcoming' ? `&datetime__date__gte=${moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD')}` : additionalfilters === 'Today' ? `&datetime__date__lte=${moment(new Date().setDate(new Date().getDate())).format('YYYY-MM-DD')}` : ''}`;
            const fields = `&fields=${reminderFields}`;

            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/?group__in=${filter === 'TODO' ? filter : filter === "CALLBACK" ? 'CALLBACK_REQUEST' : `${data === 'LeadDetail' ? 'TODO,FOLLOW_UP,NEW_LEAD' : 'TODO,FOLLOW_UP,CALLBACK_REQUEST'}`}&is_completed=${tasksCompletedUser === undefined ? false : tasksCompletedUser}${param}${fields}&lead__is_archived=false${dateFilter}${unassignedUserFilter}`);

            if (response.status === 200) {
                const result = response.data;

                if (data === 'LeadDetail') {
                    if (counter === 'counter') {
                        dispatch(slice.actions.addTaskCounter(result?.count));
                    }
                    if (grater === 'firstTime') {
                        dispatch(slice.actions.upComingTask(result?.results.length > 0 && result?.results[0]));
                        return;
                    }
                    if (grater === 'Graterthan') {
                        dispatch(slice.actions.AddLeadNotes(result));
                        dispatch(slice.actions.upComingTask(result?.results.length > 0 && result?.results[0]));
                        return;
                    }
                    if (filter === 'leadNotes' || grater === 'leadNotes') {
                        dispatch(slice.actions.AddLeadNotes(result));
                    }
                    dispatch(slice.actions.leadReminderData(result));
                    if (isLeadReminderFlow) {
                        if (offset === 0) {
                            dispatch(slice.actions.leadFlowReminderData(result));
                            return;
                        }
                        dispatch(slice.actions.leadFlowReminderMoreData(result));
                    }
                }
                if (data === 'dashboard') {
                    dispatch(slice.actions.getAllReminderData(result));
                }
                if (data === 'Tasks') {
                    dispatch(slice.actions.getTasksPageData(result));
                }
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data || 'Something went wrong'), success: false }));
                return;
            }

        }

        catch (error) {
            handleNext({
                success: false,
                message: JSON.stringify(error?.message || error) || 'Something went wrong'
            });
            dispatch(slice.actions.hasError(error?.message || error));

        }
        finally {
            dispatch(startLoadingData(false));
        }
    }
};


export const handleTaskApiLoading = (filter) => {
    const { loadingApis } = store.getState().lead;
    if (filter === '') {
        dispatch(setLoadingApis(loadingApis.filter((ele) => ele !== 'All')));
    } else {
        dispatch(setLoadingApis(loadingApis.filter((ele) => ele !== filter)));
    }
};

export function leadTaskListCounter(practiceId, type, filter, id, assigned, additionalFilter, tasksCompletedUser, isFlowCounter, taskDateRange) {
    return async () => {
        try {
            dispatch(startLoadingData(true));
            const dateFilter = taskDateRange ? `&datetime__date__range=${taskDateRange}` : '';
            const param = `&limit=${1}&offset=${0}${id ? `&lead=${id}` : ''}${assigned ? `&assignees__user__id__in=${assigned}` : ''}&ordering=datetime${filter !== 'TODO' && filter !== 'leadNotes' && filter !== '' && filter !== 'CALLBACK' ? `&lead_treatment__status=${filter}` : ''}`;
            const fields = '&fields={}';

            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/?group__in=${filter === 'TODO' ? 'TODO' : filter === 'CALLBACK' ? 'CALLBACK_REQUEST' : type === 'LeadDetail' ? 'TODO,FOLLOW_UP,NEW_LEAD' : 'TODO,FOLLOW_UP,CALLBACK_REQUEST'}&is_completed=${tasksCompletedUser === undefined ? false : tasksCompletedUser}${param}${fields}&lead__is_archived=false${dateFilter}`);
            if (response.status === 200) {
                const result = response.data;
                const { taskCounter, taskLeadCounter, separateTaskCounter, taskFlowCounter } = store.getState().lead;
                if (type === 'dashboard') {
                    dispatch(slice.actions.updateTaskCounter([{ filter, count: result?.count }, ...taskCounter]));
                }
                if (type === 'LeadDetail') {
                    dispatch(slice.actions.updateLeadTaskCounter([{ filter, count: result?.count }, ...taskLeadCounter]));
                }
                if (isFlowCounter) {
                    dispatch(slice.actions.updateFlowTaskCounter([{ filter, count: result?.count }, ...taskFlowCounter]));
                }
                if (type === 'Tasks') {
                    dispatch(slice.actions.updateSeparateTaskCounter([{ filter, count: result?.count }, ...separateTaskCounter]));
                }


            } else {
                dispatch(updateError({ message: response?.data || 'Something went wrong', success: false }));
            }

        } catch (error) {
            dispatch(updateError({ message: error.message || 'Something went wrong', success: false }));
            dispatch(slice.actions.hasError(error.message));
        }
        finally {
            handleTaskApiLoading(filter);
            dispatch(startLoadingData(false));
        }
    };
}
export function createNewLead(handleClose) {
    return async () => {
        try {
            const { newLeadDetail } = store.getState().lead;
            const { practiceId } = store.getState().practiceTreatmentList;
            dispatch(startLoadingData(true));
            dispatch(savePersonalDetails(newLeadDetail.personalDetail, practiceId, handleClose));
        } catch (error) {
            dispatch(updateError({ message: error.message, success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export const getCurrentTreatmentData = (data) => {
    const { currentTreatmentDataGlobal } = store.getState().multiTreatment;
    if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal.treatmentData;
    return data;
};

export function updateLeadDetail(data, handleClose, isNoNotes) {
    return async () => {
        try {

            const { newLeadDetail: { lead_appointment: leadAppoiment, archiveLead }, followLead, newLeadDetail, activeTab } = store.getState().lead;
            const { lead_treatments: leadTreatment } = followLead;
            const { practiceId } = store.getState().practiceTreatmentList;
            dispatch(startLoadingData(true));
            dispatch(setLoader(true));
            localStorageRemoveItem('localTreatmentData');
            if (leadTreatment && leadTreatment.length) {
                const treatment = getCurrentTreatmentData(leadTreatment[0]);
                if (data === 'Unable to contact') {
                    dispatch(slice.actions.saveTreatment(treatment)); dispatch(unableToContact(handleClose, isNoNotes));
                    return;
                }
                if (activeTab >= 1 && archiveLead && archiveLead.notes) {
                    const archiveNote = {
                        ...archiveLead.notes,
                        lead: treatment.lead,
                        treatment: treatment.id
                    };

                    if (archiveNote.note === "NHS_WAITING_LIST") {
                        dispatch(addNotes(archiveNote, practiceId, handleClose, 'nhs'));
                        return;
                    }

                    dispatch(addNotes(archiveNote, practiceId, handleClose, 'archive'));
                    return;
                }
                const updatedTreatment = newLeadDetail && newLeadDetail.lead_treatment && newLeadDetail.lead_treatment || {};
                dispatch(slice.actions.saveTreatment(treatment));
                if (treatment.treatment !== updatedTreatment.treatment || treatment.price !== updatedTreatment.custom_price || treatment.leadtreatment_specification?.teeth_count !== updatedTreatment.teethAmount) {
                    const treatmentDetail = {
                        ...newLeadDetail.lead_treatment,
                        lead: treatment.lead,
                        id: treatment.id
                    };
                    dispatch(UpdateTreatmentV2(treatmentDetail, practiceId, handleClose, 'updateLeadDetail'));
                    return;
                }
                dispatch(addAppointment({ ...leadAppoiment, lead_treatment: treatment.id, lead: treatment.lead, }, practiceId, treatment.lead, handleClose, 'followUp'));
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateError({ message: error.message, success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function getReminderData(practiceId, id) {
    return async () => {
        try {

            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/${id}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getTaskDetails(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function deleteReminderData(practiceId, id, handleSuccess, handleRefresh) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/${id}/`, 'delete');
            if (response.status === 204) {
                handleSuccess({ success: true, message: 'Reminder deleted successfully' });
                handleRefresh();
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function AddReminder(data, practiceId, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/`, 'post', data);
            if (response.status === 201) {
                handleNext({ success: true, message: 'Lead will archive in 5 minutes' });
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function ConsultationAttended(handleClose) {
    return async () => {
        try {
            const { newLeadDetail, followLead } = store.getState().lead;
            const { depositData } = store.getState().deposit;
            const { practiceId } = store.getState().practiceTreatmentList;
            const { lead_treatments: leadTreatment, lead_appointment: leadAppoiment } = followLead;
            dispatch(startLoadingData(true));
            dispatch(setLoader(true));
            if (depositData?.isDepositFlow) {
                dispatch(creteTreatmentPaymentFlow());
            }
            if (leadTreatment && leadTreatment.length && leadAppoiment && leadAppoiment.length) {
                const treatment = getCurrentTreatmentData(leadTreatment[0]);
                const appointment = leadAppoiment.filter((el) => el.status === 'ATTENDED' || el.status === 'CONSULTATION_ATTENDED')[0];
                dispatch(slice.actions.saveAppointment(appointment));
                dispatch(slice.actions.saveTreatment(treatment));
                dispatch(slice.actions.saveNewLeadDetail({ lead_treatment: treatment }));
                const { noteDetail } = newLeadDetail?.attentendAppoiment;
                dispatch(addAppointmentNotes(practiceId, followLead.id, appointment.id, noteDetail, handleClose));
                if (newLeadDetail?.addReminder) {
                    const leadNoteData = {
                        lead: followLead.id,
                        note: 'FOLLOW_UP',
                        treatment: getCurrentTreatmentIdV2(followLead?.lead_treatments[0]?.id)
                    };
                    dispatch(addNotes(leadNoteData, practiceId, handleClose, 'attended'));
                }
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
            dispatch(setLoader(true));
        }
    };
}

export function RecordAttended(handleClose) {
    return async () => {
        try {
            const { newLeadDetail: { attentendAppoiment }, followLead } = store.getState().lead;
            const { depositData } = store.getState().deposit;
            const { practiceId } = store.getState().practiceTreatmentList;
            const { lead_treatments: leadTreatment, lead_appointment: leadAppoiment } = followLead;
            dispatch(startLoadingData(true));
            dispatch(setLoader(true));
            if (leadTreatment && leadTreatment.length && leadAppoiment && leadAppoiment.length) {
                const treatment = leadTreatment[0];
                const appointment = leadAppoiment.filter((el) => el.status === 'BOOKED' || el.status === 'CONSULTATION_BOOKED')?.[0];
                dispatch(slice.actions.saveAppointment(appointment));
                dispatch(slice.actions.saveTreatment(treatment));
                dispatch(slice.actions.saveNewLeadDetail({ lead_treatment: treatment }));
                const { noteDetail } = attentendAppoiment;
                const appointmentDetail = {
                    contacted_via: appointment.contacted_via,
                    datetime: appointment.datetime,
                    lead_treatment: appointment.lead_treatment,
                    method: appointment.method,
                    note: appointment.note,
                };
                if (appointment?.assignees?.length > 0) {
                    appointmentDetail.assignees = appointment.assignees;
                }
                const handleApi = (data) => {
                    if (depositData?.isDepositFlow) {
                        dispatch(creteTreatmentPaymentFlow());
                    }
                    if (data.success) {
                        dispatch(addAppointmentNotes(practiceId, followLead.id, appointment.id, noteDetail, handleClose));
                    }
                };
                dispatch(appointmentsAttended(practiceId, followLead.id, appointment.id, appointmentDetail, handleApi));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
            dispatch(setLoader(false));
        }
    };
}

export function recordUnAttended(handleClose) {
    return async () => {
        try {
            const { newLeadDetail, followLead } = store.getState().lead;
            dispatch(startLoadingData(true));
            const { faildAttended, } = newLeadDetail;
            const { lead_treatments: leadTreatment, lead_appointment: leadAppoiment } = followLead;
            const { practiceId } = store.getState().practiceTreatmentList;
            const appoinmentID = leadAppoiment?.filter((el) => el.status === 'BOOKED' || el.status === 'CONSULTATION_BOOKED');
            const statuses = ['FAILED_TO_ATTEND_CONSULTATION', 'FAILED_TO_ATTEND'];
            const appointmentDetail = {
                lead_treatment: leadTreatment[0].id,
            };
            if (leadTreatment && leadTreatment.length) {
                const treatment = leadTreatment[0];
                const { newLeadDetail: { archiveLead }, tableRowLead } = store.getState().lead;
                dispatch(slice.actions.saveTreatment(treatment));
                dispatch(slice.actions.saveNewLeadDetail({ lead_treatment: treatment }));

                if (!statuses.includes(tableRowLead?.status) && faildAttended.activeTab !== 'Reschedule' && appoinmentID.length > 0) {
                    dispatch(FailedToAttendAppointment(appointmentDetail, leadTreatment[0]?.lead, appoinmentID[0].id, practiceId, handleClose, faildAttended?.activeTab));
                    return;
                }
                if (faildAttended.activeTab === 'Unable to contact') {
                    dispatch(unableToContact(handleClose));
                    return;
                }
                if (faildAttended.activeTab === 'Archive') {
                    const archiveNote = {
                        ...archiveLead.notes,
                        lead: tableRowLead.lead_id
                    };
                    dispatch(addNotes(archiveNote, practiceId, handleClose));
                    return;
                }
                if (faildAttended.activeTab === 'Reschedule') {
                    dispatch(Reschedule(handleClose));
                }
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
        }
    };
}

export function unableToContact(handleClose, isNoNotes) {
    return async () => {
        try {
            const { newLeadDetail, createLead } = store.getState().lead;
            const { unableToContact } = newLeadDetail;
            const { practiceId } = store.getState().practiceTreatmentList;
            if (unableToContact && unableToContact.noteDetail) {
                const noteDetail = {
                    ...unableToContact.noteDetail,
                    contacted_via: 'PHONE'
                };

                if (!isNoNotes) {
                    dispatch(addNotes(noteDetail, practiceId, handleClose, 'unable'));
                    return;
                }

                if (newLeadDetail.addReminder) {
                    const reminderDetail = {
                        ...newLeadDetail.addReminder,
                        lead_treatment: getCurrentTreatmentIdV2(createLead.lead_treatment ? createLead.lead_treatment.id : 0),
                    };
                    dispatch(addReminder(reminderDetail, practiceId, handleClose, 'unable'));
                    return;
                }
                // dispatch(updateError({ message: 'Note added successfully', success: true }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish', noteDetail?.lead);
                dispatch(startLoadingData(false));
                return;
            }

            if (newLeadDetail.addReminder) {
                const reminderDetail = {
                    ...newLeadDetail.addReminder,
                    lead_treatment: createLead.lead_treatment ? createLead.lead_treatment.id : 0,
                };
                dispatch(addReminder(reminderDetail, practiceId, handleClose, 'finish'));
                return;
            }
            handleClose('finish');
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
        }
    };
}

export function archivedLead({ practiceId, offset, limit, treatment, searchQuery, tags, referral, startDate, endDate, order, orderId, archiveReason }) {
    return async () => {
        try {
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const archiveSearchQuery = searchQuery ? `&search_query=${searchQuery}` : '';
            const archivedTags = tags ? `&lead_tags__tag__tag__name__in=${tags}` : '';
            const archiveReferral = referral ? `&lead_source__referral__in=${referral}` : '';
            const archiveDate = (startDate && endDate) ? `&created_at__range=${`${startDate} 00:00:00`},${`${endDate} 23:59:00`}` : '';
            const reason = archiveReason ? `&archived_reason__in=${archiveReason}` : '';
            const response = await callApi(`/api/v1/practices/${practiceId}/archived/?limit=${limit}&offset=${offset}${orderingLead}${treatmentQuery}${archiveSearchQuery}${archivedTags}${archiveReferral}${archiveDate}&is_archived=true${reason}`);
            if (response.status === 200) {
                dispatch(archiveLead(response.data));
                dispatch(startLoadingData(false));
            } else {
                dispatch(slice.actions.hasError(response.data.message || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.data.message));
        }
    };
}

export function Reschedule(handleClose) {
    return async () => {
        try {
            const { newLeadDetail: { startTreatment }, createLead, followLead, tableRowLead } = store.getState().lead;
            const { lead_treatments: leadTreatment } = followLead;
            const { practiceId } = store.getState().practiceTreatmentList;
            if (startTreatment) {
                const treatment = leadTreatment[0];
                if (JSON.stringify(startTreatment.updatedTreatment.treatment) !== JSON.stringify(leadTreatment[0].treatment) || JSON.stringify(startTreatment.updatedTreatment.custom_price) !== JSON.stringify(leadTreatment[0].price) || JSON.stringify(startTreatment.updatedTreatment.teethAmount) !== JSON.stringify(leadTreatment[0]?.leadtreatment_specification?.teeth_count)) {
                    const updateTreatment = {
                        ...startTreatment.updatedTreatment,
                        lead: treatment.lead,
                        id: getCurrentTreatmentIdV2(createLead.lead_treatment.id)
                    };
                    dispatch(UpdateTreatmentV2(updateTreatment, practiceId, handleClose, 'RescheduleTreatment'));
                    return;
                }
                if (tableRowLead?.status === 'FAILED_TO_ATTEND_CONSULTATION' || tableRowLead?.status === 'FAILED_TO_ATTEND') {
                    dispatch(addAppointment(startTreatment?.appointmentDetail, practiceId, treatment.lead, handleClose, 'Reschedule'));
                    return;
                }
                dispatch(UpdateAppointment(startTreatment?.appointmentDetail, practiceId, handleClose, 'Reschedule'));
                return;
            }
            handleClose('finish');
            dispatch(startLoadingData(false));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
        }
    };
}

export function attenedAppoimentNotes(practiceId, leadId, handleClose) {
    return async () => {
        const {
            newLeadDetail: {
                lead_treatment: leadTreatment,
                attentendAppoiment,
                startTreatment,
                archiveLead,
            },
            newLeadDetail,
            createLead
        } = store.getState().lead;
        if (attentendAppoiment?.activeTab === 'Starting treatment') {
            const treatment = {
                treatment: leadTreatment?.treatment,
                custom_price: leadTreatment?.price
            };
            if (JSON.stringify(startTreatment.updatedTreatment) !== JSON.stringify(treatment)) {
                const updateTreatment = {
                    ...(startTreatment?.updatedTreatment ?? {}),
                    lead: leadId,
                    id: getCurrentTreatmentIdV2(createLead?.lead_treatment.id)
                };
                dispatch(UpdateTreatmentV2(updateTreatment, practiceId, handleClose, 'finish'));
                return;
            }
            const startTreatments = {
                ...startTreatment.updatedTreatment,
                lead: leadId,
                id: getCurrentTreatmentIdV2(createLead?.lead_treatment.id)
            };
            dispatch(StartingTreatment(startTreatments, practiceId, handleClose, 'finish'));
            dispatch(updateCompleteStatus(false));
            return;
        }
        if (attentendAppoiment?.activeTab === 'Follow Up' && newLeadDetail.addReminder) {
            const reminderDetail = {
                ...newLeadDetail.addReminder,
                lead: createLead.id,
                lead_treatment: getCurrentTreatmentIdV2(createLead.lead_treatment.id) || 0,
                lead_appointment: getCurrentLeadAppointmentId(createLead.lead_appointment.id) || 0
            };
            dispatch(addReminder(reminderDetail, practiceId, handleClose));
            return;
        }
        if (attentendAppoiment?.activeTab === 'Treatment complete') {
            dispatch(CompleteTreatment(leadTreatment, leadId, getCurrentTreatmentIdV2(createLead.lead_treatment.id), practiceId, handleClose));
            return;
        }
        if (attentendAppoiment?.activeTab === 'Archive') {
            const archiveNote = {
                ...archiveLead.notes,
                lead: leadId
            };
            dispatch(addNotes(archiveNote, practiceId, handleClose));
            return;
        }
        dispatch(updateCompleteStatus(false));
        dispatch(updateError({ message: 'Treatment and Appointment updated successfully', success: true }));
        handleClose('finish', leadId);
        dispatch(startLoadingData(false));
    };
}

export function restoreArchived(handleClose) {
    return async () => {
        try {
            const { newLeadDetail: { archiveLead }, tableRowLead } = store.getState().lead;
            const { practiceId } = store.getState().practiceTreatmentList;
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${tableRowLead.lead_id}/restore/`, 'post', archiveLead.archiveData);
            if (response.status === 200) {
                dispatch(updateError({ message: 'Lead will restore in 5 minutes.', success: true }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish');
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
            dispatch(startLoadingData(false));
        }
    };
}

export function editReminder(data, id, practiceId, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/${id}/`, 'put', data);
            if (response.status === 200) {
                handleNext({ success: true, message: 'Reminder updated successfully' });
            } else {
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                });
                handleNext({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleNext({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function updateReminder(data, id, practiceId, handleRefresh, value, handleSuccess, isCompletedValue) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/${id}/`, 'patch', data);
            if (response.status === 200) {
                handleRefresh(value);
                if (value === 'markComplete' && handleSuccess && !isCompletedValue) {
                    handleSuccess({ success: true, message: 'Task updated successfully !', });
                }
                if (isCompletedValue === 'isCompleted' && handleSuccess) {
                    handleSuccess({ success: true, message: 'Task not completed !', });
                }
            } else {
                errorHandler(response.data, handleSuccess);
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function updateAssignToUser(data, id, practiceId, handleSuccess, type, updateType) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/`, 'patch', data);
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Lead updated successfully', event: type, leadUpdate: updateType });
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(updateCompleteStatus(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function ArchivedLeadDashBoard(handleClose, followLead) {
    return async () => {
        try {
            const { newLeadDetail, tableRowLead } = store.getState().lead;
            const { practiceId } = store.getState().practiceTreatmentList;
            const treatmentId =
                tableRowLead?.treatment_id ||
                tableRowLead?.lead_treatments?.[0]?.id ||
                followLead?.lead_treatments?.[0]?.id ||
                '';
            const treatment = treatmentId !== undefined ? { treatment: treatmentId } : {};
            const archiveNote = {
                ...newLeadDetail.archiveLead.notes,
                lead: tableRowLead?.lead_id || followLead?.id,
                ...treatment,
            };
            dispatch(addNotes(archiveNote, practiceId, handleClose, 'archive', followLead));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function leadDetailPayment(handleClose) {
    return async () => {
        try {
            const { newLeadDetail: { payment }, leadDetail } = store.getState().lead;
            const { practiceId } = store.getState().practiceTreatmentList;
            if (payment.payment_type !== 'No') {
                dispatch(startLoadingData(true));
                dispatch(leadTreatmentPayment(practiceId, leadDetail?.id, leadDetail?.lead_treatments[0]?.id, payment, handleClose));
            } else {
                handleClose();
                dispatch(updateCompleteStatus(false));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function leadTreatmentPayment(practiceId, leadId, treatmentId, data, handleClose) {
    return async () => {
        try {
            const value = {
                ...data,
                mode_of_payment: data.mode_of_payment === 'SEND' ? 'EMAIL' : data.mode_of_payment
            };
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${getCurrentTreatmentIdV2(treatmentId)}/payments/`, 'post', value);
            if (response.status === 201) {
                const { newLeadDetail: { payment } } = store.getState().lead;
                if (payment.mode_of_payment === 'SEND') {
                    dispatch(paymentCheckout(practiceId, leadId, getCurrentTreatmentIdV2(treatmentId), response.data.id, handleClose));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Payment detail added successfully' }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish', leadId);
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
                dispatch(updateCompleteStatus(false));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
            dispatch(startLoadingData(false));
        }
    };
}

export function getLeadTreatmentPayment(practiceId, leadId, treatmentId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/payments/`);
            if (response.status === 200) {
                dispatch(slice.actions.paymentCompleteSlice(response.data.results));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function getLeadPaymentCheckout(practiceId, leadId, treatmentId, paymentId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/payments/${paymentId}/checkout_sessions/`);
            if (response.status === 200) {
                dispatch(slice.actions.checkoutListingSlice(response.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function getLeadRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/payments/${paymentId}/checkout_sessions/${checkoutId}/refund_requests/`);
            if (response.status === 200) {
                dispatch(slice.actions.refundListingSlice(response.data.results));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function createRefundRequest(data, practiceId, leadId, treatmentId, paymentId, checkoutId, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/payments/${paymentId}/checkout_sessions/${checkoutId}/refund_requests/`, 'post', data);
            if (response.status === 201) {
                handleSuccess({ success: true, message: 'Refund added successfully' });
                dispatch(getLeadRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function retryRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/payments/${paymentId}/checkout_sessions/${checkoutId}/refund_requests/${id}/retry/`, 'post');
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Refund added successfully' });
                dispatch(getLeadRefundRequest(practiceId, leadId, treatmentId, paymentId, checkoutId));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function paymentCheckout(practiceId, lead, treatment, paymentId, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${lead}/treatments/${treatment}/payments/${paymentId}/checkout_sessions/`, 'post', {});
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Payment link send successfully' }));
                dispatch(updateCompleteStatus(false));
                handleClose('finish', lead);
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateError({ success: false, message: response.data.message || 'Something went wrong' }));
                dispatch(updateCompleteStatus(false));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(startLoadingData(false));
        }
    };
}

export function updateTreatmentDetail(practiceId, leadId, id, detail, handleSuccess, type, handleReloadAPI) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${id}/`, 'patch', detail);
            if (response.status === 200) {
                if (handleReloadAPI) {
                    handleReloadAPI('finish');
                }
                if (type === 'leadDataGrid') {
                    dispatch(updateError({ success: true, message: 'Treatment updated successfully' }));
                    return;
                }
                if (type === 'deposit_cell') {
                    handleSuccess({ success: true, message: 'Deposit status updated successfully', updateTreatment: true, event: type });
                    return;
                }
                if (type === 'choice_cell') {
                    handleSuccess({ success: true, message: 'Lead updated successfully', updateTreatment: true, event: type });
                    return;
                }
                handleSuccess({ success: true, message: 'Treatment updated successfully', updateTreatment: true, event: type });
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
                dispatch(setLoader(false));
            }
        } catch (error) {
            handleSuccess({ success: false, message: error.message || 'Something went wrong' });
            dispatch(slice.actions.hasError(error.message));
            dispatch(setLoader(false));
        }
    };
}

export function deleteTreatment(practiceId, leadId, id, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${id}/`, 'delete');
            if (response.status === 204) {
                handleNext({ success: true, message: 'Treatment deleted successfully', cancelTreatment: true });
                dispatch(followUpSlice(leadId, practiceId, 'LeadDetail'));
            } else {
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                });
                handleNext({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        } catch (error) {
            handleNext({ success: false, message: error.message || 'Something went wrong' });
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function addScheduleReminder(data, practiceId, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/`, 'post', data);
            if (response.status === 201) {
                handleNext({ success: true, message: 'Reminder added successfully', reminder: false });
            } else {
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                });
                handleNext({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            handleNext({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function getLeadSummary(practiceId, startDate, endDate, status) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/lead-treatment-category-summary/${status ? `?status=${status}` : ''}${startDate ? `?time_after=${startDate}&` : ''}${endDate ? `time_before=${endDate}` : ''}`);
            if (response.status === 200) {
                dispatch(slice.actions.leadSummaryListingSlice(response.data));
            } else {
                dispatch(updateError({ success: false, message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function getLeadStatusSummary(practiceId, startDate, endDate, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/lead-status-summary/${startDate ? `?time_after=${startDate}&` : ''}${endDate ? `time_before=${endDate}` : ''}`);
            if (response.status === 200) {
                if (type === 'ReportSummary') {
                    dispatch(slice.actions.leadStatusSummaryListingSlice(response.data));
                }
                dispatch(slice.actions.addDashboardStatusesSummery(response.data));
            } else {
                dispatch(updateError({ success: false, message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function getAutoMatedMessages(practiceId, leadId, handleNext) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/tasks/?fields={created_at,created_at_human,due,due_human,id,is_cancelled,is_enqueued,name,task_status,updated_at,updated_at_human,instance_object}`);
            if (response.status === 200) {
                dispatch(slice.actions.addAutoMatedMessages(response.data));
            } else {
                errorHandler(response.data, handleNext, slice.actions.hasError);
            }
        } catch (error) {
            errorHandler(error, handleNext, slice.actions.hasError);
        }
    };
}

export function AutoMatedMessageStatusUpdate(practiceId, leadId, id, detail, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/tasks/${id}/`, 'patch', detail);
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Message detail updated successfully' });
                dispatch(getAutoMatedMessages(practiceId, leadId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function getLeadArchivedSummary(practiceId, startDate, endDate) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/archived-lead-summary/${startDate ? `?time_after=${startDate}&` : ''}${endDate ? `time_before=${endDate}` : ''}`);
            if (response.status === 200) {
                dispatch(slice.actions.leadArchivedSummaryListingSlice(response.data));
            } else {
                dispatch(updateError({ success: false, message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function cancelAppointment(practiceId, leadId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/cancelled/`, 'post');
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Appointment cancel successfully' });
                dispatch(getAppointment(practiceId, leadId));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function deleteTaskReminder(practiceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/reminders/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ message: 'Reminder delete successfully', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error?.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function addNewLead(data, practiceId, treatmentDetail, formData, handleSuccess, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Lead created successfully' }));
                dispatch(addTreatment(treatmentDetail, practiceId, response?.data?.id, handleSuccess, { needToUpdate: treatmentDetail?.teethAmount || treatmentDetail?.teethAmount === 0, teeth: treatmentDetail?.teethAmount || 0 }));
                handleSuccess({ success: true, leadId: response?.data?.id, formData, event: type });
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(updateCompleteStatus(false));
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(startLoadingData(false));
        }
    };
}

export function createStripePayment(practiceId, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/checkout_sessions/`, 'post', data);
            if (response.status === 201) {
                handleSuccess({ success: true, message: 'Stripe payment created successfully', response: response?.data });
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
                document.body.classList.remove('points-event');
                handleSuccess({ success: false, message: JSON.stringify(response?.data) });
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function bulkArchivedOrRestore(practiceId, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/bulk_archive_restore/`, 'post', data);
            if (response.status === 200) {
                handleSuccess('finish');
                dispatch(updateError({ message: data.request_type === 'ARCHIVE' ? 'Lead will archived in 5 minutes.' : 'Restore completed successfully.', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
                handleSuccess('finish');
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function bulkUpdate(practiceId, data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/bulk_update/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateCompleteStatus(false));
                dispatch(startLoadingData(false));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function getMergeTags() {
    return async () => {
        try {
            const response = await callApi('/api/v1/utils/mergetags/');
            if (response.status === 200) {
                dispatch(slice.actions.setMergeTags(response?.data));
                localStorage.setItem('MergeTagsData', JSON.stringify(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function consultationAttended(practiceId, leadId, id, handleSuccess, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${id}/consultation_attended/`, 'post');
            if (response.status === 200) {
                if (type === 'TreatmentAccordion') {
                    handleSuccess({ success: true });
                    dispatch(startLoadingData(false));
                    return;
                }
                handleSuccess({ success: true, message: 'Lead status updated successfully' });
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            dispatch(updateCompleteStatus(false));
        }
    };
}

export function UpdateDepositPendingStatus(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data?.leadId}/treatments/${data.treatmentId}/`, 'put', data?.payload);
            if (response.status === 200) {
                dispatch(slice.actions.setCurrentTreatmentData(response?.data));
                if (handleClose) {
                    handleClose();
                } else {
                    dispatch(slice.actions.setTreatmentLoading(false));
                }
                dispatch(updateError({ message: JSON.stringify('Deposit status updated successfully'), success: true }));
                return;
            }
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            dispatch(startLoadingDepositPending(false));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
            dispatch(startLoadingDepositPending(false));
        }
    };
}
export function getIndividualTreatment(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data?.leadId}/treatments/${data.treatmentId}/`);
            dispatch(slice.actions.setTreatmentLoading(false));
            if (response.status === 200) {
                dispatch(slice.actions.setCurrentTreatmentData(response?.data));
                if (handleClose) {
                    handleClose();
                }
                return;
            }
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
        } catch (error) {
            dispatch(slice.actions.setTreatmentLoading(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function conversations(id, practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/conversations/`);
            if (response.status === 200) {
                dispatch(slice.actions.chatbotConversationSlice(response.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error?.message));
        }
    };
}

export function updateAppointmentDetail(practiceId, leadId, id, detail, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/`, 'patch', detail);
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Appointment updated successfully' });
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            handleSuccess({ success: false, message: error.message || 'Something went wrong' });
            dispatch(slice.actions.hasError(error.message));
        }
    };
}

export function updateTransitionTime(data, practiceId, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data?.lead}/treatments/${data.id}/update_transition_time/`, 'patch', data);
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Transition time updated successfully' });
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
                dispatch(startLoadingData(false));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message));
            dispatch(startLoadingData(false));
        }
    };
}
