/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Box, MenuItem, Select, InputLabel, FormControl, InputAdornment, IconButton, Button } from '@mui/material';
import { Iconify } from 'src/components';
import { followUpSlice, updateTreatmentDetail } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith
} from '../../../../utils/allTableTab';
import {
    tableCellInnerBoxStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const ChoicesField = ({ rowData, tableType, handleSetTableData, choiceValue, updateLeadPage, reloadCounter, choicesData }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [value, setValue] = useState('');
    const [dateValue, setDateValue] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const perPage = TablePerPage(tableType);
    const pageNumber = TablePageNumber(tableType);
    const status = TableStatus(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const contactCount = TabCount(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (rowData && choiceValue) {
            const newValue = rowData?.leadTreatmentAttributes?.find(el => el.key === choiceValue?.name)?.data?.[0]?.value || '';
            const dateString = rowData?.leadTreatmentAttributes?.find(el => el.key === choiceValue?.name)?.data?.[0]?.value;
            const initialDate = dateString ? moment(dateString, 'DD/MM/YYYY').toDate() : null;
            setValue(newValue);
            setDateValue(initialDate);
        }
    }, [rowData, choiceValue]);

    const updateAttributeData = (currentData, newValue) => {
        const latestCycle = Math.max(...currentData.map(d => d.cycle), 0);
        const existingCycleIndex = currentData.findIndex(d => d.cycle === latestCycle);
        if (existingCycleIndex !== -1) {
            currentData[existingCycleIndex] = { ...currentData[existingCycleIndex], value: newValue };
        } else {
            currentData.push({ value: newValue, cycle: latestCycle + 1 });
        }
        return currentData;
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        const approvalStatusKey = 'Approval Status';
        const approvedDateKey = 'Approved Date';
        let updatedAttributes = [...rowData.leadTreatmentAttributes];
        choicesData?.forEach((choice) => {
            const existingAttributeIndex = updatedAttributes.findIndex(attr => attr.key === choice.name);
            if (choice.name === choiceValue.name) {
                if (newValue === '') {
                    if (existingAttributeIndex !== -1) {
                        updatedAttributes = updatedAttributes.filter((_, index) => index !== existingAttributeIndex);
                    }
                } else if (existingAttributeIndex !== -1) {
                    const currentData = [...updatedAttributes[existingAttributeIndex].data];
                    const updatedData = updateAttributeData(currentData, newValue);
                    updatedAttributes[existingAttributeIndex] = { ...updatedAttributes[existingAttributeIndex], data: updatedData };
                } else {
                    updatedAttributes.push({
                        key: choice.name,
                        data: [{ value: newValue, cycle: 1 }],
                    });
                }
            } else if (existingAttributeIndex === -1) {
                updatedAttributes.push({
                    key: choice.name,
                    data: [{ value: '', cycle: 1 }],
                });
            }
        });
        if (choiceValue?.name?.toLowerCase().includes(approvalStatusKey.toLowerCase()) && newValue === 'Arrived') {
            const approvedDateIndex = updatedAttributes.findIndex(el => el.key === approvedDateKey);
            const currentDateString = moment().format('DD-MM-YYYY');
            if (approvedDateIndex !== -1) {
                const currentData = [...updatedAttributes[approvedDateIndex].data];
                const updatedData = updateAttributeData(currentData, currentDateString);
                updatedAttributes[approvedDateIndex] = { ...updatedAttributes[approvedDateIndex], data: updatedData };
            } else {
                updatedAttributes.push({
                    key: approvedDateKey,
                    data: [{ value: currentDateString, cycle: 1 }],
                });
            }
        }
        const detail = {
            lead_treatment_attributes: updatedAttributes
        };
        dispatch(updateTreatmentDetail(practiceId, rowData?.lead_id, rowData?.treatmentData?.[0]?.id, detail, handleSuccess, 'choice_cell'));
        setValue(newValue);
    };

    const onChangeDate = (newValue) => {
        const newValueWithDate = newValue ? moment(newValue, 'DD/MM/YYYY').format('DD-MM-YYYY') : '';
        const updatedAttributes = [...rowData.leadTreatmentAttributes];
        choicesData?.forEach((choice) => {
            const existingAttributeIndex = updatedAttributes.findIndex(attr => attr.key === choice.name);
            if (choice.name === choiceValue.name) {
                if (existingAttributeIndex !== -1) {
                    const currentData = [...updatedAttributes[existingAttributeIndex].data];
                    const updatedData = updateAttributeData(currentData, newValueWithDate);
                    updatedAttributes[existingAttributeIndex] = {
                        ...updatedAttributes[existingAttributeIndex],
                        data: updatedData
                    };
                } else {
                    updatedAttributes.push({
                        key: choice.name,
                        data: [{ value: newValueWithDate, cycle: 1 }],
                    });
                }
            } else if (existingAttributeIndex === -1) {
                updatedAttributes.push({
                    key: choice.name,
                    data: [{ value: '', cycle: 1 }],
                });
            }
        });
        const detail = {
            lead_treatment_attributes: updatedAttributes
        };
        dispatch(updateTreatmentDetail(practiceId, rowData?.lead_id, rowData?.treatmentData?.[0]?.id, detail, handleSuccess, 'choice_cell'));
        setDateValue(newValue);
        setOpen(false);
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        if (choiceValue?.name?.toLowerCase().includes('approval status')) {
            const reloadPayLoad = {
                limit: perPage,
                offset: pageNumber,
                filterStatus: status,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTagsValue,
                referral,
                category,
                bookedWith
            };
            updateLeadPage(reloadPayLoad);
            reloadCounter(reloadPayLoad);
        }
        dispatch(followUpSlice(rowData?.lead_id, practiceId, 'LeadDetail', handleClose));
    };

    const handleClose = (data) => handleSetTableData(data);

    const getBackgroundColor = (selectedValue) => {
        const selectedItem = choiceValue?.items.find(item => item.value === selectedValue);
        return selectedItem ? selectedItem.colour : 'none';
    };

    const getColor = (value) => {
        switch (value) {
            case 'Yes':
                return 'rgb(6, 94, 73) !important';
            case 'No':
                return 'rgb(39, 9, 122) !important';
            default:
                return 'none';
        }
    };

    return (<>
        {rowData?.treatmentData?.[0]?.id && <Box sx={{ ...tableCellInnerBoxStyle, bgcolor: getBackgroundColor(value) }}>
            {choiceValue?.name?.toLowerCase().includes('date') ?
                <Box>
                    <DatePicker
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        label='Date'
                        value={dateValue}
                        format={'dd/MM/yyyy'}
                        onChange={onChangeDate}
                        sx={{ '.MuiInputBase-root': { paddingRight: 0 } }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {dateValue && (
                                                <Button onClick={() => onChangeDate(null)} sx={{ minWidth: 'auto', p: 0 }}>
                                                    <Iconify height={18} width={18} icon='ci:close-big' />
                                                </Button>
                                            )}
                                            <IconButton onClick={() => setOpen(true)}>
                                                <Iconify height={24} width={24} icon='solar:calendar-mark-bold-duotone' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            },
                        }}
                    />
                </Box>
                :
                <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label' sx={{ color: getColor(value) }}>Select</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        value={value}
                        onChange={handleChange}
                        sx={{ width: '100%', color: getColor(value) }}
                        label={'Select'}
                    >
                        <MenuItem value=''>None</MenuItem>
                        {choiceValue?.items?.map((el, index) => (
                            <MenuItem value={el.value} key={index}>{el.value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </Box>}
    </>);
};

ChoicesField.propTypes = {
    rowData: PropTypes.array,
    tableType: PropTypes.string,
    handleSetTableData: PropTypes.func,
    choiceValue: PropTypes.string,
    updateLeadPage: PropTypes.func,
    reloadCounter: PropTypes.func,
    choicesData: PropTypes.array,
};

export default ChoicesField;